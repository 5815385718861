import Icon from 'src/components/Icon/Icon.component';
import { SelectFieldUIStates } from 'src/components/Select/Select.config';
import { selectFieldStateReducer } from 'src/components/Select/Select.helpers';
import { SelectProps } from 'src/components/Select/Select.types';
import { ContainerMessageSelect } from 'src/components/Select/style/Select.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  FlagIcon,
  MessageErrorSelect,
  SelectRootWrapper,
  StyledNativeSelect,
} from './style/NativeSelect.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const NativeSelect = React.memo(function Select({
  onChange,
  options,
  i18n = false,
  i18nLabel = false,
  value,
  id,
  name,
  isPresentErrorMessage,
  disabled,
}: SelectProps) {
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const {
    field: { ...rest },
  } = useController({
    name,
    control,
  });
  const error = (errors && errors[name]?.message) || errors[name];
  const touched = touchedFields[name];
  const selectedOptionIndex = React.useMemo(() => {
    return options.findIndex(
      ({ optionId }: ListboxOption) => String(optionId) === String(value),
    );
  }, [options, value]);

  const { messageProps, selectFieldState } = selectFieldStateReducer({
    error,
    touched,
  });
  const isError = selectFieldState === SelectFieldUIStates.error;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <SelectRootWrapper data-component="native-select" disabled={disabled}>
        {options[selectedOptionIndex]?.iconSrc && (
          <FlagIcon
            iconSrc={options[selectedOptionIndex].iconSrc}
            iconSize="1.6rem"
          />
        )}
        <StyledNativeSelect
          {...rest}
          disabled={disabled}
          id={id}
          isError={isError}
        >
          <option style={{ display: 'none' }} />
          {options &&
            options.map((item, index) => (
              <option value={item.optionId} key={index}>
                {i18nLabel ? <Translate id={item.label} /> : item.label}
              </option>
            ))}
        </StyledNativeSelect>
        <Icon svgIconComponent={ArrowDown} iconSize="2rem" />
      </SelectRootWrapper>
      <ContainerMessageSelect
        data-component="native-select"
        isPresentMessage={isPresentErrorMessage}
      >
        <MessageErrorSelect isError={isError}>
          {messageProps && <Translate id={messageProps} />}
        </MessageErrorSelect>
      </ContainerMessageSelect>
    </StyleSheetManager>
  );
});

export default NativeSelect;

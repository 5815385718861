import React, { useMemo } from 'react';
import DataTable from 'src/components/DataTable/DataTable';
import { DataTableProps } from 'src/components/DataTable/DataTable.types';
import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import { RequestsResultsTableColumnConfig } from './RequestsResultsTable.config';

const RequestsResultsTable: React.FC<{
  data?: any;
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  const RequestsResultsTable: DataTableProps<ListRequestsResultsTable> =
    useMemo(
      () => ({
        data: data?.data?.ResultSet?.AdvanceRequests ?? [],
        hideHeader: false,
        isLoadingData: isLoading,
        columns: RequestsResultsTableColumnConfig,
        customTableTheme: {
          cells: {
            style: {
              '&:first-child': {
                background: '#fff',
                '&::before': {
                  display: 'none',
                },
              },
            },
          },
        },
      }),
      [data],
    );

  return (
    <>
      <DataTable {...RequestsResultsTable} />
    </>
  );
};

export default RequestsResultsTable;

import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const IconCheck = styled(Icon)`
  position: absolute;
  right: 2.5rem;
  top: 1.5rem;
`;

export const FlexWrapper = styled(Flex)<{ length: number }>`
  padding: 1.6rem 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
  &:not(:first-child)::before {
    ${({ length }) => (length > 0 ? `content: ''` : '')};
    position: absolute;
    height: 100%;
    bottom: 50%;
    left: 1.9rem;
    z-index: 1;
    border-left: 0.2rem solid black;
    border-color: ${colors.lightGreyBorder};
    ${media.tablet`
    left: 2.7rem;
`}
  }
  > *:not(:first-child) {
    flex-basis: 33.33%;
  }
`;

export const FlexWrapperList = styled(Flex)<{ background?: boolean }>`
  border-radius: 8px;
  ${({ background }) =>
    background ? `background: ${colors.backgroundGrey}` : ''};
  padding: 8px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const CircularProgressSmall = styled(Flex)<{
  outlineColor?: string;
  colorGrey?: boolean;
}>`
  color: ${({ colorGrey }) =>
    colorGrey ? colors.textColorGrey : colors.textColorBlack};
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  outline: 0.5rem solid ${({ outlineColor }) => outlineColor};
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #fff;
  position: relative;
  z-index: 2;
  margin: 0.6rem 1.4rem 0.6rem 0.6rem;
  ${media.tablet`
    height: 4rem;
    width: 4rem;
    font-size: 1.8rem;
  `}
`;

export const CircularProgress = styled(Flex)<{
  outlineColor?: string;
  colorGrey?: boolean;
}>`
  color: ${({ colorGrey }) =>
    colorGrey ? colors.textColorGrey : colors.textColorBlack};
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  outline: 0.5rem solid ${({ outlineColor }) => outlineColor};
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #fff;
  position: relative;
  z-index: 2;
  ${media.tablet`
    height: 5.6rem;
    width: 5.6rem;
    font-size: 2.4rem;
  `}
`;

import Loader from 'src/components/Loader/Loader.component';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { RoutePath } from 'src/routers/routers.config';
import { getStripeLoginLink } from 'src/services/stripe/stripe.config';
import { getStripeLoginLinkMethodApi } from 'src/services/stripe/stripe.request';
import { useProfileStore } from 'src/store/store';

export interface RedirectStripeProps {}

const RedirectLoginStripe: React.FC<RedirectStripeProps> = () => {
  const setUser = useProfileStore((state) => state.setUser);

  const { isSuccess, isError, data } = useQuery({
    queryKey: [getStripeLoginLink],
    queryFn: getStripeLoginLinkMethodApi,
  });

  useEffect(() => {
    if (isSuccess) {
      setUser({ stripeLoginLink: data.data.ResultSet });
      if (data.data.ResultSet.length > 1) {
        (window as any).open(data.data.ResultSet, '_self');
      }
    }
    if (isError) (window as any).open(RoutePath.oops, '_self');
  }, [isSuccess, isError]);

  return <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />;
};

export default RedirectLoginStripe;

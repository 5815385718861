import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { advOutletContext } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import {
  ContainerBar,
  ContainerInfo,
  ResponsiveFunnelWrapper,
} from 'src/pages/AdvPaymentFlow/style/AdvPaymentFlow.style';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getUWADV } from 'src/services/UWBuyer/UWBuyer.config';
import { getUWADVMethodApi } from 'src/services/UWBuyer/UWBuyer.request';
import {
  createAdvanceRequestMethodApi,
  performAdvanceRequestMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { H4, P, PSmall } from 'src/style-utils/typographic';
import { ADVResultKey } from '../AdvPayResultPage/AdvPayResultPage.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { PerformAdvanceRequestData } from 'src/services/orderLink/orderLink.types';
import { UWADVBodyContainer } from './LinkDetail.style';
import useBeforeUnload from '../../../../utils/hooks/useBeforeUnload';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function LinkDetail() {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const navigate = useNavigate();
  const [polling, setPolling] = useState(false);
  const { selectedLinks, setSelectedLinks } =
    useOutletContext<advOutletContext>();
  const {
    isLoading: isLoadingUW,
    data: dataUW,
    isSuccess,
  } = useQuery({
    queryKey: [getUWADV],
    queryFn: () =>
      getUWADVMethodApi({
        groupGuid: data?.data.ResultSet,
      }),
    refetchInterval: 2000,
    enabled: polling,
  });
  useBeforeUnload(polling);

  useEffect(() => {
    if (isSuccess) {
      const arrayResult = dataUW.data.ResultSet;
      const UWFinished = dataUW.data.ResultSet.every(
        (item) => item.Stato === 1,
      );
      if (UWFinished) {
        setPolling(false);
        switch (true) {
          case arrayResult.every((item) => item.Esito === 'OK'):
            mutatePerform({ groupGuid: data?.data.ResultSet });
            break;
          case arrayResult.every((item) => item.Esito !== 'OK'):
            navigate(RoutePath.AdvPayResultPage, {
              state: {
                requestResult: ADVResultKey.requestError,
              },
            });
            break;
          default:
            navigate(
              RoutePath.advancePaymentRequest + RoutePath.requestsResults,
              {
                state: {
                  groupGuid: data?.data.ResultSet,
                },
              },
            );
            break;
        }
      }
    }
  }, [isSuccess, dataUW]);

  const { mutate, isPending, data } = useMutation({
    mutationFn: (values: any) => createAdvanceRequestMethodApi(values),
    onSuccess: () => {
      setPolling(true);
    },
  });

  const { mutate: mutatePerform, isPending: isLoadingPerform } = useMutation({
    mutationFn: (values: PerformAdvanceRequestData) =>
      performAdvanceRequestMethodApi(values),
    onSuccess: (res) => {
      navigate(RoutePath.AdvPayResultPage, {
        state: {
          amountPerformed: res?.data.ResultSet.AmountPerformed,
          ordersPerformed: res?.data.ResultSet.OrdersPerformed,
          requestResult: ADVResultKey.transferDone,
        },
      });
    },
    onError: () => {
      navigate(RoutePath.ThankyouPageError + RoutePath.oops);
    },
  });

  const onSubmit = () => {
    mutate(selectedLinks);
  };

  if (!selectedLinks || selectedLinks?.length === 0) {
    return (
      <Navigate to={RoutePath.advancePaymentRequest + RoutePath.chooseLink} />
    );
  }

  if (isPending || isLoadingPerform)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  if (isLoadingUW || polling) {
    return (
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Loader overlayViewMode="fullscreen" active viewMode="fluid">
          <UWADVBodyContainer>
            {selectedLinks?.length > 1 && (
              <>
                <PSmall>
                  <Translate
                    id="text.requestsAnalyzed"
                    data={{
                      min: dataUW?.data.ResultSet.filter(
                        (item: any) => item.Stato === 1,
                      ).length,
                      max: selectedLinks?.length,
                    }}
                  />
                </PSmall>
                <ContainerBar>
                  <div
                    style={{
                      width: `${
                        (dataUW?.data?.ResultSet.filter(
                          (item: any) => item?.Stato === 1,
                        )?.length ?? 1) *
                        (100 / selectedLinks.length)
                      }%`,
                    }}
                  />
                </ContainerBar>
              </>
            )}
            <H4 marginTop="4rem">
              <Translate id="text.loadingUW" />
            </H4>
          </UWADVBodyContainer>
        </Loader>
      </StyleSheetManager>
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={1}
        stepMax={2}
        title="lbl.resumeData"
        modalText="text.wannaLeaveAdv"
        leaveModal={() => {
          setSelectedLinks([]);
          navigate(RoutePath.advancePaymentRequest + RoutePath.chooseLink);
        }}
      />
      {
        <ResponsiveFunnelWrapper>
          <ContainerInfo>
            <P>
              <Translate id="lbl.totalLinks" />
            </P>
            <P colorBlack>{selectedLinks.length}</P>
            <hr />
            <P>
              <Translate id="text.total" />
            </P>
            <P colorBlack>
              {formatCurrency(
                selectedLinks?.reduce(
                  (acc, currentItem) =>
                    (acc ?? 0) + (currentItem?.GivableAmount ?? 0),
                  0,
                ),
              )}
            </P>
            <hr />
            <P>
              <Translate id="lbl.formula" />
            </P>
            <P colorBlack>
              <Translate id="text.proSoluto" />
            </P>
            <hr />
            <P>
              <Translate id="lbl.revenueHistory" />
            </P>
            {selectedLinks
              .filter((item) => item.HasAlreadyGave)
              .map((link) => (
                <P colorBlack>
                  <Translate
                    id={'text.hasAlreadyGave'}
                    data={{
                      amountAlreadyGave: link.AmountAlreadyGave,
                      OrderId: link.OrderId
                        ? link.OrderId.toString().padStart(5, '0')
                        : '',
                    }}
                  />
                </P>
              ))}
            {selectedLinks.filter((item) => item.HasAlreadyGave).length ===
              0 && (
              <P colorBlack>
                <Translate id={'text.notHasAlreadyGave'} />
              </P>
            )}
          </ContainerInfo>
          <ButtonContainer>
            <Button variant="Primary" onClick={onSubmit}>
              <Translate id="lbl.next" />
            </Button>
          </ButtonContainer>
        </ResponsiveFunnelWrapper>
      }
    </StyleSheetManager>
  );
}

export default LinkDetail;

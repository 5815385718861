import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import { ReactComponent as MailIcon } from 'src/images/icons/email.svg';
import {
  PayByLinkDetailsContext,
  resendLimit,
} from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import { FormProvider, useForm } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useNavigate, useOutletContext } from 'react-router';
import { RoutePath } from 'src/routers/routers.config';
import { recipient } from 'src/services/orderLink/orderLink.config';
import { sendLinkToBuyerMethodApi } from 'src/services/orderLink/orderLink.request';
import { SendLinkToBuyerData } from 'src/services/orderLink/orderLink.types';
import { P, PSmall } from 'src/style-utils/typographic';
import { ContainerPadding } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { formResendEmailPayByLink } from './PayByLinkActionsResendLink.helpers';
import { LastSentContainer } from './PayByLinkActionsResendLink.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function PayByLinkActionsResendLink() {
  const { initialValues, resolver } = formResendEmailPayByLink();
  const navigate = useNavigate();
  const { OrderId, refetchHandler, InviteEmailsSent, pathDetail } =
    useOutletContext<PayByLinkDetailsContext>();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (values: SendLinkToBuyerData) =>
      sendLinkToBuyerMethodApi(values),
    onSuccess: () => {
      navigate(`${pathDetail}${OrderId}/${RoutePath.actionsSuccessResend}`, {
        replace: true,
      });
      refetchHandler();
    },
    onError: () => navigate('/error-page'),
  });

  if (InviteEmailsSent.length > resendLimit) {
    return <Navigate to={`${pathDetail}${OrderId}/${RoutePath.info}`} />;
  }
  const onSubmit = async (data: any) => {
    data.orderId = OrderId;
    mutate(data);
    return;
  };

  const lastSentDate = InviteEmailsSent.at(-1)?.SentDate ?? '';

  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        title="lbl.resendLinkToCustomer"
        idLink={OrderId}
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <P colorBlack marginBottom="1.6rem">
          <Translate id="lbl.clientEmailAddress" />
        </P>
        <FormProvider {...methods}>
          <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField isPresentMessage name={recipient} />
            {InviteEmailsSent?.length > 0 && (
              <LastSentContainer align="center" gap="0.8rem" padding="0.8rem">
                <MailIcon width="4rem" />
                <div>
                  <PSmall bold colorBlack>
                    <Translate id="lbl.lastSentEmail" />
                  </PSmall>
                  <PSmall>
                    <Translate id="lbl.lastSentMailAddress" />
                    :&nbsp;{InviteEmailsSent.at(-1)?.Recipient}
                  </PSmall>
                  <PSmall>
                    <Translate id="lbl.lastSentMailDate" />
                    :&nbsp;{refactorFormatDate(lastSentDate, 'DD MMMM YYYY')}
                  </PSmall>
                  <PSmall>
                    <Translate id="lbl.lastSentMailHour" />
                    :&nbsp;{refactorFormatDate(lastSentDate, 'HH:MM')}
                  </PSmall>
                </div>
              </LastSentContainer>
            )}
            <ButtonContainer>
              <SubmitButton minWidth="100%">
                <Translate id="lbl.resendLink" />
              </SubmitButton>
              <PSmall textAlign="center">
                <Translate id="text.canOnlyDoOnce" />
              </PSmall>
            </ButtonContainer>
          </FormStyle>
        </FormProvider>
      </ContainerPadding>
    </StyleSheetManager>
  );
}

export default PayByLinkActionsResendLink;

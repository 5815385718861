import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ContainerInfo } from 'src/pages/registration/registrationGuest/style/registrationGuest.style';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import {
  InitSignUpData,
  initSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer, ResponsiveContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { CompanyGridContainer } from './registrationChooseCompany.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RegistrationChooseCompany() {
  const { setValue, results, companySearchId } = useRegistrationStore(
    (state) => state,
  );
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { mutate, isPending } = useMutation({
    mutationFn: initSignUpSessionMethodApi,
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      navigate(RoutePath.registrationMerchant + RoutePath.emailStep);
    },
    onError: () => {
      setValue('sessionGuid', '');
      navigate(RoutePath.oops);
    },
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={3}
        stepMax={9}
        title="lbl.dataConfirmation"
      />
      <FlowContainer>
        <H4>
          <Translate id="lbl.selectYourCompany" />
        </H4>
      </FlowContainer>
      <ResponsiveContainer>
        <CompanyGridContainer>
          {results.map(
            (item, index) =>
              index < 4 &&
              index > 0 && (
                <ContainerInfo key={index}>
                  {item.Name && (
                    <div>
                      <P>
                        <Translate id="lbl.payByLinkFlowBusinessName" />
                      </P>
                      <P bold colorBlack>
                        {item.Name}
                      </P>
                    </div>
                  )}
                  {item.VatCode && (
                    <div>
                      <P>
                        <Translate id={'text.vatNumber'} />
                      </P>
                      <P bold colorBlack>
                        {item.VatCode}
                      </P>
                    </div>
                  )}
                  {item.PostalCode && (
                    <div>
                      <P>
                        <Translate id="lbl.postalCode" />
                      </P>
                      <P bold colorBlack>
                        {item.PostalCode}
                      </P>
                    </div>
                  )}
                  {item.Address && (
                    <div>
                      <P>
                        <Translate id="text.address" />
                      </P>
                      <P bold colorBlack>
                        {item.Address}
                      </P>
                    </div>
                  )}
                  <Button
                    variant="Primary"
                    minWidth="100%"
                    sizeOnDesktop="small"
                    margin="auto 0 0 0"
                    i18n={true}
                    translatedText="lbl.thisIsMyCompany"
                    onClick={() => {
                      const initSignUpData: InitSignUpData = {
                        SearchId: companySearchId,
                        AccountType: 'MERCHANT',
                        Language: language,
                        RegistrationType: 'full',
                      };
                      mutate(initSignUpData);
                    }}
                    id={IDs.btnDecline}
                  />
                </ContainerInfo>
              ),
          )}
        </CompanyGridContainer>
      </ResponsiveContainer>
      <ButtonContainer marginBottom="2rem">
        <Button
          variant="Tertiary"
          minWidth="100%"
          i18n={true}
          translatedText="lbl.notFoundMyCompany"
          onClick={() => navigate(-1)}
          id={IDs.btnDecline}
        />
      </ButtonContainer>
    </StyleSheetManager>
  );
}

export default RegistrationChooseCompany;

import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { usePayByLinkStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer, Grid } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import {
  calculateInstallment,
  createArrayCustomInstallment,
} from 'src/utils/functions/createArrayCustomInstallment';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StepInstallmentAmount() {
  const navigate = useNavigate();
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { productCode, categoryCode } = useParams();
  const amount = usePayByLinkStore((state) => state.amount);
  const remodulation = usePayByLinkStore((state) => state.remodulation);
  const { setValue, customInstallments: oldCustomInstallments } =
    usePayByLinkStore((state) => state);
  const location = useLocation();
  const NumRate = location?.state?.totalInstallment;
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const { InstallmentAmount } = calculateInstallment(amount, NumRate);
  const setAllInstallment = () => {
    const customInstallments = {
      Code: 'BNPL-CUSTOM-RATE',
      FlagCessioneCredito: 0,
      NumRate,
      InstallmentAmount,
      Installments: createArrayCustomInstallment(amount, NumRate, remodulation),
      CanGiveCreditAtCreation: oldCustomInstallments?.CanGiveCreditAtCreation,
      UwMandatory: oldCustomInstallments?.UwMandatory,
    };
    setValue('customInstallments', customInstallments);
    navigate(
      remodulation
        ? `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepResume}`
        : `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepDescription}`,
    );
  };
  const editAmountAndDate = () => {
    setValue('customInstallments', {
      CanGiveCreditAtCreation: oldCustomInstallments?.CanGiveCreditAtCreation,
      UwMandatory: oldCustomInstallments?.UwMandatory,
    });
    navigate(urlRedirectToFlow, { state: { NumRate } });
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.amountInstallments"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.howMuchInstallmentsAmount" />
        </H4>
        <Grid gap="1.6rem" className="content">
          <PayByLinkChoiceCard
            labelPblFlex
            title="text.allInstallmentsSameAmount"
            onClick={setAllInstallment}
            titleData={formatCurrency(InstallmentAmount)}
            btnId={IDs.btnFlxEq}
          />
          <PayByLinkChoiceCard
            title="text.editAmountAndDate"
            onClick={editAmountAndDate}
            btnId={IDs.btnFlxCustom}
          />
        </Grid>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepInstallmentAmount;

import SupportCards from 'src/components/SupportCards/SupportCards.component';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';
import { MaxWidthContainer } from 'src/style/Container.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const Support: React.FC = () => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <MaxWidthContainer>
        <H4 marginBottom="2.4rem" marginTop="5.6rem">
          <Translate id="text.support" />
        </H4>
        <SupportCards />
      </MaxWidthContainer>
    </StyleSheetManager>
  );
};

export default Support;

import colors from 'src/style-utils/colors';
import { Card } from 'src/style/Container.style';
import styled from 'styled-components';

export const CardContainer = styled(Card)`
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 1.2rem;
  z-index: 0;
  &::after {
    content: '';
    border-radius: 10rem;
    position: absolute;
    right: -4.6rem;
    width: 11.3rem;
    height: 11.3rem;
    background: ${colors.bgButtonLink};
    z-index: -1;
  }
`;

import { cva } from 'class-variance-authority';
import Tooltip, {
  TooltipPosition,
} from 'src/components/Tooltip/Tooltip.component';
import Translate from 'src/components/Translate/Translate.component';
import { cn } from 'src/shad/lib/utils';
import { H4, P, PSmall } from 'src/style-utils/typographic';
import { InstallmentsProcessingLbl } from 'src/pages/monitoring/Monitoring.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const statsCardVariants = cva(
  'flex grow flex-col gap-1 rounded-lg border border-pblack-100 px-4 py-4 text-pgreen-500',
  {
    variants: {
      variant: {
        default: 'bg-gradient-to-br from-bgreen to-white',
        plain: 'bg-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type Props = {
  label: string;
  content?: number | string;
  contentColor?: string;
  variant?: 'default' | 'plain';
  contentText?: string;
  tooltipText?: string;
  tooltipPosition?: TooltipPosition;
  i18n?: boolean;
  className?: string;
  contentData?: {};
  installmentsProcessing?: number;
  onLblClick?: () => void;
};

const StatsCard: React.FC<Props> = ({
  label,
  content,
  contentColor = 'inherit',
  tooltipText,
  tooltipPosition,
  i18n,
  contentData,
  contentText,
  className,
  variant,
  installmentsProcessing,
  onLblClick,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="status-card"
        className={cn(statsCardVariants({ variant, className }))}
      >
        <div className="flex items-center justify-between">
          <PSmall>
            <Translate id={label} />
          </PSmall>
          {tooltipText && (
            <Tooltip
              tooltipText={tooltipText}
              tooltipPosition={tooltipPosition}
            />
          )}
        </div>
        {i18n ? (
          <P bold textColor={contentColor}>
            <Translate id={contentText} data={contentData} />
          </P>
        ) : (
          <H4 textColor={contentColor}>{content}</H4>
        )}
        {installmentsProcessing ? (
          installmentsProcessing > 0 ? (
            <InstallmentsProcessingLbl onClick={onLblClick}>
              <Translate
                id="lbl.PBMonitoringLbl"
                data={{
                  installmentsProcessing: installmentsProcessing,
                }}
              />
            </InstallmentsProcessingLbl>
          ) : null
        ) : null}
      </div>
    </StyleSheetManager>
  );
};

export default StatsCard;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CopyLink } from 'src/images/icons/copy-link.svg';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { emailField } from 'src/services/registration/registration.config';
import { FormStyle } from 'src/style/styleInput.style';
import { writeToClipboard } from 'src/utils/functions/clipboard';
import {
  IconContainer,
  InputDisabledContainer,
  PastedLabel,
} from './showEmail.style';
import { FlowContainer } from 'src/style/Container.style';
import { H4 } from 'src/style-utils/typographic';
import InputField from 'src/components/InputField/InputField.component';
import { RoutePath } from 'src/routers/routers.config';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ShowEmail() {
  const { activeOrder } = useOutletContext<RecoveryDataContext>();
  const location = useLocation();
  const email = location?.state?.value;
  const navigate = useNavigate();
  const [tooltip, setTooltip] = useState(false);

  const methods = useForm({
    defaultValues: {
      [emailField]: email,
    },
    mode: 'onChange',
  });
  setTimeout(() => {
    setTooltip(false);
  }, 1000);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowContainer>
        <H4>
          <Translate id="text.accessEmail" />
        </H4>
        <FormProvider {...methods}>
          <FormStyle>
            <InputDisabledContainer>
              <InputField
                name={emailField}
                label="lbl.payByLinkEmailAddress"
                disabled
              />
              <IconContainer>
                <Icon
                  style={{ cursor: 'pointer' }}
                  iconSize={'1.8rem'}
                  svgIconComponent={CopyLink}
                  onClick={() => {
                    setTooltip(true);
                    writeToClipboard(email);
                  }}
                />
                <PastedLabel className={tooltip ? 'show' : ''}>
                  <Translate id="lbl.pasted" />
                </PastedLabel>
              </IconContainer>
            </InputDisabledContainer>
            <ButtonContainer>
              <Button
                type="button"
                minWidth="100%"
                onClick={() =>
                  navigate(RoutePath.login, { state: { activeOrder } })
                }
              >
                <Translate id="lbl.login" />
              </Button>
            </ButtonContainer>
          </FormStyle>
        </FormProvider>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default ShowEmail;

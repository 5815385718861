import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { JSX, memo, useState } from 'react';
import { useCurrentTransactionStore } from 'src/store/store';
import { H5, P } from 'src/style-utils/typographic';
import { AccordionPageArray, idViewSepa } from './Accordion.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const Accordion = memo(function Accordion() {
  const canPayWithSDD = useCurrentTransactionStore(
    (state) => state.canPayWithSDD,
  );

  const [selected, setSelected] = useState(0);
  function clickHandler(index: number) {
    setSelected(selected !== index ? index : -1);
  }

  const filteredList = AccordionPageArray.filter(
    ({ id }) => id !== (canPayWithSDD ? null : idViewSepa),
  );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div data-component="accordion" className="grid gap-4 lg:grid-cols-2">
        {filteredList.map(
          (item, index: number): JSX.Element => (
            <div
              key={index}
              className="group h-full rounded-xl bg-white p-4 shadow-md lg:p-6"
              aria-expanded={selected === index}
              onClick={() => clickHandler(index)}
            >
              <div className="flex items-center justify-between text-pblack-400">
                <H5>
                  <Translate id={item.title} />
                </H5>
                <ArrowDown className="h-10 w-10 transition-transform group-aria-expanded:rotate-180 lg:hidden" />
              </div>
              <div className="grid grid-rows-[0fr] transition-[grid-template-rows] duration-200 group-aria-expanded:grid-rows-[1fr] lg:grid-rows-[1fr]">
                <div style={{ overflow: 'hidden' }}>
                  <P marginTop="1.4rem" marginBottom="0">
                    <Translate id={item.content} />
                  </P>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </StyleSheetManager>
  );
});

export default Accordion;

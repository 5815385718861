import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';

import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { Card, FlowContainer } from 'src/style/Container.style';
import styled, { StyleSheetManager } from 'styled-components';
import { AlertComponent } from '../../../../components/ORION/Alert/Alert.component';
import { shouldForwardProp } from 'src/style-utils/functions';

export const ContainerInfo = styled.div`
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid ${colors.backgroundOutlined};
  :nth-last-of-type(-n + 1) {
    border-bottom: none;
  }
`;

function StepCreditResume() {
  const { productCode, categoryCode } = useParams();
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const amountGivable = usePayByLinkStore((state) => state.amountGivable);
  const navigate = useNavigate();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader stepBack step={11} stepMax={13} title="lbl.resumeData" />
      <FlowContainer>
        <H4>
          <Translate id="text.resumeCreditDetail" />
        </H4>
        <Card className="container">
          <AlertComponent
            variant="informative"
            titleLbl="stepCreditResume.alert.title"
            descriptionLbl="stepCreditResume.alert.content"
          />
          <ContainerInfo>
            <P>
              <Translate id="text.total" />
            </P>
            <P colorBlack>{formatCurrency(amountGivable)}</P>
          </ContainerInfo>
          <ContainerInfo>
            <P>
              <Translate id="lbl.formula" />
            </P>
            <P colorBlack>Pro soluto</P>
          </ContainerInfo>
        </Card>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            onClick={() =>
              navigate(
                `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepInvoiceDate}`,
              )
            }
          >
            <Translate id="lbl.next" />
          </Button>
        </ButtonContainer>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepCreditResume;

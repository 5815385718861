import { MoveDetailCheckArray } from 'src/components/MoveDetailCheckbox/MoveDetailCheckbox.config';
import { DataFormBalanceMovements } from 'src/pages/balanceMovementsPage/BalanceMovementsPage.types';
import {
  flagCessioneCredito,
  flagNonCessioneCredito,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { useState } from 'react';
import { FilteredContainer } from './style/FilteredList.style';
import FilteredButton from './partial/FilteredButton.component';
import FilteredButtonBoolean from './partial/FilteredButtonBooelan.component';
import { Buyer } from 'src/services/orderLink/orderLink.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type ClientNamesType = { name: string; id: number };
type TypesArray = { type: string; name: string };

type FilteredListProps = {
  clients?: Buyer[];
  dataForm: DataFormBalanceMovements;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
};

const FilteredList = ({
  clients,
  dataForm,
  handleClick,
  setDataFilter,
}: FilteredListProps) => {
  const clientNames: ClientNamesType[] = [];
  const typesArray: TypesArray[] = [];
  const booleanArray: {
    label: string;
    fieldName: typeof flagCessioneCredito | typeof flagNonCessioneCredito;
  }[] = [];

  if (dataForm) {
    dataForm.buyers.forEach((buyerId) => {
      const client = clients?.find(({ Id }) => Id.toString() === buyerId);
      clientNames.push({ name: client!.Name, id: client!.Id });
    });
    dataForm.types.forEach((typeId) => {
      const checkDetail = MoveDetailCheckArray.find(
        ({ name }) => typeId === name,
      );
      typesArray.push({ name: checkDetail!.title, type: typeId });
    });

    if (dataForm.flagCessioneCredito) {
      booleanArray.push({
        label: 'lbl.withAdvancePaymentTitle',
        fieldName: flagCessioneCredito,
      });
    }
    dataForm.flagNonCessioneCredito &&
      booleanArray.push({
        label: 'lbl.withoutAdvancePaymentTitle',
        fieldName: flagNonCessioneCredito,
      });
  }

  //Carousel behaviour
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX;
    if (walk > 20 || walk < -20) {
      if (!isScrolling) setIsScrolling(true);
    }
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const handleLeave = () => {
    setIsDragging(false);
    setIsScrolling(false);
  };

  if (
    clientNames.length === 0 &&
    typesArray.length === 0 &&
    booleanArray.length === 0 &&
    dataForm.fromDate === undefined &&
    dataForm.toDate === undefined
  )
    return <></>;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FilteredContainer
        data-component="filtered-list"
        margin="0 -2.4rem"
        padding="2.4rem"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleLeave}
        onMouseLeave={handleLeave}
      >
        {(dataForm.fromDate || dataForm.toDate) && (
          <FilteredButton
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            fromDate={dataForm.fromDate}
            toDate={dataForm.toDate}
          />
        )}
        {typesArray.map(({ name, type }) => (
          <FilteredButton
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={type}
            filteredName={name}
            type={type}
          />
        ))}
        {clientNames.map(({ name, id }) => (
          <FilteredButton
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={id}
            filteredName={name}
            buyerId={id}
          />
        ))}
        {booleanArray.map(({ label, fieldName }, i) => (
          <FilteredButtonBoolean
            disabled={isScrolling}
            label={label}
            fieldName={fieldName}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={i}
          />
        ))}
      </FilteredContainer>
    </StyleSheetManager>
  );
};

export default FilteredList;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as error_icon } from 'src/images/pageStatus/uw-buyer-error.svg';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSession } from 'src/services/session/session.context';
import { H4 } from 'src/style-utils/typographic';
import { ErrorPagePar } from '../style/failedPage.style';
import { useProfileStore } from 'src/store/store';
import { RoutePath } from 'src/routers/routers.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const UWBuyerError: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const plugin = location?.state?.plugin;
  const AccountType = useProfileStore((state) => state.AccountType);
  const { onLogout } = useSession();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={error_icon}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id={plugin ? 'lbl.cantUseOPL' : 'lbl.UWBuyerError'} />
      </H4>
      <ErrorPagePar>
        <Translate id={plugin ? 'text.cantUseOPL' : 'text.UWBuyerError'} />
      </ErrorPagePar>
      <ButtonContainer>
        <Button
          minWidth="32.7rem"
          i18n
          onClick={() => {
            if (plugin) {
              (window as any).parent.postMessage(
                JSON.stringify({ status: 'close' }),
                '*',
              );
              onLogout();
            } else
              navigate(`${RoutePath.privateArea}?typeAccount=${AccountType}`);
          }}
        >
          <Translate id={plugin ? 'lbl.backToEcommerce' : 'lbl.close'} />
        </Button>
        <NeedHelpButton />
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default UWBuyerError;

import React from 'react';
import ListboxButton from './partials/ListboxButton.component';
import { ListboxProps } from 'src/components/Select/Select.types';
import { renderListItemId } from 'src/components/Select/Select.helpers';
import {
  ListboxRoot,
  DropdownListItem,
  DropdownList,
  HiddenPlaceholder,
  DropdownListRoot,
} from './style/Listbox.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

// Based on https://www.w3.org/TR/wai-aria-practices-1.1/#Listbox
const Listbox: React.FC<ListboxProps> = React.memo(function Listbox({
  id,
  options,
  labelledBy,
  describedBy,
  className,
  isExpanded,
  optionClickHandlerByOptionId,
  labelsByOptionId,
  iconSrcByOptionId,
  onButtonClick,
  value,
  selectedOptionIndex,
  onBlur,
  onKeyDown,
  iconComponent: IconComponent,
  isError,
}) {
  const selectedItemRef = React.useRef<HTMLLIElement>(null);
  let longestLocalizedLabel = '';
  const renderedList = options.map(
    ({ optionId, iconSrc }: ListboxOption, index: number) => {
      const selected = index === selectedOptionIndex;
      const localizedLabel = labelsByOptionId[optionId];

      if (longestLocalizedLabel.length < localizedLabel.length) {
        longestLocalizedLabel = localizedLabel;
      }

      const listItemId = renderListItemId(id, index);

      return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <DropdownListItem
            ref={selected ? selectedItemRef : null}
            aria-selected={selected}
            key={listItemId}
            role="option"
            id={listItemId}
            onClick={optionClickHandlerByOptionId[optionId]}
          >
            {iconSrc && <IconComponent iconSrc={iconSrc} />}
            <span>{localizedLabel}</span>
          </DropdownListItem>
        </StyleSheetManager>
      );
    },
  );

  const activeDescendant = renderListItemId(id, selectedOptionIndex);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ListboxRoot
        id={id}
        className={className}
        tabIndex={0}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={onButtonClick}
        aria-expanded={isExpanded}
        aria-haspopup="listbox"
        isError={isError}
      >
        <ListboxButton
          data-active-option-index={selectedOptionIndex}
          iconSrc={iconSrcByOptionId?.[value]}
          iconComponent={IconComponent}
          label={labelsByOptionId[value]}
        />
        <HiddenPlaceholder aria-hidden="true">
          {iconSrcByOptionId?.[value] && (
            <IconComponent iconSrc={iconSrcByOptionId?.[value]} />
          )}
          <span>{longestLocalizedLabel}</span>
        </HiddenPlaceholder>
        <DropdownListRoot isExpanded={isExpanded}>
          <DropdownList
            role="listbox"
            aria-describedby={describedBy}
            aria-labelledby={labelledBy}
            aria-activedescendant={activeDescendant}
            aria-expanded={isExpanded}
          >
            {renderedList}
          </DropdownList>
        </DropdownListRoot>
      </ListboxRoot>
    </StyleSheetManager>
  );
});

export default Listbox;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
// import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
// import { useProfileStore } from 'src/store/store';
import { MethodsContextType } from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import { H4 } from 'src/style-utils/typographic';
// import { getCookie } from 'src/utils/functions/cookie';
import MethodSelectElement from './MethodSelectElement.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const AddBackupMethodPage: React.FC = () => {
  const { paymentMethods, changedMethod } =
    useOutletContext<MethodsContextType>();

  const firstMethod = changedMethod
    ? changedMethod
    : paymentMethods.find((item) => item.IsPreferred);

  const navigate = useNavigate();

  const onClickFooterHandler = () => {
    navigate(RoutePath.methods + RoutePath.setBackup);
  };

  const onClickFirstHandler = () =>
    navigate(RoutePath.methods + RoutePath.chooseMethod);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="mx-auto my-8 max-w-oxl px-6">
        <H4 className="max-w-4xl lg:text-center">
          <Translate id="text.addBackupMethod" />
        </H4>
        <div className="my-10 grid gap-8">
          <MethodSelectElement
            step={1}
            titleLabel="text.setMethod1.title"
            contentLabel="text.setMethod1.contentAlt"
            paymethod={firstMethod}
            onClickHandler={onClickFirstHandler}
          />
          <hr />
          <MethodSelectElement
            step={2}
            titleLabel="text.setMethod2.title"
            paymethod={undefined}
            contentLabel="text.setMethod2.content"
            onClickHandler={undefined}
          />
        </div>
        <ButtonContainer marginTop="4rem">
          <Button
            onClick={onClickFooterHandler}
            variant="Primary"
            sizeOnDesktop="medium"
            minWidth="100%"
            type="submit"
          >
            <Translate id="lbl.enterSecondPayment" />
          </Button>
        </ButtonContainer>
      </div>
    </StyleSheetManager>
  );
};

export default AddBackupMethodPage;

import NeedHelpBase from 'src/components/NeedHelpButton/NeedHelpBase.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import React from 'react';
import colors from 'src/style-utils/colors';
import { H2, H5 } from 'src/style-utils/typographic';
import { CardWrapper, CustomCTAButton } from './CardCTA.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const CardCTA: React.FC = () => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CardWrapper>
        <H5 textColor={colors.primaryGreen}>
          <Translate id="lbl.homeCardCTATitle" />
        </H5>
        <H2 light margin="2.4rem auto 4.8rem">
          <Translate id="text.EcommerceCardCtaDescr" />
        </H2>
        <NeedHelpBase
          BaseComponent={CustomCTAButton}
          rightChild={<ArrowRight width="100%" height="100%" />}
        >
          <Translate id="lbl.ecommerceCardCtaLabel" />
        </NeedHelpBase>
      </CardWrapper>
    </StyleSheetManager>
  );
};

export default CardCTA;

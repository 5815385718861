import { Button } from 'src/components/Button/Button.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Translate from 'src/components/Translate/Translate.component';
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { H4, P, PSmall } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { OrderDetailContextType } from '../OrderDetail.component';
import InstallmentCheckboxList from './InstallmentCardCheckboxList.component';
import { ReactComponent as CounterIcon } from 'src/images/icons/installment-custom.svg';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  selectableStatus: 0 | 3;
};
const MultiplePaymentPage: React.FC<Props> = ({ selectableStatus }) => {
  const navigate = useNavigate();
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const setCurrentTransaction = useCurrentTransactionStore(
    (state) => state.setTransactions,
  );
  const setCurrentOrder = useProfileStore((state) => state.setCurrentOrder);
  const [checkedId, setCheckedId] = useState(0);
  const { orderData, orderGuid } = useOutletContext<OrderDetailContextType>();
  const installments =
    orderData?.ResultSet.Installments.filter(
      (inst) => inst.Status === selectableStatus,
    ) ?? [];
  const checkedInsts = installments.slice(0, checkedId + 1);
  const amount = checkedInsts.reduce((acc, curr) => acc + curr.Amount, 0);

  const onClickHandler = () => {
    const tGuid = checkedInsts.map((obj) => obj.Guid);
    setCurrentTransaction({
      orderGuid,
      transactionsGuid: tGuid,
      amount: amount,
      paymentMethodId: orderData?.ResultSet.PaymentMethod.Id,
      showPaymentSetupPage: false,
      orderPayment: true,
      type: 'pay',
      canPayWithSDD: orderData?.ResultSet.CanPayWithSDD,
      ChangedMethod: undefined,
      successData: undefined,
    });
    setCurrentOrder({});
    navigate(RoutePath.paymentFlow + RoutePath.payWithOpyn, {
      state: { showBack: true },
    });
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer>
        <GoBackButton />
        <div className="mx-auto my-6 max-w-oxl">
          <Flex
            flexDirection="column"
            gap="0.8rem"
            className="lg:max-w-olg lg:text-center"
            margin="0 auto"
          >
            <H4>
              <Translate
                id={
                  selectableStatus === 0
                    ? 'text.multiplePaymentTitle'
                    : 'lbl.chooseOneOrMoreOverdue'
                }
              />
            </H4>
            <P>
              <Translate
                id={
                  selectableStatus === 0
                    ? 'text.multiplePaymentSubtitle'
                    : 'text.chooseOneOrMoreOverdue'
                }
              />
            </P>
          </Flex>
          <div className="mb-24 mt-6 lg:mb-16">
            <InstallmentCheckboxList
              installments={installments ?? []}
              setCheckedHandler={setCheckedId}
              checkedId={checkedId}
            />
          </div>
          <div className="fixed bottom-0 left-0 right-0 bg-white py-6 shadow-footer">
            <ResponsiveContainer className="flex flex-col items-center justify-between gap-4 lg:flex-row">
              <div className="flex items-center gap-2 lg:gap-4">
                <CounterIcon className="block h-6 w-6 lg:h-8 lg:w-8" />
                <PSmall colorBlack>
                  <Translate
                    id="lbl.XSelectedInst"
                    data={{ x: checkedInsts.length }}
                  />
                </PSmall>
              </div>
              <Button
                variant="Primary"
                sizeOnMobile="medium"
                onClick={onClickHandler}
                minWidthMobile="100%"
              >
                <Translate
                  id="text.payNow"
                  data={{ paymentValue: formatCurrency(amount ?? 0) }}
                />
              </Button>
            </ResponsiveContainer>
          </div>
        </div>
      </ResponsiveContainer>
      {window.innerWidth < 1024 && <div style={{ height: '10vh' }}></div>}
    </StyleSheetManager>
  );
};

export default MultiplePaymentPage;

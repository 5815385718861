import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as PositiveFeed } from 'src/images/icons/positive-feed.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { H4 } from 'src/style-utils/typographic';
import {
  CopiedLinkBox,
  CopiedLinkOverlay,
  CopiedLinkP,
} from './CopiedLinkToast.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

/**
 * CopiedLinkToast
 *
 *It needs to be attached to a useState triggered and stopped with a setTimeout.
 *@example
 *<div onClick={() => {
 *       setModalOpen(true);
 *        setTimeout(() => setModalOpen(false),x)
 *   }}>
 * // set the x as the component animation duration specified in css;
 *
 **/

const CopiedLinkToast: React.FC = () => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CopiedLinkOverlay data-component="copied-link-toast">
        <CopiedLinkBox align="center" flexDirection="column" justify="center">
          <Icon
            svgIconComponent={PositiveFeed}
            iconSize="4.4rem"
            marginBottom="2rem"
          />
          <H4>
            <Translate id="text.linkCopied!" />
          </H4>
          <CopiedLinkP>
            <Translate id="text.sendNowToClient" />
          </CopiedLinkP>
        </CopiedLinkBox>
      </CopiedLinkOverlay>
    </StyleSheetManager>
  );
};

export default CopiedLinkToast;

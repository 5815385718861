import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import Loader, {
  CreditWorthinessBody,
} from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getUWBuyer } from 'src/services/UWBuyer/UWBuyer.config';
import { getUWBuyerMethodApi } from 'src/services/UWBuyer/UWBuyer.request';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import {
  canGiveCreditForOrderCreationMethodApi,
  editPlanMethodApi,
} from 'src/services/orderLink/orderLink.request';
import {
  CanGiveCreditData,
  editPlanData,
} from 'src/services/orderLink/orderLink.types';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { Card, FlowContainer } from 'src/style/Container.style';
import styled, { StyleSheetManager } from 'styled-components';
import { CategoriesEnum, ProductsEnum } from 'src/utils/types/common.types';
import StepResumeModalComponent from './modal/StepResumeModal.component';
import { IDs } from 'src/utils/config/ids';
import useBeforeUnload from '../../../../utils/hooks/useBeforeUnload';
import { shouldForwardProp } from 'src/style-utils/functions';

export const ContainerInfo = styled.div`
  padding: 1.2rem 0;
  border-bottom: 0.1rem solid ${colors.backgroundOutlined};

  :nth-last-of-type(-n + 1) {
    border-bottom: none;
  }
`;

enum statusRemodulation {
  inApproval,
  approved,
}

const statusRemodulationArray = {
  [statusRemodulation.inApproval]: true,
  [statusRemodulation.approved]: false,
};

function StepResume() {
  let { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { categoryCode } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [confirmRemodulationModal, setConfirmRemodulationModal] =
    useState(false);
  const [polling, setPolling] = useState(false);
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const {
    amount,
    description,
    buyerName,
    customInstallments,
    inviteEmail,
    variantCode,
    setValue,
    remodulation,
    orderId,
    uwId,
    sessionGuid,
    editPlanType,
    shippingDate,
  } = usePayByLinkStore();
  if (editPlanType === 'shipping_date') {
    step = 2;
    stepMax = 2;
  }
  const navigate = useNavigate();
  const uwMandatory = usePayByLinkStore(
    (state) => state.customInstallments?.UwMandatory,
  );
  useBeforeUnload(polling);

  const { mutate: mutateEditPlan, isPending: isLoadingEditPlan } = useMutation({
    mutationFn: (values: editPlanData) => editPlanMethodApi(values),
    onSuccess: async (res) => {
      navigate(
        `${RoutePath.linkDiPagamentoDetail}${orderId}/${RoutePath.successRemodulation}`,
        {
          state: {
            approvation:
              statusRemodulationArray[
                res.data.ResultSet.Status as statusRemodulation
              ],
          },
        },
      );
    },
    onError: () => {
      navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerError);
    },
  });

  const { mutate: mutateGiveCredit, isPending: isLoadingGiveCredit } =
    useMutation({
      mutationFn: canGiveCreditForOrderCreationMethodApi,
      onSuccess: async (res, variables) => {
        const AmountGivable = res.data.ResultSet.AmountGivable;
        const canGiveCredit = res.data.ResultSet.CanGiveCredit;
        setValue('amountGivable', AmountGivable);
        navigate(urlRedirectToFlow, {
          state: {
            Result: 'OK',
            canPayWithSDD: variables.canPayWithSDD,
            canGiveCredit,
          },
        });
      },
      onError: async () => {
        navigate(RoutePath.ThankyouPageError + RoutePath.internalError);
      },
    });

  const {
    isLoading: isLoadingUW,
    isSuccess,
    data,
  } = useQuery({
    queryKey: [getUWBuyer],
    queryFn: () => getUWBuyerMethodApi({ uwId }),
    refetchInterval: 2000,
    enabled: polling,
  });

  const pollingTimeout = () => {
    if (polling)
      navigate(RoutePath.ThankyouPageError + RoutePath.internalError);
  };

  useEffect(() => {
    const timer = setTimeout(pollingTimeout, 4 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [polling]);

  useEffect(() => {
    if (isSuccess) {
      const Result = data.data.ResultSet.Result;
      const Status = data.data.ResultSet.Status;
      const giveCreditEnabled = data.data.ResultSet.GiveCreditEnabled;
      const canPayWithSDD = data.data.ResultSet.CanPayWithSDD;
      setValue('canPayWithSDD', data.data.ResultSet.CanPayWithSDD);

      if (Status === 1) {
        setPolling(false);
        if (Result === 'LIGHT_OK' && uwMandatory) {
          navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerError);
          return;
        }
        if (Result === 'KO') {
          navigate(RoutePath.ThankyouPageError + RoutePath.uwBuyerError);
          return;
        }
        if (giveCreditEnabled && Result === 'OK') {
          const dataGiveCredit: CanGiveCreditData = {
            amount,
            customInstallments: customInstallments?.Installments,
            variantCode,
            orderCreationSessionGuid: sessionGuid,
          };
          mutateGiveCredit({ ...dataGiveCredit, canPayWithSDD });
          return;
        }
        navigate(urlRedirectToFlow, {
          state: {
            Result,
            canPayWithSDD,
            canGiveCredit: giveCreditEnabled ? false : undefined,
          },
        });
      }
    }
  }, [isSuccess, data]);

  if (isLoadingUW || polling || isLoadingGiveCredit) {
    return (
      <Loader overlayViewMode="fullscreen" active viewMode="fluid">
        <CreditWorthinessBody />
      </Loader>
    );
  }

  if (isLoadingEditPlan) {
    <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.resumeData"
      />
      <FlowContainer>
        <H4>
          <Translate id="lbl.areDataCorrect" />
        </H4>
        <Card className="content">
          {variantCode !== ProductsEnum.singleInstant && (
            <ContainerInfo>
              <P>
                <Translate id="text.rataPlanning" />
              </P>
              <Button
                variant="LinkPrimary"
                padding="0"
                margin="0"
                height="unset"
                onClick={() => setShowModal(true)}
              >
                <Translate id="lbl.showInstallmentPlan" />
              </Button>
            </ContainerInfo>
          )}
          {inviteEmail && (
            <ContainerInfo>
              <P>
                <Translate id="lbl.payByLinkEmail" />
              </P>
              <P colorBlack>{inviteEmail}</P>
            </ContainerInfo>
          )}
          <ContainerInfo>
            <P>
              <Translate id="lbl.orderDescription" />
            </P>
            <P colorBlack>{description}</P>
          </ContainerInfo>
          <ContainerInfo>
            <P>
              <Translate id="text.client" />
            </P>
            <P colorBlack>{buyerName}</P>
          </ContainerInfo>
          <ContainerInfo>
            <P>
              <Translate id="text.total" />
            </P>
            <P colorBlack>{formatCurrency(amount)}</P>
          </ContainerInfo>
          <ContainerInfo>
            <P>
              <Translate id="lbl.installmentNumber" />
            </P>
            <P colorBlack>{customInstallments?.NumRate}</P>
          </ContainerInfo>
          {categoryCode !== CategoriesEnum.singleInstallment && (
            <ContainerInfo>
              <P>
                <Translate id="lbl.installmentAmount" />
              </P>
              <P colorBlack>
                {customInstallments?.InstallmentMinAmount &&
                customInstallments?.InstallmentMaxAmount ? (
                  <Translate
                    id="lbl.fromXtoY"
                    data={{
                      x: formatCurrency(
                        customInstallments?.InstallmentMinAmount,
                      ),
                      y: formatCurrency(
                        customInstallments?.InstallmentMaxAmount,
                      ),
                    }}
                  />
                ) : (
                  formatCurrency(customInstallments?.InstallmentAmount!)
                )}
              </P>
            </ContainerInfo>
          )}
        </Card>
        <ButtonContainer>
          {remodulation ? (
            <Button
              variant="Primary"
              minWidth="100%"
              onClick={() => setConfirmRemodulationModal(true)}
            >
              <Translate id="lbl.confirmNewInstallmentPlan" />
            </Button>
          ) : (
            <Button
              variant="Primary"
              minWidth="100%"
              onClick={() => {
                setPolling(true);
              }}
              id={IDs.btnProceed}
            >
              <Translate id="lbl.next" />
            </Button>
          )}
        </ButtonContainer>
        {showModal && (
          <StepResumeModalComponent
            buttonTitle="text.okUnderstand"
            modalTitle="lbl.installmentDetail"
            handleClose={() => setShowModal(false)}
            i18n
          />
        )}
        {confirmRemodulationModal && (
          <DoubleChoicePopUp
            handleClose={() => setConfirmRemodulationModal(false)}
            handleSecondAction={() => {
              mutateEditPlan({
                OrderId: orderId,
                EditPlanAmount: amount,
                Installments: customInstallments?.Installments,
                Type: editPlanType || 'default',
                ShippingDate: shippingDate,
              });
            }}
            modalTitle="text.confirmRemodulationTitle"
            firstButtonLabel="lbl.confirmRemodulation"
            lastButtonLabel="lbl.back"
            mainText={'text.confirmRemodulationSubtitle'}
            icon={AlertIcon}
          />
        )}
      </FlowContainer>
      {window.innerWidth < 1024 && <div style={{ height: '15vh' }}></div>}
    </StyleSheetManager>
  );
}

export default StepResume;

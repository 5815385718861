import merge from 'deepmerge';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumn } from '../DataTable.types';
import { CellProps, CellRow } from '../style/DataTable.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type RowCellProps<TData> = {
  column: TableColumn<TData>;
  rowData: TData;
  numberOfCells: number;
};

const RowCell = <TData extends object>(props: RowCellProps<TData>) => {
  const translate = useTranslation().t;

  const {
    column: {
      cellConfig = {},
      rowCellConfig = {},
      renderFn,
      RenderComponent,
      header,
    },
    rowData,
    numberOfCells,
  } = props;

  const mergedConfig: CellProps = useMemo(
    () => merge(cellConfig, rowCellConfig),
    [cellConfig, rowCellConfig],
  );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CellRow
        align="center"
        data-parent={header && translate(header)}
        role="cell"
        w={`${100 / numberOfCells}%`}
        {...mergedConfig}
      >
        {typeof renderFn === 'function'
          ? renderFn(rowData)
          : RenderComponent && <RenderComponent {...rowData} />}
      </CellRow>
    </StyleSheetManager>
  );
};
export default memo(RowCell) as typeof RowCell;

import styled, { css } from 'styled-components';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { withPadding } from 'src/style-utils/dimensionsMixins';
import checkbox_light from 'src/images/icons/checkbox_light.svg?url';
import checkbox_dark from 'src/images/icons/checkbox_dark.svg?url';
import squareBoxUnchecked from 'src/images/icons/square_unchecked.svg?url';
import squareBoxChecked from 'src/images/icons/square_checked.svg?url';
import { StyledProps } from 'src/@types/styled-components';

export const CheckBoxWrapper = styled.div<{ viewMode?: string }>`
  && {
    ${withPadding};
  }
  display: flex;
  align-items: center;
  justify-content: end;
  ${({ viewMode }) =>
    viewMode === 'inline' &&
    css`
      width: auto;
    `};
`;

export const CheckBoxLabel = styled.span`
  display: inline-block;
  ${({ theme: { checkbox } }) => `
    margin-top: ${checkbox.label.marginTop};
    color: ${checkbox.label.color};
    font-size: ${checkbox.label.fontSize};
    line-height: ${checkbox.label.lineHeight};
    font-weight: ${checkbox.label.fontWeight};
  `};
`;

export const CheckBoxDescription = styled.p`
  color: ${colors.midGrey};
  line-height: 1.8rem;
  margin-top: 0.8rem;
  margin-bottom: 0;
  font-size: 1.2rem;
`;

export const Checkmark = styled.span<{ squareCheckBox?: boolean }>`
  position: absolute;
  top: 0.3rem;
  left: 0;
  height: 2.4rem;
  width: 5rem;
  border-radius: 0.4rem;
  ${({ squareCheckBox }) =>
    squareCheckBox
      ? `background-image: url("${squareBoxUnchecked}")`
      : `background-image: url("${checkbox_light}")`};
  background-size: 5rem 2.4rem;
`;

export const CheckboxContainer = styled.label`
  display: inline-block;
  line-height: 2.6rem;
  min-height: 2.6rem;
  position: relative;
  cursor: pointer;
  user-select: none;

  ${({ theme: { checkbox } }) => `
    padding-left: ${checkbox.root.paddingLeft};
    margin: ${checkbox.root.margin};
    `}

  ${media.desktop`
    ${({ theme: { checkbox } }: StyledProps<unknown>) => `
      padding-left: ${checkbox.root.desktop.paddingLeft};
      margin: ${checkbox.root.desktop.margin};
    `}
  `};
`;

export const CheckboxInput = styled.input<{ squareCheckBox?: boolean }>`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    ${({ squareCheckBox }) =>
      squareCheckBox
        ? `background-image: url("${squareBoxChecked}")`
        : `background-image: url("${checkbox_dark}")`};
  }
`;

import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';

import { FilteredButtonContainer } from 'src/components/FilteredList/style/FilteredList.style';
import {
  PayByLinkStatus,
  PaymentStatusMap,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { PaymentsFilteredButtonProps } from '../PaymentsFilteredList.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PaymentsFilteredButton = ({
  filteredName,
  fromDate,
  toDate,
  merchantId,
  status,
  handleClick,
  dataForm,
  setDataFilter,
  amountFrom,
  amountTo,
}: PaymentsFilteredButtonProps) => {
  const { resetField, watch, setValue } = useFormContext();

  const clientsArray = watch('Merchants');
  const statusArray: string[] = watch('Statuses') ?? [];
  const amountsArray: { From: number; To: number }[] = watch('Amounts');
  const fieldName = merchantId ? 'Merchants' : status ? 'Statuses' : 'Amounts';
  const { remove } = useFieldArray({
    name: fieldName,
  });
  const {
    formatters: { formatDate, formatCurrency },
  } = useI18n();

  const onClick = () => {
    if (fromDate || toDate) {
      resetField('FromDate');
      resetField('ToDate');
      dataForm.FromDate = '';
      dataForm.ToDate = '';
    }
    if (merchantId) {
      remove(clientsArray.indexOf(merchantId));
      dataForm.Merchants = dataForm.Merchants?.filter(
        (id) => id.toString() !== merchantId.toString(),
      );
    }
    if (status) {
      remove(statusArray.indexOf(status));
      dataForm.Statuses = dataForm.Statuses?.filter(
        (idType) => idType.toString() !== status.toString(),
      );
    }
    if (amountTo !== undefined && amountFrom !== undefined) {
      remove(
        amountsArray.findIndex(
          (i) =>
            i.From.toString() === amountFrom.toString() &&
            i.To.toString() === amountTo.toString(),
        ),
      );
      dataForm.Amounts = dataForm.Amounts?.filter(
        (x) =>
          x.From.toString() !== amountFrom.toString() &&
          x.To.toString() !== amountTo.toString(),
      );
    }
    setValue('skip', 0);
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FilteredButtonContainer onClick={onClick}>
        {fromDate && fromDate.length > 0 && (
          <>
            <Translate id="text.startPeriod" />
            &nbsp;{formatDate(new Date(fromDate))}
          </>
        )}
        {toDate && toDate.length > 0 && (
          <>
            <Translate id="text.endPeriod" />
            &nbsp;{formatDate(new Date(toDate))}
          </>
        )}
        {merchantId && filteredName}
        {status && (
          <Translate
            id={PaymentStatusMap[Number(status) as PayByLinkStatus].label}
          />
        )}
        {amountTo &&
          ((amountTo ?? -1) < 999999 ? (
            <Translate
              id="lbl.fromXtoY"
              data={{
                x: formatCurrency(amountFrom ?? 0, { hideCents: true }),
                y: formatCurrency(amountTo ?? 0, { hideCents: true }),
              }}
            />
          ) : (
            <Translate
              id="lbl.moreThanX"
              data={{
                x: formatCurrency(amountFrom ?? 0, { hideCents: true }),
              }}
            />
          ))}
        <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
      </FilteredButtonContainer>
    </StyleSheetManager>
  );
};

export default PaymentsFilteredButton;

import colors from 'src/style-utils/colors';
import { WithMarginProps, withMargin } from 'src/style-utils/dimensionsMixins';
import media from 'src/style-utils/media-queries';
import styled, { css } from 'styled-components';
import { ButtonBasePropsWithStyled } from '../Button.types';

const ButtonSize = {
  small: '4.4rem',
  medium: '5.4rem',
  large: '6.4rem',
};

export const ButtonBase = styled.button<ButtonBasePropsWithStyled>`
  /*FIXED STYLES*/
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.28px;
  cursor: pointer;
  outline: none;
  outline: 0;
  background: ${colors.white};
  color: ${colors.textBtn};

  &[disabled] {
    cursor: not-allowed;
    color: ${colors.textBtnGrey};

    &:hover {
      color: ${colors.textBtnDisabledSecondary};
      background: ${colors.bgBtnDisabledSecondary};
    }
  }

  &:hover {
    background: ${colors.bgBtnHoverPrimary};
    color: ${colors.textBtnHoverPrimary};
  }

  /* STYLES FROM PROPS */

  ${({
    borderRadius = '3rem',
    fontWeight = '500',
    fontSize = '1.4rem',
    padding = '1.2rem 1.6rem',
    minWidth = '11.2rem',
    minWidthMobile,
    height = '4.4rem',
    gap = '4px',
    margin = '0',
    display = 'inline-flex',
    underline,
    sizeOnMobile,
  }: ButtonBasePropsWithStyled) => css`
    border-radius: ${borderRadius};
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    padding: ${padding};
    min-width: ${minWidthMobile ? minWidthMobile : minWidth};
    height: ${sizeOnMobile ? ButtonSize[sizeOnMobile] : height};
    margin: ${margin};
    gap: ${gap};
    display: ${display};
    ${underline ? 'text-decoration: underline' : 'text-decoration: none'};
  `} /* DYNAMIC STYLES */ ${({ variant = 'Primary' }) =>
    ButtonTheme[variant]} /*MEDIA QUERIES */ ${media.tablet`
   ${({
     minWidth = '28rem',
     height = '6.4rem',
     sizeOnDesktop,
   }: ButtonBasePropsWithStyled) => css`
     min-width: ${minWidth};
     height: ${sizeOnDesktop ? ButtonSize[sizeOnDesktop] : height};

     ${ButtonText} {
       white-space: nowrap;
     }
   `};
  `}
`;

export const ButtonContainer = styled.div<
  { backgroundColor?: string } & WithMarginProps
>`
  display: grid;
  position: fixed;
  inset: auto 0 0;
  gap: 1.6rem;
  z-index: 5;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.backgroundGrey};
  padding: 2.4rem;
  box-shadow: 0 0.4rem 2.4rem 0 ${colors.modalFooterShadowColor};
  ${media.tablet`
    width: min(100%, 28rem);
    max-width: min(max-content, 28rem);
    margin: auto;  
    background: unset;
    position: unset;
    box-shadow: none;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 0;
    ${withMargin}
  `};
`;

const ButtonTheme = {
  Primary: css`
    background-color: ${colors.bgBtnPrimary};

    &[disabled] {
      background-color: ${colors.bgBtnDisabledPrimary};
      color: ${colors.textBtnDisabledPrimary};
    }
    &:hover {
      background-color: ${colors.bgBtnHoverPrimary};
      color: ${colors.textBtnHoverPrimary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedPrimary};
    }
    &:active {
      outline: solid 2px ${colors.bgBtnPressedPrimary};
    }
  `,
  Secondary: css`
    background-color: ${colors.bgBtnSecondary};
    border-radius: 3rem;
    color: ${colors.white};
    &[disabled] {
      background-color: ${colors.bgBtnDisabledSecondary};
      color: ${colors.textBtnDisabledSecondary};
    }
    &:hover {
      background-color: ${colors.bgBtnHoverSecondary};
      color: ${colors.textBtnHoverSecondary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedSecondary};
    }
    &:active {
      outline: solid 2px ${colors.bgBtnPressedSecondary};
    }
  `,
  Tertiary: css`
    background-color: ${colors.bgBtnTertiary};
    border-radius: 3rem;
    outline: solid 0.1rem ${colors.borderbgBtnTertiary};
    color: ${colors.textBtnHoverTertiary};
    &[disabled] {
      background-color: ${colors.bgBtnDisabledTertiary};
      color: ${colors.textBtnDisabledTertiary};
      outline: none;
    }
    &:hover {
      background-color: ${colors.bgBtnHoverTertiary};
      color: ${colors.textBtnHoverTertiary};
    }
    &:focus {
      background-color: ${colors.bgBtnFocusedTertiary};
      color: ${colors.textBtnFocusedTertiary};
    }
    &:active {
      outline: solid 0.2rem ${colors.bgBtnPressedTertiary};
    }
  `,
  Quaternary: css`
    background-color: ${colors.bgBtnQuaternary};
    border-radius: 99rem;
    color: ${colors.textBtnHoverQuaternary};
    margin-bottom: 1.2rem;
    &[disabled] {
      cursor: not-allowed;
      background-color: ${colors.bgBtnDisabledQuaternary};
      color: ${colors.textBtnDisabledQuaternary};
      outline: none;
      width: auto;
    }
    &:hover {
      background-color: ${colors.bgBtnHoverQuaternary};
      color: ${colors.textBtnHoverQuaternary};
    }
    &:focus {
      color: ${colors.textBtnHoverQuaternary};
    }
    &:active {
      outline: solid 0.2rem ${colors.borderbgBtnQuaternary};
    }
  `,
  LinkPrimary: css`
    background: none;
    outline: none;
    min-width: unset !important;
    color: ${colors.textBtnLinkPrimary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverPrimary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedPrimary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedPrimary};
    }
  `,
  LinkSecondary: css`
    background: none;
    outline: none;
    min-width: unset !important;
    color: ${colors.textBtnLinkSecondary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverSecondary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedSecondary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedSecondary};
    }
  `,
  TextButton: css`
    background: none;
    outline: none;
    color: ${colors.textBtnLinkPrimary};
    &:hover {
      background: none;
      color: ${colors.textBtnLinkHoverPrimary};
    }
    &:focus {
      color: ${colors.textBtnLinkFocusedPrimary};
    }
    &:active {
      color: ${colors.textBtnLinkPressedPrimary};
    }
    &[disabled] {
      cursor: not-allowed;
      background-color: ${colors.bgBtnDisabledQuaternary};
      color: ${colors.textBtnDisabledQuaternary};
      outline: none;
      width: auto;
    }
  `,
  MenuButton: css`
    width: 100%;
    background: none;
    outline: none;
    border-radius: 4px;
    color: ${colors.textBtn};
    font-weight: 400;
    justify-content: left;
    & > span {
      white-space: normal !important;
      text-align: left;
    }
    &:hover {
      background-color: ${colors.backgroundGrey};
    }
    &:focus-visible {
      border: 1.5px solid ${colors.lightBlueBorder};
    }
    &.active {
      color: ${colors.textBtn};
      background-color: ${colors.green10};
      font-weight: 500;
      padding-left: 0;

      &:before {
        content: '';
        background: black;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        height: 24px;
        width: 2px;
      }
    }
    &[disabled] {
      cursor: not-allowed;
      background-color: ${colors.bgBtnDisabledQuaternary};
      color: ${colors.textBtnDisabledQuaternary};
      outline: none;
      width: auto;
    }

    ${media.tablet`width: 176px;`}
  `,
};

//Messo in styled per stili futuri
export const ButtonText = styled.span``;

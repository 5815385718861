import { useEffect } from 'react';
import { ENV } from '../../settings';
import { useLanguage } from '../../services/i18n/i18n.context';
import { COOKIEHUB_GLOBAL_VAR } from '../../constants';

const COOKIEHUB_SCRIPT_ID = 'cookiehub-script';

const useCookieHub = () => {
  const { language } = useLanguage();

  useEffect(() => {
    const l = language.substring(0, 2);
    document.documentElement.lang = l;
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.setLanguage(l);
  }, [language]);

  useEffect(() => {
    const cookiehubSrc =
      ENV !== 'production'
        ? 'https://dash.cookiehub.com/dev/961e7623.js'
        : 'https://cdn.cookiehub.eu/c2/961e7623.js';

    if (document.getElementById(COOKIEHUB_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.src = cookiehubSrc;
    script.id = COOKIEHUB_SCRIPT_ID;
    script.async = true;

    script.onload = () => {
      const cpm = {
        onStatusChange: () => {
          document.dispatchEvent(
            new CustomEvent('cookiehub-onmarketingchange', {
              bubbles: true,
              detail: {
                status: (window as any).cookiehub?.hasConsented('marketing'),
              },
            }),
          );
        },
      };
      (window as any).cookiehub?.load(cpm);
      (window as any)[COOKIEHUB_GLOBAL_VAR]?.setLanguage(
        language.substring(0, 2).toLowerCase() || 'it',
      );
    };

    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById(COOKIEHUB_SCRIPT_ID);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);
};

export default useCookieHub;

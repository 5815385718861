import { ReactComponent as Information } from 'src/images/icons/information.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useAccountStore, useRegistrationStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { ClickableIcon } from './style/textDefaultPage.style';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  title: string;
  subTitle?: string;
  emailChange?: string;
  mobileChange?: string;
  inviteEmail?: string;
  modal?: boolean;
  margin?: string;
  onClickModal?: () => void;
};

export const TextDefaultPage: React.FC<Props> = ({
  title,
  subTitle,
  emailChange,
  mobileChange,
  inviteEmail,
  modal,
  margin,
  onClickModal,
}) => {
  const { email, mobile } = useRegistrationStore((state) => state);
  const { Email: emailProfile, Phone: phoneProfile } = useAccountStore(
    (state) => state,
  );
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex
        data-component="text-default-page"
        margin={margin ?? '0 0 2.4rem'}
        flexDirection="column"
      >
        <H4 className="centerOnDesktop">
          <Translate id={title} />
          {modal && (
            <ClickableIcon
              svgIconComponent={Information}
              iconSize="2.8rem"
              marginLeft="0.6rem"
              onClick={onClickModal}
              display="inline-flex"
            />
          )}
        </H4>
        {subTitle && (
          <P className="centerOnDesktop" marginTop="1.6rem">
            <Translate
              id={subTitle}
              data={{
                emailAddress: emailChange || emailProfile || email,
                phoneNumber: mobileChange || phoneProfile || mobile,
                inviteEmail,
              }}
            />
          </P>
        )}
      </Flex>
    </StyleSheetManager>
  );
};

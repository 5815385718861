import InputField from 'src/components/InputField/InputField.component';
import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import Translate from 'src/components/Translate/Translate.component';
import { descriptionField } from 'src/services/orderLink/orderLink.config';
import { shouldForwardProp } from 'src/style-utils/functions';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { StyleSheetManager } from 'styled-components';

function CollaboratorModalBody() {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ModalBody>
        <FlowContainer margin="0 auto">
          <H4 light>
            <Translate id="text.insertCollaboratorDescription" />
          </H4>
          <div className="content">
            <InputField
              label="text.collaboratorDescription"
              name={descriptionField}
            />
          </div>
        </FlowContainer>
      </ModalBody>
    </StyleSheetManager>
  );
}

export default CollaboratorModalBody;

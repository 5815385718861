import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { RoutePath } from 'src/routers/routers.config';
import { shouldForwardProp } from 'src/style-utils/functions';
import { H5, P } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';

export type UsageRequirementsType = {
  title: string;
  subtitle: string;
}[];

export const UsageRequirementsArray: UsageRequirementsType = [
  {
    title: 'text.usageRequirementsInfoTitle1',
    subtitle: 'text.usageRequirementsInfoSubtitle1',
  },
  {
    title: 'text.usageRequirementsInfoTitle2',
    subtitle: 'text.usageRequirementsInfoSubtitle2',
  },
  {
    title: 'text.usageRequirementsInfoTitle3',
    subtitle: 'text.usageRequirementsInfoSubtitle3',
  },
  {
    title: 'text.usageRequirementsInfoTitle4',
    subtitle: 'text.usageRequirementsInfoSubtitle4',
  },
];
const UsageRequirements: React.FC = () => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="container">
        <GoBackButton
          url={RoutePath.usageSettingOptions}
          label="text.linkPaymentWAdvPayment"
        />
        <div className="mx-auto max-w-md">
          <TextDefaultPage title="lbl.usageRequirements" />
          <div className="grid gap-6">
            {UsageRequirementsArray.map((info, index: number) => (
              <div key={index}>
                <H5 marginBottom="0.8rem">
                  <Translate id={info.title} />
                </H5>
                <P>
                  <Translate id={info.subtitle} />
                </P>
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyleSheetManager>
  );
};

export default UsageRequirements;

import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { GenericErrorFormProps } from './GenericErrorForm.types';
import {
  ContainerMessageGeneric,
  MessageErrorGeneric,
} from './style/GenericErrorForm.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export const GenericErrorForm: React.FC<GenericErrorFormProps> = ({
  isPresentMessage,
  errorMessageID,
  textAlign = 'center',
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerMessageGeneric
        data-component="generic-error-form"
        textAlign={textAlign}
        isPresentMessage={isPresentMessage}
      >
        <MessageErrorGeneric>
          {errorMessageID !== '' && <Translate id={errorMessageID} />}
        </MessageErrorGeneric>
      </ContainerMessageGeneric>
    </StyleSheetManager>
  );
};

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { ReactComponent as Information } from 'src/images/icons/information.svg';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { getCompanyInfo } from 'src/services/orderLink/orderLink.config';
import {
  getCompanyInfoMethodApi,
  linkWrongMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { initSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { useProfileStore, useRegistrationStore } from 'src/store/store';
import { P } from 'src/style-utils/typographic';
import IsYourCompanyModalLodable from './partials/IsYourCompanyModal.lodable';
import { Header, Subtitle, Title, TitleContainer } from './IsYourCompany.style';
import { FlowContainer } from 'src/style/Container.style';
import { ContainerInfo } from '../registration/registrationLight/style/registrationLight.style';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const IsYourCompany: React.FC = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const setValue = useRegistrationStore((state) => state.setValue);
  const setValueProfile = useProfileStore((state) => state.setValue);
  const { language } = useLanguage();
  const login = location?.state?.login;
  const guest = location?.state?.guest;
  const { OrderGuid, FlagCessioneCredito, BuyerGuid } = currentOrder;

  const { isPending, mutate } = useMutation({
    mutationFn: (data: any) => linkWrongMethodApi(data),
    onSuccess: () => navigate('/thankyou-page-error/buyer-fiscal-code-error'),
    onError: () => navigate(RoutePath.oops),
  });

  const { mutate: mutateInit, isPending: isLoadingInit } = useMutation({
    mutationFn: initSignUpSessionMethodApi,
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      setValue('mobile', res.data.ResultSet.Phone);
      navigate(RoutePath.registrationBuyer + RoutePath.phoneStep, {
        state: {
          activeOrder: true,
          creditAssignment: FlagCessioneCredito,
          guest,
        },
      });
    },
    onError: () => navigate(RoutePath.oops),
  });

  const { isLoading: isLoadingGetCompanyInfo, data } = useQuery({
    queryKey: [getCompanyInfo],
    queryFn: () => getCompanyInfoMethodApi({ guid: BuyerGuid }),
  });

  if (isPending || isLoadingGetCompanyInfo || isLoadingInit) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = () => {
    setValue('flagCessioneCreditoMerchant', !!FlagCessioneCredito);
    setValueProfile('tipoAccount', 'BUYER');
    if (login) {
      navigate(RoutePath.login + '?hideFooter=true', {
        state: {
          activeOrder: true,
          creditAssignment: FlagCessioneCredito,
          BuyerGuid,
        },
      });
      return;
    }
    mutateInit({
      BuyerGuid,
      AccountType: 'BUYER',
      Language: language,
      RegistrationType: 'full',
    });
  };

  const buyerData = data?.data?.ResultSet;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowContainer>
        <Header>
          <TitleContainer>
            <Title>
              <Translate id="text.isYourAgency" />
            </Title>
            <Icon
              svgIconComponent={Information}
              iconSize="28px"
              onClick={() => setModalOpen(true)}
            />
          </TitleContainer>

          <Subtitle>
            <Translate id="text.isYourCompanyCorrectData" />
          </Subtitle>
        </Header>

        <div className="content">
          <ContainerInfo>
            {buyerData?.Name && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkFlowBusinessName" />
                </P>
                <P bold colorBlack>
                  {buyerData?.Name}
                </P>
              </div>
            )}
            {isItalian(data?.data.ResultSet.Country) ? (
              <>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkCf" />
                  </P>
                  <P bold colorBlack>
                    {buyerData?.FiscalCode}
                  </P>
                </div>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkVatCode" />
                  </P>
                  <P bold colorBlack>
                    {buyerData?.VatCode}
                  </P>
                </div>
              </>
            ) : (
              <>
                {buyerData?.VatCode && (
                  <div>
                    <P>
                      <Translate id="text.vatNumber" />
                    </P>
                    <P bold colorBlack>
                      {buyerData.VatCode}
                    </P>
                  </div>
                )}
                {buyerData?.PostalCode && (
                  <div>
                    <P>
                      <Translate id="lbl.postalCode" />
                    </P>
                    <P bold colorBlack>
                      {buyerData.PostalCode}
                    </P>
                  </div>
                )}
              </>
            )}
            {buyerData?.Address && (
              <div>
                <P>
                  <Translate id="text.address" />
                </P>
                <P bold colorBlack>
                  {buyerData.Address}
                </P>
              </div>
            )}
          </ContainerInfo>
        </div>

        <ButtonContainer>
          <Button
            variant="Primary"
            minWidthMobile="100%"
            i18n={true}
            translatedText={'text.isMyCompany'}
            onClick={onSubmit}
          />
          <Button
            variant="Tertiary"
            minWidthMobile="100%"
            i18n={true}
            translatedText={'text.isNotMyAgency'}
            onClick={() => setShowPopUp(true)}
          />
        </ButtonContainer>
        {modalOpen && (
          <IsYourCompanyModalLodable
            buttonTitle="text.okUnderstand"
            modalTitle="Da dove abbiamo ricavato i tuoi dati aziendali?"
            handleClose={() => setModalOpen(false)}
          />
        )}
        {showPopUp && (
          <DoubleChoicePopUp
            handleSecondAction={() => mutate(OrderGuid)}
            modalTitle="lbl.infoDoesntMatch"
            handleClose={() => setShowPopUp(false)}
            firstButtonLabel="lbl.infoDoesntMatchCTA"
            lastButtonLabel="lbl.cancelAndGoBack"
            mainText="text.infoDoesntMatch"
            icon={AlertIcon}
          />
        )}
      </FlowContainer>
    </StyleSheetManager>
  );
};

export default IsYourCompany;

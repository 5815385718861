import { ReactComponent as X } from 'src/images/icons/negative_feedback_white.svg';
import {
  flagCessioneCredito,
  flagNonCessioneCredito,
  skipField,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { useFormContext } from 'react-hook-form';
import Translate from 'src/components/Translate/Translate.component';

import Icon from 'src/components/Icon/Icon.component';
import { DataFormBalanceMovements } from 'src/pages/balanceMovementsPage/BalanceMovementsPage.types';
import { FilteredButtonContainer } from '../style/FilteredList.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

interface FilteredButtonBooelanProps {
  label: string;
  fieldName: typeof flagCessioneCredito | typeof flagNonCessioneCredito;
  handleClick: Function;
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
  dataForm: DataFormBalanceMovements;
  disabled: boolean;
}

const FilteredButtonBoolean = ({
  label,
  fieldName,
  handleClick,
  dataForm,
  setDataFilter,
  disabled,
}: FilteredButtonBooelanProps) => {
  const { setValue } = useFormContext();

  const onClickHandler = () => {
    setValue(fieldName, false);
    dataForm[fieldName] = false;
    setValue(skipField, 0);
    setDataFilter(dataForm);
    handleClick(dataForm);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FilteredButtonContainer
        onClick={onClickHandler}
        data-disabled={disabled}
      >
        <Translate id={label} />
        <Icon svgIconComponent={X} iconHeight="1.7rem" iconSize="1.6rem" />
      </FilteredButtonContainer>
    </StyleSheetManager>
  );
};

export default FilteredButtonBoolean;

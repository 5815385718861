import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Logo } from 'src/images/icons/logo-opyn.svg';
import './Footer.style.css';
import { COOKIEHUB_GLOBAL_VAR } from '../../constants';

const footerLinks = [
  {
    title: 'footer.opynWebsite',
    url: 'https://www.opyn.eu',
  },
  {
    title: 'footer.privacyPolicy',
    url: 'https://www.opyn.eu/privacy-policy',
  },
];

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }: FooterProps) => {
  const year = new Date().getFullYear();
  const showCookieSettingsBanner = () => {
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.openSettings();
  };

  return (
    <footer
      data-component="footer"
      className={`${className} w-full justify-self-end border-t-2 border-pgreyoutlined p-8 text-xs leading-4 text-pgrey`}
    >
      <div className="container grid gap-4">
        <div className="grid items-center justify-between gap-4 lg:flex">
          <Logo width="64" height="16" className="h-4 w-16" />
          <ul className="flex flex-wrap">
            {footerLinks.map(({ title, url }) => (
              <li key={title}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer underline"
                >
                  <Translate id={title} />
                </a>
                &nbsp;&middot;&nbsp;
              </li>
            ))}
            <li>
              <button
                onClick={showCookieSettingsBanner}
                className="cursor-pointer underline"
              >
                <Translate id="footer.cookieManagement" />
              </button>
              &nbsp;&middot;&nbsp;
            </li>
            <p>&copy; {year} OPYN</p>
          </ul>
        </div>
        <hr />
        <div>
          <p>
            <Translate id="text.footerEcommerceCheckout" />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { initOrderCreateMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer, ResponsiveContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { ContainerInfo } from '../StepCheckData/StepCheckData.style';
import { CompanyGridContainer } from './chooseCompany.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ChooseCompany() {
  const { categoryCode, productCode } = useParams();
  const items = usePayByLinkStore((state) => state.results);
  const guid = usePayByLinkStore((state) => state.guid);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const setValues = usePayByLinkStore((state) => state.setValues);
  const navigate = useNavigate();
  const { mutate: mutateInit, isPending: isLoadingInit } = useMutation({
    mutationFn: initOrderCreateMethodApi,
    onSuccess: (res) => {
      setValue('uwId', res?.data?.ResultSet.UwId);
      setValue('sessionGuid', res?.data?.ResultSet.Guid);
      setValue('inviteEmail', res?.data?.ResultSet.LastInviteEmail);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepImport}`,
      );
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  if (isLoadingInit) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={4}
        stepMax={10}
        title="lbl.dataConfirmation"
      />
      <FlowContainer>
        <H4>
          <Translate id="text.selectCustomer" />
        </H4>
      </FlowContainer>
      <ResponsiveContainer>
        <CompanyGridContainer>
          {items.map(
            (item, index) =>
              index < 4 &&
              index > 0 && (
                <ContainerInfo key={index}>
                  {item.Name && (
                    <div>
                      <P>
                        <Translate id="lbl.payByLinkFlowBusinessName" />
                      </P>
                      <P bold colorBlack>
                        {item.Name}
                      </P>
                    </div>
                  )}
                  {item.VatCode && (
                    <div>
                      <P>
                        <Translate id={'text.vatNumber'} />
                      </P>
                      <P bold colorBlack>
                        {item.VatCode}
                      </P>
                    </div>
                  )}
                  {item.PostalCode && (
                    <div>
                      <P>
                        <Translate id="lbl.postalCode" />
                      </P>
                      <P bold colorBlack>
                        {item.PostalCode}
                      </P>
                    </div>
                  )}
                  {item.Address && (
                    <div>
                      <P>
                        <Translate id="text.address" />
                      </P>
                      <P bold colorBlack>
                        {item.Address}
                      </P>
                    </div>
                  )}
                  <Button
                    variant="Primary"
                    minWidth="100%"
                    sizeOnDesktop="small"
                    margin="auto 0 0 0"
                    i18n={true}
                    translatedText="lbl.customerCompany"
                    onClick={() => {
                      const values = {
                        buyerFiscalCode: item.FiscalCode,
                        postalCode: item.PostalCode,
                        buyerAddress: item.Address,
                        buyerName: item.Name,
                        VatCode: item.VatCode,
                        companySearchId: item.SearchId,
                        country: item.Country.toUpperCase(),
                      };
                      setValues(values);
                      mutateInit({
                        BuyerGuid: guid,
                        CompanyInfoSearchResultId: item.SearchId,
                      });
                    }}
                    id={IDs.btnDecline}
                  />
                </ContainerInfo>
              ),
          )}
        </CompanyGridContainer>
      </ResponsiveContainer>
      <ButtonContainer marginBottom="2rem">
        <Button
          variant="Tertiary"
          minWidth="100%"
          i18n={true}
          translatedText="text.companyNotFound"
          onClick={() =>
            navigate(
              `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepVAT}`,
            )
          }
          id={IDs.btnDecline}
        />
      </ButtonContainer>
    </StyleSheetManager>
  );
}

export default ChooseCompany;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Loader from 'src/components/Loader/Loader.component';
import StepperPercentage from 'src/components/Stepper/StepperPercentage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Delete } from 'src/images/icons/bin.svg';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { deleteProductTemplateMethodApi } from 'src/services/template/template.request';
import {
  Prospect,
  deleteProductTemplateData,
} from 'src/services/template/template.types';
import { H5 } from 'src/style-utils/typographic';

const UsageSettingTemplate: React.FC = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const prospect: Prospect = location?.state?.prospect;
  const templateName: string = location?.state?.templateName;
  const { productTemplateId } = useParams();
  const { mutate, isPending } = useMutation({
    mutationFn: (values: deleteProductTemplateData) =>
      deleteProductTemplateMethodApi(values),

    onSuccess: (res) => {
      navigate(RoutePath.usageSettingSavedTemplates, {
        state: { successDeleted: true },
      });
    },
    onError: (error: any) => {
      navigate(RoutePath.oops);
    },
  });

  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = () => {
    mutate({ productTemplateId });
  };
  return (
    <>
      <div className="container">
        <GoBackButton
          url={RoutePath.usageSettingSavedTemplates}
          label="lbl.savedTemplates"
        />
        <div className="mx-auto max-w-md">
          <H5>{templateName}</H5>
          <StepperPercentage dataPercentageStepper={prospect.Installments} />
          <ButtonContainer>
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
              minWidth="100%"
              variant="Tertiary"
              margin="0 auto 4rem"
            >
              <Translate id="lbl.deleteTemplate" />
            </Button>
          </ButtonContainer>
        </div>
      </div>
      {modalOpen && (
        <DoubleChoicePopUp
          handleClose={() => setModalOpen(false)}
          handleSecondAction={onSubmit}
          modalTitle="lbl.confirmDeleteTemplate"
          firstButtonLabel="lbl.confirm"
          lastButtonLabel="lbl.close"
          mainText={'text.confirmDeleteTemplate'}
          icon={Delete}
          iconColor="#FFAD0C"
        />
      )}
    </>
  );
};

export default UsageSettingTemplate;

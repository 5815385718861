import DevOnlyDashBoard from 'src/components/DevOnlyDashboard/DevOnlyDashboard.loadable';
import Footer from 'src/components/Footer/Footer.component';
import Header from 'src/components/Header/Header.component';
import { useHandle } from 'src/components/Header/Header.hooks';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useScrollToTopOnPathnameChange } from 'src/routers/routers.hooks';
import { useCheckErrorPageLoggedIn } from './MainLayout.hooks';
import { ENV } from '../../settings';
import { Sidebar } from '../Sidebar/Sidebar.component';
import { StyleSheetManager } from 'styled-components';
import { Container } from 'src/style/Container.style';
import { shouldForwardProp } from 'src/style-utils/functions';
import colors from 'src/style-utils/colors';

export const MainLayout: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const shouldLoadDevTools = ENV !== 'production';
  useScrollToTopOnPathnameChange();
  const isResponsive = useHandle().isResponsive;
  const hasFooter = useHandle().hasFooter;
  const hasNavBar = useHandle().hasNavBar;
  const isLoggedIn = useCheckErrorPageLoggedIn();

  return (
    <>
      <Header
        loggedIn={isLoggedIn}
        className={hasNavBar && isLoggedIn ? 'z-40 lg:hidden' : 'z-40'}
        setIsVisible={setIsVisible}
        isVisible={isVisible}
      />

      {isResponsive ? (
        <div className="block h-full min-h-lvh bg-[#F6F7F9] md:flex md:min-h-fit 2xl:justify-center">
          {isLoggedIn && hasNavBar && (
            <Sidebar
              loggedIn={true}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          )}
          <div
            className={
              hasNavBar
                ? 'flex w-full flex-col overflow-x-hidden bg-[#F6F7F9] 2xl:w-fit 2xl:min-w-[1240px] 2xl:max-w-[1240px]'
                : 'flex w-full flex-col overflow-x-hidden bg-[#F6F7F9]'
            }
          >
            <Outlet />
          </div>
        </div>
      ) : (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <Container backgroundStyle={colors.backgroundGrey}>
            <Outlet />
          </Container>
        </StyleSheetManager>
      )}
      {shouldLoadDevTools && <DevOnlyDashBoard />}
      {hasFooter && (
        <Footer
          className={
            hasFooter === 'onlyDesktop'
              ? 'hidden bg-white lg:block'
              : 'bg-white'
          }
        />
      )}
    </>
  );
};

import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  buyerMenu,
  collaboratorMenu,
  linkObject,
  merchantMenu,
  RoutePath,
} from 'src/routers/routers.config';
import { useSession } from 'src/services/session/session.context';
import { useAccountStore, useProfileStore } from 'src/store/store';

import ChangeAccountTypeCard from 'src/components/ChangeAccountTypeCard/ChangeAccountTypeCard.component';
import { ChangeAccountTypeCardArray } from 'src/components/ChangeAccountTypeCard/ChangeAccountTypeCard.config';
import SelectLanguage from 'src/components/Header/SelectLanguage/SelectLanguage.component';
import { useMutation } from '@tanstack/react-query';
import {
  typeBuyerGuidKey,
  typeMerchantGuidKey,
} from 'src/services/session/session.config';
import {
  buyerGetInfoMethodApi,
  merchantGetInfoMethodApi,
} from 'src/services/user/user.request';
import { Flex } from 'src/style/flexbox.style';
import { navigationStatusMappings } from 'src/utils/config/stateAccount';
import { RoleEnum } from 'src/utils/types/common.types';
import {
  AccordionArrow,
  AccordionBody,
  AccordionContainer,
  AccordionHeader,
  ResponsiveAccordionFlex,
  SidebarContainer,
  SidebarHeaderContainer,
} from './style/sidebar.style';
import { ReactComponent as NegativeLogo } from 'src/images/icons/opyn-paylater-negative.svg';
import DisableWindowScroll from '../DisableWindowScroll/DisableWindowScroll.component';
import { Button } from '../Button/Button.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { PNote } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type SidebarProps = {
  loggedIn?: boolean;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

export const Sidebar: React.FC<SidebarProps> = ({
  loggedIn,
  isVisible,
  setIsVisible,
}) => {
  const [expanded, setExpanded] = useState(0);
  const [active, setIsActive] = useState([] as any);
  const {
    Status: statoAccount,
    AccountType: tipoAccount,
    Role,
  } = useProfileStore((state) => state);
  const { pathname } = useLocation();
  const { onLogout } = useSession();
  const navigate = useNavigate();
  const {
    CanGiveCredit,
    HasPluginProduct,
    setUser,
    setValue,
    AccountType,
    CanBeMerchant,
  } = useProfileStore();
  const { BuyerRoles, MerchantRoles } = useAccountStore();
  const { mutate: mutateMerchantInfo } = useMutation({
    mutationFn: merchantGetInfoMethodApi,
    onSuccess: (res) => {
      setUser(res.data.ResultSet);
      setValue('AccountType', 'MERCHANT');
      setValue('Role', MerchantRoles && MerchantRoles[0].Role);
      const navigationPath =
        navigationStatusMappings[res.data.ResultSet.Status]?.['MERCHANT'];
      if (navigationPath) {
        navigate(navigationPath);
      }
    },
  });
  const { mutate: mutateBuyerInfo } = useMutation({
    mutationFn: buyerGetInfoMethodApi,
    onSuccess: (res) => {
      setUser(res.data.ResultSet);
      setValue('AccountType', 'BUYER');
      setValue('Role', BuyerRoles && BuyerRoles[0].Role);
      navigate(RoutePath.privateArea);
    },
  });

  useEffect(() => setCurrentActiveMenu(currentBranch), [pathname]);

  if (!AccountType) {
    return <Navigate to={RoutePath.login} />;
  }

  const { title, subTitle } =
    ChangeAccountTypeCardArray[AccountType as 'BUYER' | 'MERCHANT'];

  const showSwitch =
    (AccountType === 'MERCHANT' && BuyerRoles && BuyerRoles.length > 0) ||
    (AccountType === 'BUYER' &&
      MerchantRoles &&
      MerchantRoles.length > 0 &&
      CanBeMerchant);

  const showBannerRedirect =
    MerchantRoles &&
    MerchantRoles?.length < 1 &&
    CanBeMerchant &&
    AccountType === 'BUYER';

  let currentBranch: linkObject[] =
    tipoAccount === 'MERCHANT'
      ? Role === RoleEnum.owner
        ? merchantMenu
        : collaboratorMenu
      : buyerMenu;

  const switchAccount = () => {
    if (tipoAccount === 'BUYER' && MerchantRoles) {
      window.localStorage.removeItem(typeBuyerGuidKey);
      window.localStorage.setItem(typeMerchantGuidKey, MerchantRoles[0].Guid);
      mutateMerchantInfo();
    }
    if (tipoAccount === 'MERCHANT' && BuyerRoles) {
      window.localStorage.removeItem(typeMerchantGuidKey);
      window.localStorage.setItem(typeBuyerGuidKey, BuyerRoles[0].Guid);
      mutateBuyerInfo();
    }
    setIsVisible(false);
  };

  const setCurrentActiveMenu = (
    menuList: linkObject[],
    parent?: linkObject,
    parentIndex: number = 0,
  ) => {
    const urlsMap: Map<string, string> = new Map([
      ['order-detail', 'orders'],
      ['create-link', 'link-di-pagamento'],
    ]);

    for (let index = 0; index < menuList.length; index++) {
      const element = menuList[index];
      if (element.child?.length) {
        setCurrentActiveMenu(element.child, element, index);
      } else if (element.url?.includes(pathname) && parent) {
        setIsActive([parent, element]);
        setExpanded(parentIndex);
        return;
      } else if (
        element.url?.includes(pathname) ||
        element.url?.includes(urlsMap.get(pathname.split('/')[1]) as string)
      ) {
        setIsActive([element]);
        return;
      }
    }
  };

  const goToOpyn = () => {
    (window as any).open('https://www.opyn.eu/landing-page-opl-b2m');
    setIsVisible(false);
  };

  const displayMenuItems = () => {
    const mainSection = currentBranch.filter((element) => !element.section);
    const monitoringSection = currentBranch.filter(
      (element) => element.section === 'monitoring',
    );
    const otherSection = currentBranch.filter(
      (element) => element.section === 'other',
    );

    const renderItems = (linkSidebar: linkObject, index: number) => {
      const disabled =
        linkSidebar.viewState &&
        linkSidebar.viewState.includes(`${statoAccount}`);
      const showLink = !(
        (linkSidebar.hideByCantGiveCredit && !CanGiveCredit) ||
        (linkSidebar.hasPluginProduct && !HasPluginProduct)
      );
      return linkSidebar.child
        ? showLink && (
            <AccordionContainer
              data-disabled={disabled}
              data-expanded={disabled ? false : expanded === index}
              key={index}
              id={linkSidebar.label}
            >
              <AccordionHeader
                onClick={() => {
                  setExpanded(expanded === index ? -1 : index);
                }}
              >
                <ResponsiveAccordionFlex
                  className={active.includes(linkSidebar) ? 'active' : ''}
                >
                  <Translate id={linkSidebar.label} />
                </ResponsiveAccordionFlex>
                <AccordionArrow svgIconComponent={ArrowDown} iconSize="4rem" />
              </AccordionHeader>

              <AccordionBody>
                <div style={{ overflow: 'hidden' }}>
                  {linkSidebar.child.map((child, index) => {
                    const disabled =
                      child.viewState &&
                      child.viewState.includes(`${statoAccount}`);
                    const showLink = !(
                      (child.hideByCantGiveCredit && !CanGiveCredit) ||
                      (child.hasPluginProduct && !HasPluginProduct)
                    );
                    return (
                      showLink && (
                        <Button
                          key={`b-${index}`}
                          translatedText={child.label}
                          disabled={disabled}
                          className={active.includes(child) ? 'active' : ''}
                          i18n
                          variant="MenuButton"
                          padding="0.5rem"
                          minWidth="0"
                          height="40px"
                          onClick={() => {
                            navigate(child.url as string);
                            setIsVisible(false);
                            setIsActive([linkSidebar, child]);
                          }}
                        />
                      )
                    );
                  })}
                </div>
              </AccordionBody>
            </AccordionContainer>
          )
        : linkSidebar.url && showLink && (
            <Button
              key={index}
              translatedText={linkSidebar.label}
              disabled={disabled}
              id={linkSidebar.label}
              className={active.includes(linkSidebar) ? 'active' : ''}
              i18n
              variant="MenuButton"
              padding="0.5rem"
              minWidth="0"
              height="40px"
              onClick={() => {
                navigate(linkSidebar.url as string);
                setIsVisible(false);
                setIsActive([linkSidebar]);
                setExpanded(-1);
              }}
            />
          );
    };
    return (
      <>
        <Flex flexDirection="column" gap="0.25rem" className="pt-4 lg:pt-0">
          {mainSection.map((linkSidebar, index) =>
            renderItems(linkSidebar, index),
          )}
        </Flex>

        {!!monitoringSection.length && (
          <>
            <Flex flexDirection="column" gap="0.25rem">
              <PNote className="p-1.5 font-medium uppercase">
                <Translate id={'lbl.monitoringSection'} />
              </PNote>

              {monitoringSection.map((linkSidebar, index) =>
                renderItems(linkSidebar, index),
              )}
            </Flex>
          </>
        )}
        {!!otherSection.length && (
          <>
            <Flex flexDirection="column" gap="4px">
              <PNote className="p-1.5 font-medium uppercase">
                <Translate id={'lbl.other'} />
              </PNote>

              {otherSection.map((linkSidebar, index) =>
                renderItems(linkSidebar, index),
              )}
              <Button
                translatedText="lbl.exit"
                onClick={onLogout}
                id="lbl.exit"
                i18n
                variant="MenuButton"
                padding="0.5rem"
                minWidth="0"
                height="40px"
              />
            </Flex>
          </>
        )}
      </>
    );
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {isVisible ? <DisableWindowScroll /> : null}
      <SidebarContainer
        data-component="sidebar"
        className={
          isVisible
            ? 'open md:open px-6 py-4 lg:px-3'
            : 'md:open px-6 py-4 lg:px-3'
        }
      >
        <Flex flexDirection="column" gap="32px">
          <SidebarHeaderContainer>
            <Icon
              svgIconComponent={NegativeLogo}
              iconHeight={'18px'}
              iconSize={'15rem'}
              className="hidden md:block"
            />
          </SidebarHeaderContainer>
          {displayMenuItems()}

          {showSwitch && (
            <Flex gap="3.2rem" maxWidth="41.6rem" flexDirection="column">
              <hr
                style={{
                  width: '100%',
                  margin: '0',
                  border: '1px solid #35302F',
                }}
              />
              <ChangeAccountTypeCard
                title={title}
                subtitle={subTitle}
                onClickHandle={switchAccount}
              />
            </Flex>
          )}
          {showBannerRedirect && (
            <Flex gap="3.2rem" flexDirection="column" maxWidth="41.6rem">
              <hr
                style={{
                  width: '100%',
                  margin: '0',
                  border: 'none',
                  height: '1px',
                  background: '#35302F',
                }}
              />
              <ChangeAccountTypeCard
                title="lbl.sellToOthersCompanies"
                subtitle="lbl.sellToOthersCompaniesDescr"
                onClickHandle={goToOpyn}
                data-wa="become merchant"
              />
            </Flex>
          )}

          <SelectLanguage />
        </Flex>
      </SidebarContainer>
    </StyleSheetManager>
  );
};

import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as InfoIcon } from 'src/images/icons/information.svg';
import colors from 'src/style-utils/colors';
import { PLegal } from 'src/style-utils/typographic';
import { TooltipStyled } from './Tooltip.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  tooltipText?: string;
  tooltipPosition?: string;
};

enum TooltipPositionEnum {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export type TooltipPosition = `${TooltipPositionEnum}`;

const Tooltip: React.FC<Props> = ({ tooltipText, tooltipPosition }) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <TooltipStyled data-component="tooltip">
        <Icon
          svgIconComponent={InfoIcon}
          color={colors.lightBlueBorder}
          iconSize="2.2rem"
        />
        <div
          className={
            tooltipPosition === TooltipPositionEnum.Left
              ? 'tooltip-left'
              : 'tooltip'
          }
        >
          <PLegal textColor="unset">
            <Translate id={tooltipText} />
          </PLegal>
        </div>
      </TooltipStyled>
    </StyleSheetManager>
  );
};

export default Tooltip;

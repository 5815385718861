import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import {
  PaymentFlowContextType,
  PaymentMethodResult,
} from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { ChangeMethodFormData } from 'src/services/payments/payments.types';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { cardIsExpired } from 'src/utils/functions/cardExpired';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import Toast from 'src/components/Toast/Toast.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ChoosePaymentMethodPage() {
  const [showPopUp, setShowPopUp] = useState(false);
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const purchasing = currentOrder?.OrderId !== undefined;
  const {
    paymentMethods,
    type,
    selectedMethodId,
    SelectedMethod,
    formattedAmount,
    payNowHandler,
    changeMethodHandler,
    goToStripeFormHandler,
    paymentMethodBackup,
    confirmOrderHandler,
  } = useOutletContext<PaymentFlowContextType>();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      paymentMethodId: selectedMethodId.toString(),
    } as ChangeMethodFormData,
    mode: 'onSubmit',
  });
  const setTransaction = useCurrentTransactionStore(
    (state) => state.setTransaction,
  );
  const paymentMethodBackupExpired = cardIsExpired(paymentMethodBackup);
  const paymentIntent = useCurrentTransactionStore(
    (state) => state.paymentIntent,
  );
  const nextInstallmentDueDate = useCurrentTransactionStore(
    (state) => state.nextInstallmentDueDate,
  );
  const profileStore = useProfileStore((state) => state);
  const invalid = localStorage.getItem('opyn-invalid-payment-method');

  const onSubmit = (values: ChangeMethodFormData) => {
    const sepaMethod =
      paymentMethods.find(
        (method) =>
          method.Id === +values.paymentMethodId && method.Type === 'sepa_debit',
      ) !== undefined;

    if (type === 'change') {
      changeMethodHandler({ ...values });
      return;
    }
    if (
      sepaMethod &&
      (paymentMethodBackup === undefined || paymentMethodBackupExpired)
    ) {
      const purchasingMethod = paymentMethods.find(
        (method) => method.Id === +values.paymentMethodId,
      );
      if (purchasingMethod) {
        setTransaction(
          'ChangedMethod',
          purchasingMethod as PaymentMethodResult,
        );
        navigate(`${RoutePath.paymentFlow}${RoutePath.setMethods}`);
      }

      return;
    }
    if (purchasing) {
      confirmOrderHandler({
        paymentMethodId: values.paymentMethodId,
        orderGuid: currentOrder?.OrderGuid,
      });
      return;
    }
    payNowHandler(values);
  };

  const index =
    paymentMethods &&
    paymentMethods.map((item) => item.Id).indexOf(selectedMethodId);

  if (SelectedMethod) {
    paymentMethods.splice(index, 1);
    paymentMethods.unshift(SelectedMethod);
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {invalid === 'true' && (
        <Toast
          variant="error"
          title="text.cantUsePaymentMethod"
          subTitle="text.cantUsePaymentMethodSubtile"
        />
      )}
      <div className="container pb-8">
        <GoBackButton />
        <div className="mx-auto max-w-oxl">
          <div className="mx-auto grid max-w-olg gap-4 lg:text-center">
            <H4>
              <Translate id="text.changePaymentMethod" />
            </H4>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Flex
                flexDirection="column"
                align="center"
                gap="2.4rem"
                padding="2.4rem 0"
              >
                {paymentMethods &&
                  paymentMethods.map((payCard, i: number) => {
                    return <PaymentMethodElement key={i} {...payCard} />;
                  })}
              </Flex>
              <DividerOr />
              <ButtonCard
                createPaymentIntentHandler={() => goToStripeFormHandler()}
                type={type}
              />
              <ButtonContainer marginTop="4rem">
                <Button
                  variant="Primary"
                  sizeOnDesktop="medium"
                  type="button"
                  minWidth="100%"
                  onClick={() =>
                    type === 'change'
                      ? setShowPopUp(true)
                      : methods.handleSubmit(onSubmit)()
                  }
                  disabled={!methods.formState.isValid}
                >
                  <Translate
                    id={
                      type === 'pay' && paymentIntent
                        ? 'text.payNow'
                        : type === 'pay' && !paymentIntent
                          ? 'lbl.confirmPayFirstInstallmentDate'
                          : type === 'change' && paymentIntent
                            ? 'lbl.confirm&PayDate'
                            : type === 'change' && !paymentIntent
                              ? 'lbl.confirm&PayDate'
                              : 'text.continue'
                    }
                    data={{
                      paymentValue: formattedAmount,
                      paymentDate: nextInstallmentDueDate,
                      firstInstallmentDueDate: refactorFormatDate(
                        profileStore.currentOrder.FirstInstallmentDueDate,
                        'DD/MM/YYYY',
                      ),
                    }}
                  />
                </Button>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
        {showPopUp && (
          <DoubleChoicePopUp
            handleSecondAction={methods.handleSubmit(onSubmit)}
            modalTitle="text.youAreChangingPayment"
            handleClose={() => setShowPopUp(false)}
            firstButtonLabel="lbl.confirm"
            lastButtonLabel="lbl.cancelAndGoBack"
            mainText="text.youAreChangingPaymentSubtitle"
            icon={AlertIcon}
          />
        )}
      </div>
    </StyleSheetManager>
  );
}

export default ChoosePaymentMethodPage;

import Loader from 'src/components/Loader/Loader.component';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getStripeRegistrationLink } from 'src/services/stripe/stripe.config';
import { getStripeRegistrationLinkMethodApi } from 'src/services/stripe/stripe.request';
import { useProfileStore } from 'src/store/store';

export interface RedirectStripeProps {}

const RedirectStripe: React.FC<RedirectStripeProps> = () => {
  const setUser = useProfileStore((state) => state.setUser);
  const { state } = useLocation();

  const { isFetching, isSuccess, isError, data } = useQuery({
    queryKey: [getStripeRegistrationLink],
    queryFn: getStripeRegistrationLinkMethodApi,
  });

  useEffect(() => {
    if (isSuccess) {
      setUser({ stripeLink: data.data.ResultSet });
      if (data.data.ResultSet.length > 1) {
        (window as any).open(data.data.ResultSet, '_self');
      }
    }
    if (isError) (window as any).open(RoutePath.oops, '_self');
  }, [isSuccess, isError]);

  if (isFetching) {
    return (
      <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
    );
  }
  return <Navigate to={state?.prevPath} replace />;
};

export default RedirectStripe;

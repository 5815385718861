import ResponsiveAccordion from 'src/components/ResponsiveAccordion/ResponsiveAccordion.component';
import Tabs from 'src/components/Tabs/Tabs.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AdvPayHeroImgage } from 'src/images/products/incasso_anticipato_hero.svg';
import {
  ButtonCustom,
  CustomGrid,
  CustomHomeH2,
  CustomSeparator,
} from 'src/pages/privateArea/partials/merchant/style/MerchantArea.style';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useProfileStore } from 'src/store/store';
import { H3, P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { AccountStatus } from 'src/utils/config/stateAccount';
import {
  advPayLegalNotes,
  advPayListItems,
  tabsAdvancePaymentInfo,
} from './AdvancePaymentInfo.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const AdvancePaymentInfo: React.FC = () => {
  const accountStatus = useProfileStore((state) => state.Status);
  const navigate = useNavigate();

  const clickCTAHandler = () =>
    navigate(RoutePath.advancePaymentRequest + RoutePath.chooseLink);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="bg-white py-16">
        <CustomGrid>
          <AdvPayHeroImgage width="100%" height="auto" />
          <Flex fluid flexDirection="column" gap="2rem">
            <CustomHomeH2>
              <Translate id="text.advPayInfoTitle" />
            </CustomHomeH2>
            <ButtonCustom
              variant="Primary"
              type="button"
              disabled={accountStatus !== AccountStatus.completed}
              onClick={clickCTAHandler}
              i18n
              translatedText="lbl.startNow"
            />
          </Flex>
        </CustomGrid>
      </div>

      <CustomSeparator />

      <div className="container py-16">
        <H3 light className="mb-8 lg:mb-14">
          <Translate id="text.advPaySectionTitle1" />
        </H3>
        <Tabs tabs={tabsAdvancePaymentInfo} columnOnMobile />
      </div>

      <div className="container py-16">
        <H3 light className="mb-8 text-center lg:mb-14">
          <Translate id="text.advPayAccordionTitle" />
        </H3>
        <ResponsiveAccordion listItems={advPayListItems} />
      </div>

      <div className="container grid gap-3 py-16">
        <P bold>
          *<Translate id={advPayLegalNotes.title} />
        </P>
        {advPayLegalNotes.content.map((key, index) => (
          <PSmall key={index}>
            <Translate id={key} />
          </PSmall>
        ))}
      </div>
    </StyleSheetManager>
  );
};

export default AdvancePaymentInfo;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import {
  DataTypeArray,
  UpdateSignUpData,
  updateSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useProfileStore, useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import { formConfigRegistrationStepPhone } from './FormPhone.helpers';
import { SignInSendOtpGuestMethodApi } from 'src/services/login/login.request';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import { Privacy } from 'src/pages/registration/registrationLight/style/registrationLight.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const FormPhone: React.FC<FormProps> = ({
  nextPath,
  step,
  stepMax,
  login,
  privacy,
}) => {
  const navigate = useNavigate();
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const setValue = useRegistrationStore((state) => state.setValue);
  const buyerPhone = useProfileStore((state) => state.currentOrder?.BuyerPhone);
  const buyerPrefix = useProfileStore(
    (state) => state.currentOrder?.BuyerPhonePrefix,
  );
  const { initialValues, resolver } = formConfigRegistrationStepPhone(
    login ? maskPhoneNumber(buyerPhone) : buyerPhone,
    buyerPrefix,
  );
  const { mutate, isPending } = useMutation({
    mutationFn: (values: any) => updateSignUpSessionMethodApi(values),
    onSuccess: (res) => {
      if (res.data.ResultSet) navigate(nextPath);
    },
    onError: (error: any) => {
      const errors = servicesErrorsMap(error.response, { phone: phoneField });
      for (const key in errors) {
        methods.setError(phoneField, { message: errors[key] });
      }
    },
  });

  const {
    mutate: mutateSignInSendOtpGuest,
    isPending: isLoadingSignInSendOtpGuest,
  } = useMutation({
    mutationFn: SignInSendOtpGuestMethodApi,
    onSuccess: (res) => {
      setValue('guestSignInGuid', res.data.ResultSet);
      navigate(nextPath);
    },
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    setValue('mobile', data[phoneField]);
    setValue('prefix', data[prefix]);
    if (login) {
      setValue('mobile', buyerPhone);
      mutateSignInSendOtpGuest({
        [phoneField]: login ? buyerPhone : data[phoneField],
        [prefix]: data[prefix],
      });
      return;
    }
    const UpdateData: UpdateSignUpData = {
      DataType: DataTypeArray.phone,
      DataValue: data[phoneField],
      DataValueAdd: data[prefix],
      SessionGuid: sessionGuid,
    };
    mutate(UpdateData);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="lbl.mobileNumber"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.insertMobileNumber" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <PhoneField isPresentMessage name={phoneField} disabled={login} />
              <ButtonContainer>
                <SubmitButton
                  minWidth="100%"
                  disabled={isPending || isLoadingSignInSendOtpGuest}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
                {privacy && (
                  <Privacy>
                    <Translate
                      id="link.privacyLink"
                      extraTranslationProps={{
                        components: { u: <a /> },
                      }}
                    />
                  </Privacy>
                )}
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
};

export default FormPhone;

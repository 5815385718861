import { Button } from 'src/components/Button/Button.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Download } from 'src/images/icons/download_icon.svg';
import { ReactComponent as Pdf } from 'src/images/icons/file-PDF.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RoutePath } from 'src/routers/routers.config';
import { getDocuments } from 'src/services/user/user.config';
import {
  getDocumentData,
  getDocumentMethodApi,
  getDocumentsMethodApi,
} from 'src/services/user/user.request';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { downloadFile } from 'src/utils/functions/download';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import {
  DocumentCardContainer,
  DocumentDate,
  DocumentName,
  WrapperTextPdf,
} from './style/DocumentsPage.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const DocumentsPage: React.FC = () => {
  const { isLoading, data: dataDocuments } = useQuery({
    queryKey: [getDocuments],
    queryFn: () => getDocumentsMethodApi(),
  });
  const { isPending: isLoadingDocument, mutate: mutateDocument } = useMutation({
    mutationFn: (values: getDocumentData) => getDocumentMethodApi(values),
    onSuccess: (res, variables) => {
      const base64Data = res.data.ResultSet;
      downloadFile(base64Data, variables.documentName, true);
    },
  });
  if (isLoading || isLoadingDocument) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerPadding>
        <GoBackButton label="text.profile" url={RoutePath.account} />
        <Flex flexDirection="column">
          <TextDefaultPage title="text.documents" />
          {dataDocuments?.data.ResultSet.map((document, index) => (
            <DocumentCardContainer key={index}>
              <WrapperTextPdf>
                <Icon
                  iconSize="2.8rem"
                  iconHeight="2.8rem"
                  svgIconComponent={Pdf}
                />
                <Flex flexDirection="column">
                  <DocumentName>{document.Name}</DocumentName>
                  <DocumentDate>
                    {refactorFormatDate(document.Created)}
                  </DocumentDate>
                </Flex>
              </WrapperTextPdf>
              <Button
                underline={false}
                variant="LinkPrimary"
                padding="0"
                rightChild={
                  <Icon
                    iconHeight="2rem"
                    iconSize="2rem"
                    fontWeight="500"
                    svgIconComponent={Download}
                  />
                }
                onClick={() => {
                  mutateDocument({
                    documentId: document.Id,
                    documentName: document.Name,
                  });
                }}
              >
                <Translate id={'text.download'} />
              </Button>
            </DocumentCardContainer>
          ))}
        </Flex>
      </ContainerPadding>
    </StyleSheetManager>
  );
};

export default DocumentsPage;

import { useI18n } from 'src/services/i18n/i18n.hooks';
import { P } from 'src/style-utils/typographic';
import colors from 'src/style-utils/colors';
import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const FeeColumn: React.FC<ListRequestsResultsTable> = ({ Fee }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <P textColor={colors.textTableBody}>
        - {formatCurrency(Fee, { removeWhiteSpaces: true })}
      </P>
    </StyleSheetManager>
  );
};

export default FeeColumn;

import { Button } from 'src/components/Button/Button.component';
import CategoryCard from 'src/components/CategoryCard/CategoryCard.component';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { LinkFlowContext } from 'src/pages/createLinkFlow/CreateLinkFlow.helper';
import Translate from 'src/components/Translate/Translate.component';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { H4 } from 'src/style-utils/typographic';
import { Grid, ResponsiveContainer } from 'src/style/Container.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ChooseCategory() {
  const navigate = useNavigate();
  const categories = useOutletContext<LinkFlowContext>().categories;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer>
        <Button
          variant="LinkPrimary"
          gap="0"
          padding="0"
          minWidth="fit-content"
          onClick={() => navigate(-1)}
          leftChild={
            <Icon iconSize="4rem" iconHeight="4rem" svgIconComponent={Back} />
          }
          children={<Translate id="lbl.homepage" />}
        />
        <H4 marginBottom="2.4rem">
          <Translate id="lbl.chooseCategory" />
        </H4>
        <Grid responsive gap="2rem">
          {categories.map((item, index: number) => (
            <CategoryCard categoryCode={item} key={index} />
          ))}
        </Grid>
      </ResponsiveContainer>
    </StyleSheetManager>
  );
}

export default ChooseCategory;

import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowDown } from 'src/images/icons/arrow_down.svg';
import { useState } from 'react';
import { PluginCheckoutResponse } from 'src/services/ecommerce/ecommerce.types';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  AccordionArrow,
  AccordionBody,
  CompanyDataCardContainer,
  DataContainer,
} from './CompanyDataCard.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  companyData?: PluginCheckoutResponse;
};

const CompanyDataCard = ({ companyData }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const isBuyerItalian = isItalian(companyData?.BuyerCountry);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div>
        <CompanyDataCardContainer
          onClick={() => setCollapsed(!collapsed)}
          data-expanded={collapsed}
        >
          <Flex fluid justify="space-between" align="center">
            <P bold colorBlack>
              <Translate id="lbl.companyData" />
            </P>
            <AccordionArrow svgIconComponent={ArrowDown} iconSize="2.4rem" />
          </Flex>
          <AccordionBody>
            <div style={{ overflow: 'hidden' }}>
              <DataContainer>
                <div>
                  <PSmall>
                    <Translate id="text.companyName" />
                  </PSmall>
                  <PSmall bold colorBlack>
                    {companyData?.BuyerName}
                  </PSmall>
                </div>
                {isBuyerItalian ? (
                  <div>
                    <PSmall>
                      <Translate id="lbl.payByLinkVatCode" />
                    </PSmall>
                    <PSmall bold colorBlack>
                      {companyData?.BuyerFiscalCode}
                    </PSmall>
                  </div>
                ) : (
                  companyData?.BuyerVatCode && (
                    <div>
                      <PSmall>
                        <Translate id="text.vatNumber" />
                      </PSmall>
                      <PSmall bold colorBlack>
                        {companyData?.BuyerVatCode}
                      </PSmall>
                    </div>
                  )
                )}
                {!isBuyerItalian && (
                  <>
                    <div>
                      <PSmall>
                        <Translate id="lbl.postalCode" />
                      </PSmall>
                      <PSmall bold colorBlack>
                        {companyData?.BuyerPostalCode}
                      </PSmall>
                    </div>

                    <div>
                      <PSmall>
                        <Translate id="text.address" />
                      </PSmall>
                      <PSmall bold colorBlack>
                        {companyData?.BuyerAddress}
                      </PSmall>
                    </div>
                  </>
                )}
              </DataContainer>
            </div>
          </AccordionBody>
        </CompanyDataCardContainer>
        <NeedHelpButton
          variant="Tertiary"
          margin="1.6rem auto"
          sizeOnDesktop="small"
          minWidth="0"
          display="flex"
          className="hideOnMobile"
        />
      </div>
    </StyleSheetManager>
  );
};

export default CompanyDataCard;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import StepperRemodulation from 'src/components/Stepper/StepperRemodulation.component';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { BackArrow } from 'src/pages/PayByLinkDetailPage/style/PayByLinkDetailPage.style';
import { createBrowserHistory } from '@remix-run/router';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function BeforeEditInstallmentPlan() {
  const navigate = useNavigate();
  const {
    OrderId,
    Installments,
    EditPlanAmount,
    EditStartINo,
    Description,
    BuyerName,
  } = useOutletContext<PayByLinkDetailsContext>();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const history = createBrowserHistory();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerPadding>
        <BackArrow onClick={() => history.go(-1)} />

        <Flex gap="3.2rem" flexDirection="column" paddingBottom="2.8rem">
          <H4 light colorGray>
            <Translate id="text.shouldYouKnowTitlePage" />
          </H4>
          <div>
            <P bold>
              <Translate id="text.shouldYouKnowTitle1" />
            </P>
            <P>
              <Translate id="text.shouldYouKnowDescription1" />
            </P>
          </div>
          <div>
            <P bold>
              <Translate id="text.shouldYouKnowTitle2" />
            </P>
            <P>
              <Translate id="text.shouldYouKnowDescription2" />
            </P>
          </div>
          <div>
            <P bold>
              <Translate id="text.shouldYouKnowTitle3" />
            </P>
            <P>
              <Translate id="text.shouldYouKnowDescription3" />
            </P>
          </div>
          <div>
            <P bold>
              <Translate id="text.shouldYouKnowTitle4" />
            </P>
            <div>
              <StepperRemodulation
                amountRemodulation={EditPlanAmount}
                numInstallmentToShow={EditStartINo}
                dataStepper={Installments as any}
                onlyToPay
              />
            </div>
          </div>
          <div>
            <P bold>
              <Translate id="text.shouldYouKnowTitle5" />
            </P>
            <P>
              <Translate id="text.shouldYouKnowDescription5" />
            </P>
          </div>
        </Flex>
        <ButtonContainer margin="2rem auto auto">
          <Button
            onClick={() => {
              setValue('stateFlow', true);
              setValue('remodulation', true);
              setValue('amount', EditPlanAmount);
              setValue('orderId', OrderId);
              setValue('description', Description);
              setValue('buyerName', BuyerName);
              navigate(
                `${RoutePath.createLink}FLX/RML/${RoutePath.stepImport}`,
              );
            }}
          >
            <Translate id="lbl.next" />
          </Button>
        </ButtonContainer>
      </ContainerPadding>
    </StyleSheetManager>
  );
}

export default BeforeEditInstallmentPlan;

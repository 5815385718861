import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as Arrow } from 'src/images/icons/arrow_back_right.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import colors from 'src/style-utils/colors';
import { H5 } from 'src/style-utils/typographic';
import { CategoryCodes } from 'src/utils/types/common.types';
import { mapCategories } from './CategoryCard.config';
import {
  Action,
  CategoryCardWrapper,
  Label,
  SvgContainer,
} from './style/categoryCard.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const CategoryCard = ({ categoryCode }: { categoryCode: CategoryCodes }) => {
  const navigate = useNavigate();
  const { title, description, label, SvgIcon } = mapCategories[categoryCode];
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CategoryCardWrapper data-component="category-card">
        <SvgContainer>
          <SvgIcon />
        </SvgContainer>
        <Label textColor="white">
          <Translate id={label} />
        </Label>
        <div>
          <H5 textColor={colors.textColorGrey}>
            <Translate id={title} />
          </H5>
          <H5>
            <Translate id={description} />
          </H5>
        </div>
        <Action
          onClick={() =>
            navigate(
              RoutePath.createLink +
                categoryCode +
                '/' +
                RoutePath.chooseProduct,
            )
          }
        >
          <Icon iconSize="3.6rem" svgIconComponent={Arrow} />
        </Action>
      </CategoryCardWrapper>
    </StyleSheetManager>
  );
};

export default CategoryCard;

import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { H5, P } from 'src/style-utils/typographic';
import {
  Cash,
  configMovementsTitle,
  ServiceCosts,
  Withdrawals,
} from './MovementsCard.config';
import { MovementsCardContainer } from './style/MovementsCard.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type MovementsTitleType =
  | typeof Cash
  | typeof ServiceCosts
  | typeof Withdrawals;

type MovementsCardProps = {
  title: MovementsTitleType;
  amount: number | undefined;
};

const MovementsCard: React.FC<MovementsCardProps> = ({ title, amount }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const { operator } = configMovementsTitle[title];
  const amountColor =
    title === Cash ? colors.greenStatus : colors.textColorBlack;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <MovementsCardContainer
        data-component="movements-card"
        padding="1.6rem"
        flexDirection="column"
        align="flex-start"
      >
        <P>
          <Translate id={configMovementsTitle[title].label} />
        </P>
        <H5 style={{ color: amountColor }}>
          {amount !== 0 && operator}
          {amount && formatCurrency(amount)}
          {amount === 0 && '€'}
        </H5>
      </MovementsCardContainer>
    </StyleSheetManager>
  );
};

export default MovementsCard;

import styled from 'styled-components';
import Icon from 'src/components/Icon/Icon.component';
import { withOneLineEllipsis } from 'src/style-utils/overflow';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { SelectErrorProps } from 'src/components/Select/Select.types';
import { StyledProps } from 'src/@types/styled-components';

export const ListboxButtonRoot = styled.div`
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events */
  pointer-events: none;
  padding: ${({ theme }) => theme.select.button.padding};
  border: ${({ theme }) => theme.select.button.border};
  border-radius: ${({ theme }) => theme.select.button.borderRadius};
  border-right: ${({ theme }) => theme.select.button.borderRight};
  background: unset;
  display: flex;
  justify-content: ${({ theme }) => theme.select.button.justify};
  align-items: center;
  flex-flow: row nowrap;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  left: 0;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexes.listbox.button};
  box-shadow: ${({ theme }) => theme.select.button.boxShadow};

  /* Use 'ListboxRoot' to set the height. */
  height: 100% !important;
`;

export const ListboxButtonContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  letter-spacing: -0.15px;
  align-items: center;
  white-space: nowrap;
  flex: 1 0 auto;
  padding: ${({ theme }) => theme.select.button.contentPadding};
  position: relative;
`;

export const IconWrapper = styled.div`
  flex: 0 0 auto;
  display: inline-block;
`;

export const Label = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.select.typography.fontSize};
  font-weight: ${({ theme }) => theme.select.typography.fontWeight};
  line-height: 1.0625;
  margin: 0 1.2rem 0 0;
  flex: 0 0 auto;

  ${withOneLineEllipsis};

  ${media.desktop`
    font-size: ${({ theme }: StyledProps<{}>) =>
      theme.select.typography.fontSize};
  `};
`;

export const DropdownListRoot = styled.div<
  StyledProps<{ isExpanded: boolean }>
>`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  border: 1px solid ${colors.inputBorderColor};
  margin-top: ${({ theme }) => theme.select.list.marginTop};
  border-radius: 0.4rem;
  background: #fff;
  top: 0.4rem;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;
  ${({ isExpanded, theme }) => {
    if (isExpanded) {
      return `
      /* Has to be lower than  'SelectItemLabelRoot'' z-index'. */
        z-index: ${theme.zIndexes.listbox.listExpanded};
        height: ${theme.select.list.expanded.height};
        max-height: ${theme.select.list.expanded.maxHeight};
      `;
    }

    return `
      z-index: ${theme.zIndexes.listbox.list};
      visibility: hidden;
      height: 4rem;
    `;
  }};
`;

export const DropdownList = styled.ul`
  display: block;
  background: ${colors.transparent};
  width: 100%;
  /* pointer-events: none; */
  overflow: hidden;
  padding: ${({ theme }) => theme.select.list.padding};

  ${media.desktop`
    padding: ${({ theme }: StyledProps<{}>) =>
      theme.select.list.desktop.padding};
  `};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.select.icon.color};
`;

export const ButtonIconWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.select.buttonWrapper.right};
  flex: 0 0 auto;
  z-index: 3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ItemBase = styled.li`
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.select.listItem.padding};
  margin: ${({ theme }) => theme.select.listItem.margin};
  overflow: hidden;
  outline: none;
  height: ${({ theme }) => theme.select.listItem.height};
  font-size: ${({ theme }) => theme.select.typography.fontSize};
  font-weight: ${({ theme }) => theme.select.typography.fontWeight};

  ${media.desktop`
    font-size: ${({ theme }: StyledProps<{}>) =>
      theme.select.typography.desktop.fontSize};
  `};

  & > * {
    font-size: inherit;
    font-weight: iherit;
    pointer-events: none;
    ${withOneLineEllipsis};
  }
`;

export const HiddenPlaceholder = styled(ItemBase)`
  visibility: hidden;
  pointer-events: none;
  position: relative;
  z-index: 1;
`;

export const DropdownListItem = styled(ItemBase)`
  pointer-events: auto;
  padding: 0.5rem;
  &[aria-selected='true'] {
    background: ${({ theme }) => theme.select.listItem.selected.background};
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const ListboxRoot = styled.div<SelectErrorProps>`
  display: inline-flex;
  outline: none;
  margin: ${({ theme }) => theme.select.listboxRoot.margin};
  max-width: ${({ theme }) => theme.select.listboxRoot.maxWidth};
  min-width: ${({ theme }) => theme.select.listboxRoot.minWidth};
  height: ${({ theme }) => theme.select.listboxRoot.height};
  color: ${({ theme }) => theme.select.typography.color};

  /* Creates a static context. */
  position: relative;
  flex-flow: column nowrap;
  ${media.tablet`
    height: ${({ theme }: StyledProps<{}>) =>
      theme.select.listboxRoot.tablet.height};
  `};
  ${media.desktop`
    max-width: ${({ theme }: StyledProps<{}>) =>
      theme.select.listboxRoot.desktop.maxWidth};
    min-width: ${({ theme }: StyledProps<{}>) =>
      theme.select.listboxRoot.desktop.minWidth};
    height: ${({ theme }: StyledProps<{}>) =>
      theme.select.listboxRoot.desktop.height};
    margin: ${({ theme }: StyledProps<{}>) =>
      theme.select.listboxRoot.desktop.margin};
  `};

  ${({ isError }) => {
    if (isError) {
      return `
        & > ${ListboxButtonRoot},
        & > ${ListboxButtonRoot}:focus {
          border-color: ${colors.redInput};
        }
      `;
    }
  }};
`;

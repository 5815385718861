import Translate from 'src/components/Translate/Translate.component';

import { H5, P, PLegal } from 'src/style-utils/typographic';
import { Grid } from 'src/style/Container.style';
import { CardProps, InfoCards, InfoCardsResend } from './CreateLinkInfo.helper';
import {
  CLInfoCardsWrapper,
  CLInfoContainer,
  CustomColoredSection,
  IconCircle,
} from './CreateLinkInfo.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const InfoCard: React.FC<CardProps> = ({ SvgIcon, title, content }) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CLInfoContainer>
        <IconCircle>
          <SvgIcon width="3.6rem" height="3.6rem" />
        </IconCircle>
        <H5>
          <Translate id={title} />
        </H5>
        <P>
          <Translate id={content} />
        </P>
      </CLInfoContainer>
    </StyleSheetManager>
  );
};

export const CreateLinkInfo: React.FC<{ resent?: boolean }> = ({
  resent = false,
}) => {
  const cards = resent ? InfoCardsResend : InfoCards;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CustomColoredSection
        padding="2.4rem 0 4rem 0"
        bgColor="white"
        margin="6.4rem 0 0 0"
      >
        <CLInfoCardsWrapper>
          <H5 light>
            <Translate id="avoidExp.sectionTitle" />
          </H5>
          <Grid responsive gap="1.6rem" gapOnDesktop="2.4rem">
            {cards.map((card, key) => (
              <InfoCard {...card} key={key} />
            ))}
          </Grid>
          <PLegal>
            <Translate id="avoidExp.note" />
          </PLegal>
        </CLInfoCardsWrapper>
      </CustomColoredSection>
    </StyleSheetManager>
  );
};

export default CreateLinkInfo;

import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useQuery } from '@tanstack/react-query';
import { getBuyerInstallments } from 'src/services/installments/installments.config';
import { getInstallmentsBuyerApi } from 'src/services/installments/installments.request';
import { H4 } from 'src/style-utils/typographic';
import { ColoredSection, ResponsiveContainer } from 'src/style/Container.style';
import { TabsInstallments } from './partials/tabs/TabsInstallments.component';
import StatsCard from 'src/components/StatsCard/StatsCard.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function Installments() {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const { data, isLoading } = useQuery({
    queryKey: [getBuyerInstallments],
    queryFn: getInstallmentsBuyerApi,
  });
  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const installmentsData = data?.data;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ColoredSection bgColor="#fff" padding="0">
        <ResponsiveContainer marginDesktop="0 32px">
          <div className="flex flex-col gap-6 py-6 lg:gap-12 lg:py-8">
            <H4>
              <Translate id="menu.installments" />
            </H4>
            <div className="flex flex-col gap-4 lg:flex-row">
              <StatsCard
                label="lbl.totalInstallmentsPaid"
                tooltipText="tooltip.totalInstallmentsPaid"
                content={formatCurrency(
                  installmentsData?.ResultSet.InstallmentsPaidAmount ?? 0,
                )}
              />
              <StatsCard
                label="lbl.processingInstalments"
                tooltipText="tooltip.processingInstalments"
                content={formatCurrency(
                  installmentsData?.ResultSet.InstallmentsProcessingAmount ?? 0,
                )}
              />
              <StatsCard
                label="lbl.installmentsToBePaid"
                tooltipText="tooltip.totalInstallmentsToBePaid"
                content={formatCurrency(
                  installmentsData?.ResultSet.InstallmentsCreatedAmount ?? 0,
                )}
              />
              <StatsCard
                label="lbl.overdueInstallments"
                tooltipText="tooltip.totalInstallmentsOverdue"
                tooltipPosition="left"
                content={formatCurrency(
                  installmentsData?.ResultSet.InstallmentsOverdueAmount ?? 0,
                )}
                contentColor="#F13D3E"
              />
            </div>
          </div>
        </ResponsiveContainer>
      </ColoredSection>
      <TabsInstallments installments={installmentsData} isLoading={isLoading} />
    </StyleSheetManager>
  );
}

export default Installments;

import { ListTableRemodulationRequests } from 'src/pages/remodulationRequests/RemodulationRequests.types';
import React from 'react';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const CreationColumn: React.FC<ListTableRemodulationRequests> = ({
  Creation,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <P textColor={colors.textTableBody}>{refactorFormatDate(Creation)}</P>
    </StyleSheetManager>
  );
};

export default CreationColumn;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { shouldForwardProp } from 'src/style-utils/functions';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';

const NoLinkMessage: React.FC<{
  icon: any;
  textId: string;
  buttonTextId: string;
  action: any;
  showHelpButton?: boolean;
}> = ({ icon, textId, buttonTextId, action, showHelpButton = false }) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex flexDirection="column" gap="2.4rem" align="center">
        <Icon svgIconComponent={icon} iconHeight="auto" iconSize="16rem" />
        <H4 textAlign="center">
          <Translate id={textId} />
        </H4>
      </Flex>
      <ButtonContainer>
        <Button onClick={action}>
          <Translate id={buttonTextId} />
        </Button>
        {showHelpButton && <NeedHelpButton />}
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default NoLinkMessage;

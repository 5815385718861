import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlreadyPaid } from 'src/images/pageStatus/eCommerceAlreadyPaid.svg';
import { useSession } from 'src/services/session/session.context';
import { shouldForwardProp } from 'src/style-utils/functions';
import { H4 } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';

const AlreadyPaidPlugin = () => {
  const { onLogout } = useSession();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={AlreadyPaid}
        iconHeight="auto"
        iconSize="min(100%, 38.4rem)"
        margin="3.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.eCommerceAlreadyPaid" />
      </H4>
      <ButtonContainer>
        <Button
          margin="1.6rem 0 0 "
          onClick={() => {
            (window as any).parent.postMessage(
              JSON.stringify({ status: 'close' }),
              '*',
            );
            onLogout();
          }}
          height="5.4rem"
          type="submit"
          minWidth="100%"
        >
          <Translate id="lbl.backToEcommerce" />
        </Button>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default AlreadyPaidPlugin;

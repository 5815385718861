import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { shouldForwardProp } from 'src/style-utils/functions';
import { P } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';

const AmountColumn: React.FC<ListRequestsResultsTable> = ({ Amount }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <P textColor={colors.textTableBody}>
        {formatCurrency(Amount, { removeWhiteSpaces: true })}
      </P>
    </StyleSheetManager>
  );
};

export default AmountColumn;

import DataTable from 'src/components/DataTable/DataTable';
import { DataTableProps } from 'src/components/DataTable/DataTable.types';
import Loader from 'src/components/Loader/Loader.component';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import { installmentsTableColumnConfig } from './TableInstallments.config';
import { Flex } from '../../../../style/flexbox.style';
import Icon from '../../../../components/Icon/Icon.component';
import { CustomInstallmentsProcessingDisclaimer } from '../../../monitoring/Monitoring.style';
import Translate from '../../../../components/Translate/Translate.component';
import { ReactComponent as Star } from 'src/images/icons/star.svg';

interface Props {
  data?: GetBuyerStatsDataResponse;
  isLoading: boolean;
}

const TableInstallments = ({ data, isLoading }: Props) => {
  const tabProps: DataTableProps<GetBuyerStatsDataResponse> = {
    data: data?.ResultSet.InstallmentsStats ?? [],
    hideHeader: false,
    NoDataComponent: () => {},
    columns: installmentsTableColumnConfig,
    customTableTheme: {
      cells: {
        style: {
          '&:first-child': {
            background: '#fff',
            display: 'block',
          },
        },
      },
    },
  };

  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return (
    <>
      {data?.ResultSet?.InstallmentsProcessing
        ? data?.ResultSet?.InstallmentsProcessing > 0 && (
            <Flex>
              <Icon
                svgIconComponent={Star}
                margin="auto 0"
                iconSize="1.6rem"
                iconHeight="1.6rem"
              />
              <CustomInstallmentsProcessingDisclaimer>
                <Translate id="lbl.PBMonitoringDisclaimer" />
              </CustomInstallmentsProcessingDisclaimer>
            </Flex>
          )
        : null}
      <DataTable {...tabProps} />
    </>
  );
};

export default TableInstallments;

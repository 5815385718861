import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H2, PSmall } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export interface DisposabilityCardProps {
  totalAmount: number;
  liquidity: number;
}

const DisposabilityCard: React.FC<DisposabilityCardProps> = ({
  totalAmount,
  liquidity,
}) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="disposability-card"
        className="grid w-full gap-4 rounded-md bg-white p-3 shadow-blue4px"
      >
        <div className="bg-bblue px-3 py-4">
          <PSmall>
            <Translate id="text.liquidityNewLinkPayment" />
          </PSmall>
          <H2>{formatCurrency(liquidity)}</H2>
        </div>
        <PSmall>
          <Translate id="text.totalLiquidity" />
          &nbsp;
          <strong>{formatCurrency(totalAmount)}</strong>
        </PSmall>
      </div>
    </StyleSheetManager>
  );
};
export default DisposabilityCard;

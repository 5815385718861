import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import {
  PSCardProps,
  PaySuccessCard,
} from 'src/components/PaySuccessCard/PaySuccessCard.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Calendar } from 'src/images/icons/calendarSwipe.svg';
import { ReactComponent as CreditCardCircle } from 'src/images/icons/credit-card.svg';
import { ReactComponent as IconBill } from 'src/images/icons/iconBill.svg';
import { ReactComponent as RoundStepper } from 'src/images/icons/jump-stepper-round.svg';
import { ReactComponent as Phone } from 'src/images/icons/phone.svg';
import { ReactComponent as StepJumper } from 'src/images/icons/step-jumper.svg';
import { ReactComponent as OkPaymentSvg } from 'src/images/pageStatus/already-paid.svg';
import { ReactComponent as PaymentSvg } from 'src/images/pageStatus/payment-success.svg';
import HowDoesItWorkCard from 'src/pages/PurchaseFlow/subcomponents/HowDoesItWorkCard';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { useEffect } from 'react';
import { Navigate, useLocation, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { useSession } from 'src/services/session/session.context';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { H4, H5, P } from 'src/style-utils/typographic';
import { getCookie } from 'src/utils/functions/cookie';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function PluginPaymentSuccessPage() {
  const { successData } = useOutletContext<EcommerceContext>();
  const orderId = useCurrentTransactionStore((state) => state.orderId);
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const location = useLocation();
  const guidParam = location?.state?.guidParam;
  const { onLogout } = useSession();
  const {
    formatters: { formatCurrency },
  } = useI18n();

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({ status: 'approve', orderId }),
      '*',
    );
  }, []);
  const {
    CurrentInstallmentsPaidCount,
    InstallmentUnpaidCount,
    LastPaidINo,
    LastPaidAmount,
    MerchantName,
    NextAmount,
    NextDueDate,
    RemainingAmount,
  } = successData ?? {};

  const cardValues: PSCardProps[] = [
    {
      icon: Calendar,
      lbl: 'text.nextRata',
      value: formatCurrency(NextAmount ? NextAmount : 0),
      note: NextDueDate
        ? refactorFormatDate(NextDueDate, 'ddd. DD MMMM')
        : undefined,
    },
    {
      icon: StepJumper,
      lbl: 'text.residualRataCount',
      value: InstallmentUnpaidCount ?? 0,
    },
    {
      icon: IconBill,
      lbl: 'lbl.remainingAmount',
      value: formatCurrency(RemainingAmount ?? 0),
    },
  ];
  const formattedAmount = formatCurrency(LastPaidAmount ?? 0);

  const userGuest =
    currentOrder?.BuyerRegistrationStatus &&
    currentOrder?.BuyerRegistrationStatus !== 'registered';
  const singleInstallment =
    LastPaidAmount === 1 && InstallmentUnpaidCount === 0;
  const lastInstallment = RemainingAmount === 0;
  const labels = {
    title: lastInstallment
      ? 'text.paidLastInstallment'
      : 'text.paidInstallmentNumberX',
    subtitle: lastInstallment
      ? 'text.paymentSuccessSubtitleLast'
      : 'text.paymentSuccessSubtitle',
  };
  const MainIcon = singleInstallment ? OkPaymentSvg : PaymentSvg;
  const buttonLabel = (() => {
    if (guidParam) return 'lbl.completeProfile';
    if (userGuest) return 'lbl.completeSignUp';
    return 'lbl.backToEcommerce';
  })();
  const changed = getCookie('changed');

  if (CurrentInstallmentsPaidCount === 0)
    return (
      <Navigate to={RoutePath.ThankyouPageError + RoutePath.paymentFailed} />
    );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {changed === 'true' && (
        <Toast
          variant="success"
          title="text.changeMethodAlertTitle"
          subTitle="text.youAreChangingPaymentSubtitle"
          timeout={5000}
        />
      )}
      <div className="container my-10 grid gap-6 text-center">
        <div className="relative flex items-center justify-between">
          <MainIcon className="mx-auto w-66 lg:w-96" />
          <div className="absolute w-full text-center text-[9rem] font-bold text-pblack-400">
            {LastPaidINo}
          </div>
        </div>
        <H4>
          {singleInstallment ? (
            <Translate
              id="text.paidSingleInstallment"
              data={{
                amount: formattedAmount,
                merchant: MerchantName,
              }}
            />
          ) : (
            <Translate
              id={labels.title}
              data={{
                x: LastPaidINo,
                merchant: MerchantName,
              }}
            />
          )}
        </H4>
        <P>
          <Translate
            id={singleInstallment ? 'lbl.backOrderDetail' : labels.subtitle}
          />
        </P>
        {userGuest && (
          <>
            <H5 light textAlign="left" margin="4.8rem 0 1.6rem">
              <Translate id="lbl.whyRegister" />
            </H5>
            <div className="mb-14 grid gap-4 text-left lg:grid-cols-2">
              <HowDoesItWorkCard title="text.keepTrack" icon={RoundStepper} />
              <HowDoesItWorkCard
                title="text.associatePayment"
                icon={CreditCardCircle}
              />
              <HowDoesItWorkCard title="text.accessAnywhere" icon={Phone} />
            </div>
          </>
        )}
        <ButtonContainer marginTop="0.8rem">
          <Button
            onClick={() => {
              if (guidParam || userGuest)
                window.open(
                  `${RoutePath.registrationGuest}${RoutePath.emailStep}?guid=${guidParam}`,
                  '_blank',
                );
              window.parent.postMessage(
                JSON.stringify({ status: 'close' }),
                '*',
              );
            }}
            variant="Primary"
            type="submit"
            minWidth="100%"
            sizeOnDesktop="medium"
            i18n
            translatedText={buttonLabel}
          />
          {guidParam && (
            <Button
              onClick={() => {
                window.parent.postMessage(
                  JSON.stringify({ status: 'close' }),
                  '*',
                );
                onLogout();
              }}
              variant="Tertiary"
              type="submit"
              minWidth="100%"
              sizeOnDesktop="medium"
              i18n
              translatedText="lbl.backToEcommerce"
            />
          )}
        </ButtonContainer>
      </div>
      {!lastInstallment && (
        <div className="bg-white py-8">
          <div className="container">
            <H4 light margin="0 0 2rem">
              <Translate id="text.whatsNext" />
            </H4>
            <div className="mx-auto grid max-w-oxl gap-4">
              {cardValues.map((item, i) => (
                <PaySuccessCard key={i} {...item} />
              ))}
            </div>
          </div>
        </div>
      )}
    </StyleSheetManager>
  );
}

export default PluginPaymentSuccessPage;

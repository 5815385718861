import { InputDatePickerStyled } from 'src/components/InputDate/style/InputDate.style';
import {
  ContainerMessage,
  EyeIcon,
  EyeIconWrapper,
  IconInsideInput,
  MessageError,
} from 'src/components/InputField/style/input.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as errorIconSrc } from 'src/images/icons/alert.svg';
import { ReactComponent as alertIconSrc } from 'src/images/icons/alert_orange.svg';
import Calendar from 'src/images/icons/calendar.svg?url';
import { ReactComponent as checkIconSrc } from 'src/images/icons/check_icon.svg';
import React from 'react';
import DatePicker, {
  DatePickerProps as ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useController, useFormContext } from 'react-hook-form';
import { InputFieldUIStates } from 'src/services/forms/forms.config';
import { inputFieldStateReducer } from 'src/services/forms/forms.helpers';
import { FormGroup, LabelInput } from 'src/style/styleInput.style';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import { TransformError } from 'src/utils/validation/types';
import { useComputeDatePickerFieldHandleChange } from './DatePickerField.hooks';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string;
  name: string;
  placeholder?: string;
  id?: string;
  tracingId?: string;
  type?: string;
  hidden?: boolean;
  spellCheck?: boolean;
  isPresentMessage?: boolean;
  transformError?: TransformError;
  inputComponent?: React.ComponentType<any>;
  className?: string;
  spellcheck?: boolean;
  disabled?: boolean;
  maxLength?: number;
  filterValue?: (value: string) => boolean;
  autoCorrect?: 'off' | 'on';
  autoComplete?: string;
  required?: boolean;
  additionalInfoId?: string;
  additionalInfoData?: Record<string, string>;
  isFieldErrorAlert?: (fieldError: string) => boolean;
  inputComponentExtraProps?: Record<string, any>;
  extraErrorComponent?: React.ComponentType<any> | any;
  autoFocus?: boolean;
  custom?: boolean;
  onFocus?: () => void;
}

const DatePickerField = React.memo(function DatePickerField({
  label,
  isPresentMessage,
  id,
  hidden,
  tracingId,
  transformError,
  className,
  autoCorrect = 'off',
  filterValue,
  disabled,
  additionalInfoId,
  isFieldErrorAlert,
  additionalInfoData,
  inputComponentExtraProps,
  name,
  minDate,
  maxDate,
  popperModifiers,
  ...otherProps
}: DatePickerProps) {
  const {
    control,
    formState: { errors, touchedFields },
    setValue,
  } = useFormContext();
  const {
    field: { onChange, ...rest },
  } = useController({
    name,
    control,
  });

  const handleChange = useComputeDatePickerFieldHandleChange(
    onChange,
    setValue,
    filterValue,
  );

  const { required } = otherProps;
  const touched = touchedFields[name];

  const error = (errors && errors[name]?.message) || errors[name];
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || tracingId || compId;

  const { inputFieldState, messageProps } = inputFieldStateReducer(
    { error, touched },
    isFieldErrorAlert,
    transformError,
    additionalInfoId,
    additionalInfoData,
  );

  const iconSrc = disabled
    ? checkIconSrc
    : inputFieldState === InputFieldUIStates.alert
      ? alertIconSrc
      : errorIconSrc;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="date-picker-field"
        hidden={hidden}
        className={className}
      >
        {label && (
          <LabelInput htmlFor={domId}>
            <Translate id={label} />
          </LabelInput>
        )}
        <FormGroup
          isAlert={inputFieldState === InputFieldUIStates.alert}
          isError={inputFieldState === InputFieldUIStates.error}
          isDisabled={disabled}
        >
          <DatePicker
            {...inputComponentExtraProps}
            required={required}
            id={domId}
            disabled={disabled}
            {...rest}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={'dd/MM/yyyy'}
            dateFormatCalendar={'dd-MM-yyyy'}
            selected={rest?.value ? new Date(rest.value) : null}
            onChange={handleChange}
            customInput={<InputDatePickerStyled value={rest.value} />}
            popperModifiers={popperModifiers || []}
          />
          <EyeIconWrapper
            hasErrorIcon={inputFieldState === InputFieldUIStates.error}
          >
            <EyeIcon iconSrc={Calendar} />
          </EyeIconWrapper>
          <IconInsideInput svgIconComponent={iconSrc} />
        </FormGroup>
        {isPresentMessage && messageProps && (
          <ContainerMessage isPresentMessage={isPresentMessage}>
            <MessageError>
              <Translate
                extraTranslationProps={{
                  components: { a: <a />, u: <u /> },
                }}
                {...messageProps}
              />
            </MessageError>
          </ContainerMessage>
        )}
      </div>
    </StyleSheetManager>
  );
});

export default DatePickerField;

import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import { Input, LabelInput } from 'src/style/styleInput.style';
import {
  EyeIconWrapper,
  EyeIcon,
  FormGroupCustom,
} from './style/inputFieldCustom.style';
import { InputFieldCustomProps } from './InputFieldCustom.types';
import { InputFieldUIStates } from 'src/services/forms/forms.config';
import { inputFieldStateReducer } from 'src/services/forms/forms.helpers';
import { useComputeInputFieldCustomHandleChange } from './InputFieldCustom.hooks';
import { useController, useFormContext } from 'react-hook-form';
import Calendar from 'src/images/icons/calendar.svg?url';
import PositiveFeed from 'src/images/icons/_positive-feed.svg?url';
import { t } from 'i18next';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const InputFieldCustom = React.memo(function InputFieldCustom({
  label,
  isPresentMessage,
  id,
  tracingId,
  hidden,
  transformError,
  className,
  spellCheck = false,
  autoCorrect = 'off',
  inputComponent: InputComponent = Input,
  filterValue,
  autoComplete = 'new-password',
  maxLength,
  disabled,
  additionalInfoId,
  isFieldErrorAlert,
  additionalInfoData,
  inputComponentExtraProps,
  name,
  placeholder,
  autoFocus,
  onFocus,
  ...otherProps
}: InputFieldCustomProps) {
  const {
    control,
    formState: { errors, touchedFields, dirtyFields },
    setValue,
  } = useFormContext();
  const {
    field: { onChange, ...rest },
  } = useController({
    name,
    control,
  });

  const handleChange = useComputeInputFieldCustomHandleChange(
    onChange,
    setValue,
    filterValue,
  );

  const { required, type = 'text' } = otherProps;
  const touched = touchedFields[name];
  const dirty = dirtyFields[name];

  const error = (errors && errors[name]?.message) || errors[name];
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || tracingId || compId;

  const { inputFieldState } = inputFieldStateReducer(
    { error, touched },
    isFieldErrorAlert,
    transformError,
    additionalInfoId,
    additionalInfoData,
  );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="input-field-custom"
        hidden={hidden}
        className={className}
      >
        {label && (
          <LabelInput htmlFor={domId}>
            <Translate id={label} />
          </LabelInput>
        )}
        <FormGroupCustom
          isAlert={inputFieldState === InputFieldUIStates.alert}
          isError={inputFieldState === InputFieldUIStates.error}
          isDisabled={disabled}
        >
          <InputComponent
            {...inputComponentExtraProps}
            type={type}
            required={required}
            id={domId}
            autoCorrect={autoCorrect}
            autoComplete={autoComplete}
            spellCheck={spellCheck}
            maxLength={maxLength}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={t(placeholder ?? '')}
            onFocus={onFocus}
            {...rest}
            onChange={handleChange}
          />
          {type === 'date' && (
            <EyeIconWrapper>
              <EyeIcon iconSrc={Calendar} />
            </EyeIconWrapper>
          )}
          {!error && dirty && name === 'order-import' && (
            <EyeIconWrapper hasErrorIcon={false}>
              <EyeIcon iconSrc={PositiveFeed} />
            </EyeIconWrapper>
          )}
        </FormGroupCustom>
      </div>
    </StyleSheetManager>
  );
});

export default InputFieldCustom;

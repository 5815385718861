import React from 'react';
import { LabelSelectProps } from 'src/components/Select/Select.types';
import { LabelSelectStyle } from './style/LabelSelect.style';
import Translate from 'src/components/Translate/Translate.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  id,
  i18nLabel,
  ...otherProps
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <LabelSelectStyle htmlFor={id} {...otherProps}>
        {i18nLabel ? <Translate id={label} /> : label}
      </LabelSelectStyle>
    </StyleSheetManager>
  );
};

export default LabelSelect;

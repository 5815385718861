import InputDate from 'src/components/InputDate/InputDate.component';
import RadioBox from 'src/components/RadioBox/RadioBox.component';
import Translate from 'src/components/Translate/Translate.component';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { endDate, startDate } from '../Monitoring.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type RadioBoxPopoverProps = {
  label: string;
  name: string;
  value: string;
  id: string;
  showDate?: boolean;
  setStateHandler?: Dispatch<SetStateAction<boolean>>;
};
export const RadioBoxCSTPopover: React.FC<RadioBoxPopoverProps> = ({
  label,
  name,
  value,
  id,
  showDate,
  setStateHandler,
}) => {
  const { getValues, setValue } = useFormContext();
  const radioValue = getValues(name);
  const onClick = () => {
    setValue(startDate, '');
    setValue(endDate, '');
    if (setStateHandler) {
      setStateHandler(true);
    }
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {
        <RadioBox
          label={label}
          name={name}
          i18n
          value={value}
          id={id}
          onClick={onClick}
          large={false}
        />
      }
      {(radioValue || showDate) && (
        <>
          <Flex padding="1rem" flexDirection="column" fluid>
            <P margin="0 !important">
              <Translate id="text.startPeriod" />
            </P>
            <InputDate name={startDate} />
          </Flex>
          <Flex padding="1rem" flexDirection="column" fluid>
            <P margin="0 !important">
              <Translate id="text.endPeriod" />
            </P>
            <InputDate name={endDate} />
          </Flex>
        </>
      )}
    </StyleSheetManager>
  );
};

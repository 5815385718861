import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import Loader from 'src/components/Loader/Loader.component';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { initOrderCreateMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { ContainerInfo } from './StepCheckData.style';
import { RoutePath } from 'src/routers/routers.config';

function StepCheckData() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { categoryCode, productCode } = useParams();
  const location = useLocation();
  const stepCfValue = location?.state?.stepCfValue;
  const navigate = useNavigate();
  const {
    buyerFiscalCode,
    buyerName,
    buyerAddress,
    setValue,
    VatCode,
    country,
    postalCode,
    companySearchId,
    guid,
    results,
  } = usePayByLinkStore();

  const { mutate: mutateInit, isPending: isLoadingInit } = useMutation({
    mutationFn: initOrderCreateMethodApi,
    onSuccess: (res) => {
      setValue('uwId', res?.data?.ResultSet.UwId);
      setValue('sessionGuid', res?.data?.ResultSet.Guid);
      setValue('inviteEmail', res?.data?.ResultSet.LastInviteEmail);
      navigate(urlRedirectToFlow);
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const correctData = () =>
    mutateInit({
      BuyerGuid: guid,
      CompanyInfoSearchResultId: companySearchId,
    });

  if (isLoadingInit) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.dataConfirmation"
        stepBackHandler={() =>
          stepCfValue
            ? navigate(
                `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCf}`,
                {
                  state: {
                    stepCfValue,
                  },
                },
              )
            : navigate(-1)
        }
      />
      <FlowContainer>
        <H4>
          <Translate id="lbl.customerCorrectData" />
        </H4>
        <div className="content">
          <ContainerInfo>
            {buyerName && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkFlowBusinessName" />
                </P>
                <P bold colorBlack>
                  {buyerName}
                </P>
              </div>
            )}
            {country === 'IT' && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkCf" />
                </P>
                <P bold colorBlack>
                  {buyerFiscalCode}
                </P>
              </div>
            )}
            {VatCode && (
              <div>
                <P>
                  <Translate
                    id={
                      country === 'IT'
                        ? 'lbl.payByLinkVatCode'
                        : 'text.vatNumber'
                    }
                  />
                </P>
                <P bold colorBlack>
                  {VatCode}
                </P>
              </div>
            )}
            {country !== 'IT' && (
              <div>
                <P>
                  <Translate id="lbl.postalCode" />
                </P>
                <P bold colorBlack>
                  {postalCode}
                </P>
              </div>
            )}
            {buyerAddress && (
              <div>
                <P>
                  <Translate id="text.address" />
                </P>
                <P bold colorBlack>
                  {buyerAddress}
                </P>
              </div>
            )}
          </ContainerInfo>
        </div>
        <ButtonContainer>
          <Button
            variant="Primary"
            disabled={isLoadingInit}
            minWidth="100%"
            i18n={true}
            translatedText="lbl.correctData"
            onClick={correctData}
            id={IDs.btnConfirm}
          />
          <Button
            variant="Tertiary"
            disabled={isLoadingInit}
            minWidth="100%"
            i18n={true}
            translatedText={
              results.length > 1 ? 'text.showMoreCompanies' : 'lbl.wrongData'
            }
            onClick={() =>
              results.length > 1
                ? navigate(
                    `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.chooseCompany}`,
                  )
                : navigate(-1)
            }
            id={IDs.btnDecline}
          />
        </ButtonContainer>
      </FlowContainer>
    </>
  );
}

export default StepCheckData;

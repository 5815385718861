import { InputFieldProps } from 'src/components/InputField/InputField.types';
import React from 'react';
import {
  InputDateCustomStyled,
  InputDateStyled,
} from './style/InputDate.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const InputDate: React.FC<InputFieldProps> = (props) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {props.custom ? (
        <InputDateCustomStyled
          data-component="input-date"
          name={props.name}
          disabled={props.disabled}
          type="date"
          isPresentMessage={props.isPresentMessage}
          onFocus={props.onFocus}
        />
      ) : (
        <InputDateStyled
          data-component="input-date"
          name={props.name}
          disabled={props.disabled}
          type="date"
          isPresentMessage={props.isPresentMessage}
        />
      )}
    </StyleSheetManager>
  );
};

export default InputDate;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as mailbox } from 'src/images/pageStatus/mailbox.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { H3, P } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const SuccessRegistration: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={mailbox}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H3 textAlign="center">
        <Translate id="text.successfulRegistration" />
      </H3>
      <P marginBottom="10rem">
        <Translate id="text.successfulRegistrationExplain" />
      </P>
      <ButtonContainer>
        <Button minWidth="100%" i18n onClick={() => navigate('/private-area')}>
          <Translate id="text.reservedArea" />
        </Button>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default SuccessRegistration;

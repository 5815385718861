import { Button } from 'src/components/Button/Button.component';
import CurrentBalance from 'src/components/CurrentBalance/CurrentBalance.component';
import FilteredList from 'src/components/FilteredList/FilteredList.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import MovementsDetailCard from 'src/components/MovementDetailCard/MovementsDetailCard.component';
import MovementsCard from 'src/components/MovementsCard/MovementsCard.component';
import {
  Cash,
  ServiceCosts,
  Withdrawals,
} from 'src/components/MovementsCard/MovementsCard.config';
import { MovementsDownloadXls } from 'src/components/MovementsDownloadXls/MovementsDownloadXls.component';
import Translate from 'src/components/Translate/Translate.component';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { balanceAndMovementsInitialDataEndPoint } from 'src/services/balanceAndMovementsInitialData/balanceAndMovementsInitialData.config';
import { getBalanceAndMovementsInitialDataMethodApi } from 'src/services/balanceAndMovementsInitialData/balanceAndMovementsInitialData.request';
import { getBuyersWithMovements } from 'src/services/buyersWithMovements/buyersWithMovements.config';
import { getBuyersWithMovementsMethodApi } from 'src/services/buyersWithMovements/buyersWithMovements.request';
import { movementsForMerchantEndPoint } from 'src/services/movementsForMerchant/movementsForMerchant.config';
import {
  movementsForMerchantMethodApi,
  ResultMovementsType,
} from 'src/services/movementsForMerchant/movementsForMerchant.request';
import { useProfileStore } from 'src/store/store';
import {
  MaxWidthContainer,
  ResponsiveContainer,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { RoleEnum } from 'src/utils/types/common.types';
import { ReactComponent as FilterIcon } from 'src/images/icons/filtres.svg';
import { DataFormBalanceMovements } from './BalanceMovementsPage.types';
import {
  skipField,
  takeMovements,
} from './partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { searchCriteriaFormConfig } from './partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.helpers';
import SearchCriteriaModalLodable from './partials/searchCriteriaModal/SearchCriteriaModal.lodable';
import {
  DateLine,
  EndOrders,
  Filter,
  MovementsCardsContainer,
} from './style/BalanceMovementsPage.style';
import Toast from '../../components/Toast/Toast.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const emptyMovements: ResultMovementsType = {
  Costi: 0,
  Incassi: 0,
  Prelievi: 0,
  MovementsCount: 0,
  Movements: [],
};

const BalanceMovementsPage = () => {
  const { initialValues, resolver } = searchCriteriaFormConfig();
  const [modalOpen, setModalOpen] = useState(false);
  const [movementsResponse, setMovementsResponse] =
    useState<ResultMovementsType>(emptyMovements);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataFilter, setDataFilter] =
    useState<DataFormBalanceMovements>(initialValues);
  const totalPages = Math.ceil(
    movementsResponse?.MovementsCount / takeMovements,
  );
  const Role = useProfileStore((state) => state.Role);
  const location = useLocation();
  const showToastCreatePayout = location?.state?.showToastCreatePayout;

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    isLoading: isLoadingInitialData,
    isError: isErrorInitialData,
    data: initialData,
  } = useQuery({
    queryKey: [balanceAndMovementsInitialDataEndPoint],
    queryFn: getBalanceAndMovementsInitialDataMethodApi,
  });

  const {
    isLoading: isLoadingBuyers,
    isError: isErrorBuyers,
    data: dataBuyers,
  } = useQuery({
    queryKey: [getBuyersWithMovements],
    queryFn: getBuyersWithMovementsMethodApi,
  });

  function onSuccessHandler(res: any) {
    const { Movements, MovementsCount, Costi, Incassi, Prelievi } = res.data
      .ResultSet as ResultMovementsType;
    setMovementsResponse((prevMovements) => ({
      Costi,
      Incassi,
      Prelievi,
      MovementsCount,
      Movements: [...prevMovements.Movements, ...Movements],
    }));
  }

  const {
    isError: isErrorMovements,
    isSuccess: isSuccessMovements,
    data: dataMovements,
  } = useQuery({
    queryKey: [movementsForMerchantEndPoint],
    queryFn: () => movementsForMerchantMethodApi(initialValues),
  });

  useEffect(() => {
    if (isSuccessMovements) onSuccessHandler(dataMovements);
  }, [isSuccessMovements]);

  const { mutate, isPending, isError } = useMutation({
    mutationFn: (values: any) => movementsForMerchantMethodApi(values),
    onSuccess: (res) => onSuccessHandler(res),
  });

  function onSubmit(values: any) {
    setMovementsResponse(emptyMovements);
    setCurrentPage(1);
    methods.setValue(skipField, 0);
    setDataFilter({ ...values });
    mutate({ ...values });
    setModalOpen(false);
  }

  function nextPageHandler() {
    mutate({ ...dataFilter, skip: currentPage * takeMovements });
    setCurrentPage(currentPage + 1);
  }

  if (
    isError ||
    isErrorInitialData ||
    isErrorBuyers ||
    isErrorMovements ||
    Role !== RoleEnum.owner
  )
    return <Navigate to="/oops" />;
  if (isPending || isLoadingInitialData || isLoadingBuyers)
    return (
      <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
    );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FormProvider {...methods}>
        <CurrentBalance
          currentBalance={initialData?.data?.ResultSet.StripeBalance}
          bankAccountDetails={initialData?.data?.ResultSet.BankAccountDetails}
          nextPayoutDate={initialData?.data?.ResultSet.NextPayoutDate}
          pendingBalance={initialData?.data?.ResultSet.PendingBalance}
        />
        <ResponsiveContainer marginDesktop="0 32px">
          <MaxWidthContainer maxWidth="66.8rem" margin="2.8rem auto" noXMargin>
            <Flex gap="2.4rem" align="center">
              <MovementsDownloadXls requestPayload={dataFilter} />
              <Button
                className="hideOnMobile"
                type="button"
                i18n
                translatedText="lbl.filters"
                sizeOnDesktop="small"
                minWidth="0"
                variant="Secondary"
                onClick={() => setModalOpen(true)}
                rightChild={
                  <Icon
                    iconSize="2rem"
                    iconHeight="2rem"
                    svgIconComponent={FilterIcon}
                  />
                }
              />
            </Flex>
            <FilteredList
              setDataFilter={setDataFilter}
              handleClick={onSubmit}
              dataForm={dataFilter}
              clients={dataBuyers?.data?.ResultSet}
            />
            <MovementsCardsContainer
              margin="2rem -5.6rem 0"
              align="center"
              padding="0.5rem 5.6rem"
            >
              <MovementsCard title={Cash} amount={movementsResponse?.Incassi} />
              <MovementsCard
                title={ServiceCosts}
                amount={movementsResponse?.Costi}
              />
              <MovementsCard
                title={Withdrawals}
                amount={movementsResponse?.Prelievi}
              />
            </MovementsCardsContainer>

            <Flex
              marginBottom="5.6rem"
              marginTop="1.4rem"
              flexDirection="column"
              align="center"
              gap="1.2rem"
              fluid
            >
              {movementsResponse?.Movements.map((movement, i) => (
                <div key={i} style={{ width: '100%' }}>
                  {(i === 0 ||
                    movement.Date.slice(0, 7) !==
                      movementsResponse.Movements[i - 1].Date.slice(0, 7)) && (
                    <DateLine>
                      {refactorFormatDate(movement.Date, 'MMMM YYYY')}
                    </DateLine>
                  )}
                  <MovementsDetailCard
                    advance={movement.Advance}
                    date={refactorFormatDate(movement.Date, 'DD MMMM YYYY')}
                    movementsType={movement.Type as any}
                    amount={movement.Amount}
                    orderId={movement.Id}
                    orderGuid={movement.OrderGuid}
                    INo={movement.INo}
                    customer={movement.BuyerName}
                  />
                </div>
              ))}
            </Flex>
            <Flex justify="center" align="center">
              {currentPage + 1 <= totalPages ? (
                <Button
                  variant="LinkPrimary"
                  underline
                  onClick={nextPageHandler}
                  i18n
                  translatedText="text.showMoreMovements"
                />
              ) : (
                <EndOrders>
                  <Translate id="text.noMoreMovements" />
                </EndOrders>
              )}
            </Flex>

            <Filter
              className="hideOnDesktop"
              variant="Secondary"
              sizeType="medium"
              onClick={() => setModalOpen(true)}
              IconSrc={FilterIcon}
            />
          </MaxWidthContainer>
        </ResponsiveContainer>
        {modalOpen && (
          <SearchCriteriaModalLodable
            clients={dataBuyers?.data?.ResultSet}
            buttonTitle="text.seeMovements"
            modalTitle="text.searchCriteria"
            i18n
            applyFilters={onSubmit}
            handleClose={() => setModalOpen(false)}
          />
        )}
        {showToastCreatePayout && (
          <Toast
            variant="success"
            title="createPayout.success.title"
            subTitle="text.successChangeCollaboratorDescription"
            timeout={5000}
          />
        )}
      </FormProvider>
    </StyleSheetManager>
  );
};

export default BalanceMovementsPage;

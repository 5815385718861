import { Button } from 'src/components/Button/Button.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import { phoneField, prefix } from 'src/components/FormPhone/FormPhone.config';
import Icon from 'src/components/Icon/Icon.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { ReactComponent as FirstStepIcon } from 'src/images/icons/avatar.svg';
import { formConfigOtpPluginCheckout } from 'src/pages/ecommerce/partials/ecommerceCheckout/Otp.helpers';
import { formConfigPhonePluginCheckout } from 'src/pages/ecommerce/partials/ecommerceCheckout/Phone.helpers';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  sendOtpPluginCheckoutMethodApi,
  verifyOtpPluginCheckoutMethodApi,
} from 'src/services/ecommerce/ecommerce.request';
import {
  BuyerRegistrationStatus,
  PluginCheckoutResponse,
} from 'src/services/ecommerce/ecommerce.types';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { SupportedLanguageKey } from 'src/services/i18n/i18n.types';
import { typeBuyerGuidKey } from 'src/services/session/session.config';
import { useSession } from 'src/services/session/session.context';
import { porfileMethodApi } from 'src/services/user/user.request';
import { useAccountStore } from 'src/store/store';
import { PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import StepCard from '../StepCard/StepCard.component';
import LoginModalLoadable from '../modalLogin/LoginModalLoadable';
import OtpModalLoadable from '../modalOtp/OtpModalLoadable';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  checkoutSessionGuid: string;
  getPluginData?: PluginCheckoutResponse;
  startPolling: any;
};

const AccessCard = ({
  checkoutSessionGuid,
  getPluginData,
  startPolling,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState({ prefixNumber: '', mobileNumber: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const { onLogin, auth } = useSession();
  const setAccount = useAccountStore((state) => state.setAccount);
  const { language, setLanguage, mutateUpLang } = useLanguage();

  useEffect(() => {
    if (auth.authToken) loggedIn();
  }, [auth.authToken]);

  useEffect(() => {
    const mobileNumber = getPluginData?.BuyerPhone ?? '';
    const prefixNumber =
      getPluginData?.BuyerPhonePrefix !== ''
        ? getPluginData?.BuyerPhonePrefix
        : '39IT';
    methodsPhone.setValue(phoneField, maskPhoneNumber(mobileNumber));
    methodsPhone.setValue(prefix, prefixNumber);
  }, [getPluginData]);
  const { initialValues: otpInitialValues, resolver: otpResolver } =
    formConfigOtpPluginCheckout();
  const methodsOtp = useForm({
    defaultValues: otpInitialValues,
    resolver: otpResolver,
    mode: 'onChange',
  });
  const { mutate: mutateSendOtp } = useMutation({
    mutationFn: sendOtpPluginCheckoutMethodApi,
    onSuccess: () => {
      setOpenModal(true);
    },
    onError: (error: any) => {
      const errors = servicesErrorsMap(error.response, { phone: phoneField });
      for (const key in errors) {
        methodsPhone.setError(phoneField, { message: errors[key] });
      }
    },
  });
  const { mutate: mutateVerifyOtp, isPending: isLoadingVerifyOtp } =
    useMutation({
      mutationFn: verifyOtpPluginCheckoutMethodApi,
      onSuccess: (res) => {
        setOpenModal(false);
        onLogin(res.data.ResultSet.AccessToken);
      },
      onError: () => {
        setErrorMessage('error.incorrectCode');
      },
    });

  const { initialValues: phoneInitialValues, resolver: phoneResolver } =
    formConfigPhonePluginCheckout();
  const methodsPhone = useForm({
    defaultValues: phoneInitialValues,
    resolver: phoneResolver,
    mode: 'onChange',
  });

  const onSubmitPhone = (data: { [phoneField]: string; prefix: string }) => {
    setPhone({ prefixNumber: data[prefix], mobileNumber: data[phoneField] });
    mutateSendOtp({
      SessionGuid: checkoutSessionGuid,
      BuyerPhonePrefix: data.prefix,
      BuyerPhone: data[phoneField].includes('*')
        ? getPluginData?.BuyerPhone
        : data[phoneField],
      BuyerLanguage: language,
    });
  };

  const onSubmitOtp = (data: any) => {
    mutateVerifyOtp({
      SessionGuid: checkoutSessionGuid,
      BuyerPhoneOtp: Object.values(data).join(''),
    });
  };

  const { mutate: mutateGetInfo } = useMutation({
    mutationFn: () => porfileMethodApi(),
    onSuccess: (res) => {
      setAccount(res?.data.ResultSet);
      window.localStorage.setItem(
        typeBuyerGuidKey,
        res.data.ResultSet.BuyerRoles[0].Guid,
      );
      if (res?.data.ResultSet.Language === '') {
        mutateUpLang({ Language: language });
        return;
      }
      if (language !== res?.data.ResultSet.Language) {
        setLanguage(res?.data.ResultSet.Language as SupportedLanguageKey);
      }
    },
  });

  const loggedIn = () => {
    setLoginModal(false);
    setOpenModal(false);
    mutateGetInfo();
    startPolling(true);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <StepCard
        icon={FirstStepIcon}
        stepNumber={1}
        stepTitle="lbl.login"
        checked={auth.authToken ? true : false}
        overflow="unset"
      >
        {!auth.authToken && (
          <>
            <FormProvider {...methodsPhone}>
              <form
                className="w-full"
                onSubmit={methodsPhone.handleSubmit(onSubmitPhone)}
              >
                <PSmall colorBlack>
                  <Translate id="text.insertPhone" />
                </PSmall>
                <PhoneField
                  autoFocus
                  name={phoneField}
                  isPresentMessage
                  disabled={getPluginData?.BuyerPhone ? true : false}
                />
                <Button
                  variant="Tertiary"
                  type="submit"
                  display="flex"
                  margin="1.6rem auto 0"
                  sizeOnDesktop="medium"
                  minWidth="28rem"
                  minWidthMobile="100%"
                  padding="1.2rem"
                  translatedText="lbl.sendCode"
                  i18n
                />
              </form>
            </FormProvider>
            {getPluginData?.BuyerFiscalCode &&
              getPluginData?.BuyerRegistrationStatus ===
                BuyerRegistrationStatus.registered && (
                <>
                  <DividerOr />
                  <Flex
                    onClick={() => setLoginModal(true)}
                    align="center"
                    style={{ cursor: 'pointer' }}
                  >
                    <PSmall colorBlack>
                      <u>
                        <Translate id="text.loginEmail&Password" />
                      </u>
                    </PSmall>
                    <div>
                      <Icon svgIconComponent={ArrowRight} iconSize="2.4rem" />
                    </div>
                  </Flex>
                </>
              )}
          </>
        )}
      </StepCard>
      <FormProvider {...methodsOtp}>
        {openModal && (
          <OtpModalLoadable
            buttonTitle="lbl.close"
            modalTitle="text.verifyAccount"
            applyForm={onSubmitOtp}
            refreshOtp={() =>
              mutateSendOtp({
                SessionGuid: checkoutSessionGuid,
                BuyerPhone: getPluginData?.BuyerPhone,
                BuyerPhonePrefix: getPluginData?.BuyerPhonePrefix,
                BuyerLanguage: language,
              })
            }
            isPending={isLoadingVerifyOtp}
            handleClose={() => setOpenModal(false)}
            errorMessage={errorMessage}
            phone={phone}
            i18n
          />
        )}
      </FormProvider>
      {loginModal && (
        <LoginModalLoadable
          sessionGuid={checkoutSessionGuid}
          handleClose={() => setLoginModal(false)}
        />
      )}
    </StyleSheetManager>
  );
};

export default AccessCard;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as error_payment } from 'src/images/pageStatus/error_payment.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate } from 'react-router-dom';
import { H4 } from 'src/style-utils/typographic';
import { ButtonPaymentError, ButtonTertiary } from '../style/failedPage.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PaymentError: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={error_payment}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.somethingWentWrong" />
      </H4>
      <ButtonContainer>
        <ButtonPaymentError i18n onClick={() => navigate(-1)}>
          <Translate id="lbl.repeatPayment" />
        </ButtonPaymentError>
        <ButtonTertiary variant="Tertiary">
          <Translate id="lbl.checkData" />
        </ButtonTertiary>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default PaymentError;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H4 } from 'src/style-utils/typographic';
import { ReactComponent as Fishes } from 'src/images/icons/fishes.svg';
import { ErrorPagePar } from '../style/failedPage.style';
import { createBrowserHistory } from '@remix-run/router';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const FiscalCodeMatchError: React.FC = () => {
  const history = createBrowserHistory();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={Fishes}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.noMatchingData" />
      </H4>
      <ErrorPagePar>
        <Translate id="text.noMatchingCfExplain" />
      </ErrorPagePar>
      <ButtonContainer>
        <Button variant="Primary" onClick={() => history.go(-1)}>
          <Translate id="text.updateNow" />
        </Button>
        <NeedHelpButton />
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default FiscalCodeMatchError;

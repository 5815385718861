import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { Installment } from 'src/services/installments/installments.types';
import { P, PSmall } from 'src/style-utils/typographic';
import { MaxWidthContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { NoTabsData } from '../NoTabsData.component';
import { InstallmentsTabsProps } from '../TabsInstallments.types';
import InstallmentsModalLodable from '../modal/InstallmentsModal.lodable';
import { Button } from 'src/components/Button/Button.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export const InstallmentsOverdue: React.FC<InstallmentsTabsProps> = ({
  installments,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dataInstallment, setDataInstallment] = useState<
    Installment | undefined
  >();
  function groupInstallmentsByOrderId(installments?: Installment[]) {
    const groupedInstallments: any = {};
    installments?.forEach((installment) => {
      if (!groupedInstallments[installment.OrderId]) {
        groupedInstallments[installment.OrderId] = [];
      }
      groupedInstallments[installment.OrderId].push(installment);
    });
    return groupedInstallments;
  }
  const data: {
    [orderId: string]: Installment[];
  } = groupInstallmentsByOrderId(installments?.ResultSet.InstallmentsOverdue);
  const onClickHandler = (installment: Installment) => {
    setDataInstallment(installment);
    setShowModal(true);
  };
  const ctaHandler = () => {
    navigate(
      `${RoutePath.orderDetail}${dataInstallment?.OrderGuid}/${RoutePath.info}`,
    );
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <MaxWidthContainer maxWidth="66.8rem" noXMargin marginBottom="3.2rem">
        <div className="flex flex-col gap-8 lg:gap-12">
          {Object.entries(data).map(([orderId, installments]) => (
            <Flex
              key={orderId}
              gap="1.6rem"
              className="border-b border-pgrey pb-8 last:border-b-0 lg:pb-12"
              flexDirection="column"
            >
              <PSmall>
                <Translate id="lbl.orderN" />
                {` #${orderId.toString().padStart(5, '0')} `}
                <Translate id="lbl.ofThe" />{' '}
                {refactorFormatDate(installments[0].OrderDate, 'DD MMMM YYYY')}
              </PSmall>

              {installments.map((installment) => (
                <InstallmentCard
                  key={installment.INo}
                  name={installment.MerchantName}
                  date={installment.Expiration}
                  amount={installment.Amount}
                  NumRata={installment.INo}
                  orderStatus={installment.OrderStatus}
                  onClickHandler={() => onClickHandler(installment)}
                  status="expired"
                  shadows
                />
              ))}
              <Button
                sizeOnDesktop="medium"
                translatedText="lbl.pay"
                i18n
                margin="0 auto"
                variant="Tertiary"
                className="w-full lg:max-w-70"
                onClick={() =>
                  navigate(
                    `${RoutePath.orderDetail}${installments[0].OrderGuid}/${RoutePath.selectExpiredInstallments}`,
                  )
                }
              />
            </Flex>
          ))}
          {installments &&
            installments?.ResultSet.InstallmentsOverdueCount === 0 && (
              <NoTabsData
                title="lbl.congrats!"
                subtitle="text.installmentsCongrats"
              />
            )}
          {showModal && (
            <InstallmentsModalLodable
              buttonTitle={'lbl.goToOrder'}
              modalTitle="text.installmentDetail"
              i18n
              ctaHandler={ctaHandler}
              data={dataInstallment}
              handleClose={() => setShowModal(false)}
            />
          )}
        </div>
        {installments &&
          installments?.ResultSet.InstallmentsOverdueCount > 0 && (
            <div className="mb-10 mt-8 border-t border-pgreyoutlined pt-4 text-center">
              <P>
                <Translate id="text.nothingElseToShow" />
              </P>
            </div>
          )}
      </MaxWidthContainer>
    </StyleSheetManager>
  );
};

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { ReactComponent as RequestErrorSvg } from 'src/images/pageStatus/request-error.svg';
import { ReactComponent as RequestedTransferSvg } from 'src/images/pageStatus/requested-transfer.svg';
import { ReactComponent as TransferDoneSvg } from 'src/images/pageStatus/transfer-done.svg';
import Translate from 'src/components/Translate/Translate.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { H4, H5 } from 'src/style-utils/typographic';
import { AdvPayResultContainer, PSIcon } from './AdvPayResultPage.style';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import { FunctionComponent, SVGProps } from 'react';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export enum ADVResultKey {
  transferDone = 'transfer-done',
  requestedTransfer = 'requested-transfer',
  requestError = 'request-error',
}

type ADVResultMapType = Record<
  ADVResultKey,
  {
    title: string;
    icon: FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
    subtitle: string;
    path: string;
    actionLabel: string;
    showHelp: boolean;
  }
>;

const ADVResultArray = (ordersPerformed: number): ADVResultMapType => {
  return {
    [ADVResultKey.transferDone]: {
      title:
        ordersPerformed > 1
          ? 'text.advRequestSuccessTitle.multi'
          : 'text.advRequestSuccessTitle.single',
      icon: TransferDoneSvg,
      subtitle: 'text.advRequestSuccessSubtitle',
      path: RoutePath.advancePlanRequests,
      actionLabel: 'lbl.goToRequests',
      showHelp: false,
    },
    [ADVResultKey.requestedTransfer]: {
      title: 'text.advRequestSuccessCreditTitle',
      icon: RequestedTransferSvg,
      subtitle: 'text.advRequestSuccessCreditDescription',
      path: `${RoutePath.privateArea}?typeAccount=MERCHANT`,
      actionLabel: 'lbl.close',
      showHelp: true,
    },
    [ADVResultKey.requestError]: {
      title: 'text.advRequestErrorTitle',
      icon: RequestErrorSvg,
      subtitle: 'text.advRequestErrorDescription',
      path: RoutePath.advancePaymentRequest + RoutePath.chooseLink,
      actionLabel: 'lbl.advPayAntoherRequest',
      showHelp: true,
    },
  };
};

function ADVPayResultPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const requestResult: ADVResultKey =
    location.state?.requestResult ?? 'request-error';
  const amountPerformed: number = location.state?.amountPerformed;
  const ordersPerformed: number = location.state?.ordersPerformed;
  const ADVResultMap = ADVResultArray(ordersPerformed);
  const { title, icon, subtitle, actionLabel, path, showHelp } =
    ADVResultMap[requestResult];
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AdvPayResultContainer
        flex="1"
        flexDirection="column"
        padding="4.8rem 2.4rem"
        gap="1.6rem"
      >
        <PSIcon icon={icon} />
        <H4>
          <Translate
            id={title}
            data={{
              amountPerformed: formatCurrency(amountPerformed ?? 0),
              ordersPerformed,
            }}
          />
        </H4>
        <H5 light colorGray>
          <Translate id={subtitle} />
        </H5>
        <ButtonContainer>
          <Button
            onClick={() => {
              navigate(path);
            }}
          >
            <Translate id={actionLabel} />
          </Button>
          {showHelp && <NeedHelpButton />}
        </ButtonContainer>
      </AdvPayResultContainer>
    </StyleSheetManager>
  );
}

export default ADVPayResultPage;

import { AxiosResponse } from 'axios';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { badRequest_400 } from 'src/services/api/api.statusCodes';
import { cfField } from 'src/services/orderLink/orderLink.config';
import { searchCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { H4, PSmall } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import toTitleCase from 'src/utils/functions/formatString';
import { formConfigCfPayByLink } from './StepCf.helpers';
import { IDs } from 'src/utils/config/ids';
import { RoutePath } from 'src/routers/routers.config';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StepCf() {
  const { setValues, country } = usePayByLinkStore((state) => state);
  const location = useLocation();
  const stepCfValue = location?.state?.stepCfValue;
  const { step, stepMax } = useRedirectToFlow();
  const { initialValues, resolver } = formConfigCfPayByLink(stepCfValue);
  const navigate = useNavigate();
  const { categoryCode, productCode } = useParams();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isPending } = useMutation({
    mutationFn: searchCompanyInfoMethodApi,
    onSuccess: (res, variables) => {
      if (res.data.ResultSet.Result === 'not_found') {
        navigate(RoutePath.ThankyouPageError + RoutePath.fiscalCodeMatchError);
        return;
      }
      const {
        FiscalCode,
        PostalCode,
        Address,
        Name,
        VatCode,
        SearchId,
        Country,
      } = res.data.ResultSet.Items[0];
      const values = {
        buyerFiscalCode: FiscalCode,
        postalCode: PostalCode,
        buyerAddress: toTitleCase(Address),
        buyerName: toTitleCase(Name),
        VatCode,
        companySearchId: SearchId,
        country: Country.toUpperCase(),
        results: res.data?.ResultSet?.Items,
      };
      setValues(values);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCheckData}`,
        {
          state: {
            stepCfValue: variables.vatCode,
          },
        },
      );
    },
    onError: (error: any) => {
      const errorResponse = error.response as AxiosResponse;
      if (errorResponse?.status === badRequest_400) {
        for (const key in errorResponse.data.errorInfos) {
          if (key === 'generic') {
            errorResponse.data.errorInfos[key] === 'error.OpenApi'
              ? navigate('/thankyou-page-error/internal-error')
              : navigate('/thankyou-page-error/fiscal-code-match-error');
          }
        }
      }
    },
  });

  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = (data: any) => {
    mutate({ country, vatCode: data[cfField] });
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.stepCf"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.stepCf" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField name={cfField} maxLength={16} id={IDs.inputCf} />
              <PSmall>
                <Translate id="text.stepCfSubtitle" />
              </PSmall>
              <ButtonContainer>
                <SubmitButton
                  id={IDs.btnProceed}
                  minWidth="100%"
                  disabled={isPending}
                >
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepCf;

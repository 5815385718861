import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Checkbox from 'src/components/Checkbox/Checkbox.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Welcome } from 'src/images/pageStatus/welcome.svg';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  guidField,
  guidOrderField,
  ipAddressField,
} from 'src/services/fido/fido.config';
import { fidoMethodApi } from 'src/services/fido/fido.request';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { SupportedLanguageKey } from 'src/services/i18n/i18n.types';
import { emailField, passwordField } from 'src/services/login/login.config';
import {
  LoginGuestMethodApi,
  LoginMethodApi,
} from 'src/services/login/login.request';
import { DataLogin } from 'src/services/login/loogin.types';
import { flagCreditAssignment } from 'src/services/orderLink/orderLink.config';
import { setBuyerFlagCreditMethodApi } from 'src/services/orderLink/orderLink.request';
import { typeBuyerGuidKey } from 'src/services/session/session.config';
import { useSession } from 'src/services/session/session.context';
import { buyerGetInfo, userEndPoint } from 'src/services/user/user.config';
import {
  buyerGetInfoMethodApi,
  porfileMethodApi,
} from 'src/services/user/user.request';
import {
  useAccountStore,
  useCurrentTransactionStore,
  useProfileStore,
} from 'src/store/store';
import { H3, P, PLegal } from 'src/style-utils/typographic';
import { getPublicIp } from 'src/utils/functions/getPublicIp';
import { formConfigWelcomePage } from './WelcomePages.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const WelcomePage = ({ logged }: { logged?: boolean }) => {
  const { initialValues, resolver } = formConfigWelcomePage();
  const { email, password, login, otpPhone, guestSignInGuid } =
    useLocation()?.state ?? {};
  const navigate = useNavigate();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const setUser = useProfileStore((state) => state.setUser);
  const setValue = useProfileStore((state) => state.setValue);
  const setAccount = useAccountStore((state) => state.setAccount);
  const { onLogin } = useSession();
  const lender = useCurrentTransactionStore((state) => state.lender);
  const { language, setLanguage, isLoadingLang, isLoadingUpLang } =
    useLanguage();
  const loginLight = login === 'loginLight';
  const [ipAddress, setIpAddress] = useState('');
  useEffect(() => {
    getPublicIp().then((res) => setIpAddress(res));
  }, []);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const {
    isPending: isLoadingGetInfo,
    mutate: mutateGetInfo,
    isSuccess: isSuccessAccountInfo,
    data: dataAccount,
  } = useMutation({
    mutationKey: [userEndPoint],
    mutationFn: porfileMethodApi,
    onSuccess: (res) => {
      setAccount(res?.data.ResultSet);
      if (language !== res?.data.ResultSet.Language) {
        setLanguage(res?.data.ResultSet.Language as SupportedLanguageKey);
      }
      window.localStorage.setItem(
        typeBuyerGuidKey,
        res.data.ResultSet.BuyerRoles[0].Guid,
      );
    },
    onError: () => {
      navigate(RoutePath.internalError);
    },
  });
  const {
    isLoading: isLoadingBuyerInfo,
    isSuccess: isSuccessBuyerInfo,
    data: dataBuyerInfo,
  } = useQuery({
    queryKey: [buyerGetInfo],
    queryFn: buyerGetInfoMethodApi,
    enabled:
      isSuccessAccountInfo &&
      dataAccount?.data?.ResultSet.BuyerRoles?.length > 0,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (values: DataLogin) => LoginMethodApi(values),

    onSuccess: (res) => {
      const { AccessToken } = res.data.ResultSet;
      onLogin(AccessToken);
      mutateGetInfo();
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const { mutate: mutateLoginGuest, isPending: isLoadingLoginGuest } =
    useMutation({
      mutationFn: LoginGuestMethodApi,
      onSuccess: (res) => {
        const { AccessToken } = res.data.ResultSet;
        onLogin(AccessToken);
        mutateGetInfo();
      },
      onError: () => {
        navigate(RoutePath.oops);
      },
    });

  const {
    mutate: mutateFido,
    isPending: isLoadingFido,
    data: dataFido,
    isSuccess,
  } = useMutation({
    mutationFn: fidoMethodApi,
    onSuccess: () => {
      mutateGetInfo();
    },
    onError: () => navigate('/thankyou-page-error/login-buyer-error'),
  });

  useEffect(() => {
    if (isSuccess) {
      return navigate(
        dataFido?.data.ResultSet
          ? `${RoutePath.paymentFlow}${RoutePath.payWithOpyn}`
          : '',
      ) as void;
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessBuyerInfo) {
      setValue('AccountType', 'BUYER');
      setUser(dataBuyerInfo?.data.ResultSet);
      mutateBuyerFlagCredit({
        OrderGuid: currentOrder?.OrderGuid,
        BuyerFlagCredit: true,
      });
    }
  }, [isSuccessBuyerInfo]);

  const { mutate: mutateBuyerFlagCredit, isPending: isLoadingBuyerFlagCredit } =
    useMutation({
      mutationFn: setBuyerFlagCreditMethodApi,
      onSuccess: () => {
        const data = {
          [guidField]: currentOrder?.BuyerGuid,
          [guidOrderField]: currentOrder?.OrderGuid,
          [ipAddressField]: ipAddress,
        };

        mutateFido(data);
      },
      onError: () => navigate('/thankyou-page-error/login-buyer-error'),
    });

  const onSubmit = () => {
    if (logged) {
      mutateBuyerFlagCredit({
        OrderGuid: currentOrder?.OrderGuid,
        BuyerFlagCredit: true,
      });
      return;
    }
    if (loginLight) {
      mutateLoginGuest({ otp: otpPhone, guestSignInGuid });
      return;
    }
    const dataLogin = {
      [emailField]: email,
      [passwordField]: password,
    };
    mutate(dataLogin);
  };

  if (
    isPending ||
    isLoadingFido ||
    isLoadingBuyerFlagCredit ||
    isLoadingGetInfo ||
    isLoadingLang ||
    isLoadingUpLang ||
    isLoadingBuyerInfo ||
    isLoadingLoginGuest
  ) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="container my-10 flex flex-col items-center gap-8">
        <Welcome className="w-72 lg:w-96" />
        <H3>
          <Translate id="text.welcome" />
        </H3>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex max-w-[44.8rem] gap-4 rounded-xl bg-white p-4 shadow-md">
              <div>
                <P bold colorBlack>
                  <Translate id="text.creditAssignment" />
                </P>
                <PLegal colorBlack>
                  <Translate
                    id="text.okCreditAssignment"
                    data={{ x: lender }}
                  />
                </PLegal>
              </div>
              <Checkbox viewMode="inline" name={flagCreditAssignment} />
            </div>
            <ButtonContainer marginTop="4.8rem">
              <SubmitButton
                minWidthMobile="100%"
                disabled={isPending || isLoadingFido}
                i18n
                translatedText="lbl.next"
              />
            </ButtonContainer>
          </form>
        </FormProvider>
      </div>
    </StyleSheetManager>
  );
};

export default WelcomePage;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  refreshRecoveryOtp1MethodApi,
  verifyRecoveryOtp1MethodApi,
} from 'src/services/recoveryData/recoveryData.request';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import { formConfigRecoveryOtp } from './Otp.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function InsertOtp() {
  const { initialValues, resolver } = formConfigRecoveryOtp();
  const { sessionGuid, type, activeOrder } =
    useOutletContext<RecoveryDataContext>();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const value = location?.state?.value;
  const prefixNumber = location?.state?.prefix ?? '';
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isPending } = useMutation({
    mutationFn: verifyRecoveryOtp1MethodApi,
    onSuccess: (res) => {
      res.data.ResultSet.Otp1IsValid
        ? navigate(
            `${RoutePath.recoveryData}${
              type === 'email' ? RoutePath.showEmail : RoutePath.performOtp
            }?sessionGuid=${sessionGuid}`,
            {
              state: {
                value:
                  type === 'email'
                    ? res.data.ResultSet.Email
                    : res.data.ResultSet.Phone,
                prefix: res.data.ResultSet.PhonePrefix,
                activeOrder,
              },
            },
          )
        : setErrorMessage('error.otpNotMatching');
    },
  });
  const { mutate: mutateRefresh, isPending: isLoadingRefresh } = useMutation({
    mutationFn: refreshRecoveryOtp1MethodApi,
  });
  if (isLoadingRefresh) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const dataOtp = {
      sessionGuid,
      AuthOtp1: Object.values(data).join(''),
    };
    mutate(dataOtp);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        stepNumber={2}
        stepMaxNumber={4}
        showWhiteSpaceRight
        showBackButton
        backButtonAction={() => navigate(-1)}
        label="text.insertCodeLite"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryOtp" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {type === 'email' && (
              <OtpForm
                mobile={maskPhoneNumber(value)}
                prefix={prefixNumber.slice(0, -2)}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                sendAgainHandler={() => mutateRefresh({ sessionGuid })}
                isLoadingRefresh={isLoadingRefresh}
              />
            )}
            {type === 'password' && (
              <OtpForm
                email={value}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                sendAgainHandler={() => mutateRefresh({ sessionGuid })}
                isLoadingRefresh={isLoadingRefresh}
              />
            )}
            <ButtonContainer>
              <SubmitButton
                i18n
                disabled={isPending}
                translatedText="lbl.next"
              />
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default InsertOtp;

import { AmountsType } from 'src/components/PaymentsFilteredList/PaymentsFilteredList.types';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import {
  flagCessioneCredito,
  flagNonCessioneCredito,
} from 'src/pages/balanceMovementsPage/partials/searchCriteriaModal/SearchCriteriaForm/SearchCriteriaForm.config';
import { FilterCheckboxConfig } from 'src/pages/payByLinkPage/partials/PayByLinkFilterModal/PayByLinkFilterModal.config';
import { DataFormPayByLink } from 'src/pages/payByLinkPage/partials/SearchCriteriaForm.helpers';
import { useState } from 'react';
import FilteredButtonBooleanPayByLink from './partial/FilteredButtonBooelanPayByLink.component';
import FilteredButtonPayByLink from './partial/FilteredButtonPayByLink.component';
import { FilteredContainer } from './style/FilteredList.style';
import { Buyers } from 'src/services/linksFilterInitialData/linksFilterInitialData.types';
import Translate from '../Translate/Translate.component';
import { Button } from '../Button/Button.component';
import { useFormContext } from 'react-hook-form';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type ClientNamesType = { name: string; id: string };
type StatusArray = { status: PayByLinkStatus; name: string };

type Props = {
  clients: Buyers[];
  dataForm: DataFormPayByLink;
  initialData: DataFormPayByLink;
  handleClick: Function;
  amounts: AmountsType[];
  setDataFilter: React.Dispatch<React.SetStateAction<any>>;
};

const FilteredListPayByLink = ({
  clients,
  dataForm,
  initialData,
  amounts,
  handleClick,
  setDataFilter,
}: Props) => {
  const { reset } = useFormContext();
  const clientNames: ClientNamesType[] = [];
  const statusArray: StatusArray[] = [];
  const paymentsArray: AmountsType[] = [];
  const booleanArray: {
    label: string;
    fieldName: typeof flagCessioneCredito | typeof flagNonCessioneCredito;
  }[] = [];

  if (dataForm) {
    dataForm.buyers.forEach((buyerId: string) => {
      const client = clients.find(({ Id }) => Id.toString() === buyerId);
      clientNames.push({ name: client!.Name, id: client!.Id.toString() });
    });
    dataForm.statuses.forEach((status: number) => {
      const checkStatus = FilterCheckboxConfig.find(
        ({ state }) => status.toString() === state.toString(),
      );
      statusArray.push({
        status: checkStatus?.state ?? 0,
        name: checkStatus?.title ?? '',
      });
    });

    if (dataForm.flagCessioneCredito) {
      booleanArray.push({
        label: 'lbl.withAdvancePaymentTitle',
        fieldName: flagCessioneCredito,
      });
    }
    dataForm.flagNonCessioneCredito &&
      booleanArray.push({
        label: 'lbl.withoutAdvancePaymentTitle',
        fieldName: flagNonCessioneCredito,
      });
  }

  dataForm.amounts?.forEach((x: any) => {
    const amount = amounts.find(
      ({ From, To }) =>
        From.toString() === x.From.toString() &&
        To.toString() === x.To.toString(),
    );
    amount && paymentsArray.push(amount);
  });

  //Carousel behaviour
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startX;
    if (walk > 20 || walk < -20) {
      if (!isScrolling) setIsScrolling(true);
    }
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const handleLeave = () => {
    setIsDragging(false);
    setIsScrolling(false);
  };

  const resetFilter = () => {
    reset();
    handleClick(initialData);
  };

  if (
    clientNames.length === 0 &&
    statusArray.length === 0 &&
    paymentsArray.length === 0 &&
    booleanArray.length === 0 &&
    !(dataForm.fromDate || dataForm.toDate)
  )
    return <></>;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FilteredContainer
        margin="0 -2.4rem"
        padding="2.4rem 2.4rem 0"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleLeave}
        onMouseLeave={handleLeave}
      >
        {(dataForm.fromDate || dataForm.toDate) && (
          <FilteredButtonPayByLink
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            fromDate={dataForm.fromDate}
            toDate={dataForm.toDate}
          />
        )}
        {paymentsArray.map((item, i) => (
          <FilteredButtonPayByLink
            dataForm={dataForm}
            disabled={isScrolling}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={i}
            amountFrom={item.From}
            amountTo={item.To}
          />
        ))}
        {statusArray.map(({ name, status }) => (
          <FilteredButtonPayByLink
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={status}
            filteredName={name}
            state={status}
          />
        ))}
        {clientNames.map(({ name, id }) => (
          <FilteredButtonPayByLink
            disabled={isScrolling}
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={id}
            filteredName={name}
            buyerId={id}
          />
        ))}
        {booleanArray.map(({ label, fieldName }, i) => (
          <FilteredButtonBooleanPayByLink
            disabled={isScrolling}
            label={label}
            fieldName={fieldName}
            dataForm={dataForm}
            // setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={i}
          />
        ))}
        <Button
          minWidthMobile="max-content"
          minWidth="max-content"
          variant="TextButton"
          height="3.6rem"
          onClick={resetFilter}
        >
          <Translate id="lbl.deleteAll" />
        </Button>
      </FilteredContainer>
    </StyleSheetManager>
  );
};

export default FilteredListPayByLink;

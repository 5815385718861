import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import PlanCard from 'src/components/PlanCard/PlanCard.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { usePayByLinkStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import {
  InstallmentConfigForm,
  variantRadioBox,
} from './StepInstallmentDelay.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StepInstallmentDelay() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const customInstallments = usePayByLinkStore(
    (state) => state.customInstallments,
  );
  const variants = usePayByLinkStore((state) => state.variants);
  const { initialValues, resolver } = InstallmentConfigForm(
    customInstallments?.Code,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const onSubmit = async (data: any) => {
    setValue(
      'customInstallments',
      variants?.find((item: any) => item.Code === data[variantRadioBox]),
    );
    setValue('variantCode', data[variantRadioBox]);
    navigate(urlRedirectToFlow);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.paymentDate"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.pblDelayInfo" />
        </H4>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="content grid gap-6"
          >
            {variants?.map((plan, index: number) => (
              <PlanCard
                key={index}
                name={variantRadioBox}
                value={plan.Code}
                rateDate={plan.Installments[0].Expiration}
                productCode={plan.Code}
              />
            ))}
            <ButtonContainer>
              <SubmitButton
                disabled={!methods.formState.isValid}
                minWidth="100%"
              >
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepInstallmentDelay;

import Avatar from 'src/components/Avatar/Avatar.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import {
  AnimatedBg,
  LoginContainer,
} from 'src/components/FormLogin/style/FormLogin.style';
import Translate from 'src/components/Translate/Translate.component';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BuyerRegistrationStatus } from 'src/services/ecommerce/ecommerce.types';
import {
  useCurrentTransactionStore,
  useRegistrationStore,
} from 'src/store/store';
import { PSmall } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';
import FormLogin from 'src/components/FormLogin/FormLogin.component';

const Login: React.FC = () => {
  const setValues = useRegistrationStore((state) => state.setValues);
  const location = useLocation();
  const activeOrder = location.state?.activeOrder;
  const registrationStatus =
    useCurrentTransactionStore().buyerRegistrationStatus;
  const hideSignUp =
    registrationStatus === BuyerRegistrationStatus.registered && activeOrder;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="relative z-0 h-full overflow-hidden">
        <LoginContainer maxWidth="51.2rem" margin="2.4rem auto">
          <Avatar title="lbl.login" />
          <FormLogin />
          {!hideSignUp && (
            <>
              <DividerOr />
              <PSmall onClick={() => setValues(resetRegistrationStore)}>
                <Translate id="lbl.signUp" />
              </PSmall>
            </>
          )}
        </LoginContainer>
        <div style={{ height: window.innerWidth < 1024 ? '15vh' : '0' }}></div>
        <AnimatedBg />
      </div>
    </StyleSheetManager>
  );
};

export default Login;

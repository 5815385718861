import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import ExpiredCartIcon from 'src/images/pageStatus/expired-cart.svg?url';
import {
  LandingPageContainer,
  LandingPageIcon,
} from 'src/pages/landingPage/LandingPage.style';
import { H4, H5 } from 'src/style-utils/typographic';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import { useSession } from 'src/services/session/session.context';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ExpiredCart() {
  const { onLogout } = useSession();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <LandingPageContainer
        flex="1"
        flexDirection="column"
        padding="4.8rem 2.4rem"
        gap="1.6rem"
      >
        <LandingPageIcon src={ExpiredCartIcon} />
        <H4 className="mt-2">
          <Translate id="text.expiredCart" />
        </H4>
        <H5 light colorGray>
          <Translate id="text.expiredCartSubtitle" />
        </H5>
        <ButtonContainer>
          <Button
            variant="Primary"
            onClick={() => {
              (window as any).parent.postMessage(
                JSON.stringify({ status: 'close' }),
                '*',
              );
              onLogout();
            }}
          >
            <Translate id="lbl.backToEcommerce" />
          </Button>
          <NeedHelpButton />
        </ButtonContainer>
      </LandingPageContainer>
    </StyleSheetManager>
  );
}

export default ExpiredCart;

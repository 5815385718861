import { AxiosResponse } from 'axios';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import { GenericErrorForm } from 'src/components/GenericErrorForm/GenericErrorForm.component';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProps } from 'src/pages/registration/Registration.types';
import { Privacy } from 'src/pages/registration/registrationMerchant/style/registrationMerchant.style';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { emailField } from 'src/services/registration/registration.config';
import {
  DataTypeArray,
  UpdateSignUpData,
  initSignUpSessionMethodApi,
  updateSignUpSessionMethodApi,
} from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import { IDs } from 'src/utils/config/ids';
import { formConfigEmailRegistration } from './FormEmail.helpers';
import FormEmailModalLoadable from './modal/PaymSuccessModal.loadable';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function FormEmail({
  nextPath,
  step,
  stepMax,
  privacy,
  typeAccount,
}: FormProps) {
  const email = useRegistrationStore((state) => state.email);
  const setValue = useRegistrationStore((state) => state.setValue);
  const sessionGuid = useRegistrationStore((state) => state.sessionGuid);
  const { initialValues, resolver } = formConfigEmailRegistration(email);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const BuyerGuid = searchParams.get('guid')!;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (BuyerGuid) {
      setTimeout(() => {
        setShowModal(true);
      }, 1000);
    }
  }, []);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (values: any) => updateSignUpSessionMethodApi(values),
    onSuccess: (res) => {
      if (res.data.ResultSet) navigate(nextPath);
    },
    onError: (error: any) => {
      const errorResponse = error.response as AxiosResponse;
      setErrorMessage(errorResponse.data.errorInfos.code);
    },
  });

  const { mutate: mutateInitSession, isPending: isLoadingInitSession } =
    useMutation({
      mutationFn: initSignUpSessionMethodApi,
      onSuccess: (res) => {
        setValue('sessionGuid', res.data.ResultSet.Guid);
        setValue('mobile', res.data.ResultSet.Phone);
        const getValues = methods.getValues();
        const UpdateData: UpdateSignUpData = {
          DataType: DataTypeArray.email,
          DataValue: getValues[emailField],
          SessionGuid: res.data.ResultSet.Guid,
        };
        mutate(UpdateData);
      },
      onError: () => {
        navigate(RoutePath.oops);
      },
    });

  const onSubmit = async (data: any) => {
    setValue('email', data[emailField]);
    if (typeAccount === 'BUYER' && BuyerGuid) {
      mutateInitSession({
        BuyerGuid,
        AccountType: 'BUYER',
        Language: language,
        RegistrationType: 'full',
      });
      return;
    }
    const UpdateData: UpdateSignUpData = {
      DataType: DataTypeArray.email,
      DataValue: data[emailField],
      SessionGuid: sessionGuid,
    };
    mutate(UpdateData);
  };

  if (isPending || isLoadingInitSession) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="lbl.registrationEmailAddress"
        showWhiteSpaceRight
        stepNumber={step}
        stepMaxNumber={stepMax}
        showBackButton={!/registration-buyer-guest/.test(window.location.href)}
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.registrationEmail" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField name={emailField} id={IDs.inputEmail} />
              <GenericErrorForm
                isPresentMessage
                errorMessageID={errorMessage}
              />
              <ButtonContainer>
                <SubmitButton minWidth="100%" id={IDs.btnSummary}>
                  <Translate id="lbl.next" />
                </SubmitButton>
                {privacy && (
                  <Privacy>
                    <Translate
                      id="link.privacyLink"
                      extraTranslationProps={{
                        components: { u: <a /> },
                      }}
                    />
                  </Privacy>
                )}
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
      {showModal && (
        <FormEmailModalLoadable
          handleClose={() => setShowModal(false)}
          i18n
          modalTitle="lbl.paymentConfirmed"
        />
      )}
    </StyleSheetManager>
  );
}

export default FormEmail;

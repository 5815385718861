import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { FindOutMoreEnum } from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.config';
import FindOutMoreModalLoadable from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.loadable';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { openSigningSupportMethodApi } from 'src/services/onboarding/onboarding.request';
import { OpenSigningSupportData } from 'src/services/onboarding/onboarding.types';
import { GetUrlToIdentify, getLegalInfo } from 'src/services/user/user.config';
import {
  GetUrlToIdentifyMethodApi,
  getLegalInfoMethodApi,
} from 'src/services/user/user.request';
import { H4, H5, P } from 'src/style-utils/typographic';
import { Card, FlowContainer } from 'src/style/Container.style';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function SignWithSpid() {
  const location = useLocation();
  const wrongData = location?.state?.wrongData;
  const [showModalHeader, setShowModalHeader] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const { isLoading, data: dataLegal } = useQuery({
    queryKey: [getLegalInfo],
    queryFn: getLegalInfoMethodApi,
  });

  const { data, isLoading: isLoadingGetUrl } = useQuery({
    queryKey: [GetUrlToIdentify],
    queryFn: GetUrlToIdentifyMethodApi,
  });
  const { isPending: isLoadingSupport, mutate } = useMutation({
    mutationFn: (params: OpenSigningSupportData) =>
      openSigningSupportMethodApi(params),
    onSuccess: async () => {
      navigate(RoutePath.onboarding + RoutePath.assistance);
    },
    onError: () => {
      navigate(RoutePath.internalError);
    },
  });
  const spid = () => {
    wrongData
      ? mutate({
          hasSpid: true,
          representativeIsWrong: false,
          otherWrongData: true,
        })
      : (window as any).open(data?.data?.ResultSet, '_self');
  };
  const notSpid = () => {
    mutate({
      hasSpid: false,
      representativeIsWrong: false,
      otherWrongData: wrongData ? true : false,
    });
  };
  if (isLoading || isLoadingSupport || isLoadingGetUrl) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="lbl.signWithSpidPage"
        showBackButton
        stepNumber={2}
        stepMaxNumber={3}
        showInfoButton
        infoButtonAction={() => setShowModalHeader(true)}
        backButtonAction={() =>
          navigate(RoutePath.onboarding + RoutePath.readContract, {
            state: {
              alreadyReading: true,
            },
          })
        }
      />
      <FlowContainer>
        <H4>
          <Translate id="text.signWithSpidTitle" />
        </H4>
        <P>
          <Translate id="text.signWithSpidDescription" />
        </P>
        <Card margin="4rem auto">
          <H5 light colorGray marginBottom="1.2rem">
            <Translate id="lbl.signatoryData" />
          </H5>
          <P>
            <Translate id="lbl.firstNameLastName" />
          </P>
          <P bold marginBottom="1.2rem" colorBlack>
            {`${dataLegal?.data.ResultSet.FirstName} ${dataLegal?.data.ResultSet.LastName}`}
          </P>
          <P>
            <Translate id="lbl.payByLinkCf" />
          </P>
          <P bold marginBottom="1.2rem" colorBlack>
            {dataLegal?.data.ResultSet.FiscalCode}
          </P>
        </Card>
        <ButtonContainer>
          <Button variant="Primary" minWidth="100%" onClick={() => spid()}>
            <Translate id="lbl.hasSpid" />
          </Button>
          <Button
            variant="Tertiary"
            minWidth="100%"
            onClick={() => setShowPopUp(true)}
          >
            <Translate id="lbl.doesntHaveSpid" />
          </Button>
        </ButtonContainer>
      </FlowContainer>
      {showModalHeader && (
        <FindOutMoreModalLoadable
          buttonTitle="lbl.close"
          modalTitle="text.findOutMoreNamirialTitlePage"
          type={FindOutMoreEnum.namirial}
          handleClose={() => setShowModalHeader(false)}
          i18n
        />
      )}
      {showPopUp && (
        <DoubleChoicePopUp
          handleSecondAction={notSpid}
          handleClose={() => setShowPopUp(false)}
          firstButtonLabel="lbl.next"
          lastButtonLabel="lbl.cancel"
          mainText="text.popUpSpid"
          icon={AlertIcon}
        />
      )}
    </StyleSheetManager>
  );
}

export default SignWithSpid;

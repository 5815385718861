import { IconButton } from 'src/components/Button/IconButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ChangeAccountCard } from './ChangeAccountTypeCard.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

interface Props {
  title?: string;
  subtitle?: string;
  onClickHandle?: any;
}

const ChangeAccountTypeCard: React.FC<Props> = ({
  title,
  subtitle,
  onClickHandle,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ChangeAccountCard
        data-component="change-account-type-card"
        margin="0"
        padding="1.6rem"
        onClick={onClickHandle}
      >
        <Flex justify="space-between" gap="2.4rem">
          <div>
            {title && (
              <P bold colorBlack>
                <Translate id={title} />
              </P>
            )}
            {subtitle && (
              <P>
                <Translate id={subtitle} />
              </P>
            )}
          </div>
          <div>
            <IconButton IconSrc={ArrowRight} variant="Secondary" />
          </div>
        </Flex>
      </ChangeAccountCard>
    </StyleSheetManager>
  );
};

export default ChangeAccountTypeCard;

import Translate from 'src/components/Translate/Translate.component';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  CheckBoxWrapper,
  CheckboxContainer,
  CheckboxInput,
  Checkmark,
} from './checkboxStripe.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type CheckboxStripeProps = {
  description: string;
  checked: boolean;
  disabled?: boolean;
  setChecked?: any;
};
function CheckboxStripe({
  description,
  checked,
  setChecked,
  disabled,
}: CheckboxStripeProps) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex justify="space-between" align="center" flexWrap="nowrap" gap="2rem">
        <P marginTop="0">
          <Translate id={description} />
        </P>
        <CheckBoxWrapper>
          <CheckboxContainer>
            <CheckboxInput
              checked={checked}
              type="checkbox"
              onClick={() => setChecked(!checked)}
              disabled={disabled}
            />
            <Checkmark />
          </CheckboxContainer>
        </CheckBoxWrapper>
      </Flex>
    </StyleSheetManager>
  );
}

export default CheckboxStripe;

import PayByLinkChoiceCard from 'src/components/PayByLinkChoiceCard/PayByLinkChoiceCard.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  getVariantsData,
  getVariantsWithPlanMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { FlowContainer, Grid } from 'src/style/Container.style';
import { ProductCodes } from 'src/utils/types/common.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StartInstallmentPlan() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { productCode, categoryCode } = useParams();
  const navigate = useNavigate();
  const { setValue, amount } = usePayByLinkStore((state) => state);

  const { mutate } = useMutation({
    mutationFn: getVariantsWithPlanMethodApi,
    onSuccess: (res) => {
      setValue('variants', res.data.ResultSet);
      setValue('customInstallments', res.data.ResultSet[0]);
      setValue('firstInstallmentDate', '');
      setValue('isDeferred', false);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepInstallmentPlan}`,
      );
    },
  });

  const clickHandler = () => {
    const dataVariant: getVariantsData = {
      code: productCode as ProductCodes,
      amount: amount,
    };
    mutate(dataVariant);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.startInstallmentPlan"
      />
      <FlowContainer>
        <Grid gap="2.4rem" gapOnDesktop="3.2rem" className="content">
          <PayByLinkChoiceCard
            title="text.startInstallmentPlanFuture"
            subTitle="text.startInstallmentPlanFutureDescr"
            onClick={() => navigate(urlRedirectToFlow)}
          />
          <PayByLinkChoiceCard
            title="text.startInstallmentPlanNow"
            subTitle="text.startInstallmentPlanNowDescr"
            onClick={clickHandler}
          />
        </Grid>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StartInstallmentPlan;

import { AxiosResponse } from 'axios';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import InputField from 'src/components/InputField/InputField.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { badRequest_400 } from 'src/services/api/api.statusCodes';
import { initRecoveryDataMethodApi } from 'src/services/recoveryData/recoveryData.request';
import { InitRecoveryData } from 'src/services/recoveryData/recoveryData.types';
import { emailField } from 'src/services/registration/registration.config';
import { H4 } from 'src/style-utils/typographic';
import { FormStyle } from 'src/style/styleInput.style';
import { formRecoveryPasswordConfig } from './RecoveryPassword.helpers';
import { FlowContainer } from 'src/style/Container.style';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RecoveryPassword() {
  const { initialValues, resolver } = formRecoveryPasswordConfig();
  const { activeOrder } = useOutletContext<RecoveryDataContext>();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: initRecoveryDataMethodApi,
    onSuccess: (res, variables) => {
      navigate(
        `${RoutePath.recoveryData}${RoutePath.insertOtp}?sessionGuid=${res.data.ResultSet}`,
        {
          state: {
            type: 'password',
            value: variables.Auth1TargetValue,
            activeOrder,
          },
        },
      );
    },
    onError: (error: any) => {
      const errorResponse = error.response as AxiosResponse;
      if (errorResponse?.status === badRequest_400) {
        methods.setError(emailField, {
          message: 'error.accountNotExists',
        });
      }
    },
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const params: InitRecoveryData = {
      Type: 'password',
      Auth1TargetValue: data[emailField],
    };
    mutate(params);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        stepNumber={1}
        stepMaxNumber={4}
        showWhiteSpaceRight
        showBackButton
        backButtonAction={() => navigate(-1)}
        label="lbl.payByLinkEmailAddress"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryPassword" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField isPresentMessage name={emailField} />
              <ButtonContainer>
                <SubmitButton i18n translatedText="lbl.next" />
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default RecoveryPassword;

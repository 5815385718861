import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import ButtonCard from 'src/components/ButtonCard/ButtonCard.component';
import DividerOr from 'src/components/DividerOr/DividerOr.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Loader from 'src/components/Loader/Loader.component';
import PaymentMethodElement from 'src/components/PaymentMethodElement/PaymentMethodElement.component';
import Translate from 'src/components/Translate/Translate.component';
import { MethodsContextType } from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { setPreferredPaymentMethodApi } from 'src/services/payments/payments.request';
import { ChangeMethodFormData } from 'src/services/payments/payments.types';
import { useProfileStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ChoosePaymentMethodPage() {
  const { setValue } = useProfileStore((state) => state);
  const { mutate: setPreferred, isPending: isSettingPreferred } = useMutation({
    mutationFn: (id: number) => setPreferredPaymentMethodApi(id),
    onSuccess: () => {
      refetchPaymentMethods();
      const d = new Date();
      d.setTime(d.getTime() + 24 * 3600 * 1000);
      document.cookie = 'changed=true; expires=' + d.toUTCString() + 'path=/';
      navigate(RoutePath.methods + RoutePath.paymentMethods, {
        state: { changed: true },
      });
    },
    onError: () => navigate(RoutePath.oops),
  });
  const {
    paymentMethods,
    goToStripeFormHandler,
    changedMethod,
    refetchPaymentMethods,
  } = useOutletContext<MethodsContextType>();
  const navigate = useNavigate();

  const selectedMethodId = changedMethod
    ? changedMethod.Id
    : (paymentMethods.find((item) => item.IsPreferred)?.Id ?? -1);

  const methods = useForm({
    defaultValues: {
      paymentMethodId: selectedMethodId.toString(),
    } as ChangeMethodFormData,
    mode: 'onSubmit',
  });

  const onSubmit = (values: ChangeMethodFormData) => {
    const sepaMethod = paymentMethods.find(
      (method) =>
        method.Id === +values.paymentMethodId && method.Type === 'sepa_debit',
    );
    if (sepaMethod) {
      setValue('changedMethod', sepaMethod);
      navigate(RoutePath.methods + RoutePath.addBackupMethod);
      return;
    }
    setPreferred(+values.paymentMethodId);
    return;
  };

  const index =
    paymentMethods &&
    paymentMethods.map((item) => item.Id).indexOf(selectedMethodId);

  if (changedMethod) {
    paymentMethods.splice(index, 1);
    paymentMethods.unshift(changedMethod);
  }

  if (isSettingPreferred) return <Loader active />;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="container pb-8">
        <GoBackButton />
        <div className="mx-auto max-w-oxl">
          <div className="mx-auto grid max-w-olg gap-4 lg:text-center">
            <H4>
              <Translate id="text.changePaymentMethod" />
            </H4>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Flex
                flexDirection="column"
                align="center"
                gap="2.4rem"
                padding="2.4rem 0"
              >
                {paymentMethods &&
                  paymentMethods.map((payCard, i: number) => {
                    return <PaymentMethodElement key={i} {...payCard} />;
                  })}
              </Flex>
              <DividerOr />
              <ButtonCard
                createPaymentIntentHandler={() => goToStripeFormHandler()}
                type={'change'}
              />
              <ButtonContainer marginTop="4rem">
                <Button
                  variant="Primary"
                  sizeOnDesktop="medium"
                  type="button"
                  minWidth="100%"
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={!methods.formState.isValid}
                >
                  <Translate id="text.continue" />
                </Button>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </div>
    </StyleSheetManager>
  );
}

export default ChoosePaymentMethodPage;

import Loader from 'src/components/Loader/Loader.component';
import { useMutation } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { getEligibleOrdersMethodApi } from 'src/services/orderLink/orderLink.request';
import { SelectedItem, advOutletContext } from './AdvPaymentFlow.types';
import React, { useEffect, useRef, useState } from 'react';
import {
  DataFormEligibleOrders,
  searchCriteriaFormConfig,
} from './partials/ChooseLinkPage/partials/SearchCriteriaForm.helpers';
import { Buyers } from 'src/services/monitoring/monitoring.types';

function ADVPaymentFlow() {
  const { initialValues } = searchCriteriaFormConfig();
  const [selectedLinks, setSelectedLinks] = useState<SelectedItem[]>([]);
  const [filtersLinks, setFiltersLinks] =
    useState<DataFormEligibleOrders>(initialValues);
  const [clients, setClients] = useState<Buyers[]>([]);
  const initialMutationCompleted = useRef(false);
  const { mutate, data, isPending, isSuccess, isError } = useMutation({
    mutationFn: getEligibleOrdersMethodApi,
  });

  const selectedLinksMemo = React.useMemo(() => selectedLinks, [selectedLinks]);
  const filtersLinksMemo = React.useMemo(() => filtersLinks, [filtersLinks]);

  useEffect(() => {
    if (!isPending) {
      mutate({ selectedLinks, filtersLinks });
    }
  }, [selectedLinksMemo, filtersLinksMemo]);

  useEffect(() => {
    if ((isSuccess || isError) && !initialMutationCompleted.current) {
      initialMutationCompleted.current = true;
      const links = data?.data?.ResultSet || [];
      const uniqueBuyers: Buyers[] = links.reduce((acc: Buyers[], link) => {
        if (!acc.some((buyer) => buyer.Id === link.BuyerId)) {
          acc.push({ Id: link.BuyerId, Name: link.BuyerName });
        }
        return acc;
      }, []);
      setClients(uniqueBuyers);
    }
  }, [isSuccess, isError, data]);

  if (isPending)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  const links = isSuccess ? data?.data?.ResultSet : [];
  const advOutletContext: advOutletContext = {
    dataLinks: links,
    selectedLinks,
    setSelectedLinks,
    filtersLinks,
    setFiltersLinks,
    clients,
    refetchHandler: () => mutate({ selectedLinks, filtersLinks }),
  };
  return isSuccess || isError ? <Outlet context={advOutletContext} /> : null;
}

export default ADVPaymentFlow;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { ContainerInfo } from 'src/pages/AdvPaymentFlow/style/AdvPaymentFlow.style';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { H4, PSmall } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { useState } from 'react';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function EcommerceCheckData() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [searchParams] = useSearchParams();
  const checkoutSessionGuid: string = searchParams.get('checkoutSessionGuid')!;
  const sessionData = useOutletContext<EcommerceContext>().sessionData;
  const isBuyerItalian = isItalian(sessionData?.BuyerCountry);
  const navigate = useNavigate();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowContainer>
        <H4>
          <Translate id="lbl.isYourCompanyTitle" />
        </H4>
        <div className="content">
          <ContainerInfo className="flex flex-col gap-6">
            <div>
              <PSmall marginBottom="0.4rem">
                <Translate id="lbl.payByLinkFlowBusinessName" />
              </PSmall>
              <PSmall bold colorBlack>
                {sessionData?.BuyerName}
              </PSmall>
            </div>
            {isBuyerItalian ? (
              <div>
                <PSmall marginBottom="0.4rem">
                  <Translate id="lbl.payByLinkCf" />
                </PSmall>
                <PSmall bold colorBlack>
                  {sessionData?.BuyerFiscalCode}
                </PSmall>
              </div>
            ) : (
              sessionData?.BuyerVatCode && (
                <div>
                  <PSmall marginBottom="0.4rem">
                    <Translate id="text.vatNumber" />
                  </PSmall>
                  <PSmall bold colorBlack>
                    {sessionData?.BuyerVatCode}
                  </PSmall>
                </div>
              )
            )}
            {isBuyerItalian
              ? sessionData?.BuyerVatCode && (
                  <div>
                    <PSmall marginBottom="0.4rem">
                      <Translate id="lbl.payByLinkVatCode" />
                    </PSmall>
                    <PSmall bold colorBlack>
                      {sessionData?.BuyerVatCode}
                    </PSmall>
                  </div>
                )
              : sessionData?.BuyerPostalCode && (
                  <div>
                    <PSmall marginBottom="0.4rem">
                      <Translate id="lbl.postalCode" />
                    </PSmall>
                    <PSmall bold colorBlack>
                      {sessionData?.BuyerPostalCode}
                    </PSmall>
                  </div>
                )}
            {sessionData?.BuyerAddress && (
              <div>
                <PSmall marginBottom="0.4rem">
                  <Translate id="text.address" />
                </PSmall>
                <PSmall bold colorBlack>
                  {sessionData?.BuyerAddress}
                </PSmall>
              </div>
            )}
          </ContainerInfo>
        </div>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.yesIsMyCompany"
            onClick={() =>
              navigate(
                sessionData.SameCompanyError
                  ? RoutePath.oopsPlugin
                  : `${RoutePath.ecommerce}${RoutePath.ecommerceCheckout}?checkoutSessionGuid=${checkoutSessionGuid}`,
              )
            }
            id={IDs.btnConfirm}
          />
          <Button
            variant="Tertiary"
            minWidth="100%"
            i18n={true}
            translatedText={'lbl.notMyCompany'}
            onClick={() => setShowPopUp(true)}
            id={IDs.btnDecline}
          />
        </ButtonContainer>
        {showPopUp && (
          <DoubleChoicePopUp
            handleSecondAction={() =>
              navigate(
                RoutePath.ThankyouPageError + RoutePath.ecommerceWrongData,
              )
            }
            modalTitle="lbl.infoDoesntMatch"
            handleClose={() => setShowPopUp(false)}
            firstButtonLabel="lbl.confirm"
            lastButtonLabel="lbl.cancelAndGoBack"
            mainText="text.infoDoesntMatch"
            icon={AlertIcon}
          />
        )}
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default EcommerceCheckData;

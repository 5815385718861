import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { emailField } from 'src/services/registration/registration.config';
import { usePayByLinkStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import { IDs } from 'src/utils/config/ids';
import { formConfigEmailPayByLink } from './StepEmail.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StepEmail() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const inviteEmail = usePayByLinkStore((state) => state.inviteEmail);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const { initialValues, resolver } = formConfigEmailPayByLink(inviteEmail);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    setValue('inviteEmail', data[emailField]);
    navigate(urlRedirectToFlow);
    return;
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.emailCustomer"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.payByLinkEmail" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <InputField
                isPresentMessage
                name={emailField}
                id={IDs.inputEmail}
              />
              <ButtonContainer>
                <SubmitButton minWidth="100%" id={IDs.btnSummary}>
                  <Translate id="lbl.seeResume" />
                </SubmitButton>
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepEmail;

import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { CheckboxProps } from './Checkbox.types';

import {
  CheckboxContainer,
  CheckboxInput,
  Checkmark,
  CheckBoxLabel,
  CheckBoxWrapper,
  CheckBoxDescription,
} from 'src/components/Checkbox/style/Checkbox.style';
import { useController, useFormContext } from 'react-hook-form';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const Checkbox = React.memo(function Checkbox({
  viewMode,
  label,
  defaultChecked,
  checked,
  description,
  i18n,
  onClick,
  className,
  onFocus,
  onBlur: onBlurField,
  name,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  style,
  fontWeight,
  squareCheckBox,
  ...otherProps
}: CheckboxProps) {
  const { control } = useFormContext();
  const {
    field: { ...ref },
  } = useController({
    name,
    control,
  });

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CheckBoxWrapper
        data-component="checkbox"
        viewMode={viewMode}
        className={className}
        padding={padding}
        style={style}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
      >
        <CheckboxContainer squareCheckBox={squareCheckBox}>
          <CheckBoxLabel fontWeight={fontWeight}>
            {i18n ? <Translate id={label} /> : label}
          </CheckBoxLabel>
          <CheckboxInput
            squareCheckBox={squareCheckBox}
            checked={checked}
            defaultChecked={defaultChecked}
            type="checkbox"
            onClick={onClick}
            onFocus={onFocus}
            {...ref}
            {...otherProps}
          />
          <Checkmark squareCheckBox={squareCheckBox} />
          {!!description && (
            <CheckBoxDescription>
              {i18n ? <Translate id={description} /> : description}
            </CheckBoxDescription>
          )}
        </CheckboxContainer>
      </CheckBoxWrapper>
    </StyleSheetManager>
  );
});

export default Checkbox;

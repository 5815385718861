import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Toast from 'src/components/Toast/Toast.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_right.svg';
import { LinkAccount } from 'src/pages/account/style/LinkAccount.style';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getProductTemplates } from 'src/services/template/template.config';
import { getProductTemplatesMethodApi } from 'src/services/template/template.request';
import { P } from 'src/style-utils/typographic';

const UsageSettingSavedTemplates: React.FC = () => {
  const location = useLocation();
  const successDeleted = location?.state?.successDeleted;

  const { isLoading, data } = useQuery({
    queryKey: [getProductTemplates],
    queryFn: () => getProductTemplatesMethodApi(),
  });

  if (isLoading) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <div className="container">
      <GoBackButton url={RoutePath.usageSetting} label="lbl.usageSetting" />
      <div className="mx-auto max-w-md">
        <TextDefaultPage
          title="lbl.savedTemplates"
          subTitle="text.savedTemplatesSettings"
        />
        {data?.data?.ResultSet.map((template) => (
          <>
            <LinkAccount
              to={RoutePath.usageSettingTemplate + template.Id}
              state={{
                prospect: template.Prospect,
                templateName: template.Name,
              }}
            >
              <P>{template.Name}</P>
              <Icon iconSize={'2.8rem'} svgIconComponent={ArrowRight} />
            </LinkAccount>
          </>
        ))}
      </div>
      {successDeleted && (
        <Toast
          variant="success"
          title="text.deletedTemplateTitle"
          subTitle="text.deletedTemplateSubtitle"
          timeout={5000}
        />
      )}
    </div>
  );
};

export default UsageSettingSavedTemplates;

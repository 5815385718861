import Icon from 'src/components/Icon/Icon.component';
import StatusPayByLink from 'src/components/StatusPayByLink/StatusPayByLink.component';
import {
  PayByLinkStatus,
  PayByLinkStatusMap,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert.svg';
import { ReactComponent as ClockIcon } from 'src/images/icons/clock.svg';
import { ReactComponent as CheckIcon } from 'src/images/icons/circular_checkmark_green.svg';
import { ReactComponent as NegativeIcon } from 'src/images/icons/negative feedback_point_red.svg';
import { ReactComponent as CalendarIcon } from 'src/images/icons/calendar.svg';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import Translate from 'src/components/Translate/Translate.component';
import { useOutletContext } from 'react-router-dom';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { ContainerWrapper } from '../style/PayByLinkDetailInfo.style';
import { pblDetailPageArray } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.config';
import { Hr } from 'src/style-utils/hr';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export function LinkInfoTab1() {
  const dataLink = useOutletContext<PayByLinkDetailsContext>();
  const madeFromPlugin =
    useOutletContext<PayByLinkDetailsContext>().madeFromPlugin;
  const { subtitle } =
    PayByLinkStatusMap[dataLink.Status as StatusPyByLinkValues];
  const label =
    pblDetailPageArray[
      madeFromPlugin.toString() as keyof typeof pblDetailPageArray
    ];

  const formattedCreationDate = {
    date: refactorFormatDate(dataLink.Created, 'DD MMMM YYYY'),
    time: refactorFormatDate(dataLink.Created, 'HH:mm'),
  };

  const formattedExpirationDate = {
    date: refactorFormatDate(dataLink.Expiration, 'DD MMMM YYYY'),
    time: refactorFormatDate(dataLink.Expiration, 'HH:mm'),
  };

  const formattedExpiredDate = dataLink.LastUpdate
    ? {
        date: refactorFormatDate(dataLink.LastUpdate, 'DD MMMM YYYY'),
        time: refactorFormatDate(dataLink.LastUpdate, 'HH:mm'),
      }
    : null;

  const formattedUnderwritingDate = {
    date: refactorFormatDate(dataLink.UnderwritingDate, 'DD/MM/YYYY'),
    time: refactorFormatDate(dataLink.UnderwritingDate, 'HH:mm'),
  };

  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerWrapper>
        <div>
          <P bold>
            <Translate id="lbl.linkStatus" />
          </P>
          <Flex align="center" gap="1rem">
            <StatusPayByLink statusLink={dataLink.Status} />
            <P colorBlack>
              <Translate id={subtitle} />
            </P>
          </Flex>
        </div>
        <Hr marginBlock="1.2rem" />
        <div>
          <P bold>
            <Translate id="text.incomeMethod" />
          </P>
          <Flex align="center" gap="1rem">
            <P colorBlack>
              <Translate
                id={
                  dataLink.FlagCredit
                    ? 'text.advancePayment'
                    : 'text.withoutAdvancePayment'
                }
              />
            </P>
          </Flex>
        </div>
        <Hr marginBlock="1.2rem" />
        <div>
          {dataLink.Underwriting !== '' ? (
            <>
              <Flex align="center" gap="0.5rem">
                <P bold>
                  <Translate id="text.creditRating" />
                </P>
                {dataLink.Underwriting === 'LIGHT_OK' && (
                  <Icon
                    iconSize="1.6rem"
                    color={colors.textP}
                    svgIconComponent={AlertIcon}
                  />
                )}
              </Flex>
              <Flex gap="2.4rem">
                <Flex align="center" gap="4px">
                  <Icon
                    iconSize="2rem"
                    color={colors.textP}
                    svgIconComponent={
                      dataLink.Underwriting === 'OK' ? CheckIcon : NegativeIcon
                    }
                  />
                  <P colorBlack>
                    <Translate
                      id={
                        dataLink.Underwriting === 'OK' ? 'lbl.UwOk' : 'lbl.UwKo'
                      }
                    />
                  </P>
                </Flex>
                <Flex align="center" gap="4px">
                  <Icon
                    iconSize="20px"
                    color={colors.textP}
                    svgIconComponent={CalendarIcon}
                  />
                  <P colorBlack>{formattedUnderwritingDate.date}</P>
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              <Flex align="center" gap="0.5rem">
                <P bold>
                  <Translate id="text.creditRating" />
                </P>
              </Flex>
              <Flex gap="2.4rem">
                <Flex align="center" gap="4px">
                  <P colorBlack>
                    <Translate id={'lbl.uwInProgress'} />
                  </P>
                </Flex>
              </Flex>
            </>
          )}
        </div>
        <Hr marginBlock="1.2rem" />
        <div>
          <P bold>
            <Translate id={label.info.tab1.paragraphDate} />
          </P>
          <Flex align="center">
            <P colorBlack>
              {madeFromPlugin
                ? formattedExpirationDate.date
                : formattedCreationDate.date}{' '}
            </P>
            <Icon
              iconSize="1.6rem"
              iconHeight="1.6rem"
              marginLeft="1rem"
              svgIconComponent={ClockIcon}
            />
            <P colorBlack>
              {madeFromPlugin
                ? formattedExpirationDate.time
                : formattedCreationDate.time}
            </P>
          </Flex>
        </div>
        {dataLink.Status === PayByLinkStatus.createdLink ||
        dataLink.Status === PayByLinkStatus.expiredLink ||
        dataLink.Status === PayByLinkStatus.expiredPayment ||
        dataLink.Status === PayByLinkStatus.cancelledLink ||
        dataLink.Status === PayByLinkStatus.wrongLink ? (
          <>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.linkExpirationDate" />
              </P>
              <Flex align="center">
                <P colorBlack>{formattedExpirationDate.date}</P>
                <Icon
                  iconSize="1.6rem"
                  iconHeight="1.6rem"
                  marginLeft="1rem"
                  svgIconComponent={ClockIcon}
                />
                <P colorBlack>{formattedExpirationDate.time}</P>
              </Flex>
            </div>
          </>
        ) : !formattedExpiredDate ? (
          <>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id="lbl.lastDate" />
              </P>
              <Flex align="center">
                <P colorBlack>
                  {refactorFormatDate(
                    dataLink.LastInstallmentDate,
                    'DD MMMM YYYY',
                  )}
                </P>
              </Flex>
            </div>
          </>
        ) : null}
        {formattedExpiredDate && (
          <>
            <Hr marginBlock="1.2rem" />
            <div>
              <P bold>
                <Translate id={'lbl.lastDate'} />
              </P>
              <Flex align="center">
                <P colorBlack>
                  {madeFromPlugin
                    ? formattedExpiredDate.date
                    : formattedExpiredDate.date}{' '}
                </P>
                <Icon
                  iconSize="1.6rem"
                  iconHeight="1.6rem"
                  marginLeft="1rem"
                  svgIconComponent={ClockIcon}
                />
                <P colorBlack>
                  {madeFromPlugin
                    ? formattedExpirationDate.time
                    : formattedCreationDate.time}
                </P>
              </Flex>
            </div>
          </>
        )}
        <Hr marginBlock="1.2rem" />
        <div>
          <P bold>
            <Translate id="lbl.serviceCost" />
          </P>
          <P colorBlack>{formatCurrency(dataLink.FeeAmount)}</P>
        </div>
        <Hr marginBlock="1.2rem" />
        {(dataLink?.BuyerEmail.length > 0 ||
          dataLink?.InviteEmailsSent.length > 0) && (
          <>
            <div>
              <P bold>
                <Translate id="lbl.payByLinkDetailEmailAddress" />
              </P>
              <P colorBlack>
                {dataLink?.BuyerEmail.length > 0
                  ? dataLink?.BuyerEmail
                  : dataLink.InviteEmailsSent?.at(-1)?.Recipient}
              </P>
            </div>
            <Hr marginBlock="1.2rem" />
          </>
        )}
        <div>
          <P bold>
            <Translate id="lbl.description" />
          </P>
          <P colorBlack>{dataLink.Description}</P>
        </div>
      </ContainerWrapper>
    </StyleSheetManager>
  );
}

import { StyledProps } from 'src/@types/styled-components';
import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { TextArea, Input, FormGroup } from 'src/style/styleInput.style';
import styled from 'styled-components';

export const IconInsideInput = styled(Icon)`
  position: absolute;
  right: 0;
  margin-right: 10px;
  bottom: 1rem;
  display: none;
`;

type EyeIconProps = {
  iconSrc: string;
};

export const EyeIcon = styled.div<StyledProps<EyeIconProps>>`
  background: ${({ iconSrc }) => `url("${iconSrc}") no-repeat center`};
  width: 2.6rem;
  background-size: contain;
  height: 2.4rem;
`;

type EyeIconWrapperProps = {
  hasErrorIcon?: boolean;
  iconColor?: string;
};

export const EyeIconWrapper = styled.div<StyledProps<EyeIconWrapperProps>>`
  cursor: pointer;
  position: absolute;
  top: -0.1rem;
  padding: 0;
  right: ${({ hasErrorIcon }) => (hasErrorIcon ? '3.6rem' : '1rem')};
  color: ${({ iconColor }) => (iconColor ? iconColor : '')};
  display: flex;
  align-items: center;
  height: 100%;
  ${media.tablet`
    top: 0.1rem;
  `}
`;

export const FormGroupCustom = styled(FormGroup)`
  ${({ isError }) => {
    if (isError) {
      return `
        color: ${colors.redInput};


        /* '& > Input:focus' is there only to fix specificity issues. */

        & > ${Input},
        & > ${Input}:focus,
        & > ${TextArea},
        & > ${TextArea}:focus {
          border-color: ${colors.yellowStatus};
        }

        & > ${IconInsideInput} {
          display: block;
        }
      `;
    }
  }}
`;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { getCompanyInfo } from 'src/services/orderLink/orderLink.config';
import { getCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import { initSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { ContainerInfo } from '../../style/registrationGuest.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RegistrationGuestStepCheckData() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { language } = useLanguage();
  const BuyerGuid: string = searchParams.get('guid')!;
  const { setValue, setValues } = useRegistrationStore();

  const { isLoading: isLoadingGetCompanyInfo, data } = useQuery({
    queryKey: [getCompanyInfo],
    queryFn: () => getCompanyInfoMethodApi({ guid: BuyerGuid }),
  });

  const dataCompany = data?.data.ResultSet;

  const { mutate, isPending } = useMutation({
    mutationFn: initSignUpSessionMethodApi,
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      setValue('mobile', res.data.ResultSet.Phone);
      setValue('longRegistration', true);
      navigate(RoutePath.registrationGuest + RoutePath.emailStep);
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const onSubmit = () => {
    mutate({
      BuyerGuid,
      AccountType: 'BUYER',
      Language: language,
      RegistrationType: 'full',
    });
  };

  if (isPending || isLoadingGetCompanyInfo) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="lbl.dataConfirmation"
        showWhiteSpaceRight
        stepNumber={3}
        stepMaxNumber={7}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />
      <FlowContainer>
        <H4>
          <Translate id="lbl.areCompanyDataCorrect" />
        </H4>
        <div className="content">
          <ContainerInfo>
            {dataCompany?.Name && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkFlowBusinessName" />
                </P>
                <P bold colorBlack>
                  {dataCompany?.Name}
                </P>
              </div>
            )}
            {dataCompany?.Country === 'it' ? (
              <>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkCf" />
                  </P>
                  <P bold colorBlack>
                    {dataCompany?.FiscalCode}
                  </P>
                </div>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkVatCode" />
                  </P>
                  <P bold colorBlack>
                    {dataCompany?.VatCode}
                  </P>
                </div>
              </>
            ) : (
              <>
                {dataCompany?.VatCode && (
                  <div>
                    <P>
                      <Translate id="text.vatNumber" />
                    </P>
                    <P bold colorBlack>
                      {dataCompany?.VatCode}
                    </P>
                  </div>
                )}
                {dataCompany?.PostalCode && (
                  <div>
                    <P>
                      <Translate id="lbl.postalCode" />
                    </P>
                    <P bold colorBlack>
                      {dataCompany?.PostalCode}
                    </P>
                  </div>
                )}
              </>
            )}
            {dataCompany?.Address && (
              <div>
                <P>
                  <Translate id="text.address" />
                </P>
                <P bold colorBlack>
                  {dataCompany?.Address}
                </P>
              </div>
            )}
          </ContainerInfo>
        </div>
        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.correctData"
            onClick={onSubmit}
            id={IDs.btnConfirm}
          />
          <Button
            variant="Tertiary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.wrongData"
            onClick={() => {
              setValues(resetRegistrationStore);
              navigate(RoutePath.support, { replace: true });
            }}
            id={IDs.btnDecline}
          />
        </ButtonContainer>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default RegistrationGuestStepCheckData;

import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { PSmall } from 'src/style-utils/typographic';
import {
  BalanceContainer,
  CustomCurrentBalance,
  CustomPendingBalance,
  CustomText,
  OrderDatasContainer,
  PendingIncomeContainer,
  PendingIncomeContentContainer,
  PendingIncomeTitle,
} from './CurrentBalance.style';
import { useState } from 'react';
import { TextDefaultPage } from '../TextDefaultPage/TextDefaultPage.component';
import BalanceMovementsModalLoadable from '../../pages/balanceMovementsPage/partials/BalanceMovementsModal/BalanceMovementsModal.loadable';
import { refactorFormatDate } from '../../utils/functions/refactorFormatDate';
import { Button } from '../Button/Button.component';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { RoutePath } from '../../routers/routers.config';
import { useNavigate } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  currentBalance: number;
  nextPayoutDate: string;
  bankAccountDetails: string;
  pendingBalance: number;
};

const CurrentBalance = ({
  currentBalance,
  nextPayoutDate,
  pendingBalance,
  bankAccountDetails,
}: Props) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const navigate = useNavigate();
  const [modalInfoOpen, setModalInfoOpen] = useState(false);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="bg-white">
        <ResponsiveContainer marginDesktop="0 32px">
          <Flex gap="2.4rem" flexDirection="column" padding="3.2rem 0">
            <TextDefaultPage
              title="menu.movements"
              modal={true}
              margin="0"
              onClickModal={() => setModalInfoOpen(true)}
            />
            <Flex
              className="flex flex-wrap gap-6 md:gap-0"
              data-component="current-balance"
            >
              <div className={`w-full md:basis-9/12`}>
                <OrderDatasContainer>
                  <BalanceContainer hasBorder={true}>
                    <CustomText>
                      {currentBalance > 0 ? (
                        <Translate
                          id="text.balanceMovements.nextScheduled"
                          data={{
                            x: bankAccountDetails,
                            y: refactorFormatDate(
                              nextPayoutDate,
                              'DD MMMM YYYY',
                            ),
                          }}
                        />
                      ) : (
                        <Translate
                          id="text.balanceMovements.nextScheduledNoBalance"
                          data={{
                            x: bankAccountDetails,
                            y: refactorFormatDate(
                              nextPayoutDate,
                              'DD MMMM YYYY',
                            ),
                          }}
                        />
                      )}
                      {currentBalance > 0 && (
                        <>
                          <br />
                          <Translate id="text.balanceMovements.currentBalance" />{' '}
                        </>
                      )}
                    </CustomText>
                    {''}
                    <CustomCurrentBalance>
                      {formatCurrency(currentBalance, {
                        removeWhiteSpaces: true,
                      })}
                    </CustomCurrentBalance>
                    <div className="capitalize">
                      <div className="mr-4 inline">
                        <PSmall className="inline">
                          <Translate id="lbl.or" />
                        </PSmall>
                      </div>
                      <Button
                        variant="Tertiary"
                        sizeOnDesktop="small"
                        minWidth="max-content"
                        padding={'1.2rem 1.6rem'}
                        i18n
                        translatedText="text.transferBalance"
                        className="ml-4"
                        disabled={currentBalance <= 0}
                        onClick={() => navigate(RoutePath.fundTransfer)}
                      />
                    </div>
                  </BalanceContainer>
                </OrderDatasContainer>
              </div>

              <div className="w-full md:basis-3/12 md:pl-6">
                <PendingIncomeContainer>
                  <PendingIncomeContentContainer>
                    <PendingIncomeTitle>
                      <Translate id="lbl.pendingIncomesTitle" />
                    </PendingIncomeTitle>
                    <CustomPendingBalance>
                      {formatCurrency(pendingBalance || 0, {
                        removeWhiteSpaces: true,
                      })}
                    </CustomPendingBalance>
                  </PendingIncomeContentContainer>
                </PendingIncomeContainer>
              </div>
            </Flex>
          </Flex>
        </ResponsiveContainer>
        {modalInfoOpen && (
          <BalanceMovementsModalLoadable
            buttonTitle="text.okUnderstand"
            modalTitle="menu.movements"
            supportButton={true}
            nextPayoutDate={nextPayoutDate}
            handleClose={() => setModalInfoOpen(false)}
            i18n
          />
        )}
      </div>
    </StyleSheetManager>
  );
};

export default CurrentBalance;

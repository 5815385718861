import NeedHelpBase from 'src/components/NeedHelpButton/NeedHelpBase.component';
import ResponsiveAccordion from 'src/components/ResponsiveAccordion/ResponsiveAccordion.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as MagentoLogo } from 'src/images/ecommerces/magentoLogo.svg';
import { ReactComponent as PrestashopLogo } from 'src/images/ecommerces/prestashopLogo.svg';
import { ReactComponent as CollaboratorsHeroImgage } from 'src/images/hero/ecommerceinfo_hero.svg';
import HomeStepper from 'src/pages/privateArea/partials/merchant/partials/homeStepper/HomeStepper.component';
import {
  ButtonCustom,
  CustomGrid,
  CustomHomeH2,
  CustomSeparator,
} from 'src/pages/privateArea/partials/merchant/style/MerchantArea.style';
import { H3, H5 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import {
  ecommerceListItems,
  ecommerceStepperArray,
} from '../ecommerceInfo/EcommerceInfo.config';
import ECommerceCard from './partials/ECommerceInfoCard.component';
import CardCTA from './partials/cardCTA/CardCTA.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const ECommerceList = [
  {
    img: MagentoLogo,
    label: 'link.goToSite',
    url: 'https://commercemarketplace.adobe.com/opyn-opynpaylater.html',
  },
  {
    img: PrestashopLogo,
    label: undefined,
    url: undefined,
  },
];

function EcommerceInfo() {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="bg-white py-10">
        <CustomGrid>
          <CollaboratorsHeroImgage width="100%" height="auto" />
          <Flex fluid flexDirection="column" gap="2rem">
            <CustomHomeH2>
              <Translate id="text.ecommerceInfoTitle" />
            </CustomHomeH2>
            <NeedHelpBase
              BaseComponent={ButtonCustom}
              i18n
              translatedText="lbl.contactUs"
            />
          </Flex>
        </CustomGrid>
      </div>

      <CustomSeparator />

      <div className="container py-16">
        <H3 light className="mb-8 text-center lg:mb-14">
          <Translate id="text.ecommerceAccordionTitle" />
        </H3>
        <ResponsiveAccordion listItems={ecommerceListItems} />
      </div>

      <div className="container py-16">
        <H3 light className="mb-8 lg:mb-14">
          <Translate id="text.ecommerceStepperTitle" />
        </H3>
        <HomeStepper stepperArray={ecommerceStepperArray} />
      </div>

      <div className="container py-16">
        <div className="mb-8 lg:mb-14">
          <H3 light>
            <Translate id="text.ecommerceListTitle" />
          </H3>
          <H5 colorGray light>
            <Translate id="text.ecommerceListDescription" />
          </H5>
        </div>
        <Flex
          gap="2.4rem"
          justify="flex-start"
          marginTop="3.2rem"
          flexWrap="wrap"
        >
          {ECommerceList.map((item, i) => (
            <ECommerceCard key={i} {...item} />
          ))}
        </Flex>
      </div>

      <div className="relative z-0 overflow-hidden py-16">
        <div className="container">
          <CardCTA />
        </div>
      </div>
    </StyleSheetManager>
  );
}

export default EcommerceInfo;

import { Flex } from 'src/style/flexbox.style';
import {
  AmountsType,
  MerchantNamesType,
  PaymentsFilteredListProps,
  StatusesType,
} from './PaymentsFilteredList.types';
import PaymentsFilteredButton from './partial/PaymentsFilteredButton.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PaymentsFilteredList = ({
  merchants,
  amounts,
  statuses,
  dataForm,
  handleClick,
  setDataFilter,
}: PaymentsFilteredListProps) => {
  const merchantNames: MerchantNamesType[] = [];
  const statusesArray: StatusesType[] = [];
  const paymentsArray: AmountsType[] = [];

  dataForm.Merchants?.forEach((item) => {
    const merchant = merchants.find(
      ({ Id }) => Id.toString() === item.toString(),
    );
    merchantNames.push({ name: merchant!.RagioneSociale, id: merchant!.Id });
  });
  dataForm.Statuses?.forEach((x) => {
    const statusId = statuses.find((id) => id.toString() === x.toString());
    statusId && statusesArray.push({ id: statusId });
  });
  dataForm.Amounts?.forEach((x) => {
    const amount = amounts.find(
      ({ From, To }) =>
        From.toString() === x.From.toString() &&
        To.toString() === x.To.toString(),
    );
    amount && paymentsArray.push(amount);
  });

  if (
    merchantNames.length === 0 &&
    statusesArray.length === 0 &&
    paymentsArray.length === 0 &&
    dataForm.FromDate === undefined &&
    dataForm.ToDate === undefined
  )
    return <></>;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex
        data-component="payments-filterd-list"
        justify="flex-start"
        margin="2.4rem -5.2rem"
        padding="0 5.2rem"
        gap="1.2rem"
        overflow="auto"
        flexWrap="nowrap"
      >
        {(dataForm.FromDate || dataForm.ToDate) && (
          <PaymentsFilteredButton
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            fromDate={dataForm.FromDate}
            toDate={dataForm.ToDate}
          />
        )}
        {statusesArray.map(({ id }) => (
          <PaymentsFilteredButton
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={id}
            filteredName={id.toString()}
            status={id}
          />
        ))}
        {merchantNames.map(({ name, id }) => (
          <PaymentsFilteredButton
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={id}
            filteredName={name}
            merchantId={id}
          />
        ))}
        {paymentsArray.map((item, i) => (
          <PaymentsFilteredButton
            dataForm={dataForm}
            setDataFilter={setDataFilter}
            handleClick={handleClick}
            key={i}
            amountFrom={item.From}
            amountTo={item.To}
          />
        ))}
      </Flex>
    </StyleSheetManager>
  );
};

export default PaymentsFilteredList;

import InputAmountNumber from 'src/components/InputAmountNumber/InputAmountNumber.component';
import InputFieldCustom from 'src/components/InputFieldCustom/InputFieldCustom.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { ReactComponent as InstallmentIcon } from 'src/images/icons/installment-custom.svg';
import { ReactComponent as PositiveFeed } from 'src/images/icons/positive-feed.svg';
import colors from 'src/style-utils/colors';
import { PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { IDs } from 'src/utils/config/ids';
import { transformNumberRangeError } from 'src/utils/validation/numberInRange';
import { flexAmount, flexDate } from './FlexInstallmentCard.config';
import {
  CustomGridDiv,
  InstallmentCard,
  LabelCard,
} from './style/flexInstallmentCard.style';
import DatePickerField from 'src/components/DatePickerField/DatePickerField.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export interface FlexInstallmentCardProps {
  index: number;
  valid: boolean;
  disabled: boolean;
  errorMessageDate: string;
  errorMessageAmount: string;
  focused: boolean;
  minDate: Date;
  maxDate: Date;
}

const FlexInstallmentCard: React.FC<FlexInstallmentCardProps> = ({
  index,
  valid,
  disabled,
  errorMessageDate,
  errorMessageAmount,
  focused,
  minDate,
  maxDate,
}) => {
  const nameAmount = `${flexAmount}-${index}`;
  const nameDate = `${flexDate}-${index}`;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <InstallmentCard
        data-component="flex-installment-card"
        data-focused={focused}
      >
        <Flex
          justify="space-between"
          fluid
          align="center"
          marginBottom="1.6rem"
        >
          <LabelCard>
            <InstallmentIcon width="2rem" height="2rem" /> &nbsp;
            {index > 0 ? (
              <>
                <Translate id="lbl.rateNumber" />
                &nbsp;{index + 1}
              </>
            ) : (
              <Translate id="lbl.customInstallmentFirst" />
            )}
          </LabelCard>
          <Flex align="center" gap="0.6rem">
            {valid && (
              <>
                <PositiveFeed width="1.6rem" />
                <PSmall textColor={colors.greenStatus}>
                  <Translate id="lbl.wellDone" />
                </PSmall>
              </>
            )}
            {(errorMessageDate || errorMessageAmount) && (
              <>
                <AlertIcon width="1.6rem" />
                <PSmall textColor={colors.yellowStatus}>
                  <Translate
                    id={
                      errorMessageAmount ? errorMessageAmount : errorMessageDate
                    }
                  />
                </PSmall>
              </>
            )}
          </Flex>
        </Flex>
        <CustomGridDiv>
          <InputFieldCustom
            name={nameAmount}
            disabled={disabled}
            transformError={transformNumberRangeError}
            inputComponent={InputAmountNumber}
            placeholder={'lbl.import'}
            id={`${IDs.inputAmountInstall}${index}`}
          />
          <DatePickerField
            name={nameDate}
            disabled={disabled}
            isPresentMessage
            minDate={minDate}
            maxDate={maxDate}
          />
        </CustomGridDiv>
      </InstallmentCard>
    </StyleSheetManager>
  );
};

export default FlexInstallmentCard;

import { useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { CheckContainer } from './style/PaymentStatusCheckbox.style';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { PaymentStatusCheckboxMap } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Translate from 'src/components/Translate/Translate.component';
import { InstallmentStatus } from 'src/services/getOrderDetails/getOrderDetails.types';
import { StatusInstallment } from '../StatusInstallment/StatusInstallment.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type PaymentStatusCheckboxInput = {
  statusId: string;
};

const PaymentStatusCheckbox = ({ statusId }: PaymentStatusCheckboxInput) => {
  const statuses: string[] = useWatch({ name: 'Statuses' }) ?? [];

  const { append, remove } = useFieldArray({
    name: 'Statuses',
  });
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    checked ? remove(statuses.indexOf(statusId)) : append(statusId);
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(statuses.indexOf(statusId) > -1);
  }, [statuses]);

  const label =
    PaymentStatusCheckboxMap[Number(statusId) as StatusPyByLinkValues];
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CheckContainer
        data-component="payment-status-checkbox"
        onClick={handleClick}
        checked={checked}
      >
        <Flex flexDirection="column">
          <StatusInstallment
            statusInstallment={Number(statusId) as InstallmentStatus}
          />
          <PSmall>
            <Translate id={label} />
          </PSmall>
        </Flex>
      </CheckContainer>
    </StyleSheetManager>
  );
};

export default PaymentStatusCheckbox;

import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { PNote } from 'src/style-utils/typographic';
import { FlexProps } from 'src/style/flexbox.style';
import { PasswordStatusDisplay } from '../style/InputPassword.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = FlexProps & {
  hasValidLength: boolean;
  hasNumber: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSpecialChar: boolean;
  className?: string;
  activated: boolean;
};

const PasswordStatus: React.FC<Props> = ({
  hasValidLength,
  hasNumber,
  hasLowerCase,
  hasUpperCase,
  hasSpecialChar,
  activated: touched = false,
}) => {
  const specialCharacters = `!"£#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`;

  const validationsArray = [
    { valueName: hasValidLength, label: 'text.mustContainMaxCar' },
    { valueName: hasNumber, label: 'text.mustContainNumber' },
    { valueName: hasLowerCase, label: 'text.mustContainLowerCase' },
    { valueName: hasUpperCase, label: 'text.mustContainCapital' },
    {
      valueName: hasSpecialChar,
      label: 'text.mustContainSpecialChar',
      char: specialCharacters,
    },
  ];
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div>
        <PNote bold margin="1.6rem 0">
          <Translate id="lbl.passwordMustContain" />
        </PNote>
        {validationsArray.map((item, i: number) => (
          <PasswordStatusDisplay
            data-valid={item.valueName}
            data-touched={touched}
            key={i}
          >
            <Translate id={item.label} />
            {item.char ?? undefined}
          </PasswordStatusDisplay>
        ))}
      </div>
    </StyleSheetManager>
  );
};

export default PasswordStatus;

import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import styled from 'styled-components';
import radioBoxChecked from 'src/images/icons/radioBoxChecked.svg?url';
import radioBoxUnchecked from 'src/images/icons/radioUnchecked.svg?url';

export const AccordionArrow = styled(Icon)`
  color: ${colors.greyStatus};
  rotate: 0deg;
  transition: rotate 250ms ease-in-out;
  cursor: pointer;
`;

export const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 250ms ease-in-out;
  grid-template-rows: 0fr;
`;

export const AccordionContainer = styled.div`
  position: relative;
  isolation: isolate;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 0.8rem;
  outline: 1.5px solid ${colors.greyBorder};
  overflow: hidden;
  &[data-expanded='true'] ${AccordionBody} {
    grid-template-rows: 1fr;
  }
  &[data-expanded='true'] ${AccordionArrow} {
    rotate: -180deg;
  }
  &[data-disabled='true'] {
    background-color: ${colors.bgDisabledInput};
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: ${colors.bgDisabledInput};
      opacity: 0.6;
      z-index: 10;
    }
  }
`;

export const ResponsiveAccordionFlex = styled.div`
  color: ${colors.textColorBlack};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
`;

export const Checkmark = styled.span`
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  background-image: url(\"${radioBoxUnchecked}\");
  background-size: 1.6rem 1.6rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const DetailContainer = styled.div`
  display: grid;
  gap: 0.8rem;
  > div {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    background-color: ${colors.backgroundBlue};
  }
`;

export const CustomRadioBox = styled.input`
  display: none;
  &:checked + div {
    outline-color: ${colors.greyStatus};
    ${Checkmark} {
      background-image: url(\"${radioBoxChecked}\");
    }
  }
`;

import DataTable from 'src/components/DataTable/DataTable';
import { DataTableProps } from 'src/components/DataTable/DataTable.types';
import Translate from 'src/components/Translate/Translate.component';
import React, { useMemo } from 'react';
import { Hr } from 'src/style-utils/hr';
import { P } from 'src/style-utils/typographic';
import { advPlanRequestsTableColumnConfig } from '../AdvancePlanRequests.config';
import { ListTableAdvPlanRequests } from '../AdvancePlanRequests.types';
import NoDataRequests from './partialsTable/NoDataRequests.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const AdvancePlanRequestsTable: React.FC<{
  data?: any;
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  const advancePlanRequestsTableConfig: DataTableProps<ListTableAdvPlanRequests> =
    useMemo(
      () => ({
        data: data?.data?.ResultSet?.AdvanceRequests ?? [],
        hideHeader: false,
        isLoadingData: isLoading,
        NoDataComponent: NoDataRequests,
        columns: advPlanRequestsTableColumnConfig,
        customTableTheme: {
          cells: {
            style: {
              '&:first-child': {
                background: '#fff',
                '&::before': {
                  display: 'none',
                },
              },
            },
          },
        },
      }),
      [data],
    );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <DataTable {...advancePlanRequestsTableConfig} />
      {data?.data?.ResultSet?.AdvanceRequests && (
        <>
          <Hr />
          <P bold textAlign="center">
            <Translate id="text.noRequestsToShow" />
          </P>
        </>
      )}
    </StyleSheetManager>
  );
};

export default AdvancePlanRequestsTable;

import { memo, useMemo } from 'react';
import { TableColumn } from '../DataTable.types';
import Translate from 'src/components/Translate/Translate.component';
import { CellProps, CellRowHeader } from '../style/DataTable.style';
import { merge } from 'lodash-es';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type HeaderCellProps<TData> = {
  column: TableColumn<TData>;
  numberOfCells: number;
  i18nHeader: boolean;
};

const HeaderCell = <TData,>({
  column,
  numberOfCells,
  i18nHeader,
}: HeaderCellProps<TData>) => {
  const { header = '', cellConfig, rowCellConfig } = column;

  const mergedConfig: CellProps = useMemo(
    () => merge(cellConfig, rowCellConfig),
    [cellConfig, rowCellConfig],
  );
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <CellRowHeader
        style={{ width: `calc(100% / ${numberOfCells})` }}
        {...mergedConfig}
      >
        {i18nHeader ? <Translate id={header} /> : header}
      </CellRowHeader>
    </StyleSheetManager>
  );
};
export default memo(HeaderCell) as typeof HeaderCell;

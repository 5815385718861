import Icon from 'src/components/Icon/Icon.component';
import React, { useState } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { ErrorPagePar } from '../style/failedPage.style';
import { ReactComponent as Fishes } from 'src/images/icons/fishes.svg';
import { ReactComponent as Information } from 'src/images/icons/information_blue.svg';
import { H4, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import NotMyCompanyErrorModal from './NotMyCompanyErrorModal/NotMyCompanyErrorModal.loadable';
import { ReactComponent as Arrow } from 'src/images/icons/arrow_right.svg';
import { InfoLinkContainer, ClickableIcon } from './NotMyCompany.style';
import { Button } from 'src/components/Button/Button.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const NotMyCompany: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={Fishes}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="lbl.insertedWrongCf" />
      </H4>
      <ErrorPagePar marginBottom="15rem">
        <Translate id="text.insertedWrongCfExplain" />
      </ErrorPagePar>
      <InfoLinkContainer>
        <ClickableIcon
          svgIconComponent={Information}
          onClick={() => setModalOpen(true)}
        />
        <PSmall colorBlack marginTop="0.5rem">
          <Translate id="text.whyFiscalCode" />
        </PSmall>
        <Flex justify="flex-end">
          <Button
            variant="LinkPrimary"
            padding="0"
            height="fit-content"
            children={<Translate id="link.findOutMore" />}
            rightChild={
              <Icon
                svgIconComponent={Arrow}
                iconHeight={'2rem'}
                iconSize={'2rem'}
              />
            }
            onClick={() => setModalOpen(true)}
          />
        </Flex>
      </InfoLinkContainer>
      {modalOpen && (
        <NotMyCompanyErrorModal
          buttonTitle="text.okUnderstand"
          modalTitle="Da dove abbiamo ricavato i tuoi dati aziendali?"
          handleClose={() => setModalOpen(false)}
        />
      )}
    </StyleSheetManager>
  );
};

export default NotMyCompany;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { LoginGuestMethodApi } from 'src/services/login/login.request';
import {
  OtpTypeArray,
  VerifySignUpSessionOtpData,
  refreshSignUpSessionOtpMethodApi,
  verifySignUpSessionOtpMethodApi,
} from 'src/services/registration/registration.request';
import { useRegistrationStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { maskPhoneNumber } from 'src/utils/functions/maskPhone';
import { formConfigRegistrationStepOtpPhone } from './OtpConfirmPhoneStepRegistration.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const OtpConfirmPhoneStepRegistration: React.FC = () => {
  const sessionGuid: string = useRegistrationStore(
    (state) => state.sessionGuid,
  );
  const mobile = useRegistrationStore((state) => state.mobile);
  const prefix = useRegistrationStore((state) => state.prefix).slice(0, -2);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const guidTokenGuest = location?.state?.guidTokeGuest;
  const { initialValues, resolver } = formConfigRegistrationStepOtpPhone();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (values: any) => verifySignUpSessionOtpMethodApi(values),
    onSuccess: (res) => {
      res.data.ResultSet
        ? navigate(RoutePath.registrationBuyer + RoutePath.emailStep)
        : setErrorMessage('error.otpNotMatching');
    },
    onError: () => {},
  });
  const { mutate: mutateTokenGuest, isPending: isLoadingTokenGuest } =
    useMutation({
      mutationFn: LoginGuestMethodApi,
      onSuccess: (res) => {
        res.data.ResultSet
          ? navigate(RoutePath.registrationBuyer + RoutePath.emailStep)
          : setErrorMessage('error.otpNotMatching');
      },
      onError: () => {
        setErrorMessage('error.otpNotMatching');
      },
    });

  const { mutate: mutateRefresh, isPending: isLoadingRefresh } = useMutation({
    mutationFn: refreshSignUpSessionOtpMethodApi,
    onSuccess: () => {
      methods.reset();
      setErrorMessage('');
    },
  });

  const onSubmit = async (data: any) => {
    if (guidTokenGuest) {
      const dataOtp = {
        guestSignInGuid: guidTokenGuest,
        otp: Object.values(data).join(''),
      };
      mutateTokenGuest(dataOtp);
      return;
    }
    const dataOtp: VerifySignUpSessionOtpData = {
      OtpType: OtpTypeArray.phone,
      SessionGuid: sessionGuid,
      OtpValue: Object.values(data).join(''),
    };
    mutate(dataOtp);
  };

  if (isLoadingRefresh || isLoadingTokenGuest) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        label="text.insertCodeLite"
        showWhiteSpaceRight
        stepNumber={2}
        stepMaxNumber={8}
        showBackButton
        backButtonAction={() => navigate(-1)}
      />

      <FlowContainer>
        <H4 light>
          <Translate id="text.recoveryOtp" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OtpForm
              sendAgainHandler={() =>
                mutateRefresh({
                  OtpType: OtpTypeArray.phone,
                  SessionGuid: sessionGuid,
                })
              }
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isLoadingRefresh={isLoadingRefresh}
              prefix={prefix}
              mobile={maskPhoneNumber(mobile)}
            />
            <ButtonContainer>
              <SubmitButton minWidth="100%" disabled={isPending}>
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </StyleSheetManager>
  );
};

export default OtpConfirmPhoneStepRegistration;

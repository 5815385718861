import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import ModalSortByLoadable from 'src/components/ModalSortBy/ModalSortBy.loadable';
import PaymentsFilteredList from 'src/components/PaymentsFilteredList/PaymentsFilteredList.component';
import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Sorting } from 'src/images/icons/Sorting.svg';
import { ReactComponent as FilterIcon } from 'src/images/icons/filtres.svg';
import { Filter } from 'src/pages/balanceMovementsPage/style/BalanceMovementsPage.style';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';
import {
  getTransactionsEndPoint,
  getTransactionsInitialEndPoint,
} from 'src/services/transactions/transactions.config';
import {
  getTransactionsInitialDataMethodApi,
  getTransactionsMethodApi,
} from 'src/services/transactions/transactions.request';
import { P, PSmall } from 'src/style-utils/typographic';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import {
  DataFormAmounts,
  searchCriteriaPaymentsFormConfig,
  takePayments,
} from './PaymentSummary.helper';
import {
  DataFormPayments,
  TransactionData,
  TransactionsResponseType,
} from './PaymentSummary.types';
import PaymentsFilterModal from './partials/PaymentsFilterModal/PaymentsFilterModal.component';
import AutoSubmitOnChange from 'src/components/AutoSubmitOnChange/AutoSubmitOnChange.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PaymentSummary: React.FC = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [modalSortByOpen, setModalSortByOpen] = useState(false);
  const [transactionList, setTransactionList] = useState<TransactionData[]>([]);
  const { initialValues, resolver } = searchCriteriaPaymentsFormConfig();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataFilter, setDataFilter] = useState<DataFormPayments>(initialValues);
  const nextPage = () => {
    mutate({ ...dataFilter, skip: currentPage * takePayments });
    setCurrentPage(currentPage + 1);
  };
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onSubmit',
  });

  const {
    isLoading: isLoadingInitialData,
    isError: isErrorInitialData,
    data: initialData,
  } = useQuery({
    queryKey: [getTransactionsInitialEndPoint],
    queryFn: getTransactionsInitialDataMethodApi,
    staleTime: 4 * 60 * 1000,
  });

  const { mutate, isPending, isError, data } = useMutation({
    mutationKey: [getTransactionsEndPoint],
    mutationFn: (data: DataFormPayments) => getTransactionsMethodApi(data),
    onSuccess: (res: any) => {
      const { Transactions, TransactionsCount } = res?.data
        .ResultSet as TransactionsResponseType;
      setTransactionList((x) => [...x, ...Transactions]);
      setTotalPages(Math.ceil(TransactionsCount / takePayments));
    },
  });

  useEffect(() => {
    mutate({ ...initialValues });
  }, []);

  function onSubmit(values: any) {
    setTransactionList([]);
    methods.setValue('skip', 0);
    setCurrentPage(1);
    setDataFilter({ ...values });
    setModalSortByOpen(false);
    setFilterModalOpen(false);
    mutate({ ...values });
  }

  if (isError || isErrorInitialData) {
    return <Navigate to="/oops" />;
  }
  if (isPending || isLoadingInitialData) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerPadding>
        <TextDefaultPage title={'text.installments'} />
        <FormProvider {...methods}>
          <AutoSubmitOnChange onSubmit={onSubmit} watchFields={['sort']} />
          <PaymentsFilteredList
            setDataFilter={setDataFilter}
            handleClick={onSubmit}
            dataForm={dataFilter}
            merchants={initialData?.data?.ResultSet.Merchants}
            amounts={DataFormAmounts}
            statuses={['0', '1', '3']}
          />
          <Flex
            justify="space-between"
            align="center"
            marginBottom="0"
            marginTop="3rem"
          >
            <PSmall>
              {data?.data.ResultSet.TransactionsCount + ' '}
              <Translate id={'lbl.results'} />
            </PSmall>
            <Button
              padding="0"
              minWidth="0"
              variant="LinkPrimary"
              type="button"
              onClick={() => setModalSortByOpen(true)}
            >
              <Flex justify="space-between" align="center">
                <PSmall>
                  <Translate id={'text.orderForLowercase'} />
                </PSmall>
                <Icon
                  iconHeight="2rem"
                  iconSize="2rem"
                  svgIconComponent={Sorting}
                />
              </Flex>
            </Button>
          </Flex>
          <Flex
            flexDirection="column"
            align="center"
            justify="space-evenly"
            gap="1.6rem"
            fluid
            padding="0.7rem 0"
          >
            {transactionList?.map((payment: TransactionData, i: number) => (
              <InstallmentCard
                key={i}
                amount={payment.ImportoRata}
                date={payment.DataScadenza}
                name={payment.RagioneSocialeMerchant}
                NumRata={payment.NumRata}
              />
            ))}
            <Flex justify="center" align="center">
              {currentPage + 1 <= totalPages ? (
                <Button
                  variant="LinkPrimary"
                  underline
                  onClick={nextPage}
                  i18n
                  translatedText="text.showMorePayments"
                />
              ) : (
                <P bold>
                  <Translate id="text.noMorePaymentsToShow" />
                </P>
              )}
            </Flex>
          </Flex>
          {modalSortByOpen && (
            <ModalSortByLoadable
              i18n
              onChange={onSubmit}
              setModal={setModalSortByOpen}
              buttonTitle="lbl.apply"
              modalTitle="text.orderFor"
              modalContent={'contenuto'}
              handleClose={() => setModalSortByOpen(false)}
            />
          )}
          {filterModalOpen && (
            <PaymentsFilterModal
              i18n
              merchants={initialData?.data.ResultSet.Merchants}
              paymentStatusArray={initialData?.data.ResultSet.Statuses}
              buttonTitle="lbl.seePayments"
              applyFilters={onSubmit}
              modalTitle="text.searchCriteria"
              handleClose={() => setFilterModalOpen(false)}
            />
          )}

          <Filter
            variant="Secondary"
            sizeType="medium"
            onClick={() => setFilterModalOpen(true)}
            IconSrc={FilterIcon}
          />
        </FormProvider>
      </ContainerPadding>
    </StyleSheetManager>
  );
};

export default PaymentSummary;

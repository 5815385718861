import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { advOutletContext } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import RequestsResultsTable from './partials/RequestsResultsTable.component';
import { getAdvanceRequests } from 'src/services/advanceRequests/advanceRequests.config';
import { getAdvanceRequestsMethodApi } from 'src/services/advanceRequests/advanceRequests.request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { performAdvanceRequestMethodApi } from 'src/services/orderLink/orderLink.request';
import { Button } from 'src/components/Button/Button.component';
import Loader from 'src/components/Loader/Loader.component';
import { ADVResultKey } from '../AdvPayResultPage/AdvPayResultPage.component';
import {
  ButtonContainerCustom,
  ResponsiveContainerCustom,
} from '../ChooseLinkPage/ChooseLinkPage.style';
import { Flex } from 'src/style/flexbox.style';
import Icon from 'src/components/Icon/Icon.component';
import { PSmall } from 'src/style-utils/typographic';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Bill } from 'src/images/icons/bill.svg';
import { AdvPlanRequestsStatus } from './partials/RequestsResultsTable.config';
import { ResponsiveContainer } from 'src/style/Container.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RequestsResults() {
  const { setSelectedLinks, selectedLinks } =
    useOutletContext<advOutletContext>();
  const navigate = useNavigate();
  const location = useLocation();
  const groupGuid = location?.state?.groupGuid;
  const { isLoading, data } = useQuery({
    queryKey: [getAdvanceRequests],
    queryFn: () => getAdvanceRequestsMethodApi({ groupGuid }),
  });
  const arrayLinksApproved = data?.data.ResultSet.AdvanceRequests.filter(
    (link) => link.Status === AdvPlanRequestsStatus.approved,
  );
  const numLinksApproved = arrayLinksApproved?.length;
  const totalAmountApproved = arrayLinksApproved?.reduce(
    (acc, currentItem) => (acc ?? 0) + (currentItem?.Amount ?? 0),
    0,
  );
  const { mutate: mutatePerform, isPending: isLoadingPerform } = useMutation({
    mutationFn: (values: any) => performAdvanceRequestMethodApi(values),
    onSuccess: (res) => {
      navigate(RoutePath.AdvPayResultPage, {
        state: {
          amountPerformed: res?.data.ResultSet.AmountPerformed,
          ordersPerformed: res?.data.ResultSet.OrdersPerformed,
          requestResult: ADVResultKey.transferDone,
        },
      });
    },
    onError: () => {
      navigate(RoutePath.ThankyouPageError + RoutePath.oops);
    },
  });
  if (!selectedLinks || selectedLinks?.length === 0) {
    return (
      <Navigate to={RoutePath.advancePaymentRequest + RoutePath.chooseLink} />
    );
  }
  if (isLoadingPerform)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        step={2}
        stepMax={2}
        title="lbl.dataSummary"
        modalText="text.wannaLeaveAdv"
        leaveModal={() => {
          setSelectedLinks([]);
          navigate(RoutePath.advancePaymentRequest + RoutePath.chooseLink);
        }}
      />
      <ResponsiveContainer>
        <RequestsResultsTable data={data} isLoading={isLoading} />
      </ResponsiveContainer>
      <ButtonContainerCustom>
        <ResponsiveContainerCustom>
          <Flex justify="center" align="center" gap="0.5rem">
            <div>
              <Icon svgIconComponent={Bill} />
            </div>
            <PSmall colorBlack>
              <Translate
                id={'text.advPaymTotal'}
                data={{
                  total: totalAmountApproved,
                  linkNumber: numLinksApproved,
                }}
              />
            </PSmall>
          </Flex>
          <Button
            i18n
            translatedText="lbl.nextWithRequest"
            onClick={() => mutatePerform({ groupGuid })}
          />
        </ResponsiveContainerCustom>
      </ButtonContainerCustom>
    </StyleSheetManager>
  );
}

export default RequestsResults;

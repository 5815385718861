import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Support } from 'src/images/icons/support.svg';
import colors from 'src/style-utils/colors';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { COOKIEHUB_GLOBAL_VAR, INDIGO_GLOBAL_VAR } from '../../constants';
import { useEffect, useState } from 'react';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const SupportCard1 = ({ handleClick }: any) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Card>
        <Icon
          svgIconComponent={Support}
          iconSize="5rem"
          marginBottom="1.2rem"
          color={colors.textColorGrey}
        />
        <PSmall>
          <Translate id="supportPage.CardSubtitle1" />
        </PSmall>
        <H5 marginBottom="0.5rem">
          <Translate id="supportPage.CardTitle1" />
        </H5>
        <P>
          <Translate id="supportPage.CardContent1" />
        </P>
        <Button
          i18n
          translatedText="supportPage.Button1"
          margin="2.4rem auto 0 0"
          onClick={handleClick}
        />
      </Card>
    </StyleSheetManager>
  );
};

const SupportCard1NoCookies = ({ handleClick }: any) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Card>
        <Icon
          svgIconComponent={Support}
          iconSize="5rem"
          marginBottom="1.2rem"
          color={colors.textColorGrey}
        />
        <PSmall>
          <Translate id="supportPage.CardSubtitle1" />
        </PSmall>
        <H5 marginBottom="0.5rem">
          <Translate id="supportPage.CardTitle1" />
        </H5>
        <P>
          <Translate id="supportPage.CardContent1NoCookies" />
          &nbsp;
          <Button
            i18n
            translatedText="supportPage.Button1NoCookies"
            variant="LinkPrimary"
            padding="0"
            minWidth="0"
            height="0"
            underline
            onClick={handleClick}
          />
        </P>
      </Card>
    </StyleSheetManager>
  );
};

const SupportCards = () => {
  const [marketingConsented, setMarketingConsented] = useState(
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.hasInitialised &&
      (window as any)[COOKIEHUB_GLOBAL_VAR]?.hasConsented('marketing'),
  );
  const hasInitialised = (window as any)[COOKIEHUB_GLOBAL_VAR]?.hasInitialised;
  const showCookieBanner = () =>
    (window as any)[COOKIEHUB_GLOBAL_VAR]?.openDialog();

  const showHumanChat = () => {
    if ((window as any)[INDIGO_GLOBAL_VAR]) {
      (window as any)[INDIGO_GLOBAL_VAR].setOpen(true);
    } else {
      document.dispatchEvent(
        new CustomEvent('opyn-init-indigo', {
          bubbles: true,
        }),
      );
    }
  };

  useEffect(() => {
    document.addEventListener('cookiehub-onmarketingchange', (event: any) => {
      setMarketingConsented(event.detail?.status);
    });
  }, []);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex
        data-component="support-cards"
        flexDirection="column"
        gap="2.4rem"
        marginBottom="3em"
      >
        {hasInitialised ? (
          marketingConsented ? (
            <SupportCard1 handleClick={showHumanChat} />
          ) : (
            <SupportCard1NoCookies handleClick={showCookieBanner} />
          )
        ) : null}
      </Flex>
    </StyleSheetManager>
  );
};

export default SupportCards;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext } from 'react-hook-form';
import { ClientButtonSelection } from '../style/ClientsInput.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

interface Props {
  label: string;
  id: string;
  fieldName: string;
}

const ClientButton = ({ label, id, fieldName }: Props) => {
  const { register } = useFormContext();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ClientButtonSelection htmlFor={id}>
        <input type="checkbox" id={id} value={id} {...register(fieldName)} />
        <span>{label}</span>
      </ClientButtonSelection>
    </StyleSheetManager>
  );
};

export default ClientButton;

import { P, PLegal } from 'src/style-utils/typographic';
import { StepCardContainer } from './StepCard.style';
import { Flex } from 'src/style/flexbox.style';
import Translate from 'src/components/Translate/Translate.component';
import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as CheckedIcon } from 'src/images/icons/feedback-point-checked.svg';
import colors from 'src/style-utils/colors';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type Props = {
  stepNumber?: number;
  stepTitle?: string;
  children?: React.ReactNode;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  checked?: boolean;
  overflow?: string | CSSOverflow;
};

const StepCard = ({
  stepNumber,
  stepTitle,
  children,
  icon,
  checked,
  overflow,
}: Props) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <StepCardContainer data-checked={checked} overflow={overflow}>
        <Flex gap="0.8rem" align="center" justify="space-between">
          <Flex gap="0.8rem" align="center">
            <Icon
              svgIconComponent={icon}
              iconSize="2.4rem"
              iconHeight="2.4rem"
            />
            <div>
              <PLegal>
                <Translate id="lbl.step" /> {stepNumber}
              </PLegal>
              <P bold colorBlack>
                <Translate id={stepTitle} />
              </P>
            </div>
          </Flex>
          {checked && (
            <Icon
              svgIconComponent={CheckedIcon}
              iconSize="2.4rem"
              iconHeight="2.4rem"
              color={colors.greenInput}
            />
          )}
        </Flex>
        {children && <div>{children}</div>}
      </StepCardContainer>
    </StyleSheetManager>
  );
};

export default StepCard;

import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { H5 } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled, { css } from 'styled-components';

export const ClientInfoCardContainer = styled.div<{ statusLink: number }>`
  isolation: isolate;
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
  background-color: ${colors.white};
  box-shadow: 0rem 0.2rem 0.4rem 0.1rem ${colors.cardShadowBlue};
  overflow: hidden;
  outline: 0.15rem solid ${colors.greyBorder};
  ${H5} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ statusLink }) =>
    statusLink === 4 &&
    css`
      outline-color: ${colors.redStatus};
    `};
`;

export const ClientInfoCardHeader = styled(Flex)`
  background-color: ${colors.backgroundBlue};
  justify-content: space-between;
  ${media.tablet`
    justify-content: unset;
  `}
`;

export const ClientInfoCardBody = styled(Flex)`
  div:first-child {
    min-width: 0;
    flex-grow: 1;
  }
  position: relative;
  &::after {
    content: '';
    border-radius: 10rem;
    position: absolute;
    right: -4rem;
    width: 11rem;
    height: 11rem;
    background: ${colors.bgButtonLink};
    z-index: -1;
    transition: all 500ms ease-in-out;
  }
`;

import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import colors from 'src/style-utils/colors';

interface BulletTextProps {
  innerColor: string;
  borderColor: string;
}

export const BulletText = styled(Flex)<BulletTextProps>`
  align-items: center;

  &::before {
    content: '';
    margin-right: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${(props) => props.innerColor || 'none'};
    border-radius: 50%;
    flex-shrink: 0;
    border: 1px solid ${(props) => props.borderColor || 'none'};
  }
`;

export const LinkDetailInfoContainer = styled(BulletText)`
  background-color: ${colors.backgroundBlue};
  border-radius: 0.8rem;
`;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as PayFail } from 'src/images/pageStatus/error_payment.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { H4, P } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PaymentFailed: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayFail className="mx-auto mb-8 w-66 lg:w-96" />
      <H4 className="text-center">
        <Translate id="error.paymentFailed" />
      </H4>
      <P className="mt-1 text-center lg:mt-4">
        <Translate id="error.tryAnotherPayment" />
      </P>
      <ButtonContainer>
        <Button
          minWidth="100%"
          variant="Primary"
          onClick={() =>
            navigate(
              `${RoutePath.paymentFlow}${RoutePath.chooseMethod}?type=pay`,
            )
          }
        >
          <Translate id="text.changePaymentMethod" />
        </Button>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default PaymentFailed;

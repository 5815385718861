import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import AssistanceIcon from 'src/images/pageStatus/assistance.svg?url';
import { FindOutMoreEnum } from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.config';
import FindOutMoreModalLoadable from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.loadable';
import {
  ResultPageContainer,
  ResultPageIcon,
} from 'src/pages/onboardingFlow/variants/style/OnboardingFlow.style';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { merchantGetInfo } from 'src/services/user/user.config';
import { merchantGetInfoMethodApi } from 'src/services/user/user.request';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { navigationStatusMappings } from 'src/utils/config/stateAccount';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function Assistance() {
  const setUser = useProfileStore((state) => state.setUser);
  const setValue = useProfileStore((state) => state.setValue);
  const MerchantRoles = useAccountStore((state) => state.MerchantRoles);
  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: [merchantGetInfo],
    queryFn: merchantGetInfoMethodApi,
  });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setUser(data.data.ResultSet);
      setValue('AccountType', 'MERCHANT');
      setValue('Role', MerchantRoles && MerchantRoles[0].Role);
      const navigationPath =
        navigationStatusMappings[data.data.ResultSet.Status]?.['MERCHANT'];
      if (navigationPath) {
        navigate(navigationPath);
      }
    }
    if (isError) navigate(RoutePath.internalError);
  }, [isSuccess, isError]);

  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResultPageContainer
        flex="1"
        flexDirection="column"
        padding="4.8rem 2.4rem"
        gap="1.6rem"
      >
        <ResultPageIcon src={AssistanceIcon} />
        <H4>
          <Translate id="text.assistanceTitle" />
        </H4>
        <P>
          <Translate id="text.assistanceSubtitle" />
        </P>
        <ButtonContainer>
          <Button variant="Tertiary" onClick={() => setShowModal(true)}>
            <Translate id="link.findOutMoreNamirial" />
          </Button>
        </ButtonContainer>
      </ResultPageContainer>
      {showModal && (
        <FindOutMoreModalLoadable
          buttonTitle="lbl.close"
          modalTitle="text.findOutMoreNamirialTitlePage"
          type={FindOutMoreEnum.namirial}
          handleClose={() => setShowModal(false)}
          i18n
        />
      )}
    </StyleSheetManager>
  );
}

export default Assistance;

import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import { FormGroup, Input, LabelInput } from 'src/style/styleInput.style';
import { ReactComponent as AlertIconSrc } from 'src/images/icons/alert.svg';
import { ReactComponent as ErrorIconSrc } from 'src/images/icons/alert_red.svg';
import { ReactComponent as CheckIconSrc } from 'src/images/icons/check_icon.svg';
import {
  ContainerMessage,
  IconInsideInput,
  MessageError,
  EyeIconWrapper,
  EyeIcon,
  CalendarIconWrapper,
} from './style/input.style';
import { InputFieldProps } from './InputField.types';
import { InputFieldUIStates } from 'src/services/forms/forms.config';
import { inputFieldStateReducer } from 'src/services/forms/forms.helpers';
import { useComputeInputFieldHandleChange } from './InputField.hooks';
import { useController, useFormContext } from 'react-hook-form';
import toggle from 'src/utils/functions/toggle';
import { ReactComponent as EyeCloseIconSrc } from 'src/images/icons/eye_close.svg';
import { ReactComponent as EyeOpenIconSrc } from 'src/images/icons/eye_open.svg';
import Calendar from 'src/images/icons/calendar.svg?url';
import PositiveFeed from 'src/images/icons/_positive-feed.svg?url';
import colors from 'src/style-utils/colors';
import Icon from 'src/components/Icon/Icon.component';
import { t } from 'i18next';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const InputField = React.memo(function InputField({
  label,
  isPresentMessage,
  id,
  tracingId,
  hidden,
  transformError,
  className,
  spellCheck = false,
  autoCorrect = 'off',
  inputComponent: InputComponent = Input,
  filterValue,
  autoComplete = 'new-password',
  maxLength,
  disabled,
  additionalInfoId,
  isFieldErrorAlert,
  additionalInfoData,
  inputComponentExtraProps,
  name,
  placeholder,
  autoFocus,
  ...otherProps
}: InputFieldProps) {
  const {
    control,
    formState: { errors, touchedFields, dirtyFields },
    setValue,
    getValues,
  } = useFormContext();
  const {
    field: { onChange, ...rest },
  } = useController({
    name,
    control,
  });

  const handleChange = useComputeInputFieldHandleChange(
    onChange,
    setValue,
    filterValue,
  );

  const { required, type = 'text' } = otherProps;
  const touched = touchedFields[name];
  const dirty = dirtyFields[name];
  const fieldValue: string = getValues(name);
  const activated = fieldValue?.length > 0;
  const error = (errors && errors[name]?.message) || errors[name];
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || tracingId || compId;
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const handleChangePasswordVisiblityClick = React.useCallback(() => {
    setIsPasswordVisible(toggle);
  }, []);

  const { inputFieldState, messageProps } = inputFieldStateReducer(
    { error, touched },
    isFieldErrorAlert,
    transformError,
    additionalInfoId,
    additionalInfoData,
  );

  const iconSrc = disabled
    ? CheckIconSrc
    : inputFieldState === InputFieldUIStates.alert
      ? AlertIconSrc
      : ErrorIconSrc;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div data-component="input-field" hidden={hidden} className={className}>
        {label && (
          <LabelInput htmlFor={domId}>
            <Translate id={label} />
          </LabelInput>
        )}
        <FormGroup
          isAlert={inputFieldState === InputFieldUIStates.alert}
          isError={inputFieldState === InputFieldUIStates.error}
          isDisabled={disabled}
        >
          <InputComponent
            {...inputComponentExtraProps}
            type={isPasswordVisible ? 'text' : type}
            required={required}
            id={domId}
            autoCorrect={autoCorrect}
            autoComplete={autoComplete}
            spellCheck={spellCheck}
            maxLength={maxLength}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder && t(placeholder)}
            {...rest}
            onChange={handleChange}
          />
          {type === 'password' && (
            <EyeIconWrapper
              onClick={handleChangePasswordVisiblityClick}
              hasErrorIcon={inputFieldState === InputFieldUIStates.error}
              aria-label={isPasswordVisible ? 'hide password' : 'show password'}
              iconColor={
                activated ? colors.textColorBlack : colors.textColorLightGrey
              }
            >
              <Icon
                iconSize="1.8rem"
                iconHeight="1.8rem"
                svgIconComponent={
                  isPasswordVisible ? EyeCloseIconSrc : EyeOpenIconSrc
                }
              />
            </EyeIconWrapper>
          )}
          {type === 'date' && (
            <CalendarIconWrapper
              hasErrorIcon={inputFieldState === InputFieldUIStates.error}
            >
              <EyeIcon iconSrc={Calendar} />
            </CalendarIconWrapper>
          )}
          {!error && dirty && name === 'order-import' && (
            <EyeIconWrapper hasErrorIcon={false}>
              <EyeIcon iconSrc={PositiveFeed} />
            </EyeIconWrapper>
          )}

          <IconInsideInput svgIconComponent={iconSrc} />
        </FormGroup>
        {isPresentMessage && (
          <ContainerMessage isPresentMessage={isPresentMessage}>
            <MessageError>
              {messageProps && (
                <Translate
                  extraTranslationProps={{
                    components: { a: <a />, u: <u /> },
                  }}
                  {...messageProps}
                />
              )}
            </MessageError>
          </ContainerMessage>
        )}
      </div>
    </StyleSheetManager>
  );
});

export default InputField;

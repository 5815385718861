import colors from 'src/style-utils/colors';
import { PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

export const VerticalStepperContainer = styled(Flex)<{ disabled: boolean }>`
  position: relative;
  width: fit-content;

  color: ${colors.greenStatus};
  --borderStyle: solid ${colors.bgBtnPrimary};

  &[disabled] {
    color: ${colors.lightGrey};
    --borderStyle: dashed ${colors.lightGrey};
  }

  &[data-error='true'] {
    color: ${colors.yellowStatus};
  }

  &[data-focused='true'] {
    color: ${colors.blueStatus};
    --crownColor: white;
  }

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    height: 100%;
    bottom: 50%;
    left: 0.9rem;
    border-left: 0.2rem var(--borderStyle);
  }
`;

export const StepperCircleContainer = styled.span`
  height: 2rem;
  width: 2rem;
  display: flex;
  z-index: 2;
`;

export const VerticalStepperRowLabel = styled(PSmall)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
`;

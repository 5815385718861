import { TableColumn } from 'src/components/DataTable/DataTable.types';
import { memo } from 'react';

import Column from './RowCell';
import TableRowCellWrapper, {
  TableRowWrapper,
  TableRowWrapperProps,
} from '../style/DataTable.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type TableRowProps<TData> = TableRowWrapperProps & {
  columns: TableColumn<TData>[];
  rowData: TData;
  defaultExpanded?: boolean;
};

const TableRow = <TData extends object>(props: TableRowProps<TData>) => {
  const { rowData, columns } = props;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <TableRowWrapper role="rowgroup">
        <TableRowCellWrapper role="row">
          {columns.map((column) => (
            <Column
              key={column.id}
              numberOfCells={columns.length}
              column={column}
              rowData={rowData}
            />
          ))}
        </TableRowCellWrapper>
      </TableRowWrapper>
    </StyleSheetManager>
  );
};

export default memo(TableRow) as typeof TableRow;

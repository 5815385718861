import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { shouldForwardProp } from 'src/style-utils/functions';
import { PNote, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';

type Props = {
  label: string;
  value: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconSize?: string;
};

const PersonalDataCard: React.FC<Props> = ({
  label,
  value,
  icon,
  iconSize = '2.4rem',
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="personal-data-card"
        className="flex items-center gap-3 overflow-hidden rounded p-2 outline outline-1 outline-pgreyoutlined"
      >
        <div>
          <Icon
            svgIconComponent={icon}
            iconSize={iconSize}
            iconHeight={iconSize}
          />
        </div>

        <Flex flexDirection="column">
          <PNote>
            <Translate id={label} />
          </PNote>
          <PSmall className="line-clamp-1 break-all font-bold">{value}</PSmall>
        </Flex>
      </div>
    </StyleSheetManager>
  );
};

export default PersonalDataCard;

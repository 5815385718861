import Translate from 'src/components/Translate/Translate.component';
import { shouldForwardProp } from 'src/style-utils/functions';
import { H2, H4, H5, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StyleSheetManager } from 'styled-components';

type PurchaseFlowProps = {
  titleMerchant: string;
  titlePrice: string;
  date: string;
  paymentIntent: boolean;
};

export const HeaderPurchaseFlow: React.FC<PurchaseFlowProps> = ({
  titleMerchant,
  titlePrice,
  date,
  paymentIntent,
}) => {
  const time = refactorFormatDate(date, 'HH:mm');
  const dateTime = refactorFormatDate(date);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="mx-auto grid max-w-olg gap-2 pb-10 pt-6 text-center lg:gap-4">
        {paymentIntent ? (
          <H5 light>{titleMerchant}</H5>
        ) : (
          <H4 light>
            <Translate
              id="text.invitedToPayMerchant"
              data={{ merchant: titleMerchant }}
            />
          </H4>
        )}
        {paymentIntent && <H2>{titlePrice}</H2>}
        {paymentIntent ? (
          <H5 light colorGray>
            <Translate
              id="text.paymentDeadline"
              data={{
                time: time,
                date: dateTime,
              }}
            />
          </H5>
        ) : (
          <P>
            <Translate
              id="text.paymentDeferredDeadline"
              data={{
                time: time,
                date: dateTime,
              }}
            />
          </P>
        )}
      </div>
    </StyleSheetManager>
  );
};

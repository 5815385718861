import React, { useEffect, useMemo, useState } from 'react';
import { I18nContextProps, SupportedLanguageKey } from './i18n.types';
import { getI18nLanguageRequest, getLocalizationsApi } from './i18n.requests';
import { useMutation } from '@tanstack/react-query';
import { languageLocalizationsEndpoint } from './i18n.config';
import { useSession } from 'src/services/session/session.context';
import { setCookie } from 'src/utils/functions/cookie';
import { updateLanguageMethodApi } from 'src/services/user/user.request';
import { addi18nLanguage } from './i18n.helpers';
import { useAccountStore, useProfileStore } from 'src/store/store';

export const i18nContext = React.createContext<I18nContextProps>(
  {} as I18nContextProps,
);

export const I18nProvider: React.FC<any> = ({ children }) => {
  const { language } = getI18nLanguageRequest();
  const { auth } = useSession();
  const [lang, setLang] = useState(language);
  const [isLangLabelsLoaded, setIsLangLabelsLoaded] = useState(false);
  const setValue = useProfileStore((state) => state.setValue);
  const languageStore = useAccountStore((state) => state.Language);

  const { isPending, mutate: mutateLangLabels } = useMutation({
    mutationKey: [languageLocalizationsEndpoint],
    mutationFn: getLocalizationsApi,
    onSuccess: (res, variables) => {
      addi18nLanguage(variables, res.data);
      setIsLangLabelsLoaded(true);
    },
  });

  const { isPending: isLoadingUpLang, mutate: mutateUpLang } = useMutation({
    mutationFn: updateLanguageMethodApi,
    onSuccess: (res) => {
      setValue('Language', res.data.ResultSet.Language);
    },
    onError: () => {
      setLang(languageStore);
    },
  });

  const handleSetLanguage = (lan: SupportedLanguageKey) => {
    setLang(lan);
    setIsLangLabelsLoaded(false);
    mutateLangLabels(lan);
    setCookie('language', lan, 30);

    if (auth?.authToken) {
      mutateUpLang({ Language: lan });
    }
    return;
  };

  useEffect(() => {
    mutateLangLabels(language);
  }, []);

  const contextValue = useMemo(
    () => ({
      language: lang,
      isLoadingLang: isPending,
      isLoadingUpLang: isLoadingUpLang,
      isLangLabelsLoaded,
      setLanguage: handleSetLanguage,
      mutateLangLabels: mutateLangLabels,
      mutateUpLang: mutateUpLang,
    }),
    [lang, isPending, isLoadingUpLang],
  );

  return (
    <i18nContext.Provider value={contextValue as I18nContextProps}>
      {children}
    </i18nContext.Provider>
  );
};

export function useLanguage() {
  return React.useContext(i18nContext);
}

import Icon from 'src/components/Icon/Icon.component';
import {
  PayByLinkStatus,
  PaymentStatusMap,
} from 'src/components/StatusPayByLink/StatusPayByLink.config';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import Translate from 'src/components/Translate/Translate.component';
import { useOutletContext } from 'react-router-dom';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P, PNote, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import {
  ContainerWrapper,
  PNoteStyled,
} from '../style/PayByLinkDetailInfo.style';
import { t } from 'i18next';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function getHolder(x: string) {
  switch (x) {
    case 'merchant':
      return t('lender.merchant');
    case 'lender':
      return t('lender.label');
    default:
      return '-';
  }
}

function LinkInfoTab3() {
  const dataLink = useOutletContext<PayByLinkDetailsContext>();

  const { Installments, Status } = dataLink;

  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerWrapper>
        {Installments.map((installment, i) => {
          const interrupted =
            (Status === PayByLinkStatus.cancelledLink ||
              Status === PayByLinkStatus.interrupted) &&
            installment.Status === 0
              ? PayByLinkStatus.cancelledLink
              : null;
          const { label, icon } =
            PaymentStatusMap[interrupted || installment.Status];
          return (
            <div key={i}>
              <div style={{ padding: '1.2rem' }}>
                <Flex justify="space-between">
                  <PSmall>
                    {refactorFormatDate(installment.Expiration, 'DD MMMM YYYY')}
                  </PSmall>

                  <Flex
                    justify="center"
                    align="center"
                    style={{ color: colors.textColorGrey }}
                  >
                    <PSmall>
                      {installment.Status === 4 && (
                        <Translate id="lbl.processing" />
                      )}
                      <Translate id={label} />
                    </PSmall>
                    {icon && (
                      <Icon
                        iconSize="2rem"
                        iconHeight="2rem"
                        marginLeft="0.6rem"
                        svgIconComponent={icon}
                      />
                    )}
                  </Flex>
                </Flex>
                <Flex justify="space-between">
                  <P colorBlack bold>
                    <Translate id="lbl.rateNumber" />
                    {installment.Number}
                  </P>
                  <P colorBlack bold>
                    {formatCurrency(installment.Amount)}
                  </P>
                </Flex>
                {installment.Holders.map((holder, i) => (
                  <Flex justify="space-between" align="center" key={i}>
                    <PNoteStyled>
                      &bull;&nbsp;{getHolder(holder.Holder)}
                    </PNoteStyled>
                    <PNote bold>{formatCurrency(holder.Amount)}</PNote>
                  </Flex>
                ))}
              </div>
              {i + 1 < Installments.length && <hr />}
            </div>
          );
        })}
      </ContainerWrapper>
    </StyleSheetManager>
  );
}

export default LinkInfoTab3;

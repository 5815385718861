import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import media from 'src/style-utils/media-queries';
import { Card } from 'src/style/Container.style';
import styled, { StyleSheetManager } from 'styled-components';
import React from 'react';
import { shouldForwardProp } from 'src/style-utils/functions';

const ECommerceCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  max-width: unset;
  ${media.tablet`
    max-width: 26.67rem;
  `}
`;

const ECommerceCard: React.FC<{
  img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  url?: string;
  label?: string;
}> = ({ img: ImgComponent, url, label }) => {
  const handleClick = () => {
    (window as any).open(url);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ECommerceCardWrapper margin="0">
        <Icon
          display="flex"
          style={{ justifyContent: 'center' }}
          svgIconComponent={ImgComponent}
          iconHeight="4rem"
          iconSize="auto"
        />
        <Button
          onClick={handleClick}
          i18n
          translatedText={label ?? 'lbl.comingSoon'}
          disabled={!url}
          minWidth="100%"
          sizeOnDesktop="small"
          variant="Tertiary"
        />
      </ECommerceCardWrapper>
    </StyleSheetManager>
  );
};

export default ECommerceCard;

import React, { MouseEventHandler } from 'react';
import Translate from 'src/components/Translate/Translate.component';
import {
  BodyContainer,
  CtaLink,
  IconContainer,
  TextContainer,
  Toast,
} from './Alert.style';
import { PSmall } from 'src/style-utils/typographic';
import Icon from '../../Icon/Icon.component';
import { ReactComponent as IconDefault } from 'src/images/icons/alert/alert-default.svg';
import { ReactComponent as IconPositive } from 'src/images/icons/alert/alert-positive.svg';
import { ReactComponent as IconError } from 'src/images/icons/alert/alert-error.svg';
import { ReactComponent as IconWarning } from 'src/images/icons/alert/alert-warning.svg';
import { ReactComponent as IconInformative } from 'src/images/icons/alert/alert-informative.svg';
import colors from 'src/style-utils/colors';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const Icons = (variant: string) => {
  switch (variant) {
    case 'default':
      return IconDefault;
    case 'positive':
      return IconPositive;
    case 'error':
      return IconError;
    case 'warning':
      return IconWarning;
    case 'informative':
      return IconInformative;
    default:
      return IconDefault;
  }
};
const TextColor = (variant: string) => {
  switch (variant) {
    case 'default':
      return colors.textColorGrey;
    case 'positive':
      return colors.textColorBlack;
    case 'error':
      return colors.textColorBlack;
    case 'warning':
      return colors.textColorBlack;
    case 'informative':
      return colors.textColorBlack;
    default:
      return colors.textColorBlack;
  }
};

type AlertComponentProps = {
  variant: 'default' | 'positive' | 'error' | 'warning' | 'informative';
  titleLbl?: string;
  descriptionLbl?: string;
  hyperlinkLbl?: string;
  onClick?: MouseEventHandler<Element>;
};

export const AlertComponent: React.FC<AlertComponentProps> = ({
  variant,
  titleLbl,
  descriptionLbl,
  hyperlinkLbl,
  onClick,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Toast fluid flexDirection="row" variant={variant} data-orion="alert">
        <IconContainer>
          <Icon svgIconComponent={Icons(variant)} />
        </IconContainer>

        <BodyContainer>
          <TextContainer>
            {titleLbl && (
              <PSmall bold textColor={TextColor(variant)}>
                <Translate id={titleLbl} />
              </PSmall>
            )}

            {descriptionLbl && (
              <PSmall textColor={TextColor(variant)}>
                <Translate id={descriptionLbl} />
              </PSmall>
            )}
          </TextContainer>

          {hyperlinkLbl && (
            <CtaLink onClick={onClick}>
              <Translate id={hyperlinkLbl} />
            </CtaLink>
          )}
        </BodyContainer>
      </Toast>
    </StyleSheetManager>
  );
};

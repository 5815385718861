import DataTable from 'src/components/DataTable/DataTable';
import { DataTableProps } from 'src/components/DataTable/DataTable.types';
import Translate from 'src/components/Translate/Translate.component';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getEditPlanRequests } from 'src/services/orderLink/orderLink.config';
import { getEditPlanRequestsMethodApi } from 'src/services/orderLink/orderLink.request';
import { Hr } from 'src/style-utils/hr';
import { P } from 'src/style-utils/typographic';
import { remodulationRequestsTableColumnConfig } from '../RemodulationRequests.config';
import { ListTableRemodulationRequests } from '../RemodulationRequests.types';
import NoDataRequests from './partialsTable/NoDataRequests.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const RemodulationRequestsTable: React.FC = () => {
  const { isLoading, data } = useQuery({
    queryKey: [getEditPlanRequests],
    queryFn: getEditPlanRequestsMethodApi,
  });

  const RemodulationRequestsTableConfig: DataTableProps<ListTableRemodulationRequests> =
    useMemo(
      () => ({
        data: data?.data?.ResultSet?.Requests ?? [],
        hideHeader: false,
        isLoadingData: isLoading,
        NoDataComponent: NoDataRequests,
        columns: remodulationRequestsTableColumnConfig,
        customTableTheme: {
          cells: {
            style: {
              '&:first-child': {
                background: '#fff',
                '&::before': {
                  display: 'none',
                },
              },
            },
          },
        },
      }),
      [data],
    );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <DataTable {...RemodulationRequestsTableConfig} />
      {data?.data?.ResultSet?.Requests && (
        <>
          <Hr />
          <P marginBottom="2rem" bold textAlign="center">
            <Translate id="text.noRequestsToShow" />
          </P>
        </>
      )}
    </StyleSheetManager>
  );
};

export default RemodulationRequestsTable;

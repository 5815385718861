import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H5, P, PNote } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export type HowDoesItWorkCardProps = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  title: string;
  subTitle?: string;
  requisition?: string;
};

const HowDoesItWorkCard: React.FC<HowDoesItWorkCardProps> = ({
  icon,
  title,
  subTitle,
  requisition,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="rounded-xl bg-white p-4 shadow-md">
        <Icon svgIconComponent={icon} iconSize="4.8rem" marginBottom="2.4rem" />
        <H5>
          <Translate id={title} />
        </H5>
        {subTitle && (
          <P>
            <Translate id={subTitle} />
          </P>
        )}
        {requisition && (
          <PNote bold marginTop="0.9rem">
            <Translate id={requisition} />
          </PNote>
        )}
      </div>
    </StyleSheetManager>
  );
};

export default HowDoesItWorkCard;

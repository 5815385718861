import { css, ExecutionContext } from 'styled-components';
import createExtractCssFromProps from './createExtractCssFromProps';
import { StyledProps } from 'src/@types/styled-components';

const mappedProperties = {
  display: 'display',
  textSize: 'font-size',
  textTransform: 'text-transform',
  textDecoration: 'text-decoration',
  lineHeight: 'line-height',
  maxWidth: 'max-width',
  textColor: 'color',
  margin: 'margin',
  overflow: 'overflow',
  letterSpacing: 'letter-spacing',
};

const mappedPropertiesMixin = createExtractCssFromProps(mappedProperties);

export type CommonTextProps = StyledProps<
  Parameters<typeof mappedPropertiesMixin>['0']
>;

export interface TextAlignProps extends ExecutionContext {
  textAlign?: 'left' | 'right' | 'center' | CSSGlobalRuleValue;
}

export const withTextAlign = css`
  ${function setTextAlign({ textAlign }: TextAlignProps): string {
    if (!textAlign) {
      return '';
    }
    return `
      text-align: ${textAlign};
    `;
  }};
`;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Loader from 'src/components/Loader/Loader.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { initRecoveryDataMethodApi } from 'src/services/recoveryData/recoveryData.request';
import { InitRecoveryData } from 'src/services/recoveryData/recoveryData.types';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import { H4 } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { FormStyle } from 'src/style/styleInput.style';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import { formRecoveryEmailConfig } from './RecoveryEmail.helpers';
import { RecoveryDataContext } from 'src/pages/recoveryData/RecoveryData.types';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RecoveryEmail() {
  const { activeOrder } = useOutletContext<RecoveryDataContext>();
  const navigate = useNavigate();
  const { initialValues, resolver } = formRecoveryEmailConfig();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: initRecoveryDataMethodApi,
    onSuccess: (res, variables) => {
      navigate(
        `${RoutePath.recoveryData}${RoutePath.insertOtp}?sessionGuid=${res.data.ResultSet}`,
        {
          state: {
            type: 'email',
            value: variables.Auth1TargetValue,
            prefix: variables.prefix,
            activeOrder,
          },
        },
      );
    },
    onError: (error: any) => {
      const errors = servicesErrorsMap(error.response, { phone: phoneField });
      for (const key in errors) {
        methods.setError(phoneField, { message: errors[key] });
      }
    },
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const params: InitRecoveryData = {
      Type: 'email',
      Auth1TargetValue: data[phoneField],
      prefix: data[prefix],
    };
    mutate(params);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowHeader
        stepNumber={1}
        stepMaxNumber={4}
        showWhiteSpaceRight
        showBackButton
        backButtonAction={() => navigate(-1)}
        label="lbl.mobileNumber"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.insertPhoneNumber" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <PhoneField isPresentMessage name={phoneField} />
              <ButtonContainer>
                <SubmitButton i18n translatedText="lbl.next" />
              </ButtonContainer>
            </FormStyle>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default RecoveryEmail;

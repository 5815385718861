import Icon from 'src/components/Icon/Icon.component';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { ResponsiveContainer } from 'src/style/Container.style';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  color: ${colors.textColorBlack};
  background-color: ${colors.white};
  overflow: auto;
  position: fixed;
  inset: 0;
  z-index: 30;
  transition: transform 0.5s;
  transform: translateX(100%);
  box-shadow: 4px 0px 4px 0px #35302f14;

  &.open {
    transform: translateX(0%);
    display: block;
  }

  ${media.tablet`
    width: 200px;
    min-width: 200px;
    overflow: auto;
    position: static;
    inset: auto;
    transform: translateX(0%);
    transition: none;
  `}
`;

export const HamburgerContainer = styled.div`
  cursor: pointer;

  p {
    display: none;
  }
  ${media.tablet`
    p {
      display: block;
    }
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    &:hover {
      border-width: 0.2rem;
    }
    padding: 0.4rem 1.2rem;
    gap: 0.4rem;
    border: 0.1rem solid ${colors.black};
    border-radius: 25rem;
  `}
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  p {
    display: none;
  }
`;

export const SidebarHeaderContainer = styled.div`
  display: flex;
  padding: 1rem 0.75rem;
`;

export const SidebarHeaderFlex = styled(ResponsiveContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6.4rem;
  ${media.tablet`
    min-height: 7.2rem;
  `}
`;

export const AccordionArrow = styled(Icon)`
  color: ${colors.greyStatus};
  rotate: 0deg;
  transition: rotate 250ms ease-in-out;
  cursor: pointer;
`;

export const AccordionHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  height: 40px;
  &:hover {
    background-color: ${colors.backgroundGrey};
  }
`;

export const AccordionBody = styled.div`
  display: grid;
  transition: grid-template-rows 250ms ease-in-out;
  grid-template-rows: 0fr;
  padding: 0 0.5rem 0.5rem;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  background: none;
  outline: none;
  border-radius: 4px;
  color: ${colors.textBtn};
  font-weight: 400;
  font-size: 1.4rem;
  cursor: pointer;

  &[data-expanded='true'] ${AccordionBody} {
    grid-template-rows: 1fr;
  }
  &[data-expanded='true'] ${AccordionArrow} {
    rotate: -180deg;
  }
  &[data-disabled='true'] {
    background-color: ${colors.bgDisabledInput};
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: ${colors.bgDisabledInput};
      opacity: 0.6;
      z-index: 10;
    }
  }

  ${media.tablet`width: 176px;`}
`;

export const ResponsiveAccordionFlex = styled.div`
  color: ${colors.textColorBlack};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  &.active {
    color: ${colors.textBtn};
    font-weight: 500;
  }

  &:focus {
    border: 1.5px solid ${colors.lightBlueBorder};
  }
`;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import NamirialSuccess from 'src/images/pageStatus/mailbox.svg?url';
import { ResultPageIcon } from 'src/pages/onboardingFlow/variants/style/OnboardingFlow.style';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import colors from 'src/style-utils/colors';
import { H5 } from 'src/style-utils/typographic';
import { ColoredSection, ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import AlertCardsContainer from './partials/contractCard/AlertCardsContainer.component';
import { useProfileStore } from 'src/store/store';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { useMutation } from '@tanstack/react-query';
import { proceedNotItalianSigningMethodApi } from 'src/services/onboarding/onboarding.request';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ContinueRegistration() {
  const navigate = useNavigate();
  const country = useProfileStore((state) => state.Country);
  const isIta = isItalian(country);
  const { mutate } = useMutation({
    mutationFn: proceedNotItalianSigningMethodApi,
    onSuccess: (res) => {
      navigate(RoutePath.onboarding + RoutePath.successNamirial);
    },
  });
  const continueRegistration = () => {
    if (!isIta) {
      return mutate();
    }
    navigate(RoutePath.onboarding + RoutePath.readContract);
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer padding="0 0 6rem">
        <Flex margin="4.2rem auto" justify="center">
          <ResultPageIcon src={NamirialSuccess} />
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          style={{ textAlign: 'center' }}
        >
          <TextDefaultPage
            title="text.successAccountCreatedTitle"
            subTitle="text.successAccountCreatedSubTitle"
          />
        </Flex>
        <ButtonContainer backgroundColor="white">
          <Button onClick={continueRegistration} variant="Primary">
            <Translate id="link.continueRegistration" />
          </Button>
        </ButtonContainer>
      </ResponsiveContainer>
      <ColoredSection bgColor={colors.white} style={{ marginTop: 'auto' }}>
        <ResponsiveContainer>
          <H5 light marginBottom="4rem">
            <Translate id="lbl.whatToDoNow" />
          </H5>
          <AlertCardsContainer isIta={isIta} />
        </ResponsiveContainer>
      </ColoredSection>
    </StyleSheetManager>
  );
}

export default ContinueRegistration;

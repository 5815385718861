import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { createBrowserHistory } from '@remix-run/router';
import { ReactComponent as error_icon } from 'src/images/pageStatus/error_icon.svg';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { H4 } from 'src/style-utils/typographic';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { ErrorPagePar } from '../style/failedPage.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const CreateLinkError: React.FC = () => {
  const history = createBrowserHistory();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon
        svgIconComponent={error_icon}
        iconHeight="auto"
        iconSize="min(100%, 25.6rem)"
        margin="4.2rem auto"
      />
      <H4 textAlign="center">
        <Translate id="text.somethingWentWrong" />
      </H4>
      <ErrorPagePar>
        <Translate id="text.errorFiscalCodeFailedPage" />
      </ErrorPagePar>
      <ButtonContainer>
        <Button minWidth="32.7rem" i18n onClick={() => history.go(-1)}>
          <Translate id="lbl.close" />
        </Button>
      </ButtonContainer>
    </StyleSheetManager>
  );
};

export default CreateLinkError;

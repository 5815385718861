import StatsCard from 'src/components/StatsCard/StatsCard.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { GetStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import colors from 'src/style-utils/colors';
import { Flex } from 'src/style/flexbox.style';
import PendingBalanceInfoModalLoadable from 'src/components/PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';
import { useState } from 'react';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type StatsCardWrapperProps = {
  statsData?: GetStatsDataResponse;
};
const StatsCardWrapper: React.FC<StatsCardWrapperProps> = ({ statsData }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const TotalAmount = statsData?.ResultSet.TotalAmount ?? 0;
  const InstallmentsCreated = statsData?.ResultSet.InstallmentsCreated ?? 0;
  const InstallmentsOverdue = statsData?.ResultSet.InstallmentsOverdue ?? 0;
  const InstallmentsPaid = statsData?.ResultSet.InstallmentsPaid ?? 0;
  const InstallmentsProcessing =
    statsData?.ResultSet.InstallmentsProcessing ?? 0;
  const OrdersCount = statsData?.ResultSet.OrdersCount;
  const {
    formatters: { formatCurrency },
  } = useI18n();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <section>
        <Flex gap="0.8rem" responsive marginBottom="0.8rem">
          <StatsCard
            label="lbl.totalSales"
            content={formatCurrency(TotalAmount)}
            tooltipText="text.totalAmountTooltip"
          />
          <StatsCard
            label="lbl.orders"
            content={OrdersCount}
            tooltipText="text.ordersTooltip"
            tooltipPosition="left"
          />
        </Flex>
        <Flex gap="0.8rem" responsive>
          <StatsCard
            label="lbl.installmentsPaid"
            content={formatCurrency(InstallmentsPaid)}
            tooltipText="text.installmentsAmountTooltip"
          />
          <StatsCard
            label="lbl.instToProcess"
            content={
              InstallmentsProcessing <= 0
                ? formatCurrency(InstallmentsCreated)
                : formatCurrency(InstallmentsCreated + InstallmentsProcessing)
            }
            tooltipText="text.installmentsToBePaidTooltip"
            installmentsProcessing={InstallmentsProcessing}
            onLblClick={() => setModalOpen(true)}
          />
          <StatsCard
            label="lbl.overdueInstallments"
            content={formatCurrency(InstallmentsOverdue)}
            contentColor={colors.redStatus}
            tooltipText="text.overdueInstallmentsTooltip"
            tooltipPosition="left"
          />
        </Flex>
        {modalOpen && (
          <PendingBalanceInfoModalLoadable
            modalTitle="lbl.PBModalTitle"
            modalBodyType="merchant"
            i18n
            handleClose={() => setModalOpen(false)}
          />
        )}
      </section>
    </StyleSheetManager>
  );
};

export default StatsCardWrapper;

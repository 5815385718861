import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputFieldUIStates } from 'src/services/forms/forms.config';
import { inputFieldStateReducer } from 'src/services/forms/forms.helpers';
import { FormGroup, LabelInput, TextArea } from 'src/style/styleInput.style';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import { useComputeInputFieldHandleChange } from './InputTextArea.hooks';
import { InputTextAreaProps } from './InputTextArea.types';
import { ContainerMessage, MessageError } from './style/InputTextArea.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const InputTextArea = React.memo(function InputTextArea({
  label,
  isPresentMessage,
  id,
  tracingId,
  hidden,
  transformError,
  className,
  spellCheck = false,
  autoCorrect = 'off',
  inputComponent: InputComponent = TextArea,
  filterValue,
  autoComplete = 'new-password',
  maxLength,
  disabled,
  additionalInfoId,
  isFieldErrorAlert,
  additionalInfoData,
  inputComponentExtraProps,
  name,
  placeholder,
  ...otherProps
}: InputTextAreaProps) {
  const {
    control,
    formState: { errors, touchedFields },
    setValue,
  } = useFormContext();
  const {
    field: { onChange, ...rest },
  } = useController({
    name,
    control,
  });

  const handleChange = useComputeInputFieldHandleChange(
    onChange,
    setValue,
    filterValue,
  );

  const { required } = otherProps;
  const touched = touchedFields[name];
  const error = (errors && errors[name]?.message) || errors[name];
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || tracingId || compId;
  const { t } = useTranslation();

  const { inputFieldState, messageProps } = inputFieldStateReducer(
    { error, touched },
    isFieldErrorAlert,
    transformError,
    additionalInfoId,
    additionalInfoData,
  );

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="input-textarea"
        hidden={hidden}
        className={className}
      >
        {label && (
          <LabelInput htmlFor={domId}>
            <Translate id={label} />
          </LabelInput>
        )}
        <FormGroup
          isAlert={inputFieldState === InputFieldUIStates.alert}
          isError={inputFieldState === InputFieldUIStates.error}
          isDisabled={disabled}
        >
          <InputComponent
            {...inputComponentExtraProps}
            required={required}
            id={domId}
            autoCorrect={autoCorrect}
            autoComplete={autoComplete}
            spellCheck={spellCheck}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={t(placeholder ?? '')}
            {...rest}
            onChange={handleChange}
          />
        </FormGroup>
        <ContainerMessage isPresentMessage={isPresentMessage}>
          <MessageError>
            {messageProps && <Translate {...messageProps} />}
          </MessageError>
        </ContainerMessage>
      </div>
    </StyleSheetManager>
  );
});

export default InputTextArea;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Ops } from 'src/images/pageStatus/ops.svg';
import { useNavigate } from 'react-router-dom';
import { H3 } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

// Note that unlike other pages, we load the ErrorPage with the main bundle,
// hence no *.loadable, because it has to be always **available**
// even if there's no connection.
const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer textAlign="center">
        <Icon
          iconSize="auto"
          svgIconComponent={Ops}
          margin="4rem"
          display="block"
        />
        <H3 margin="4rem auto">
          <Translate id="text.opsSomethingWentWrong" />
        </H3>
        <ButtonContainer marginBottom="2rem">
          <Button
            variant="Primary"
            onClick={() => navigate('/private-area')}
            minWidth="50%"
          >
            <Translate id="text.backToPrivateArea" />
          </Button>
          <NeedHelpButton />
        </ButtonContainer>
      </ResponsiveContainer>
    </StyleSheetManager>
  );
};

export default ErrorPage;

import { ListTableAdvPlanRequests } from 'src/pages/advancePlanRequests/AdvancePlanRequests.types';
import React from 'react';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { P } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export const FeeColumn: React.FC<ListTableAdvPlanRequests> = ({ Fee }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <P textColor={colors.textTableBody}>-&nbsp;{formatCurrency(Fee)}</P>;
    </StyleSheetManager>
  );
};

export default FeeColumn;

import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { H5, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { EmptyStateContentStyled } from './style/EmptyStateData.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type EmptyStateDataProps = {
  title: string;
  subtitle: string;
};
export const EmptyStateData: React.FC<EmptyStateDataProps> = ({
  title,
  subtitle,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex
        data-component="empty-state-data"
        padding="3.2rem"
        backgroundStyle="#fff"
        align="center"
        flexDirection="column"
        gap="0.8rem"
        className="w-full rounded-2xl text-center"
      >
        <EmptyStateContentStyled>
          <H5 light>
            <Translate id={title} />
          </H5>
          <P>
            <Translate id={subtitle} />
          </P>
        </EmptyStateContentStyled>
      </Flex>
    </StyleSheetManager>
  );
};

import { useState } from 'react';
import { ResultPage } from 'src/components/ResultPage/ResultPage.component';
import { ReactComponent as EvaluationBuyerOk } from 'src/images/pageStatus/transfer-done.svg';
import Translate from 'src/components/Translate/Translate.component';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import { RoutePath } from 'src/routers/routers.config';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { ReactComponent as EvaluationBuyerKo } from 'src/images/pageStatus/request-error.svg';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import { PayByLinkDetailsContext } from '../../PayByLinkDetailPage.types.d';
import { useMutation } from '@tanstack/react-query';
import { StatusPyByLinkValues } from 'src/components/StatusPayByLink/StatusPayByLink.types';
import { setOrderStatusMethodApi } from 'src/services/orderLink/orderLink.request';

export const PayByLinkActionsResultEvaluationBuyer = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const order = searchParams.get('EvaluationBuyer');

  const { refetchHandler, OrderGuid, Status, OrderId, CanBeCanceled } =
    useOutletContext<PayByLinkDetailsContext>();

  const { mutate } = useMutation({
    mutationFn: (values: { OrderGuid: string; Status: StatusPyByLinkValues }) =>
      setOrderStatusMethodApi(values),

    onSuccess: () => {
      navigate(
        `${RoutePath.linkDiPagamentoDetail}${RoutePath.actionsSuccessCanceled}`,
      );
      refetchHandler();
    },
    onError: () => navigate(RoutePath.oops),
  });

  const deleteLink = () => mutate({ OrderGuid, Status });

  return (
    <>
      {order === 'OK' && (
        <ResultPage
          svgIconComponent={EvaluationBuyerOk}
          titleId={'text.successCreditWorthinessOkTitle'}
          descriptionId={'text.successCreditWorthinessOkDesc'}
        >
          <ButtonContainer>
            <Button
              onClick={() =>
                navigate(
                  `${RoutePath.linkDiPagamentoDetail}${OrderId}/${RoutePath.info}`,
                )
              }
              height="5.4rem"
              type="submit"
              minWidth="100%"
            >
              <Translate id="lbl.close" />
            </Button>
          </ButtonContainer>
        </ResultPage>
      )}

      {order === 'KO' || order === 'LIGHT_OK' ? (
        <ResultPage
          svgIconComponent={EvaluationBuyerKo}
          titleId={'text.successEvBuyerKoTitle'}
          descriptionId={
            CanBeCanceled
              ? 'text.resultEvalBuyerKoDesc'
              : 'text.resultEvalBuyerKoDescNoCanc'
          }
        >
          <ButtonContainer>
            {CanBeCanceled ? (
              <Button
                onClick={() => setModalOpen(true)}
                height="54px"
                type="submit"
                minWidth="100%"
              >
                <Translate id="lbl.btnCancelLink" />
              </Button>
            ) : null}
            <Button
              onClick={() =>
                navigate(
                  `${RoutePath.linkDiPagamentoDetail}${OrderId}/${RoutePath.info}`,
                )
              }
              height="54px"
              variant={CanBeCanceled ? 'Tertiary' : 'Primary'}
              minWidth="100%"
            >
              <Translate id="lbl.close" />
            </Button>
          </ButtonContainer>
        </ResultPage>
      ) : null}

      {modalOpen && (
        <DoubleChoicePopUp
          handleClose={() => setModalOpen(false)}
          handleSecondAction={deleteLink}
          modalTitle="text.titleAreYouSureUndoLink"
          mainText="text.areYouSureUndoLink"
          icon={AlertIcon}
          firstButtonLabel="lbl.yesUndoLink"
          lastButtonLabel="lbl.close"
        />
      )}
    </>
  );
};

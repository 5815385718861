import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Tabs from 'src/components/Tabs/Tabs.component';
import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { pblDetailPageArray } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.config';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { P } from 'src/style-utils/typographic';
import {
  MaxWidthContainer,
  ResponsiveContainer,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { LinkInfoTab1 } from './partials/LinkInfoTab1.component';
import LinkInfoTab2 from './partials/LinkInfoTab2.component';
import LinkInfoTab3 from './partials/LinkInfoTab3.component';
import ResponsiveLinkDetailCard from './partials/ResponsiveLinkDetailCard';
import { BtnHelp, BtnHelpContainer } from './style/PayByLinkDetailInfo.style';
import PendingBalanceInfoModalLoadable from 'src/components/PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';
import { useState } from 'react';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function PayByLinkDetailInfo() {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dataLink = useOutletContext<PayByLinkDetailsContext>();
  const { path, pathDetail, madeFromPlugin } =
    useOutletContext<PayByLinkDetailsContext>();

  const label =
    pblDetailPageArray[
      madeFromPlugin.toString() as keyof typeof pblDetailPageArray
    ];

  const LinkDetailTabs: ObjectTab[] = [
    {
      tabTitle: 'lbl.detaillTabLinkData',
      content: <LinkInfoTab1 />,
    },
    {
      tabTitle: 'lbl.detaillTabIncomes',
      content: <LinkInfoTab2 />,
    },
    {
      tabTitle: 'lbl.detaillTabInstallments',
      content: <LinkInfoTab3 />,
    },
  ];

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer>
        {location.state?.link && (
          <Toast
            variant="success"
            title="text.successCreatedLinkPopupTitle"
            subTitle="text.successCreatedLinkPopup"
            slideIn
            timeout={3000}
          />
        )}
        <Flex marginTop="1.6rem" marginBottom="1.6rem">
          <Button
            variant="LinkPrimary"
            padding="0"
            onClick={() => navigate(path)}
            leftChild={
              <Icon
                iconSize="2.4rem"
                iconHeight="2.4rem"
                svgIconComponent={Back}
              />
            }
          >
            <P colorBlack>
              <Translate id={label.info.backButton} />
            </P>
          </Button>
        </Flex>
        {dataLink.CanBeRenewed && (
          <AlertComponent
            variant="informative"
            descriptionLbl="text.renewLinkCard"
          />
        )}
        {dataLink?.IncomeRatios?.Processing
          ? dataLink.IncomeRatios.Processing > 0 &&
            [
              PayByLinkStatus.regularIncoming,
              PayByLinkStatus.planned,
              PayByLinkStatus.expiredPayment,
            ].includes(dataLink.Status) && (
              <>
                <AlertComponent
                  variant="informative"
                  descriptionLbl="lbl.PBAlertMerchant"
                  hyperlinkLbl="lbl.whatsthemeaning"
                  onClick={() => setModalOpen(true)}
                />
                {modalOpen && (
                  <PendingBalanceInfoModalLoadable
                    modalTitle="lbl.PBModalTitle"
                    modalBodyType="merchant"
                    i18n
                    handleClose={() => setModalOpen(false)}
                  />
                )}
              </>
            )
          : null}
        {dataLink.Status === 0 && (
          <AlertComponent
            variant="warning"
            descriptionLbl="text.infoPaymentLink"
          />
        )}
        <ResponsiveLinkDetailCard
          dataLink={dataLink}
          labelExpiration={label.info.expiration}
          labelNumberLink={label.info.numberLink}
        />

        <BtnHelpContainer justify="center">
          <BtnHelp
            variant="Tertiary"
            i18n
            translatedText="text.whatCanIdoLink"
            minHeight="fit-content"
            onClick={() => navigate(`${pathDetail}${dataLink.OrderId}/help`)}
          />
        </BtnHelpContainer>

        <MaxWidthContainer maxWidth="66.8rem" noXMargin marginBottom="3.2rem">
          <Tabs
            tabs={LinkDetailTabs}
            columnOnMobile={false}
            select={dataLink.Status === PayByLinkStatus.regularIncoming ? 1 : 0}
            fullWidth
          />
        </MaxWidthContainer>
      </ResponsiveContainer>
    </StyleSheetManager>
  );
}

export default PayByLinkDetailInfo;

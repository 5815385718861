import AutoSubmitOnChange from 'src/components/AutoSubmitOnChange/AutoSubmitOnChange.component';
import Checkbox from 'src/components/Checkbox/Checkbox.component';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { RoutePath } from 'src/routers/routers.config';
import {
  FlagB,
  FlagC,
  FlagPrivacyPolicy,
} from 'src/services/marketing/marketing.config';
import { setFlagMarketingMethod } from 'src/services/marketing/marketing.request';
import { useAccountStore, useProfileStore } from 'src/store/store';
import { P, PNote } from 'src/style-utils/typographic';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { MarketingChoiceConfig } from './MarketingChoice.helpers';
import { LineHeader } from './style/MarketingChoice.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const MarketingChoice: React.FC = () => {
  const flagPrivacyPolicy = useAccountStore((state) => state.FlagPrivacyPolicy);
  const flagFinalitaB = useAccountStore((state) => state.FlagB);
  const flagFinalitaC = useAccountStore((state) => state.FlagC);
  const typeAccount = useProfileStore((state) => state.AccountType);
  const setValues = useAccountStore((state) => state.setAccount);
  const { initialValues, resolver } = MarketingChoiceConfig(
    flagPrivacyPolicy,
    flagFinalitaB,
    flagFinalitaC,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate } = useMutation({
    mutationFn: setFlagMarketingMethod,
    onSuccess: (res, variables) => {
      setValues(variables);
    },
    onError: (res: any) => {},
  });

  const onChange = (data: any) => {
    mutate({ ...data, typeAccount });
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ContainerPadding>
        <GoBackButton url={RoutePath.account} label="text.profile" />
        <TextDefaultPage
          title={'text.marketingChoose'}
          subTitle={'text.personalData'}
        />
        <FormProvider {...methods}>
          <form>
            <AutoSubmitOnChange onSubmit={onChange} />
            <Flex justify="space-between" align="center" gap="2rem">
              <div>
                <PNote>
                  <Translate id={'lbl.obbligatoryStrong'} />
                </PNote>
                <P>
                  <Translate id={'text.marketingChooseGdpr'} />
                </P>
              </div>
              <Checkbox
                disabled
                defaultChecked={flagPrivacyPolicy}
                viewMode="inline"
                name={FlagPrivacyPolicy}
              />
            </Flex>
            <LineHeader />
            <Flex justify="space-between" align="center" gap="2rem">
              <P>
                <Translate id={'text.marketingChooseDirect'} />
              </P>
              <Checkbox
                defaultChecked={flagFinalitaB}
                viewMode="inline"
                name={FlagB}
              />
            </Flex>
            <LineHeader />
            <Flex justify="space-between" align="center" gap="2rem">
              <P>
                <Translate id={'text.marketingChooseCommercial'} />
              </P>
              <Checkbox
                defaultChecked={flagFinalitaC}
                viewMode="inline"
                name={FlagC}
              />
            </Flex>
          </form>
        </FormProvider>
      </ContainerPadding>
    </StyleSheetManager>
  );
};

export default MarketingChoice;

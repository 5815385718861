import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputAmountNumber from 'src/components/InputAmountNumber/InputAmountNumber.component';
import InputField from 'src/components/InputField/InputField.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { orderImport } from 'src/services/orderLink/orderLink.config';
import {
  getVariantsData,
  getVariantsWithPlanMethodApi,
} from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, PSmall } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { Input } from 'src/style/styleInput.style';
import styled, { StyleSheetManager } from 'styled-components';
import { ProductCodes } from 'src/utils/types/common.types';
import { transformNumberRangeError } from 'src/utils/validation/numberInRange';
import { formConfigOrderImportPayByLink } from './StepImport.helpers';
import MaxAmountModal from './partial/modal/MaxAmountModal.loadable';
import { IDs } from 'src/utils/config/ids';
import { shouldForwardProp } from 'src/style-utils/functions';

export const InputFieldCustom = styled(InputField)<{
  isError: any;
  notEmpty: any;
  disabled: any;
}>`
  ${({ isError, notEmpty, disabled }) => {
    if (Object.keys(isError)?.length === 0 && notEmpty) {
      return `
      & ${Input} {
        border-color: ${disabled ? colors.greyBorder : colors.greenInput}
      }
      `;
    }
  }};
`;

function StepImport() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const { productCode } = useParams();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const { remodulation, amount } = usePayByLinkStore((state) => state);
  const { initialValues, resolver } = formConfigOrderImportPayByLink(amount);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate } = useMutation({
    mutationFn: getVariantsWithPlanMethodApi,
    onSuccess: (res) => {
      setValue('variants', res.data.ResultSet);
      setValue('customInstallments', res.data.ResultSet[0]);
      navigate(urlRedirectToFlow);
    },
  });

  const onSubmit = async (data: any) => {
    setValue('amount', data[orderImport]);
    setValue('variantCode', productCode);
    if (remodulation) {
      navigate(urlRedirectToFlow);
      return;
    }
    const dataVariant: getVariantsData = {
      code: productCode as ProductCodes,
      amount: data[orderImport],
    };
    mutate(dataVariant);
    return;
  };

  const isError = methods.formState.errors;
  const value = methods.getValues(orderImport);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.import"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.payByLinkImportNcc" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputFieldCustom
                isPresentMessage
                name={orderImport}
                transformError={transformNumberRangeError}
                inputComponent={InputAmountNumber}
                isError={isError}
                notEmpty={value}
                disabled={remodulation}
                id={IDs.inputAmount}
              />
              <Flex align="center">
                <PSmall>
                  <Translate id="lbl.verifyPlafond" />
                </PSmall>
              </Flex>
              <ButtonContainer>
                <SubmitButton minWidth="100%" id={IDs.btnProceed}>
                  <Translate id="lbl.next" />
                </SubmitButton>
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
        {modalOpen && (
          <MaxAmountModal
            buttonTitle="text.okUnderstand"
            modalTitle="text.maxImport"
            handleClose={() => setModalOpen(false)}
            i18n
          />
        )}
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepImport;

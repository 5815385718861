import Icon from 'src/components/Icon/Icon.component';
import { ReactComponent as X } from 'src/images/icons/negative-feedback-point.svg';
import { ReactComponent as NegativeLogo } from 'src/images/icons/opyn-paylater-negative.svg';
import React from 'react';
import {
  CloseButtonRoot,
  HeaderContainer,
  HeaderFlex,
} from './style/Header.style';
// import { DesktopNavbar } from 'src/components/DesktopNavbar/DesktopNavbar.component';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'src/services/session/session.context';
import colors from 'src/style-utils/colors';
import { useHandle } from './Header.hooks';
import SelectLanguage from './SelectLanguage/SelectLanguage.component';
import { useProfileStore } from 'src/store/store';
import { RoutePath } from 'src/routers/routers.config';
import {
  CloseContainer,
  HamburgerContainer,
} from '../Sidebar/style/sidebar.style';
import { ReactComponent as Menu } from 'src/images/icons/hamburger-menu.svg';
import { ReactComponent as Close } from 'src/images/icons/hamburger-close.svg';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const Header: React.FC<{
  loggedIn?: boolean;
  className?: string;
  setIsVisible: (isVisible: boolean) => void;
  isVisible: boolean;
}> = ({ loggedIn, className, setIsVisible, isVisible }) => {
  const navigate = useNavigate();
  const hasNavBar = useHandle().hasNavBar;
  const hasHeaderPlugin = useHandle().hasHeaderPlugin;
  const { onLogout } = useSession();
  const AccountType = useProfileStore((state) => state.AccountType);
  const navigateLogo = () => {
    if (hasNavBar)
      navigate(`${RoutePath.privateArea}?typeAccount=${AccountType}`);
    if (isVisible) setIsVisible(false);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <HeaderContainer data-component="header" className={className}>
        <HeaderFlex>
          <Icon
            svgIconComponent={NegativeLogo}
            iconHeight={'2.4rem'}
            iconSize={'17.7rem'}
            cursor={hasNavBar ? 'pointer' : ''}
            onClick={navigateLogo}
          />
          {hasHeaderPlugin && <SelectLanguage />}
          {loggedIn &&
            hasNavBar &&
            (!isVisible ? (
              <HamburgerContainer
                data-component="sidebar"
                onClick={() => setIsVisible(true)}
                id="IconMenu"
              >
                <Icon
                  svgIconComponent={Menu}
                  iconHeight="3.2rem"
                  iconSize="3.2rem"
                />
              </HamburgerContainer>
            ) : (
              <CloseContainer onClick={() => setIsVisible(false)}>
                <Icon iconSize="3.2rem" svgIconComponent={Close} />
              </CloseContainer>
            ))}
          {!loggedIn && !hasHeaderPlugin && <SelectLanguage />}
          {hasHeaderPlugin && (
            <CloseButtonRoot
              onClick={() => {
                (window as any).parent.postMessage(
                  JSON.stringify({ status: 'close' }),
                  '*',
                );
                onLogout();
              }}
            >
              <Icon
                fill={colors.textColorGrey}
                svgIconComponent={X}
                iconSize="3.16rem"
                iconHeight="3.16rem"
              />
            </CloseButtonRoot>
          )}
        </HeaderFlex>
      </HeaderContainer>
    </StyleSheetManager>
  );
};

export default Header;

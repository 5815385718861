import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputField from 'src/components/InputField/InputField.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import Translate from 'src/components/Translate/Translate.component';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { H4, PLegal } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import {
  formConfigNamePostalCodePayByLink,
  nameField,
  postalCodeField,
} from './StepNamePostalCode.helpers';
import { Button } from 'src/components/Button/Button.component';
import { IDs } from 'src/utils/config/ids';
import { ContainerInput } from './StepNamePostalCode.style';
import { useMutation } from '@tanstack/react-query';
import { searchCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { RoutePath } from 'src/routers/routers.config';
import Loader from 'src/components/Loader/Loader.component';
import toTitleCase from 'src/utils/functions/formatString';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function StepNamePostalCode() {
  const { step, stepMax } = useRedirectToFlow();
  const navigate = useNavigate();
  const { categoryCode, productCode } = useParams();
  const { country, setValues, validation } = usePayByLinkStore(
    (state) => state,
  );

  const { mutate, isPending } = useMutation({
    mutationFn: searchCompanyInfoMethodApi,
    onSuccess: (res) => {
      if (res.data.ResultSet.Result === 'not_found') {
        navigate(RoutePath.ThankyouPageError + RoutePath.fiscalCodeMatchError);
        return;
      }
      const {
        FiscalCode,
        PostalCode,
        Address,
        Name,
        VatCode,
        SearchId,
        Country,
      } = res.data.ResultSet.Items[0];

      const values = {
        buyerFiscalCode: FiscalCode,
        postalCode: PostalCode,
        buyerAddress: toTitleCase(Address),
        buyerName: toTitleCase(Name),
        VatCode,
        companySearchId: SearchId,
        country: Country.toUpperCase(),
        results: res.data?.ResultSet?.Items,
      };
      setValues(values);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCheckData}`,
      );
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const onSubmit = (data: any) => {
    mutate({
      country,
      name: data[nameField],
      postalCode: data[postalCodeField],
    });
  };
  const { initialValues, resolver } = formConfigNamePostalCodePayByLink(
    validation?.postalCode?.format,
    validation?.name?.format,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.searchCustomerAgency"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.searchCustomerAgency" />
        </H4>
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <ContainerInput>
                <InputField
                  label="lbl.agencyCustomerNameRequired"
                  name={nameField}
                />
                <InputField
                  label="lbl.postalCodeRequired"
                  name={postalCodeField}
                />
              </ContainerInput>
              <PLegal marginTop="1.6rem">
                <Translate id="text.fieldsRequired" />
              </PLegal>
              <ButtonContainer>
                <SubmitButton
                  variant="Primary"
                  minWidth="100%"
                  i18n={true}
                  translatedText="lbl.search"
                  id={IDs.btnConfirm}
                />
                {validation?.vatNumber.isAvailable && (
                  <Button
                    type="button"
                    variant="Tertiary"
                    minWidth="100%"
                    i18n={true}
                    translatedText="lbl.searchByVAT"
                    id={IDs.btnDecline}
                    onClick={() =>
                      navigate(
                        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepVAT}`,
                      )
                    }
                  />
                )}
              </ButtonContainer>
            </form>
          </FormProvider>
        </div>
      </FlowContainer>
    </StyleSheetManager>
  );
}

export default StepNamePostalCode;

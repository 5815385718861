import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as IconWarning } from 'src/images/icons/alert_orange_tiny.svg';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_right.svg';
import { ReactComponent as CloseSvg } from 'src/images/icons/close-icon.svg';
import { ReactComponent as PrimaryIcon } from 'src/images/icons/credit_card.svg';
import { ReactComponent as IconInfo } from 'src/images/icons/information_blue.svg';
import { ReactComponent as IconError } from 'src/images/icons/negative-feedback-point-bg.svg';
import { ReactComponent as IconSuccess } from 'src/images/icons/positive-feed.svg';
import { MouseEventHandler, memo, useEffect, useState } from 'react';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { CloseIcon, ToastContainer, ToastWrapper } from './Toast.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const svgIcons = {
  primary: PrimaryIcon,
  success: IconSuccess,
  warning: IconWarning,
  info: IconInfo,
  error: IconError,
};

type ToastProps = {
  slideIn?: boolean;
  title: string;
  subTitle?: string;
  buttonAction?: MouseEventHandler;
  buttonText?: string;
  buttonShowIcon?: boolean;
  variant?: 'primary' | 'success' | 'warning' | 'info' | 'error';
  customIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  textOnly?: boolean;
  timeout?: number;
  cookieName?: string;
};

const IconComponent: React.FC<{ variant: keyof typeof svgIcons }> = ({
  variant,
}) => {
  const Icon = svgIcons[variant];

  if (!Icon) {
    return null;
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Icon height="24" width="24" />;
    </StyleSheetManager>
  );
};

const Toast = memo(function Toast({
  slideIn = true,
  title,
  subTitle,
  customIcon,
  buttonAction,
  buttonText,
  variant = 'primary',
  textOnly = false,
  buttonShowIcon = true,
  timeout,
  cookieName = 'changed',
}: ToastProps) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setVisible(false);
      }, timeout);
    }
    setTimeout(() => {
      document.cookie = `${cookieName}=false`;
    }, 2000);
  }, []);

  if (!visible) return <></>;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ToastWrapper data-component="toast">
        <ToastContainer animation={slideIn} data-type={variant}>
          <CloseIcon onClick={() => setVisible(false)}>
            <CloseSvg width="100%" height="100%" />
          </CloseIcon>
          {customIcon !== undefined ? (
            <Icon
              svgIconComponent={customIcon}
              iconSize="2.4rem"
              iconHeight="2.4rem"
            />
          ) : (
            !textOnly && <IconComponent variant={variant} />
          )}

          <Flex flexDirection="column">
            <P textColor="inherit" bold>
              <Translate id={title} />
            </P>
            {subTitle && (
              <PSmall colorBlack>
                <Translate id={subTitle} />
              </PSmall>
            )}
          </Flex>
          {buttonText !== undefined && (
            <Button
              variant="LinkPrimary"
              type="button"
              padding="0"
              margin="0 0 0 0"
              minWidth="fit-content"
              onClick={buttonAction}
              i18n
              translatedText={buttonText}
              rightChild={
                buttonShowIcon ? (
                  <Icon
                    svgIconComponent={ArrowRight}
                    iconHeight="2rem"
                    iconSize="2rem"
                  />
                ) : undefined
              }
            />
          )}
        </ToastContainer>
      </ToastWrapper>
    </StyleSheetManager>
  );
});

export default Toast;

import Icon from '../Icon/Icon.component';
// import { ReactComponent as BackRightGreen } from 'src/images/icons/arrow-right-green.svg';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_back_right.svg';
// import { ReactComponent as GreyArrow } from 'src/images/icons/grey_button_arrow.svg';
import { Button } from 'src/components/Button/Button.component';
import { IconButton } from 'src/components/Button/IconButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as NextInstallment } from 'src/images/icons/next_installment.svg';
import { ReactComponent as Startup } from 'src/images/icons/startup.svg';
import moment from 'moment';
import colors from 'src/style-utils/colors';
import { H5, PSmall } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { PayByLinkChoiceCardProps } from './PayByLinkChoiceCard.types';
import { LabelAmountMax } from './style/payByLinkChoiceCard.style';
import { useProfileStore } from '../../store/store';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PayByLinkChoiceCard: React.FC<PayByLinkChoiceCardProps> = ({
  labelChoice,
  labelPblFlex,
  textLinkAction,
  openModal,
  title,
  subTitle,
  onClick,
  disabled,
  titleData,
  subTitleData,
  light,
  linkAction,
  customTitle,
  btnId,
  showEmail,
}) => {
  const orderInvoiceRange = useProfileStore((state) => state.OrderInvoiceRange);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Card data-component="pay-by-link-choice-card">
        <div>
          {labelChoice && (
            <LabelAmountMax>
              <Icon
                svgIconComponent={Startup}
                iconHeight="1.6rem"
                iconSize="1.6rem"
                marginRight="0.6rem"
              />
              <Translate id="lbl.recommendedChoice" />
            </LabelAmountMax>
          )}
          {labelPblFlex && (
            <LabelAmountMax>
              <Icon
                svgIconComponent={NextInstallment}
                iconHeight="1.6rem"
                iconSize="1.6rem"
                marginRight="0.6rem"
              />
              <Translate id="text.intervalInstallment" />
            </LabelAmountMax>
          )}
          <H5 light={light}>
            {title && (
              <Translate
                id={title}
                data={titleData ? { installmentAmount: titleData } : undefined}
              />
            )}
            {customTitle}
          </H5>
          {subTitle && (
            <PSmall marginTop="0.8rem">
              <Translate
                id={subTitle}
                data={{
                  totalAmount: subTitleData,
                  invoiceDate: moment()
                    .subtract(orderInvoiceRange.DaysBefore, 'days')
                    .format('DD-MM-yyyy'),
                }}
              />{' '}
              {showEmail && (
                <Button
                  variant="LinkPrimary"
                  onClick={() => {
                    openModal(true);
                  }}
                  minWidth="unset"
                  underline
                  padding="0"
                  fontWeight="400"
                  height="auto"
                >
                  <Translate id="lbl.seePrev" />
                </Button>
              )}
            </PSmall>
          )}
        </div>
        <Flex
          marginTop="1.6rem"
          justify={textLinkAction ? 'space-between' : 'flex-end'}
        >
          {textLinkAction && (
            <Button
              variant="LinkPrimary"
              gap="0"
              padding="0"
              minWidth="fit-content"
              onClick={() => {
                openModal(true);
                if (linkAction) {
                  linkAction();
                }
              }}
              rightChild={
                <Icon
                  iconSize="2rem"
                  iconHeight="2rem"
                  svgIconComponent={ArrowRight}
                />
              }
            >
              <PSmall textColor={colors.textColorBlack}>
                <Translate id={textLinkAction} />
              </PSmall>
            </Button>
          )}
          <IconButton
            disabled={disabled}
            variant={light ? 'Tertiary' : 'Primary'}
            onClick={onClick}
            IconSrc={ArrowRight}
            id={btnId}
          />
        </Flex>
      </Card>
    </StyleSheetManager>
  );
};

export default PayByLinkChoiceCard;

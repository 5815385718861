import React from 'react';
import PhonePrefixField from 'src/components/PhonePrefixField/PhonePrefixField.component';
import { LabelInput } from 'src/style/styleInput.style';
import Translate from 'src/components/Translate/Translate.component';
import { InputPhone, PhoneFieldWrapper } from './style/phoneField.style';
import { PhoneFieldProps } from './PhoneField.types';
import InputPhoneNumber from 'src/components/InputPhoneNumber/InputPhoneNumber.component';
import { transformLengthError } from 'src/utils/validation/length';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PhoneField = React.memo(function Phone({
  label,
  isPresentMessage,
  id,
  tracingId,
  tracingIdError,
  hidden,
  transformError,
  className,
  inputComponent: PhoneField = InputPhone,
  filterValue,
  maxLength,
  disabled,
  isFieldErrorAlert,
  name,
  placeholder,
  autoFocus,
  ...otherProps
}: PhoneFieldProps) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {label && (
        <LabelInput>
          <Translate id={label} />
        </LabelInput>
      )}
      <PhoneFieldWrapper data-component="phone-field">
        <PhonePrefixField id="1" name="prefix" ref={null} disabled={disabled} />
        <PhoneField
          isPresentMessage={isPresentMessage}
          name={name}
          disabled={disabled}
          transformError={transformLengthError}
          placeholder={placeholder}
          autoFocus={autoFocus}
          {...otherProps}
          type="tel"
          inputComponent={InputPhoneNumber}
        />
      </PhoneFieldWrapper>
    </StyleSheetManager>
  );
});

export default PhoneField;

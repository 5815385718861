import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import {
  PaySuccessCard,
  PSCardProps,
} from 'src/components/PaySuccessCard/PaySuccessCard.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Calendar } from 'src/images/icons/calendarSwipe.svg';
import { ReactComponent as IconBill } from 'src/images/icons/iconBill.svg';
import { ReactComponent as StepJumper } from 'src/images/icons/step-jumper.svg';
import { ReactComponent as EuroSymbol } from 'src/images/icons/euroSymbol.svg';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NamirialSuccess } from 'src/images/pageStatus/mailbox.svg';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import {
  useCurrentTransactionStore,
  useRegistrationStore,
} from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { AlertContainer } from 'src/components/ORION/Alert/Alert.style';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';
import SepaPaymentInfoModalLoadable from '../../../paymentFlow/partials/paymentSuccessPage/partials/SepaPaymentInfoModal/SepaPaymentInfoModal.loadable';
import {
  BodyContainer,
  ContentBodyContainer,
  CopyContainer,
  IconContainer,
  OrderDetailCardContainer,
  OrderDetailTitleContainer,
} from './SuccessPage.style';
import { getPaymentIcon } from 'src/components/PaymentMethodElement/PaymentMethodElement.helpers';
import Icon from 'src/components/Icon/Icon.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function SuccessPage({
  nextPath,
  title,
  subTitle,
  buttonLabel,
  showSuccessData,
}: {
  nextPath: string;
  title: string;
  subTitle?: string;
  buttonLabel?: string;
  showSuccessData?: boolean;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const setValues = useRegistrationStore((state) => state.setValues);
  const successData = useCurrentTransactionStore((state) => state.successData);
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const {
    InstallmentUnpaidCount,
    NextAmount,
    NextDueDate,
    RemainingAmount,
    IsDeferred,
    IsSingleInstallment,
  } = successData ?? {};
  const lastInstallment = RemainingAmount === 0;
  const navigate = useNavigate();
  const icon = getPaymentIcon(successData?.PaymentMethod?.Details.Network);
  const { Last4, Country } = successData?.PaymentMethod?.Details ?? {};

  const cardValues: PSCardProps[] = [
    {
      icon: EuroSymbol,
      lbl: 'text.paymentMethod',
      value: (
        <>
          <Flex
            style={{
              alignItems: 'center',
            }}
            gap="1.6rem"
          >
            <img className="h-10 w-10 p-1" src={icon} alt="card logo" />
            <H4 textAlign="right">
              {successData?.PaymentMethod?.Type === 'sepa_debit' &&
                Country + ' '}
              &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;
              {Last4}
            </H4>
          </Flex>
        </>
      ),
      variant: 'column',
    },
    {
      icon: Calendar,
      lbl: 'text.nextRata',
      value: formatCurrency(NextAmount ? NextAmount : 0),
      note: NextDueDate
        ? refactorFormatDate(NextDueDate, 'ddd. DD MMMM')
        : undefined,
    },
    {
      icon: StepJumper,
      lbl: 'text.residualRataCount',
      value: InstallmentUnpaidCount ?? 0,
    },
    {
      icon: IconBill,
      lbl: 'lbl.remainingAmount',
      value: formatCurrency(RemainingAmount ?? 0),
    },
  ];

  const SingleInstallmentCardValues: PSCardProps[] = [
    {
      icon: EuroSymbol,
      lbl: 'text.paymentMethod',
      value: (
        <>
          <Flex
            style={{
              alignItems: 'center',
            }}
            gap="1.6rem"
          >
            <img className="h-10 w-10 p-1" src={icon} alt="card logo" />
            <H4 textAlign="right">
              {successData?.PaymentMethod?.Type === 'sepa_debit' &&
                Country + ' '}
              &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;
              {Last4}
            </H4>
          </Flex>
        </>
      ),
      variant: 'column',
    },
  ];

  useEffect(() => {
    setValues(resetRegistrationStore);
  }, []);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AlertContainer>
        <AlertComponent
          variant="default"
          titleLbl="lbl.defAlertTYpage"
          hyperlinkLbl="lbl.whatsthemeaning2"
          onClick={() => setModalOpen(true)}
        />
      </AlertContainer>
      {modalOpen && (
        <SepaPaymentInfoModalLoadable
          modalTitle="lbl.sepaModalTItle"
          modalBody="lbl.sepaModalBody"
          i18n
          handleClose={() => setModalOpen(false)}
        />
      )}

      <BodyContainer>
        <ContentBodyContainer>
          <IconContainer>
            <Icon
              svgIconComponent={NamirialSuccess}
              iconSize={window.innerWidth < 1024 ? '26.4rem' : '38.4rem'}
              iconHeight={window.innerWidth < 1024 ? '19.2rem' : '27.9rem'}
            />
          </IconContainer>

          <CopyContainer>
            <TextDefaultPage title={title} subTitle={subTitle} />
          </CopyContainer>
          {IsSingleInstallment && !IsDeferred && (
            <>
              <ContentBodyContainer>
                {SingleInstallmentCardValues.map((item, i) => (
                  <PaySuccessCard key={i} {...item} />
                ))}
              </ContentBodyContainer>
            </>
          )}

          <ButtonContainer backgroundColor="white">
            <Button onClick={() => navigate(nextPath)} variant="Primary">
              <Translate id={buttonLabel} />
            </Button>
          </ButtonContainer>
        </ContentBodyContainer>
      </BodyContainer>

      {showSuccessData &&
        ((lastInstallment && IsDeferred) || !lastInstallment) && (
          <div className="bg-white py-10">
            <div className="container">
              <OrderDetailTitleContainer>
                <H4 light>
                  <Translate id="lbl.TyPageDetailTitle" />
                </H4>
                <P>
                  <Translate id="text.paymentSuccessSubtitle" />
                </P>
              </OrderDetailTitleContainer>
              <OrderDetailCardContainer>
                {cardValues.map((item, i) => (
                  <PaySuccessCard key={i} {...item} />
                ))}
              </OrderDetailCardContainer>
            </div>
          </div>
        )}
      {window.innerWidth < 1024 && <div style={{ height: '15vh' }}></div>}
    </StyleSheetManager>
  );
}

export default SuccessPage;

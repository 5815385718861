import PaymentMethodElementButton from 'src/components/PaymentMethodElement/PaymentMethodElementButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CheckedIcon } from 'src/images/icons/checkmark.svg';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { shouldForwardProp } from 'src/style-utils/functions';
import { P, PSmall } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';

type Props = {
  step: number;
  titleLabel: string;
  contentLabel?: string;
  formattedAmount?: string;
  paymethod?: PaymentMethodResult;
  onClickHandler?: () => void;
};

const MethodSelectElement: React.FC<Props> = ({
  step,
  paymethod,
  titleLabel,
  contentLabel,
  formattedAmount,
  onClickHandler,
}) => {
  const checked = paymethod !== undefined;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="grid gap-4">
        <div className="flex gap-2 text-pblack-400">
          {checked ? (
            <CheckedIcon className="m-0.5 h-5 w-5 shrink-0 text-success" />
          ) : (
            <div className="m-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border text-xs">
              {step}
            </div>
          )}
          <div className="grid gap-2">
            <P bold colorBlack>
              <Translate id={titleLabel} />
            </P>
            {contentLabel && (
              <PSmall>
                <Translate id={contentLabel} data={{ x: formattedAmount }} />
              </PSmall>
            )}
          </div>
        </div>
        {paymethod && (
          <PaymentMethodElementButton
            payCard={paymethod}
            onClickHandler={onClickHandler}
          />
        )}
      </div>
    </StyleSheetManager>
  );
};

export default MethodSelectElement;

import Translate from 'src/components/Translate/Translate.component';
import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import colors from 'src/style-utils/colors';
import { shouldForwardProp } from 'src/style-utils/functions';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';

const OrderIdColumn: React.FC<ListRequestsResultsTable> = ({
  OrderId,
  BuyerName,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Flex fluid justify="flex-start" flexDirection="column">
        <PSmall textColor={colors.textTableLightBody}>
          <Translate id={'advPlanRequestTableColumn.OrderId'} />
          {OrderId}
        </PSmall>
        <P textColor={colors.textTableBody}>{BuyerName}</P>
      </Flex>
    </StyleSheetManager>
  );
};

export default OrderIdColumn;

import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { ToastContainer } from './style/ToastCard';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type ToastProps = {
  title: string;
  subTitle?: string;
  variant?: 'primary' | 'success' | 'warning' | 'info' | 'error';
  customIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  colorBlack?: boolean;
};

const ToastCard = ({
  title,
  subTitle,
  customIcon,
  variant = 'primary',
  colorBlack = false,
}: ToastProps) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ToastContainer data-component="toast-card" data-type={variant}>
        <Flex gap="0.8rem" align="center" marginBottom="auto">
          {customIcon !== undefined && (
            <div>
              <Icon
                svgIconComponent={customIcon}
                iconSize="3.2rem"
                iconHeight="3.2rem"
              />
            </div>
          )}

          <Flex flexDirection="column">
            <P colorBlack={colorBlack} bold>
              <Translate id={title} />
            </P>
            {subTitle && (
              <PSmall colorBlack={colorBlack}>
                <Translate id={subTitle} />
              </PSmall>
            )}
          </Flex>
        </Flex>
      </ToastContainer>
    </StyleSheetManager>
  );
};

export default ToastCard;

import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { getBuyerStatsInitialData } from 'src/services/monitoring/monitoring.config';
import {
  getBuyerStatsDataMethodApi,
  getBuyerStatsInitialDataMethodApi,
} from 'src/services/monitoring/monitoring.request';
import { CollaboratorsStats } from 'src/services/monitoring/monitoring.types';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { RoleEnum } from 'src/utils/types/common.types';
import {
  DataFormMonitoringBuyerStats,
  searchMonitoringDetailStatsFormConfig,
} from './MonitoringBuyerDetail.helpers';
import FilteredListMonitoring from './partials/FilteredList/FilteredListMonitoring.component';
import CollaboratorsPopover from './partials/popover/collaborators/CollaboratorsPopover.component';
import LinkPopover from './partials/popover/link/LinkPopover.component';
import PeriodPopover from './partials/popover/period/PeriodPopover.component';
import StatsCardWrapper from './partials/statsCardWrapper/StatsCardWrapper.component';
import { TabsMonitoring } from './partials/tabs/TabsMonitoring.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function MonitoringBuyerDetail() {
  const Role = useProfileStore((state) => state.Role);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const AccountType = useProfileStore((state) => state.AccountType);
  const navigate = useNavigate();
  const { guid } = useParams();
  const { resolver, initialValues } = searchMonitoringDetailStatsFormConfig();
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [getBuyerStatsInitialData],
    queryFn: () => getBuyerStatsInitialDataMethodApi(guid),
  });
  const {
    mutate,
    data: dataBuyerStats,
    isPending: isLoadingGetBuyerStats,
  } = useMutation({ mutationFn: getBuyerStatsDataMethodApi });
  const collaboratorsInitialData = data?.data.ResultSet.Collaborators ?? [];
  const yearsInitialData = data?.data.ResultSet.Years ?? [];
  const [collaboratorsList, setCollaboratorsList] = useState<
    CollaboratorsStats[] | undefined
  >();
  const [dataFiltered, setDataFiltered] = useState(initialValues);
  useEffect(() => {
    if (isSuccess) {
      if (Role !== RoleEnum.owner || AccountType !== 'MERCHANT' || !guid) {
        return navigate(RoutePath.oops) as void;
      }
      setCollaboratorsList(collaboratorsInitialData);
      mutate({ ...initialValues, buyerGuid: guid });
    }
  }, [isSuccess]);

  const methods = useForm({ defaultValues: initialValues, resolver });
  const onSubmit = (data: any) => {
    setValue('stateFlow', false);
    setDataFiltered({ ...data });
    mutate({ ...data, buyerGuid: guid });
  };
  const onCloseHandler = () => {
    Object.keys(dataFiltered).map((field) =>
      methods.setValue(
        field,
        dataFiltered[field as keyof DataFormMonitoringBuyerStats],
      ),
    );
  };

  if (isLoading || isLoadingGetBuyerStats) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const buyerStats = dataBuyerStats?.data;

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div className="bg-white">
        <ResponsiveContainer>
          <Button
            variant="LinkPrimary"
            height="4.8rem"
            padding="0"
            margin="0.8rem 0"
            onClick={() => navigate(-1)}
            fontWeight="400"
            leftChild={
              <Icon
                iconSize="4.8rem"
                iconHeight="4.8rem"
                svgIconComponent={Back}
              />
            }
          >
            <P colorBlack>
              <Translate id="lbl.monitoring" />
            </P>
          </Button>
          <Flex gap="4.8rem" flexDirection="column" padding="3.2rem 0">
            <Flex className="lg:gap:2 flex flex-col gap-8 lg:flex-row lg:items-center lg:justify-between">
              <div>
                <P>
                  <Translate id="text.client" />
                </P>
                <H4>
                  <Translate id={data?.data.ResultSet.BuyerName} />
                </H4>
              </div>
              <Button
                sizeOnDesktop="medium"
                variant="Primary"
                i18n
                sizeOnMobile="medium"
                className="max-w-[32.7rem]"
                onClick={() => {
                  setValue('stateFlow', true);
                  navigate(RoutePath.createLink + RoutePath.chooseProduct, {
                    state: { fromMonitoring: guid },
                  });
                }}
                translatedText="text.createLink"
              />
            </Flex>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex gap-3 border-0 border-b border-solid border-[#E7E4E4] py-3 pb-3">
                  <PeriodPopover
                    submitHandler={methods.handleSubmit(onSubmit)}
                    initialData={yearsInitialData}
                    dataFiltered={dataFiltered}
                    onCloseHandler={onCloseHandler}
                  />
                  {data?.data.ResultSet.HasPlugin && (
                    <LinkPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                  )}
                  {collaboratorsInitialData.length > 0 && (
                    <CollaboratorsPopover
                      submitHandler={methods.handleSubmit(onSubmit)}
                      collaboratorsList={collaboratorsList}
                      initialData={collaboratorsInitialData}
                      setData={setCollaboratorsList}
                      dataFiltered={dataFiltered}
                      onCloseHandler={onCloseHandler}
                    />
                  )}
                </div>
                <FilteredListMonitoring
                  setDataFilter={setDataFiltered}
                  handleClick={onSubmit}
                  dataForm={dataFiltered}
                  collaborators={collaboratorsInitialData}
                  initialData={initialValues}
                />
              </form>
            </FormProvider>
            <div>
              <P bold>
                <Translate id="lbl.overview" />
              </P>
              <P marginBottom="2.4rem">
                <Translate id="text.overviewDescr" />
              </P>
              <StatsCardWrapper buyerStats={buyerStats} />
            </div>
          </Flex>
        </ResponsiveContainer>
      </div>
      <TabsMonitoring
        buyerStats={buyerStats}
        isLoading={isLoadingGetBuyerStats}
      />
    </StyleSheetManager>
  );
}

export default MonitoringBuyerDetail;

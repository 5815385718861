import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import { ReactComponent as Close } from 'src/images/icons/hamburger-close.svg';
import { ReactComponent as WhiteIcon } from 'src/images/icons/white_icon.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { H5, PSmall } from 'src/style-utils/typographic';
import { ColoredSection } from 'src/style/Container.style';
import { Button } from '../Button/Button.component';
import Icon from '../Icon/Icon.component';
import {
  ContainerBar,
  CustomResponsiveContainer,
} from './style/payByLinkHeader.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type PayByLinkHeaderProps = {
  title?: string;
  step?: number;
  stepMax?: number;
  idLink?: number;
  stepBack?: boolean;
  closeIcon?: boolean;
  alertBack?: () => void;
  stepBackHandler?: () => void;
  modalText?: string;
  pathDetail?: string;
  leaveModal?: () => void;
};

const PayByLinkHeader: React.FC<PayByLinkHeaderProps> = ({
  step = 0,
  stepMax = 1,
  title,
  idLink,
  stepBack,
  closeIcon = true,
  alertBack,
  modalText,
  leaveModal,
  pathDetail,
  stepBackHandler,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };

  // const setValues = usePayByLinkStore((state) => state.setValues);
  const navigate = useNavigate();

  const leave = async () => {
    handleClose();
    // await setValues(ResetPayByLinkStore);
    navigate(`${RoutePath.createLinkExit}?typeAccount=MERCHANT`);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ColoredSection
        data-component="pay-by-link-header"
        bgColor="white"
        padding="1.6rem 0"
      >
        <CustomResponsiveContainer>
          <Button
            disabled={!stepBack}
            variant="LinkPrimary"
            padding="0"
            minWidth="fit-content"
            onClick={
              alertBack
                ? alertBack
                : stepBackHandler
                  ? stepBackHandler
                  : () => navigate(-1)
            }
            leftChild={
              <Icon
                iconSize="4rem"
                iconHeight="4rem"
                svgIconComponent={stepBack ? Back : WhiteIcon}
              />
            }
          />
          <div className="PBLHeaderTitle">
            {idLink
              ? null
              : step > 0 && (
                  <PSmall>
                    <strong>
                      <Translate id="lbl.step" /> {step}
                    </strong>
                  </PSmall>
                )}
            <H5>
              <Translate id={title} />
            </H5>
          </div>
          {closeIcon && (
            <Button
              onClick={() => {
                if (idLink) {
                  navigate(`${pathDetail}${idLink}/info`);
                } else {
                  setModalOpen(true);
                }
              }}
              variant="LinkPrimary"
              gap="0"
              padding="0"
              minWidth="fit-content"
              rightChild={
                <Icon
                  iconSize="3.6rem"
                  iconHeight="3.6rem"
                  svgIconComponent={Close}
                />
              }
            />
          )}
        </CustomResponsiveContainer>
      </ColoredSection>
      <ContainerBar data-component="pay-by-link-header">
        <div style={{ width: `${step * (100 / stepMax)}%` }} />
      </ContainerBar>
      {!idLink && modalOpen && (
        <DoubleChoicePopUp
          handleClose={handleClose}
          handleSecondAction={leaveModal ? leaveModal : leave}
          firstButtonLabel="lbl.leave"
          lastButtonLabel="lbl.next"
          mainText={modalText ? modalText : 'text.wannaLeave'}
          icon={AlertIcon}
        />
      )}
    </StyleSheetManager>
  );
};

export default PayByLinkHeader;

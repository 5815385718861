import Footer from 'src/components/Footer/Footer.component';
import { useHandle } from 'src/components/Header/Header.hooks';
import { Navigate, Outlet } from 'react-router-dom';
import { useScrollToTopOnPathnameChange } from 'src/routers/routers.hooks';
import { useSession } from 'src/services/session/session.context';
import DevOnlyDashBoard from '../DevOnlyDashboard/DevOnlyDashboard.loadable';
import React, { useState } from 'react';
import { ENV } from '../../settings';
import { Sidebar } from '../Sidebar/Sidebar.component';
import Header from '../Header/Header.component';
import { Container } from 'src/style/Container.style';
import colors from 'src/style-utils/colors';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

export const ProtectedRoute: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { auth } = useSession();
  const authToken = auth.authToken;
  const shouldLoadDevTools = ENV !== 'production';

  const isResponsive = useHandle().isResponsive;
  const hasHeader = useHandle().hasHeader;
  const hasFooter = useHandle().hasFooter;
  const hasNavBar = useHandle().hasNavBar;
  useScrollToTopOnPathnameChange();

  return authToken ? (
    <>
      {hasHeader && (
        <Header
          loggedIn={true}
          className={hasNavBar ? 'z-40 lg:hidden' : 'z-40'}
          setIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      )}

      {isResponsive ? (
        <div className="block min-h-lvh bg-[#F6F7F9] md:flex md:bg-[#E7E4E4] 2xl:justify-center">
          {hasNavBar && (
            <Sidebar
              loggedIn={true}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          )}
          <div
            className={
              hasNavBar
                ? 'flex w-full flex-col justify-between overflow-x-hidden bg-[#F6F7F9] 2xl:w-fit 2xl:min-w-[1240px] 2xl:max-w-[1240px]'
                : 'flex w-full flex-col justify-between overflow-x-hidden bg-[#F6F7F9]'
            }
          >
            <div>
              <Outlet />
            </div>
            {hasFooter && (
              <Footer
                className={
                  hasFooter === 'onlyDesktop'
                    ? 'hidden bg-white lg:block'
                    : 'bg-white'
                }
              />
            )}
          </div>
        </div>
      ) : (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <Container backgroundStyle={colors.backgroundGrey}>
            <Outlet />
          </Container>
        </StyleSheetManager>
      )}

      {shouldLoadDevTools && <DevOnlyDashBoard />}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

import { Button } from 'src/components/Button/Button.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ResetPayByLinkStore } from 'src/pages/createLinkFlow/CreateLinkFlow.config';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { initOrderCreateMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H4, P } from 'src/style-utils/typographic';
import { CategoryCodes, ProductCodes } from 'src/utils/types/common.types';
import { ProductCardArray } from './ProductCard.config';
import { Flex } from '../../style/flexbox.style';
import Icon from '../Icon/Icon.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type ProductCodePropsType = {
  categoryCode: CategoryCodes;
  productCode: ProductCodes;
  visual: string;
  disabled: boolean;
  fromMonitoring?: string;
};

const ProductCard = ({
  productCode,
  categoryCode,
  visual,
  disabled,
  fromMonitoring,
}: ProductCodePropsType) => {
  const { title, subtitle1, subtitle2, id } =
    ProductCardArray[productCode] ?? {};
  const navigate = useNavigate();
  const setValues = usePayByLinkStore((state) => state.setValues);
  const setValue = usePayByLinkStore((state) => state.setValue);
  const SvgIcon = visual
    ? () => <div dangerouslySetInnerHTML={{ __html: visual }} />
    : null;

  const { mutate: mutateInit, isPending: isLoadingInit } = useMutation({
    mutationFn: initOrderCreateMethodApi,
    onSuccess: (res) => {
      setValue('stateFlow', true);
      setValue('uwId', res?.data?.ResultSet.UwId);
      setValue('sessionGuid', res?.data?.ResultSet.Guid);
      setValue('inviteEmail', res?.data?.ResultSet.LastInviteEmail);
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepImport}`,
      );
    },
    onError: () => {},
  });
  if (isLoadingInit) {
    return (
      <Loader overlayViewMode="fullscreen" active={true} viewMode="fluid" />
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="product-card"
        className="flex min-h-[56rem] w-70 flex-col overflow-hidden rounded-xl bg-white shadow-blue4px lg:min-h-[60rem] lg:w-96 lg:shadow-blue8px"
      >
        <div className="flex h-40 items-center bg-pblack-400 px-6 py-8">
          <H4 textColor={colors.white}>
            <Translate id={title} />
          </H4>
        </div>
        <div className="flex flex-1 flex-col gap-4 px-4 py-6 lg:gap-8 lg:px-6 lg:py-8">
          {SvgIcon ? (
            <Flex align="center" justify="center">
              <Icon
                svgIconComponent={SvgIcon}
                iconHeight="17.6rem"
                iconWidth="19.8rem"
                marginBottom="1.2rem"
                className="h-36 w-auto lg:h-44"
              />
            </Flex>
          ) : null}
          <div className="flex flex-1 flex-col gap-3">
            {subtitle1 && (
              <P colorBlack>
                <Translate id={subtitle1} />
              </P>
            )}
            {subtitle2 && (
              <P colorBlack>
                <Translate id={subtitle2} />
              </P>
            )}
          </div>
          <Button
            variant="Tertiary"
            minWidth="100%"
            sizeOnDesktop="medium"
            disabled={disabled}
            translatedText="text.createLink"
            i18n
            onClick={() => {
              setValues(ResetPayByLinkStore);
              if (fromMonitoring) {
                mutateInit({ BuyerGuid: fromMonitoring });
                return;
              }
              navigate(
                `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCustomer}`,
              );
            }}
            id={id}
          />
        </div>
      </div>
    </StyleSheetManager>
  );
};

export default ProductCard;

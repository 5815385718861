import InputDate from 'src/components/InputDate/InputDate.component';
import SelectPopover from 'src/components/SelectPopover/SelectPopover.component';
import Translate from 'src/components/Translate/Translate.component';
import { startDate, endDate } from '../../SearchCriteriaForm.config';
import React, { MouseEventHandler } from 'react';
import { P } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import { DataFormEligibleOrders } from '../../SearchCriteriaForm.helpers';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

type PeriodPopoverProps = {
  submitHandler?: MouseEventHandler | undefined;
  dataFiltered: DataFormEligibleOrders;
  onCloseHandler?: () => void;
};
export const PeriodPopover: React.FC<PeriodPopoverProps> = ({
  submitHandler,
  dataFiltered,
  onCloseHandler,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <SelectPopover
        submitHandler={submitHandler}
        label="text.period"
        countFilters={
          dataFiltered?.[startDate] || dataFiltered?.[endDate] ? 1 : undefined
        }
        onCloseHandler={onCloseHandler}
      >
        <Flex padding="1rem" gap="0.4rem" flexDirection="column" fluid>
          <P>
            <Translate id="text.startPeriod" />
          </P>
          <InputDate name={startDate} />
        </Flex>
        <Flex padding="1rem" gap="0.4rem" flexDirection="column" fluid>
          <P>
            <Translate id="text.endPeriod" />
          </P>
          <InputDate name={endDate} />
        </Flex>
      </SelectPopover>
    </StyleSheetManager>
  );
};

export default PeriodPopover;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputPasswordField from 'src/components/InputPasswordField/InputPasswordField.component';
import Loader from 'src/components/Loader/Loader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { updateNewValueForDataChangeMethodApi } from 'src/services/dataChange/dataChange.request';
import { passwordField } from 'src/services/registration/registration.config';
import { H4, P } from 'src/style-utils/typographic';
import { FormStyle } from 'src/style/styleInput.style';
import { DataChangeContext } from '../../ChangeAccessData.types';
import { formConfigChangePassword } from './ChangePassword.helpers';

function ChangePassword() {
  const { initialValues, resolver } = formConfigChangePassword();
  const { sessionGuid } = useOutletContext<DataChangeContext>();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { mutate, isPending } = useMutation({
    mutationFn: updateNewValueForDataChangeMethodApi,
    onSuccess: (res) => {
      if (res.data.ResultSet)
        navigate(RoutePath.ThankyouPage + RoutePath.successChangePassword);
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = async (data: any) => {
    const dataPassword = {
      NewValue: data[passwordField],
      sessionGuid,
    };
    mutate({ ...dataPassword });
  };
  return (
    <>
      <H4>
        <Translate id="text.changeAccessPassw" />
      </H4>
      <P marginBottom="2rem">
        <Translate id="text.changeAccessPasswExplain" />
      </P>
      <FormProvider {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
          <InputPasswordField name={passwordField} label={'lbl.password'} />
          <ButtonContainer>
            <SubmitButton minWidth="100%" disabled={isPending}>
              <Translate id="text.continue" />
            </SubmitButton>
          </ButtonContainer>
        </FormStyle>
      </FormProvider>
    </>
  );
}

export default ChangePassword;

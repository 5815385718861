import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Information } from 'src/images/icons/information.svg';
import { resetRegistrationStore } from 'src/pages/registration/Registration.config';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { getCompanyInfo } from 'src/services/orderLink/orderLink.config';
import { getCompanyInfoMethodApi } from 'src/services/orderLink/orderLink.request';
import { initSignUpSessionMethodApi } from 'src/services/registration/registration.request';
import { useProfileStore, useRegistrationStore } from 'src/store/store';
import { P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import CheckDataModalLodable from './partials/CheckDataModal.lodable';
import {
  Header,
  Subtitle,
  Title,
  TitleContainer,
} from './RegistrationLightStepCheckData.style';
import { ContainerInfo } from '../../style/registrationLight.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RegistrationLightStepCheckData() {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { setValue, setValues } = useRegistrationStore();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const { mutate, isPending } = useMutation({
    mutationFn: initSignUpSessionMethodApi,
    onSuccess: (res) => {
      setValue('sessionGuid', res.data.ResultSet.Guid);
      setValue('mobile', res.data.ResultSet.Phone);
      navigate(RoutePath.registrationLight + RoutePath.phoneStep);
    },
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  const { isLoading: isLoadingGetCompanyInfo, data } = useQuery({
    queryKey: [getCompanyInfo],
    queryFn: () => getCompanyInfoMethodApi({ guid: currentOrder?.BuyerGuid }),
  });
  const dataCompany = data?.data.ResultSet;
  const onSubmit = () => {
    if (currentOrder?.BuyerRegistrationStatus !== 'not_registered') {
      navigate(RoutePath.registrationLight + RoutePath.phoneStep);
      return;
    }
    mutate({
      BuyerGuid: currentOrder?.BuyerGuid,
      AccountType: 'BUYER',
      Language: language,
      RegistrationType: 'light',
    });
  };

  if (isPending || isLoadingGetCompanyInfo) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FlowContainer>
        <Header>
          <TitleContainer>
            <Title>
              <Translate id="text.isYourAgency" />
            </Title>
            <Icon
              svgIconComponent={Information}
              iconSize="28px"
              onClick={() => setOpenModal(true)}
            />
          </TitleContainer>

          <Subtitle>
            <Translate id="text.isYourCompanyCorrectData" />
          </Subtitle>
        </Header>

        <div className="content">
          <ContainerInfo>
            {dataCompany?.Name && (
              <div>
                <P>
                  <Translate id="lbl.payByLinkFlowBusinessName" />
                </P>
                <P bold colorBlack>
                  {dataCompany.Name}
                </P>
              </div>
            )}
            {isItalian(data?.data.ResultSet.Country) ? (
              <>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkCf" />
                  </P>
                  <P bold colorBlack>
                    {dataCompany?.FiscalCode}
                  </P>
                </div>
                <div>
                  <P>
                    <Translate id="lbl.payByLinkVatCode" />
                  </P>
                  <P bold colorBlack>
                    {dataCompany?.VatCode}
                  </P>
                </div>
              </>
            ) : (
              <>
                {dataCompany?.VatCode && (
                  <div>
                    <P>
                      <Translate id="text.vatNumber" />
                    </P>
                    <P bold colorBlack>
                      {dataCompany.VatCode}
                    </P>
                  </div>
                )}
                {dataCompany?.PostalCode && (
                  <div>
                    <P>
                      <Translate id="lbl.postalCode" />
                    </P>
                    <P bold colorBlack>
                      {dataCompany.PostalCode}
                    </P>
                  </div>
                )}
              </>
            )}
            {dataCompany?.Address && (
              <div>
                <P>
                  <Translate id="text.address" />
                </P>
                <P bold colorBlack>
                  {dataCompany.Address}
                </P>
              </div>
            )}
          </ContainerInfo>
        </div>

        <ButtonContainer>
          <Button
            variant="Primary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.yesIsMyCompany"
            onClick={onSubmit}
            id={IDs.btnConfirm}
          />
          <Button
            variant="Tertiary"
            minWidth="100%"
            i18n={true}
            translatedText="lbl.notMyCompany"
            onClick={() => {
              setValues(resetRegistrationStore);
              navigate(RoutePath.support, { replace: true });
            }}
            id={IDs.btnDecline}
          />
        </ButtonContainer>
      </FlowContainer>
      {openModal && (
        <CheckDataModalLodable
          buttonTitle="text.okUnderstand"
          modalTitle="text.corporateData"
          handleClose={() => setOpenModal(false)}
          i18n
        />
      )}
    </StyleSheetManager>
  );
}

export default RegistrationLightStepCheckData;

import Translate from 'src/components/Translate/Translate.component';
import { BadgeStatusContainer } from './style/BadgeStatus.style';
import { BadgeStatusProps } from './BadgeStatus.types';
import Icon from 'src/components/Icon/Icon.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const BadgeStatus = ({ label, style, i18n = true, icon }: BadgeStatusProps) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <BadgeStatusContainer
        data-component="badge-status"
        justify="center"
        align="center"
        padding="0 0.6rem"
        gap={'0.4rem'}
        {...style}
      >
        {icon && (
          <Icon iconSize="1.2rem" iconHeight="auto" svgIconComponent={icon} />
        )}
        <span>{i18n ? <Translate id={label} /> : label}</span>
      </BadgeStatusContainer>
    </StyleSheetManager>
  );
};

export default BadgeStatus;

import colors from 'src/style-utils/colors';
import { withMargin, WithMarginProps } from 'src/style-utils/dimensionsMixins';
import media from 'src/style-utils/media-queries';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ backgroundStyle?: string }>`
  flex: 1 0 auto;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  overflow: hidden auto;
  position: relative;
  ${(props) =>
    props.backgroundStyle ? `background: ${props.backgroundStyle};` : null}
  ${media.tablet`
    margin: 2.4rem auto;
    max-width: 55rem;
    outline: solid 0.1rem ${colors.greyBorder};
    border-radius: 1.2rem;
  `}
`;

export const ContainerPadding = styled.div`
  width: 100%;
  padding: 2.4rem;
  ${media.tablet`
    padding: 3.2rem 8.8rem;
  `}
`;

/**NB: da usare solo nel MainLayout o ProtectedRoute*/
export const ContainerPage = styled.div`
  flex: 1 0 auto;
  margin: 0 auto;
  width: 100%;
  background-color: ${colors.backgroundGrey};
`;

interface ColoredSectionProps {
  bgColor?: string;
  /**
   * @defaultValue  '1.6rem 2.4rem'
   */
  padding?: string;
  textAlign?: 'left-align' | 'right-align' | 'center' | CSSGlobalRuleValue;
  margin?: string | CSSGlobalRuleValue;
}

export const ColoredSection = styled.div<ColoredSectionProps>`
  ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : null}
  ${({ padding }) =>
    padding
      ? css`
          padding: ${padding};
        `
      : css`
          padding: 4.8rem 0;
        `};
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : null};
  ${({ bgColor }) =>
    bgColor &&
    css`
      background: ${bgColor};
    `};
`;

// export const ResponsiveContainer = styled.div<{
//   backgroundStyle?: string;
//   textAlign?: 'left-align' | 'right-align' | 'center' | CSSGlobalRuleValue;
//   margin?: string | CSSGlobalRuleValue;
// }>`
//   ${({ textAlign }) =>
//     textAlign
//       ? css`
//           text-align: ${textAlign};
//         `
//       : null}
//   ${({ margin }) =>
//     margin
//       ? css`
//           margin: ${margin};
//         `
//       : css`
//           margin: 0 auto;
//         `}
//   width: min(123.2rem, 100% - 6.4rem);
// `;

export const ResponsiveContainer = styled.div<{
  backgroundStyle?: string;
  textAlign?: 'left-align' | 'right-align' | 'center' | CSSGlobalRuleValue;
  margin?: string | CSSGlobalRuleValue;
  marginDesktop?: string | CSSGlobalRuleValue;
  padding?: string | CSSGlobalRuleValue;
}>`
  ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : null}
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin: 0 auto;
        `}
         ${({ marginDesktop }) =>
    marginDesktop
      ? css`
          ${media.desktop`
            margin: ${marginDesktop};
          `}
        `
      : css`
          ${media.desktop`
            margin: ${marginDesktop};
          `}
        `}
    ${({ padding }) =>
    padding
      ? css`
          padding: ${padding};
        `
      : ''}
    width: min(123.2rem, 100% - 4.8rem);
  ${(props) =>
    props.backgroundStyle ? `background: ${props.backgroundStyle};` : null}
  ${media.mobileSm`
    max-width: 43.2rem;
  `}
    ${media.mobileMd`
    max-width: 67.2rem;
  `}
    ${media.tablet`
    max-width: 84.8rem;
  `}
    ${media.desktop`
    max-width: 104rem;
  `}
    ${media.desktopXl`
    max-width: 123.2rem;
  `}
`;

export const MaxWidthContainer = styled.div<{
  margin?: string | CSSGlobalRuleValue;
  maxWidth?: string | CSSGlobalRuleValue;
  noXMargin?: boolean;
  marginTop?: string | CSSGlobalRuleValue;
  marginBottom?: string | CSSGlobalRuleValue;
}>`
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin: 0 auto;
        `}
  width: min(
    ${({ maxWidth }) => (maxWidth ? `${maxWidth}` : `44.8rem`)}, 100% ${({
    noXMargin,
  }) => !noXMargin && '- 4.8rem'});
  ${({ marginTop }) => `margin-top: ${marginTop};`}
  ${({ marginBottom }) => `margin-bottom: ${marginBottom};`}
`;

export const FlowContainer = styled(ResponsiveContainer)`
  h4 {
    margin-bottom: 1.6rem;
  }

  ${media.mobileSm`
    h4, .centerOnDesktop {
      text-align: center;
    }
    h4{
      margin-bottom: 3.2rem;
    }
    .fit-content {
    width: fit-content;
  }
  `}
  width: min(56rem, 100% - 4.8rem);

  .content {
    max-width: 44.8rem;
    margin: 0 auto;
  }

  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin-top: 4.8rem;
        `}
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const Card = styled.div<{
  maxWidth?: string | CSSGlobalRuleValue;
  backgroundStyle?: string;
  margin?: string | CSSGlobalRuleValue;
  notShadow?: boolean;
  padding?: string | CSSGlobalRuleValue;
  overflow?: string | CSSOverflow;
}>`
  background: ${(props) =>
    props.backgroundStyle ? `${props.backgroundStyle}` : '#fff'};
  padding: 1.6rem 2.4rem;
  ${({ margin }) =>
    margin
      ? css`
          margin: ${margin};
        `
      : css`
          margin: 0 auto;
        `}
  ${({ padding }) =>
    padding
      ? css`
          padding: ${padding};
        `
      : ''}
    border-radius: 1.6rem;
  box-shadow: ${(props) =>
    props.notShadow ? 'unset' : `0px 2px 4px 1px ${colors.cardShadowBlue}`};
  overflow: ${(props) => (props.overflow ? `${props.overflow}` : 'hidden')};
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : '44.8rem')};
`;

export const Grid = styled.div<{
  gap?: string;
  gapOnDesktop?: string;
  responsive?: boolean;
  textAlign?: 'left-align' | 'right-align' | 'center' | CSSGlobalRuleValue;
}>`
  display: grid;
  ${({ responsive }) =>
    responsive &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    `}
  ${({ gap }) => (gap ? `gap: ${gap};` : null)}
    ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : null}
    ${({ gapOnDesktop }) =>
    gapOnDesktop ? media.tablet`gap: ${gapOnDesktop};` : null}
`;

type GridContainerProps = WithMarginProps & {
  cols?: number;
  colsOnDesktop?: number;
  gap?: string;
  gapOnDesktop?: string;
  textAlign?: 'left-align' | 'right-align' | 'center' | CSSGlobalRuleValue;
};

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  ${({ cols }) =>
    cols
      ? css`
          grid-template-columns: repeat(${cols}, 1fr);
        `
      : null}
  ${({ colsOnDesktop }) =>
    colsOnDesktop
      ? media.mobileMd`grid-template-columns: repeat(${colsOnDesktop}, 1fr);`
      : null}
    ${({ gap }) => (gap ? `gap: ${gap};` : null)}
    ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : null}
    ${({ gapOnDesktop }) =>
    gapOnDesktop ? media.mobileMd`gap: ${gapOnDesktop};` : null}
    ${withMargin}
`;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import { ReactComponent as CreatedLinkSuccess } from 'src/images/pageStatus/created_link_success.svg';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import React from 'react';
import Translate from 'src/components/Translate/Translate.component';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import CreateLinkInfo from 'src/pages/createLinkFlow/partials/CreateLinkInfo/CreateLinkInfo.component';
import ClientEmailCard from 'src/components/ClientEmailCard/ClientEmailCard.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const PayByLinkActionsSuccessResend: React.FC = () => {
  const navigate = useNavigate();
  const { OrderId, InviteEmailsSent, path, pathDetail } =
    useOutletContext<PayByLinkDetailsContext>();

  const lastRecipient = InviteEmailsSent.at(-1)?.Recipient ?? '';

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayByLinkHeader
        title="text.sendingBackMail"
        idLink={OrderId}
        pathDetail={pathDetail}
      />
      <ContainerPadding>
        <Flex
          flexDirection="column"
          align="center"
          gap="2.4rem"
          style={{ textAlign: 'center' }}
        >
          <CreatedLinkSuccess height="20rem" />
          <TextDefaultPage
            inviteEmail={lastRecipient}
            title="text.sendingBackLinkSuccess"
            subTitle="text.opynSentEmailToClient"
          />
        </Flex>
        <ClientEmailCard
          email={lastRecipient}
          label="lbl.payByLinkDetailEmailAddress"
        />
        <ButtonContainer>
          <Button
            onClick={() => navigate(path)}
            variant="Primary"
            minWidth="100%"
          >
            <Translate id="text.seeAllPayByLink" />
          </Button>
        </ButtonContainer>
      </ContainerPadding>
      <CreateLinkInfo resent />
    </StyleSheetManager>
  );
};

export default PayByLinkActionsSuccessResend;

import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { PaymentFlowContextType } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { H4 } from 'src/style-utils/typographic';
import { cardIsExpired } from 'src/utils/functions/cardExpired';
import MethodSelectElement from './MethodSelectElement.component';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

const SetMethodPage: React.FC = () => {
  const {
    paymentMethods,
    SelectedMethod,
    type,
    formattedAmount,
    paymentMethodBackup,
    goToStripeFormHandler,
    payNowHandler,
    confirmOrderHandler,
    ChangedMethod,
  } = useOutletContext<PaymentFlowContextType>();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const paymentIntent = useCurrentTransactionStore(
    (state) => state.paymentIntent,
  );

  const expired = cardIsExpired(paymentMethodBackup);
  const firstMethod = ChangedMethod ?? SelectedMethod;
  const isBackup =
    firstMethod?.Type === 'sepa_debit' &&
    (paymentMethodBackup === undefined || expired);
  const buttonLabel = !firstMethod
    ? 'lbl.enterMainPayment'
    : isBackup
      ? 'lbl.enterSecondPayment'
      : type === 'pay' && paymentIntent
        ? 'text.payNow'
        : !paymentIntent
          ? 'lbl.confirm&PayDate'
          : 'lbl.confirm';

  const navigate = useNavigate();
  const invalid = localStorage.getItem('opyn-invalid-payment-method');

  const onClickFooterHandler = () => {
    const cardPaymentMethod = paymentMethods.filter(
      (method) => method.Type === 'card',
    );
    if (!firstMethod || isBackup) {
      const path = isBackup ? RoutePath.setBackup : RoutePath.chooseMethod;

      cardPaymentMethod.length > 0
        ? navigate(`${RoutePath.paymentFlow}${path}`)
        : goToStripeFormHandler(isBackup);
      return;
    }
    currentOrder?.OrderId !== undefined
      ? confirmOrderHandler({
          paymentMethodId: firstMethod.Id,
          orderGuid: currentOrder?.OrderGuid,
        })
      : payNowHandler({
          paymentMethodId: firstMethod.Id,
        });
  };

  const onClickFirstHandler = () =>
    navigate(RoutePath.paymentFlow + RoutePath.chooseMethod);

  const onClickSecondHandler = () =>
    navigate(RoutePath.paymentFlow + RoutePath.setBackup);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {invalid === 'true' && (
        <Toast
          variant="error"
          title="text.cantUsePaymentMethod"
          subTitle="text.cantUsePaymentMethodSubtile"
        />
      )}
      <div className="mx-auto my-8 max-w-oxl px-6">
        <H4 className="max-w-4xl lg:text-center">
          <Translate id="text.payWithOpynTitle" />
        </H4>
        <div className="my-10 grid gap-8">
          <MethodSelectElement
            step={1}
            titleLabel="text.setMethod1.title"
            contentLabel="text.setMethod1.content"
            formattedAmount={formattedAmount}
            paymethod={firstMethod}
            onClickHandler={onClickFirstHandler}
          />
          <hr />
          <MethodSelectElement
            step={2}
            titleLabel="text.setMethod2.title"
            paymethod={paymentMethodBackup}
            contentLabel="text.setMethod2.content"
            onClickHandler={onClickSecondHandler}
          />
        </div>
        <ButtonContainer marginTop="4rem">
          <Button
            onClick={onClickFooterHandler}
            variant="Primary"
            sizeOnDesktop="medium"
            minWidth="100%"
            type="submit"
            disabled={expired}
          >
            <Translate
              id={buttonLabel}
              data={{
                paymentValue: formattedAmount,
                paymentDate: refactorFormatDate(
                  currentOrder?.FirstInstallmentDueDate,
                  'DD/MM/YYYY',
                ),
              }}
            />
          </Button>
        </ButtonContainer>
      </div>
    </StyleSheetManager>
  );
};

export default SetMethodPage;

import Translate from 'src/components/Translate/Translate.component';
import { shouldForwardProp } from 'src/style-utils/functions';
import { PSmall } from 'src/style-utils/typographic';
import { StyleSheetManager } from 'styled-components';

type Props = {
  bold?: boolean;
};

const DividerOr = ({ bold }: Props) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <div
        data-component="divider-or"
        className="my-6 flex w-full items-center"
      >
        <hr className="w-full border-b border-pgrey" />
        <PSmall bold={bold} className="px-2">
          <Translate id="lbl.or" />
        </PSmall>
        <hr className="w-full border-b border-pgrey" />
      </div>
    </StyleSheetManager>
  );
};

export default DividerOr;

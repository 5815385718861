import { ListRequestsResultsTable } from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.types';
import colors from 'src/style-utils/colors';
import { shouldForwardProp } from 'src/style-utils/functions';
import { P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { StyleSheetManager } from 'styled-components';

const InvoiceDateColumn: React.FC<ListRequestsResultsTable> = ({
  InvoiceDate,
}) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <P textColor={colors.textTableBody}>{refactorFormatDate(InvoiceDate)}</P>
    </StyleSheetManager>
  );
};

export default InvoiceDateColumn;

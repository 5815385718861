import media from 'src/style-utils/media-queries';
import styled from 'styled-components';

export const TooltipStyled = styled.div`
  --wrapper-width: 13.6rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip,
  .tooltip-left {
    &::after {
      content: '';
      position: absolute;
      bottom: calc(50% - 5px);
      left: 100%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #35302f;
    }
    word-wrap: break-word;
    translate: 0 -50%;
    top: 50%;
    right: 140%;
    width: var(--wrapper-width);
    background-color: #35302f;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 0.6rem 1.2rem;
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 150ms ease-in;
  }
  &:hover .tooltip,
  &:focus .tooltip,
  &:hover .tooltip-left,
  &:focus .tooltip-left {
    visibility: visible;
    opacity: 1;
  }

  ${media.tablet`
  .tooltip {
    --wrapper-width: 17.6rem;
    top: 125%;
    left: 50%;
    margin-top: 5px;
    translate: 0;
    margin-left: calc(-1 * var(--wrapper-width) / 2);
    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #35302f transparent;
    }
  }

  .tooltip-left {
    --wrapper-width: 17.6rem;
    top: 50%;
    left: auto;
    right: 125%;
    margin-top: 0;
    &::after {
      top: 50%;
      right: -5px;
      margin-right: -4px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent  transparent  transparent #35302f;
      transform: translateY(-50%);
    }
  }
  `}
`;

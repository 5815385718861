import colors from 'src/style-utils/colors';
import styled from 'styled-components';
import squareBoxUnchecked from 'src/images/icons/square_unchecked.svg?url';
import squareBoxChecked from 'src/images/icons/square_checked.svg?url';
import media from 'src/style-utils/media-queries';

export const CheckContainer = styled.div<{
  checked: boolean;
  fullWidth: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 1.2rem 1.6rem;
  gap: 1.6rem;
  border-radius: 0.8rem;
  background-color: ${colors.white};
  min-height: 6.8rem;
  margin-bottom: 1.6rem;
  line-height: 2rem;
  &::before {
    height: 1.6rem;
    min-width: 1.6rem;
    content: '';
    background-size: cover;
    ${({ checked }) =>
      checked
        ? `background-image: url("${squareBoxChecked}")`
        : `background-image: url("${squareBoxUnchecked}")`};
  }
  ${({ fullWidth }) =>
    !fullWidth &&
    `
    ${media.tablet`
      width: 40%;
      flex-grow: 1;
    `}
  `};
`;

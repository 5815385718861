import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import OtpForm from 'src/components/Otp/OtpForm.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import {
  performDataChangeMethodApi,
  refreshPerformOtpForDataChangeMethodApi,
} from 'src/services/dataChange/dataChange.request';
import { H4, P } from 'src/style-utils/typographic';
import { FormStyle } from 'src/style/styleInput.style';
import {
  DataChangeContext,
  PerformOtpArray,
} from '../../ChangeAccessData.types';
import { formConfigChangeOtp } from './Otp.helpers';

function parseMobileNumber(mobileNumber: string) {
  const out = mobileNumber.replace(/[A-Za-z]/g, '').replace('_', ' ');
  return out;
}
function OtpPerform() {
  const { sessionGuid, type } = useOutletContext<DataChangeContext>();
  const location = useLocation();
  const value = location?.state?.value;
  const [errorMessage, setErrorMessage] = useState('');
  const { redirect } = PerformOtpArray[type];
  const mobileNumber = parseMobileNumber(value);

  const { initialValues, resolver } = formConfigChangeOtp();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: performDataChangeMethodApi,
    onSuccess: (res) => {
      if (res.data.ResultSet) {
        navigate(redirect);
      }
      setErrorMessage('error.otpNotMatching');
    },
    onError: () => navigate(RoutePath.oops),
  });
  const { mutate: mutateRefresh, isPending: isLoadingRefresh } = useMutation({
    mutationFn: refreshPerformOtpForDataChangeMethodApi,
    onError: () => {
      navigate(RoutePath.oops);
    },
  });
  if (isLoadingRefresh) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  const onSubmit = (data: any) => {
    const dataOtp = {
      sessionGuid,
      PerformOtp: Object.values(data).join(''),
    };
    mutate({ ...dataOtp });
  };
  return (
    <>
      <H4>
        <Translate id="text.insertSixCode" />
      </H4>
      <P marginBottom="2rem">
        <Translate id="text.recoveryOtp" />
      </P>
      <FormProvider {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
          <OtpForm
            sendAgainHandler={() => mutateRefresh({ sessionGuid })}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            email={type === 'email' ? value : undefined}
            mobile={type === 'email' ? undefined : mobileNumber}
            isLoadingRefresh={isLoadingRefresh}
          />
          <ButtonContainer>
            <SubmitButton minWidth="100%" disabled={isPending}>
              <Translate id="text.continue"></Translate>
            </SubmitButton>
          </ButtonContainer>
        </FormStyle>
      </FormProvider>
    </>
  );
}

export default OtpPerform;

import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Loader from 'src/components/Loader/Loader.component';
import PhoneField from 'src/components/PhoneField/PhoneField.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { updateNewValueForDataChangeMethodApi } from 'src/services/dataChange/dataChange.request';
import {
  phoneField,
  prefix,
} from 'src/services/registration/registration.config';
import { H4, P } from 'src/style-utils/typographic';
import { FormStyle } from 'src/style/styleInput.style';
import { DataChangeContext } from '../../ChangeAccessData.types';
import { formConfigPhone } from './ChangePhone.helpers';
import servicesErrorsMap from 'src/utils/functions/servicesErrorsMap';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function ChangePhone() {
  const { initialValues, resolver } = formConfigPhone();
  const { sessionGuid, type } = useOutletContext<DataChangeContext>();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: updateNewValueForDataChangeMethodApi,
    onSuccess: (res, variables) => {
      if (res.data.ResultSet)
        navigate(
          RoutePath.changeAccessData +
            RoutePath.performOtp +
            `?sessionGuid=${sessionGuid}`,
          {
            state: {
              type,
              value: variables.NewValue,
            },
          },
        );
    },
    onError: (error: any) => {
      const errors = servicesErrorsMap(error.response, { phone: phoneField });
      for (const key in errors) {
        methods.setError(phoneField, { message: errors[key] });
      }
    },
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  if (isPending) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  const onSubmit = async (data: any) => {
    const dataPhone = {
      NewValue: `${data[prefix]}_${data[phoneField]}`,
      sessionGuid,
    };
    mutate({ ...dataPhone });
  };
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <H4>
        <Translate id="lbl.phoneNumber" />
      </H4>
      <P marginBottom="2rem">
        <Translate id="text.phoneNumberUtility" />
      </P>
      <FormProvider {...methods}>
        <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
          <PhoneField
            isPresentMessage
            label="lbl.phoneNumber"
            name={phoneField}
          />
          <ButtonContainer>
            <SubmitButton minWidth="100%" disabled={isPending}>
              <Translate id="text.continue"></Translate>
            </SubmitButton>
          </ButtonContainer>
        </FormStyle>
      </FormProvider>
    </StyleSheetManager>
  );
}

export default ChangePhone;

import Icon from 'src/components/Icon/Icon.component';
import StatusPayByLink from 'src/components/StatusPayByLink/StatusPayByLink.component';
import { PayByLinkStatus } from 'src/components/StatusPayByLink/StatusPayByLink.config';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ClockIcon } from 'src/images/icons/clock.svg';
import { PayByLinkDetailsContext } from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.types.d';
import { useOutletContext } from 'react-router-dom';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import colors from 'src/style-utils/colors';
import { Hr } from 'src/style-utils/hr';
import { H2, H3, H4, P } from 'src/style-utils/typographic';
import { Card } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import styled, { StyleSheetManager } from 'styled-components';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { useIsAtLeastTabletBreakpoint } from 'src/utils/hooks/media-queries';
import { shouldForwardProp } from 'src/style-utils/functions';

const ClampedH4 = styled(H4)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const StyledFlex66 = styled(Flex)`
  flex-basis: 66%;
`;

const StyledFlex33 = styled(Flex)`
  border-left: 1px solid ${colors.greyBorder};
  flex-basis: 33%;
`;

type Props = {
  dataLink: PayByLinkDetailsContext;
  labelNumberLink: string;
  labelExpiration: string;
};

const ResponsiveLinkDetailCard = ({
  dataLink,
  labelExpiration,
  labelNumberLink,
}: Props) => {
  const isTablet = useIsAtLeastTabletBreakpoint();
  const { madeFromPlugin } = useOutletContext<PayByLinkDetailsContext>();
  const {
    formatters: { formatCurrency },
  } = useI18n();

  const showExpiration =
    dataLink.Status === PayByLinkStatus.createdLink ||
    dataLink.Status === PayByLinkStatus.expiredLink ||
    dataLink.Status === PayByLinkStatus.wrongLink ||
    dataLink.Status === PayByLinkStatus.cancelledLink ||
    dataLink.Status === PayByLinkStatus.unsuitableLink ||
    madeFromPlugin;

  const linkID = madeFromPlugin
    ? dataLink?.PluginExternalId?.toString().padStart(5, '0')
    : dataLink?.OrderId?.toString().padStart(5, '0');

  const formattedCreationDate = {
    date: refactorFormatDate(dataLink.Created, 'DD MMMM YYYY'),
    time: refactorFormatDate(dataLink.Created, 'HH:mm'),
  };

  const formattedExpirationDate = {
    date: refactorFormatDate(dataLink.Expiration, 'DD MMMM YYYY'),
    time: refactorFormatDate(dataLink.Expiration, 'HH:mm'),
  };

  const formattedAmount = formatCurrency(dataLink.Amount);

  if (isTablet) {
    return (
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Card maxWidth="100%" padding="3.2rem">
          <Flex>
            <StyledFlex66
              flexDirection="column"
              gap="3.2rem"
              marginRight="2.4rem"
            >
              <div>
                <P>
                  <Translate id="text.client" />
                </P>
                <ClampedH4>{dataLink.BuyerName}</ClampedH4>
              </div>
              <div>
                <P>
                  <Translate id="text.total" />
                </P>
                <H3> {formattedAmount}</H3>
              </div>
            </StyledFlex66>
            <StyledFlex33
              flexDirection="column"
              gap="2.4rem"
              paddingLeft="2.4rem"
            >
              <div>
                <P style={{ marginBottom: '0.4rem' }}>
                  <Translate id="lbl.linkStatus" />
                </P>
                <StatusPayByLink statusLink={dataLink.Status} />
              </div>
              <div>
                <P style={{ marginBottom: '0.4rem' }}>
                  <Translate id={labelNumberLink} />
                </P>
                <P bold>{linkID}</P>
              </div>
              <div>
                <P style={{ marginBottom: '0.4rem' }}>
                  <Translate id="lbl.totalInstallment" />
                </P>
                <P bold>{dataLink.InstallmentCount}</P>
              </div>
              <div>
                {showExpiration ? (
                  <>
                    <P style={{ marginBottom: '0.4rem' }}>
                      <Translate id={labelExpiration} />
                    </P>
                    <Flex align="center">
                      <P bold>{formattedExpirationDate.date} </P>
                      <Icon
                        iconSize="1.6rem"
                        iconHeight="1.6rem"
                        marginLeft="1.6rem"
                        svgIconComponent={ClockIcon}
                      />
                      &nbsp;
                      <P bold>{formattedExpirationDate.time}</P>
                    </Flex>
                  </>
                ) : (
                  <>
                    <P style={{ marginBottom: '0.4rem' }}>
                      <Translate id="lbl.creationDate" />
                    </P>
                    <P bold>{formattedCreationDate.date}</P>
                  </>
                )}
              </div>
            </StyledFlex33>
          </Flex>
        </Card>
      </StyleSheetManager>
    );
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Card maxWidth="100%">
        <Flex justify="space-between" align="flex-start">
          <div>
            <P>
              <Translate id={labelNumberLink} />
            </P>
            <H4 marginBottom="1.2rem">{linkID}</H4>
          </div>
          <StatusPayByLink statusLink={dataLink.Status} />
        </Flex>
        <P>
          <Translate id="text.client" />
        </P>
        <ClampedH4 marginBottom="1.2rem">{dataLink.BuyerName}</ClampedH4>
        <P>
          <Translate id="text.total" />
        </P>
        <H2 marginBottom="1.2rem"> {formattedAmount}</H2>
        <Hr marginBlock="1.2rem" />
        <Flex gap="1.6rem">
          <div>
            <P>
              <Translate id="lbl.totalInstallment" />
            </P>
            <P bold marginBottom="1.2rem">
              {dataLink.InstallmentCount}
            </P>
          </div>
          <span style={{ background: colors.greyBorder, width: '0.1rem' }} />
          <div>
            {showExpiration ? (
              <>
                <P>
                  <Translate id={labelExpiration} />
                </P>
                <Flex align="center">
                  <P bold>{formattedExpirationDate.date} </P>
                  <Icon
                    iconSize="1.6rem"
                    iconHeight="1.6rem"
                    marginLeft="1.6rem"
                    svgIconComponent={ClockIcon}
                  />
                  &nbsp;
                  <P bold>{formattedExpirationDate.time}</P>
                </Flex>
              </>
            ) : (
              <>
                <P>
                  <Translate id="lbl.creationDate" />
                </P>
                <P bold>{formattedCreationDate.date}</P>
              </>
            )}
          </div>
        </Flex>
      </Card>
    </StyleSheetManager>
  );
};

export default ResponsiveLinkDetailCard;

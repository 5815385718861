import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import FlowHeader from 'src/components/FlowHeader/FlowHeader.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Info } from 'src/images/icons/information-icon.svg';
import { FindOutMoreEnum } from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.config';
import FindOutMoreModalLoadable from 'src/pages/onboardingFlow/variants/modal/FindOutMoreModal.loadable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { isItalian } from 'src/services/i18n/i18n.helpers';
import { useProfileStore } from 'src/store/store';
import colors from 'src/style-utils/colors';
import { H5 } from 'src/style-utils/typographic';
import {
  ColoredSection,
  FlowContainer,
  ResponsiveContainer,
} from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function SignStripe() {
  const navigate = useNavigate();
  const [showModalHeader, setShowModalHeader] = useState(false);
  const country = useProfileStore((state) => state.Country);
  const isIta = isItalian(country);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {isIta ? (
        <FlowHeader
          label="lbl.openStripeAccount"
          showWhiteSpace
          stepNumber={3}
          stepMaxNumber={3}
          showInfoButton
          infoButtonAction={() => setShowModalHeader(true)}
        />
      ) : (
        <ColoredSection bgColor="white" padding="1.6rem 0">
          <ResponsiveContainer>
            <Flex gap="1.6rem" align="center" justify="space-between">
              <H5>
                <Translate id="lbl.openStripeAccount" />
              </H5>
              <Button
                onClick={() => setShowModalHeader(true)}
                variant="LinkPrimary"
                gap="0"
                padding="0"
                minWidth="fit-content"
                rightChild={
                  <Icon
                    iconSize="3.6rem"
                    svgIconComponent={Info}
                    color={colors.lightBlueBorder}
                  />
                }
              />
            </Flex>
          </ResponsiveContainer>
        </ColoredSection>
      )}

      <FlowContainer>
        <TextDefaultPage
          title="text.openStripeAccountTitle"
          subTitle="text.openStripeAccountSubtitle"
        />
        <ButtonContainer>
          <Button onClick={() => navigate(RoutePath.financialTransaction)}>
            <Translate id="link.openStripeAccount" />
          </Button>
        </ButtonContainer>
      </FlowContainer>
      {showModalHeader && (
        <FindOutMoreModalLoadable
          buttonTitle="lbl.close"
          modalTitle="text.findOutMoreNamirialTitlePage"
          type={FindOutMoreEnum.stripe}
          handleClose={() => setShowModalHeader(false)}
          i18n
        />
      )}
    </StyleSheetManager>
  );
}

export default SignStripe;

import Translate from 'src/components/Translate/Translate.component';

import { H4, P } from 'src/style-utils/typographic';
import { ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import RemodulationRequestsTable from './partials/RemodulationRequestsTable.component';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'src/style-utils/functions';

function RemodulationRequests() {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ResponsiveContainer margin="4.8rem auto 0">
        <Flex fluid justify="space-between" flexDirection="row">
          <div>
            <H4 margin="0 0 0.8rem">
              <Translate id="remodulationRequest.title" />
            </H4>
            <P marginBottom="3.2rem">
              <Translate id="remodulationRequest.subTitle" />
            </P>
          </div>
        </Flex>
        <RemodulationRequestsTable />
      </ResponsiveContainer>
    </StyleSheetManager>
  );
}

export default RemodulationRequests;
